import fetchClient from "api/axios";

// Authorization
import store from "store";

const getCurrentUserId = () => {
  return store.getState().auth.uuid;
};

export const getPaymentMOP = async () => {
  const response = await fetchClient.get(
    `/mode-of-payment/payment/` + getCurrentUserId()
  );
  return response.status === 200 ? response.data : [];
};

export const getMOPDetails = async (id) => {
  const response = await fetchClient.get(`/mode-of-payment/info/` + id);
  return response.status === 200 ? response.data : [];
};

export const getMOPChannelsOfPlayer = async () => {
  const response = await fetchClient.get(
    `/mode-of-payment/` + getCurrentUserId()
  );
  return response.status === 200 ? response.data : [];
};

export const postMOPToPlayer = async (payload) => {
  payload.status = "ACTIVE";
  payload.uuidAccount = getCurrentUserId();

  const response = await fetchClient.post(`/mode-of-payment`, payload);
  return response.status === 200 ? true : false;
};

export const getMOPChannels = async (type) => {
  const response = await fetchClient.get(
    `/mode-of-payment/channels?type=` + type
  );
  return response.status === 200 ? response.data : [];
};

export const patchPIN = async (pin) => {
  const response = await fetchClient.patch(
    `/accounts/${getCurrentUserId()}?withdrawalPin=` + pin
  );
  return response.status === 200 ? true : false;
};

export const resetPIN = async (password, pin) => {
  const response = await fetchClient.post(
    `/p2p/withdrawal-pin/reset/${getCurrentUserId()}?password=${password}&pin=${pin}`
  );
  return response;
};

export const createMOP = async (payload) => {
  const response = await fetchClient.post(`/mode-of-payment`, payload);
  return response.status === 200 ? response.data : [];
};

export const patchMOP = async (id, attribute, value) => {
  const response = await fetchClient.patch(
    `/mode-of-payment/${id}?attribute=${attribute}&value=${value}`
  );
  return response.status === 200 ? response.data : [];
};

export const deleteMOP = async (uuidMOP) => {
  const response = await fetchClient.delete(`/mode-of-payment/${uuidMOP}`);
  return response.status === 200 ? response.data : [];
};

export const sendMessageMOP = async (id, message) => {
  const response = await fetchClient.post(`/mode-of-payment/message`, {
    transactionId: id,
    message: message,
    messageFrom: "LOADER",
  });
  return response.status === 200 ? response.data : [];
};

export const sendMessageReciverMOP = async (id, message) => {
  const response = await fetchClient.post(`/mode-of-payment/message`, {
    transactionId: id,
    message: message,
    messageFrom: "RECEIVER",
  });
  return response.status === 200 ? response.data : [];
};

export const getMessagesMOP = async (id) => {
  const response = await fetchClient.get(`/mode-of-payment/message/${id}`);
  return response.status === 200 ? response.data : [];
};

export const postCreditRequest = async (body) => {
  body.origin = "P2P";
  body.creditType = "CHIPS";
  body.transactionType = "DEPOSIT";

  const response = await fetchClient.post(`/credit`, body);
  return response.status === 200 ? response.data : { status: 412 };
};

export const postWithdrawCreditRequest = async (body) => {
  body.origin = "P2P";
  body.creditType = "CHIPS";
  body.transactionType = "WITHDRAW";

  const response = await fetchClient.post(
    `/credit?withdrawalPin=` + body.pin,
    body
  );
  return response;
};

export const updateScreenshot = async (body) => {
  const response = await fetchClient.put(`/credit/proof-of-payment`, body);
  return response.status === 200 ? true : false;
};
