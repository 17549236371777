import { useState, useEffect } from "react";
import {
  Card,
  CardHeader,
  Button,
  Row,
  Col,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Table,
  Input,
  CardBody,
} from "reactstrap";

import DataTable from "react-data-table-component";

import { columns } from "./data";

// Components
import LoadingBar from "components/LoadBars";

import { getGameSettings, patchGameSettings } from "api/video-karera";

import { nofitySuccess } from "components/Toasts";

function VideoKareraSettings() {
  const [rows, setRows] = useState([]);
  const [sync, setSync] = useState(false);
  const [modal, setModal] = useState(false);

  const [maximumBet, setMaximumBet] = useState(false);
  const [maintenanceMode, setMaintenanceMode] = useState(false);

  const [scheme1, setScheme1] = useState();
  const [scheme2, setScheme2] = useState();

  const toggle = () => setModal(!modal);

  // Table Handling
  useEffect(() => {
    getGameSettings("SCHEME").then((response) => {
      setRows(response);

      setScheme1(+response[0].value);
      setScheme2(+response[1].value);
      setSync(false);
    });

    // Getting Maximum Bet
    getGameSettings("MAXIMUM_BET").then((response) => {
      setMaximumBet(response[0].value);
    });

    // Getting Maximum Bet
    getGameSettings("MAINTENANCE_MODE").then((response) => {
      setMaintenanceMode(response[0].value);
    });
  }, [sync]);

  const refreshTable = () => {
    setSync(!sync);
  };

  const handleChange = (e) => {
    e.preventDefault();
    setScheme1(+document.getElementById("SCHEME_1").value);
    setScheme2(+document.getElementById("SCHEME_2").value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    patchGameSettings("SCHEME_1", scheme1);
    patchGameSettings("SCHEME_2", scheme2);

    nofitySuccess("Video Karera Settings Updated");
    toggle();
    refreshTable();
  };

  const handleUpdateMaximumBetAmount = (e) => {
    e.preventDefault();

    patchGameSettings("MAXIMUM_BET", maximumBet);

    nofitySuccess("Video Karera - Maximum Bet Updated");
    refreshTable();
  };

  const handleUpdateMaintenanceMode = (e) => {
    e.preventDefault();

    patchGameSettings("MAINTENANCE_MODE", e.target.checked);
    setMaintenanceMode(e.target.checked);
    nofitySuccess("Video Karera - Maintenance mode updated");
  };

  return (
    <>
      <Card className="shadow">
        <CardHeader className="border-0">
          <Row>
            <Col>
              <h1 className="mb-0">Game Settings</h1>
            </Col>
            <Col className="text-right">
              <Button onClick={toggle} color="primary">
                UPDATE
              </Button>
            </Col>
          </Row>
        </CardHeader>

        <DataTable
          columns={columns(refreshTable)}
          data={rows}
          pagination
          striped
          progressComponent={<LoadingBar />}
          progressPending={!rows.length}
        />
      </Card>

      <Row className="my-3">
        <Col>
          <Card className="shadow">
            <CardHeader className="border-0">
              <h1 className="mb-0">Maximum Bet Amount</h1>
            </CardHeader>
            <CardBody>
              <Row>
                <Col>
                  <Input
                    type="text"
                    placeholder="amount"
                    value={maximumBet}
                    onChange={(e) => setMaximumBet(e.target.value)}
                  />
                </Col>
                <Col>
                  <Button
                    color="primary"
                    onClick={handleUpdateMaximumBetAmount}
                  >
                    Update
                  </Button>
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Col>
        <Col className="mt-sm-0 mt-3">
          <Card className="shadow">
            <CardHeader className="border-0">
              <h1 className="mb-0">Maintenance Mode</h1>
            </CardHeader>
            <CardBody>
              <label className="custom-toggle">
                <input
                  defaultChecked={maintenanceMode}
                  type="checkbox"
                  className="h1"
                  onChange={handleUpdateMaintenanceMode}
                />
                <span className="custom-toggle-slider rounded-circle" />
              </label>
            </CardBody>
          </Card>
        </Col>
      </Row>

      <Modal centered isOpen={modal} toggle={toggle}>
        <ModalHeader toggle={toggle}>Game Scheme Percentage</ModalHeader>
        <ModalBody>
          <Table hover bordered>
            <thead>
              <tr>
                <th>#</th>
                <th>Scheme</th>
                <th>Value</th>
              </tr>
            </thead>
            <tbody>
              {rows.map((item) => {
                return (
                  <tr key={item.id}>
                    <td>{item.id}</td>
                    <td>
                      <h4>
                        <b> {item.name}</b>
                      </h4>
                    </td>
                    <td>
                      <Input
                        type="number"
                        id={item.name}
                        name={item.name}
                        defaultValue={item.value}
                        onChange={handleChange}
                        maxLength="2"
                      />
                    </td>
                  </tr>
                );
              })}
              <tr>
                <td></td>
                <td>TOTAL %</td>
                <td>
                  <b className="text-right text-lg">
                    <span
                      className={`text-${
                        +scheme1 + +scheme2 === 100 ? "success" : "danger"
                      }`}
                    >
                      {+scheme1 + +scheme2}
                    </span>
                  </b>
                </td>
              </tr>
            </tbody>
          </Table>
        </ModalBody>
        <ModalFooter>
          <Button
            color="primary"
            onClick={handleSubmit}
            disabled={scheme1 + scheme2 !== 100}
          >
            Update
          </Button>
          <Button color="secondary" onClick={toggle}>
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
    </>
  );
}

export default VideoKareraSettings;
