let INIT_STATE = {
  user: "",
  pendingCreditRequest: { payload: [] },
  commission: 0,
  commissionCoins: 0,
};

const entitiesReducer = (state = INIT_STATE, action) => {
  const payload = action.payload;

  switch (action.type) {
    case "SIGNOUT":
      return {
        user: {
          accountType: "",
          permissions: "",
          name: "",
          role: "",
          username: "",
          mobile: "",
          referralCode: "",
          chips: 0,
          coins: 0,
          tipsCoins: 0,
          email: "",
          activePlayerCount: 0,
          inActivePlayerCount: 0,
          withdrawalPin: "",
        },
        pendingCreditRequest: { payload: [] },
        commission: 0,
        commissionCoins: 0,
      };
    case "UPDATE":
      return {
        ...state,
        user: {
          accountType: payload.accountType,
          permissions: payload.permissions,
          name: payload.name,
          role: payload.role,
          username: payload.username,
          mobile: payload.mobileNumber,
          referralCode: payload.referralCode,
          chips: payload.balanceChips,
          coins: payload.balanceCoin,
          tipsCoins: payload.balanceTipCoins,
          email: payload.email,
          activePlayerCount: payload.activePlayerCount,
          inActivePlayerCount: payload.inActivePlayerCount,
          withdrawalPin: payload.withdrawalPin,
        },
      };
    case "COMMISION":
      return {
        ...state,
        commission: payload,
      };
    case "COMMISION_COINS":
      return {
        ...state,
        commissionCoins: payload,
      };
    case "CREDIT_PENDING":
      return {
        ...state,
        pendingCreditRequest: {
          payload,
        },
      };
    case "ACCOUNT_TREE":
      return {
        ...state,
        accountTree: {
          payload,
        },
      };
    case "ACCESS_LEVEL":
      return {
        ...state,
        accessLevel: {
          payload,
        },
      };
    case "CHIP_TRANSACTIONS":
      return {
        ...state,
        chipTransactions: {
          payload,
        },
      };
    case "COIN_TRANSACTIONS":
      return {
        ...state,
        coinTransactions: {
          payload,
        },
      };
    case "SITE_OWNER":
      return {
        ...state,
        siteOwners: {
          payload,
        },
      };
    case "MASTER":
      return {
        ...state,
        masters: {
          payload,
        },
      };
    case "AGENT":
      return {
        ...state,
        agents: {
          payload,
        },
      };
    case "ACCOUNTS_UPPER_ACTIVE":
      return {
        ...state,
        accountsByUpperActive: {
          payload,
        },
      };
    case "ACCOUNTS_UPPER_INACTIVE":
      return {
        ...state,
        accountsByUpperInactive: {
          payload,
        },
      };
    default:
      return state;
  }
};

export default entitiesReducer;
