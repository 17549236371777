import { useEffect, useRef } from "react";
import { Route, Switch, useHistory } from "react-router-dom";

// core components
import callAgentIcon from "assets/img/homepage/call-center-agent.png";
import downloadAppHere from "assets/img/homepage/download-app-here.png";
import LandingPageFooter from "components/Footers/LandingPageFooter";
import LandingPageNavbar from "components/Navbars/AuthNavbar.js";
import Sidebar from "components/Sidebar/SideBarHome";
import homepageRoutes from "views/homepage/homepage-routes";

// services
import { syncUserData } from "api/AccountAPI";
import AccountDetection from "features/websocket/AccountDetection";
import { useDispatch, useSelector } from "react-redux";
import GoldenGateXVendorGameListPage from "views/homepage/pages/golden-gatex/vendor-games-page";
import GoldenGateXPage from "views/homepage/pages/golden-gatex/page";

const Home = (props) => {
  const mainContent = useRef(null);

  const history = useHistory();
  const dispatch = useDispatch();
  const isRolePlayer = useSelector((state) => state.ui.userType) === "PLAYER";
  const isLoggedIn =
    useSelector((state) => state.ui.loginOutcome) === "LOGGED_IN";

  const getRoutes = (routes) => {
    return routes.map((prop, key) => {
      return <Route path={prop.path} component={prop.component} key={key} />;
    });
  };

  useEffect(() => {
    if (isLoggedIn) {
      if (!isRolePlayer) {
        history.push("/portal/index");
      }

      setInterval(() => {
        syncUserData(dispatch);
      }, 6000);
    }

    if (window.location.pathname === "/") {
      history.push("/home");
    }

    // eslint-disable-next-line
  }, []);

  return (
    <div className="relative w-full h-full">
      <AccountDetection />
      <LandingPageNavbar />

      <div className="home-content">
        <Sidebar
          {...props}
          routes={homepageRoutes}
          logo={{
            innerLink: "/portal/index",
            imgSrc: require("../assets/img/brand/iplayevo.png").default,
            imgAlt: "...",
          }}
        />
        <div
          className="main-content main-content-home min-h-screen"
          ref={mainContent}
          style={{
            background: "#0E121A",
            backgroundSize: "cover",
            backgroundPosition: "center top",
          }}
        >
          <Switch>{getRoutes(homepageRoutes)}</Switch>

          <Route
            path="/golden-gatex/:vendor"
            component={GoldenGateXVendorGameListPage}
          />
          <Route path="/game/golden-gatex" component={GoldenGateXPage} />

          {/* <Redirect from="*" to="/home" /> */}
          <LandingPageFooter />
        </div>
      </div>

      <div className="sm:hidden flex flex-col gap-3 fixed bottom-3 left-3">
        <a href={process.env.REACT_APP_APP_URL}>
          <img alt="..." src={downloadAppHere} className="w-16 h-16" />
        </a>
        <a href="/player/contact">
          <img alt="..." src={callAgentIcon} className="w-16 h-16" />
        </a>
      </div>

      <div className="hidden sm:block flex flex-col gap-3 fixed bottom-4 right-4">
        <a href="/player/contact">
          <img alt="..." src={callAgentIcon} className="w-24 h-24" />
        </a>
      </div>
    </div>
  );
};

export default Home;
