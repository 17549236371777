import { commas } from "views/shared/utils";

import EditForm from "components/Modals/edit";
import { Button, Row, Col } from "reactstrap";

import { Link } from "react-router-dom";

export const columns = (handleSync, isEditEnabled) => [
  {
    name: "Action",
    selector: (row) => row.username,
    sortable: true,
    width: "150px",
    cell: (row) => (
      <Row>
        {isEditEnabled && (
          <Col md={12} className="mb-2">
            <EditForm sync={handleSync} row={row}></EditForm>
          </Col>
        )}

        {row.role === "PLAYER" ? (
          <Col md={12} className="mb-2">
            <Link to={"/portal/history/bet/" + row.username}>
              <Button size="sm" color="primary" outline className="w-100">
                <i className="fas fa-history" /> BETS
              </Button>
            </Link>
          </Col>
        ) : null}
        {row.role !== "PLAYER" ? (
          <Col md={12}>
            <Link to={"/portal/downlines/" + row.username}>
              <Button size="sm" color="primary" outline className="w-100">
                <i className="fas fa-graph" /> DOWNLINES
              </Button>
            </Link>
          </Col>
        ) : null}
      </Row>
    ),
  },
  {
    name: "Username",
    selector: (row) => row.username,
    sortable: true,
    cell: (row) => <b>{row.username}</b>,
  },
  {
    name: "Name",
    sortable: true,
    selector: (row) => row.name,
  },
  {
    name: "Chips",
    sortable: true,
    selector: (row) => commas(row.balanceChips),
  },
  {
    name: "Coins",
    sortable: true,
    selector: (row) => commas(row.balanceCoin),
  },
  {
    name: "Mobile Number",
    sortable: true,
    selector: (row) => row.mobileNumber,
  },
  {
    name: "Email Address",
    sortable: true,
    selector: (row) => row.email,
  },
  {
    name: "Date Registered",
    sortable: true,
    selector: (row) => row.dateRegistered,
  },
];
