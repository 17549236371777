import Swal from "sweetalert2";

export const alertDialogTheme = (title, confirmLabel, type) => {
  Swal.fire({
    confirmButtonText: confirmLabel,
    title: title,
    icon: type,
    color: "#fff",
    confirmButtonColor: "rgb(157 134 0)",
    background:
      "radial-gradient(79.09% 79.09% at 50% 14.18%, #4e4d4c 0%, #060606 100%)",
  });
};
