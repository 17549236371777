import { useState } from "react";

// reactstrap components
import {
  Card,
  CardBody,
  CardTitle,
  Container,
  Row,
  Col,
  Badge,
  Spinner,
} from "reactstrap";
import { Link } from "react-router-dom";

import { useSelector, useDispatch } from "react-redux";
import SearchModal from "components/Modals/search";

import { formatWithTwoDecimal } from "views/shared/utils";

import { getCurrentWeekCommision, getCoinCommissions } from "api/Commision";
import { putBalanceExchange } from "api/Credit";
import { syncUserData } from "api/AccountAPI";
import { getCommissionProfit, getCoinsCommission } from "actions";

// images
import chipsImage from "assets/img/chips-icon.png";
import coinsImage from "assets/img/coins-icon.png";
import HeaderBg from "../../assets/img/header-bg.png";

// alert prompt
import Swal from "sweetalert2";

import { commas } from "views/shared/utils";

const Header = () => {
  const commissionChips = useSelector((state) =>
    commas(formatWithTwoDecimal(state.entities.commission))
  );

  const balanceCoins = useSelector((state) =>
    commas(formatWithTwoDecimal(state.entities.user.coins))
  );
  const balanceChips = useSelector((state) =>
    commas(state.entities.user.chips)
  );
  const tipsCoins = useSelector((state) =>
    commas(state.entities.user.tipsCoins)
  );

  const username = useSelector((state) => state.entities.user.username);
  const role = useSelector((state) => state.entities.user.role);
  const dispatch = useDispatch();

  const [refreshChips, setRefreshChips] = useState(false);
  const [refreshCoins, setRefreshCoins] = useState(false);

  const [refreshBalance, setRefreshBalance] = useState(false);

  const handleChipsRefresh = () => {
    setRefreshChips(true);
    getCurrentWeekCommision(username).then((response) => {
      dispatch(getCommissionProfit(response.netProfit));
      setRefreshChips(false);
    });
  };

  const handleCoinsRefresh = () => {
    setRefreshCoins(true);
    getCoinCommissions(username).then((response) => {
      dispatch(getCoinsCommission(response.netProfit));
      setRefreshCoins(false);
    });
  };

  const handleRefreshBalance = () => {
    setRefreshBalance(true);
    syncUserData(dispatch);
    setRefreshBalance(false);
  };

  const exchangeCredit = (e, creditType) => {
    e.preventDefault();

    const titleCredit = creditType === "CHIPS" ? "COINS" : "CHIPS";

    Swal.fire({
      title: `Convert ${creditType} to ${titleCredit}`,
      message: `Balance ${creditType} ${commas(
        creditType === "CHIPS" ? balanceChips : balanceCoins
      )}`,
      input: "number",
      html: `
        <div class="mb-2">
          Balance ${commas(
            creditType === "CHIPS" ? balanceChips : balanceCoins
          )}
        </div>
        <div class="d-flex align-items-center justify-content-center">
          <img src=${
            creditType === "CHIPS" ? chipsImage : coinsImage
          } class="mr-2" style="float: left; width: 10%;">
          <i class="fa fa-arrow-right mr-2" aria-hidden="true"></i>
          <img src=${
            creditType !== "CHIPS" ? chipsImage : coinsImage
          } style="float: left; width: 10%;">
        </div>
      `,
      inputAttributes: {
        autocapitalize: "off",
        min: 1,
        max: 1000000000,
        step: 1,
        pattern: "[0-9]{10}",
      },
      inputPlaceholder: "Enter amount here...",
      showCancelButton: true,
      confirmButtonText: "Exchange",
      showLoaderOnConfirm: true,
      preConfirm: (amount) => {
        return putBalanceExchange(titleCredit, amount)
          .then((response) => {
            handleRefreshBalance(dispatch);
          })
          .catch((error) => {
            Swal.showValidationMessage(`${error.response.data.message}`);
          });
      },
      allowOutsideClick: () => !Swal.isLoading(),
    });
  };

  const convertTips = (e) => {
    e.preventDefault();

    Swal.fire({
      title: `Convert Tips to Balance`,
      input: "number",
      html: `
        <div class="mb-2">
          Tips Balance ${commas(tipsCoins)}
        </div>
        <div class="d-flex align-items-center justify-content-center">
          <img src=${coinsImage} class="mr-2" style="float: left; width: 10%;">
          <i class="fa fa-arrow-right mr-2" aria-hidden="true"></i>
          <img src=${coinsImage} style="float: left; width: 10%;">
        </div>
      `,
      inputAttributes: {
        autocapitalize: "off",
        min: 1,
        max: 1000000000,
        step: 1,
        pattern: "[0-9]{10}",
      },
      inputPlaceholder: "Enter amount here...",
      showCancelButton: true,
      confirmButtonText: "Convert",
      showLoaderOnConfirm: true,
      preConfirm: (amount) => {
        return putBalanceExchange("TIP_COINS", amount)
          .then((response) => {
            handleRefreshBalance(dispatch);
          })
          .catch((error) => {
            Swal.showValidationMessage(`${error.response.data.message}`);
          });
      },
      allowOutsideClick: () => !Swal.isLoading(),
    });
  };

  const placeBadge = (strNumber) => {
    return (
      <Badge color="" className="badge-dot">
        <i
          className={`bg-${
            strNumber && strNumber.includes("-") ? "danger" : "success"
          }`}
        />
      </Badge>
    );
  };

  const refreshAndExchange = (credit) => {
    return (
      <div className="d-flex">
        <div className="w-50">
          <p
            className="mt-3 mb-0 text-muted text-sm pointer"
            onClick={handleRefreshBalance}
          >
            <span className="text-success mr-2">
              {refreshBalance ? (
                <Spinner color="primary" size="sm">
                  Loading...
                </Spinner>
              ) : (
                <i className="fa fa-refresh"></i>
              )}
            </span>
            <span className="text-nowrap">Refresh</span>
          </p>
        </div>
        <div className="w-50">
          <p
            className="mt-3 mb-0 text-muted text-sm"
            onClick={(e) => exchangeCredit(e, credit)}
          >
            <span className="text-info mr-2">
              <i className="fa fa-money" aria-hidden="true"></i>
            </span>
            <span className="text-nowrap pointer">Convert</span>
          </p>
        </div>
      </div>
    );
  };

  const balanceCard = (balance, image, tag) => {
    return (
      <Col lg="6" xl="3">
        <Card className="card-stats mb-4 mb-xl-0">
          <CardBody>
            <Row>
              <div className="col">
                <CardTitle tag="h5" className="text-uppercase text-muted mb-0">
                  Balance
                </CardTitle>
                <span className="h2 font-weight-bold mb-0">
                  {placeBadge(balance)}
                  {balance}
                </span>
              </div>
              <Col className="col-auto">
                <div className="icon text-white rounded-circle shadow">
                  <Link to="/portal/active-accounts">
                    <img src={image} alt="chipsImage" className="w-100" />
                  </Link>
                </div>
              </Col>
            </Row>
            {refreshAndExchange(tag)}
          </CardBody>
        </Card>
      </Col>
    );
  };

  const commissionCard = (commission, image, refresh, handleRefresh) => {
    return (
      <Col lg="6" xl="3">
        <Card className="card-stats mb-4 mb-xl-0 mt-3 mt-md-0">
          <CardBody>
            <Row>
              <div className="col">
                <CardTitle tag="h5" className="text-uppercase text-muted mb-0">
                  Commission
                </CardTitle>
                <span className="h2 font-weight-bold mb-0">
                  {placeBadge(commission)}
                  {commission}
                </span>
              </div>
              <Col className="col-auto">
                <div className="icon text-white rounded-circle shadow">
                  <Link to="/portal/reports/commission/summary">
                    <img src={image} alt="chipsImage" className="w-100" />
                  </Link>
                </div>
              </Col>
            </Row>

            <p
              className="mt-3 mb-0 text-muted text-sm pointer"
              onClick={handleRefresh}
            >
              <span className="text-success mr-2">
                {refresh ? (
                  <Spinner color="primary" size="sm">
                    Loading...
                  </Spinner>
                ) : (
                  <i className="fa fa-refresh"></i>
                )}
              </span>
              <span className="text-nowrap">Refresh Commission</span>
            </p>
          </CardBody>
        </Card>
      </Col>
    );
  };

  return (
    <>
      <div
        className="header pb-8 pt-md-7"
        style={{
          backgroundImage:
            "url(" + HeaderBg + "), linear-gradient(#eb01a5, #d13531)",
          backgroundSize: "cover",
          backgroundPosition: "center top",
        }}
      >
        <Container fluid>
          <div className="header-body">
            <Row>
              <Col md={12} className="d-md-none text-center mt-3">
                <span className="w-100 mx-auto">
                  <SearchModal />
                </span>
              </Col>
              {commissionCard(
                commissionChips,
                chipsImage,
                refreshChips,
                handleChipsRefresh
              )}
              {commissionCard(
                commissionChips,
                coinsImage,
                refreshCoins,
                handleCoinsRefresh
              )}
              {balanceCard(balanceChips, chipsImage, "CHIPS")}
              {balanceCard(balanceCoins, coinsImage, "COINS")}

              {role === "ADMIN" && (
                <Col lg="6" xl="3" className="mt-3">
                  <Card className="card-stats mb-4 mb-xl-0">
                    <CardBody>
                      <Row>
                        <div className="col">
                          <CardTitle
                            tag="h5"
                            className="text-uppercase text-muted mb-0"
                          >
                            Tips Coins
                          </CardTitle>
                          <span className="h2 font-weight-bold mb-0">
                            {placeBadge(tipsCoins)}
                            {tipsCoins}
                          </span>
                        </div>
                        <Col className="col-auto">
                          <div className="icon text-white rounded-circle shadow">
                            <Link to="/portal/active-accounts">
                              <img
                                src={coinsImage}
                                alt="chipsImage"
                                className="w-100"
                              />
                            </Link>
                          </div>
                        </Col>
                      </Row>
                      <div className="d-flex">
                        <div className="w-50">
                          <p
                            className="mt-3 mb-0 text-muted text-sm pointer"
                            onClick={handleRefreshBalance}
                          >
                            <span className="text-success mr-2">
                              {refreshBalance ? (
                                <Spinner color="primary" size="sm">
                                  Loading...
                                </Spinner>
                              ) : (
                                <i className="fa fa-refresh"></i>
                              )}
                            </span>
                            <span className="text-nowrap">Refresh</span>
                          </p>
                        </div>
                        <div className="w-50">
                          <p
                            className="mt-3 mb-0 text-muted text-sm"
                            onClick={(e) => convertTips(e)}
                          >
                            <span className="text-info mr-2">
                              <i className="fa fa-money" aria-hidden="true"></i>
                            </span>
                            <span className="text-nowrap pointer">Convert</span>
                          </p>
                        </div>
                      </div>
                    </CardBody>
                  </Card>
                </Col>
              )}
            </Row>
          </div>
        </Container>
      </div>
    </>
  );
};

export default Header;
