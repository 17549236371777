import { Carousel } from "react-responsive-carousel";
import { useHistory } from "react-router-dom";

import { Card, Col, Row } from "reactstrap";

// images
import {
  Banner1,
  Banner2,
  Banner3,
  Banner4,
  Banner5,
  cash,
  subHeadingImage,
  treasure,
  withdraw,
} from "assets/img/homepage";
import "react-responsive-carousel/lib/styles/carousel.min.css";

import { bombBanner } from "assets/img/homepage/dodge-bomb";
import { useSelector } from "react-redux";
import { useMediaQuery } from "react-responsive";
import Swal from "sweetalert2";

const alertDialog = (title, confirmLabel, type) => {
  Swal.fire({
    confirmButtonText: confirmLabel,
    title: title,
    icon: type,
    color: "#fff",
    confirmButtonColor: "rgb(157 134 0)",
    background:
      "radial-gradient(79.09% 79.09% at 50% 14.18%, #4e4d4c 0%, #060606 100%)",
  });
};

const Banners = () => {
  const history = useHistory();
  const isLoggedIn = useSelector(
    (state) => state.ui.loginOutcome === "LOGGED_IN"
  );
  const isPortrait = useMediaQuery({ query: "(orientation: portrait)" });

  const handleWithdraw = () => {
    if (!isLoggedIn) {
      alertDialog("Please login to continue", "Okay", "warning");
    } else {
      history.push("/player/cashier/withdraw");
    }
  };

  const handleDeposit = () => {
    if (!isLoggedIn) {
      alertDialog("Please login to continue", "Okay", "warning");
    } else {
      history.push("/player/cashier/deposit");
    }
  };

  const banners = [bombBanner, Banner2, Banner4, Banner3, Banner1, Banner5];

  return (
    <section className="landing-banner w-100">
      <Card
        style={{
          background:
            "linear-gradient(90deg, rgb(25, 30, 44) 0%, rgb(56 56 56 / 49%) 48.79%, rgb(25 30 44 / 69%) 70%) 0% 0% / auto, url('" +
            subHeadingImage +
            "')",
          backgroundSize: "auto",
        }}
        className="w-100 text-center"
      >
        <div className="welcome-marquee">
          Welcome {!isPortrait && " to iPLAYEVO!"}
          {!isLoggedIn && (
            <b className="ml-2">
              <a href="/auth/register" className="font-gold text-underline">
                JOIN NOW
              </a>
            </b>
          )}
        </div>
      </Card>

      <div className="flex items-center xl:h-[30rem] overflow-hidden relative">
        <Carousel
          autoPlay={true}
          interval={6000}
          infiniteLoop={true}
          showStatus={false}
          showIndicators={true}
          showThumbs={false}
          dynamicHeight={true}
        >
          {banners.map((banner, index) => (
            <div key={index}>
              <img
                alt="..."
                className="object-cover object-center"
                src={banner}
              />
            </div>
          ))}
        </Carousel>
      </div>

      <section className="bg-btn mt--1-px">
        <Row className="mx-0 py-2">
          <Col lg={4} md={4} sm={4} xs={4}>
            <Row className="features pointer d-flex align-items-center">
              <Col lg={4} md={8} xs={12} className="text-lg-right">
                <img alt="..." src={treasure} />
              </Col>
              <Col lg={8} md={12} xs={12}>
                <a href={process.env.REACT_APP_APP_URL}>
                  <span className="d-flex justify-content-center justify-content-md-start title pointer">
                    Join <span className="d-none d-sm-block ml-2">Gaming</span>
                  </span>
                  <span className="d-none d-sm-block text-white">
                    Quick & Easy Sign Up!
                  </span>
                </a>
              </Col>
            </Row>
          </Col>
          <Col lg={4} md={4} sm={4} xs={4}>
            <Row
              className="features pointer d-flex align-items-center"
              onClick={handleDeposit}
            >
              <Col lg={4} md={8} xs={12} className="text-lg-right">
                <img alt="..." src={cash} />
              </Col>
              <Col lg={8} md={12} xs={12}>
                <span className="title pointer">Deposit</span>
                <span className="d-none d-sm-block text-white">
                  Fast & Secure Deposit Time!
                </span>
              </Col>
            </Row>
          </Col>
          <Col lg={4} md={4} sm={4} xs={4}>
            <Row
              className="features d-flex align-items-center pointer"
              onClick={handleWithdraw}
            >
              <Col lg={4} md={8} xs={12} className="text-lg-right">
                <img alt="..." src={withdraw} />
              </Col>
              <Col lg={8} md={12} xs={12}>
                <span className="title pointer">Withdraw</span>
                <span className="d-none d-sm-block text-white">
                  Claim Your Winnings Now!
                </span>
              </Col>
            </Row>
          </Col>
        </Row>
      </section>
    </section>
  );
};

export default Banners;
