import { useEffect, useState } from "react";
import Header from "components/Headers/Header.js";
import { Card, CardHeader, Row, Container } from "reactstrap";

import DataTable from "react-data-table-component";
import DataTableExtensions from "react-data-table-component-extensions";
import { columns } from "./data";

import Filter from "components/Filters";
import { todaysDefaultFilter } from "components/Filters/default";

import { useSelector } from "react-redux";
import { getLoginLogs } from "api/AccountAPI";

function UserLoginHistory() {
  const [data, setData] = useState([]);
  const [sync, setSync] = useState(true);

  const currentUsername = useSelector((state) => state.entities.user.username);
  const [filter, setFilter] = useState(todaysDefaultFilter(currentUsername));

  const toggleSync = () => {
    setSync(!sync);
  };

  // Table Handling
  useEffect(() => {
    getLoginLogs(filter).then((response) => {
      setSync(false);
      setData(response);
    });

    // eslint-disable-next-line
  }, [sync]);

  const tableData = {
    columns,
    data,
  };

  return (
    <>
      <Header />

      <Container className="mt--7" fluid>
        <Row>
          <div className="col">
            <Card className="shadow">
              <CardHeader className="border-0">
                <h3 className="mb-0">User Login History</h3>
                <Filter
                  filter={setFilter}
                  username={currentUsername}
                  sync={toggleSync}
                  searchToggle={sync}
                  noUsername
                />
              </CardHeader>
              <DataTableExtensions {...tableData}>
                <DataTable
                  columns={columns}
                  data={data}
                  noHeader
                  defaultSortField="id"
                  defaultSortAsc={true}
                  pagination
                  highlightOnHover
                  striped
                />
              </DataTableExtensions>
            </Card>
          </div>
        </Row>
      </Container>
    </>
  );
}

export default UserLoginHistory;
