import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import useSWR from "swr";
import { fetcher } from "./api";
import LoadingBar from "components/LoadBars";
import LoadingSpinner from "components/LoadBars/loading-spinner";

const GoldenGateXPage = () => {
  const { data, isLoading } = useSWR("/vendors", fetcher);
  const history = useHistory();

  if (isLoading) {
    return (
      <div className="mt-24 p-3">
        <LoadingSpinner />
      </div>
    );
  }

  return (
    <div className="mt-24 p-3">
      <div className="grid grid-cols-4 gap-4">
        {data &&
          data.map((vendor, index) => (
            <div key={index}>
              <div
                onClick={() =>
                  history.push(`/golden-gatex/${vendor.vendorCode}`)
                }
                class="block max-w-sm p-4 border border-gray-200 rounded-lg shadow hover:bg-gray-100 bg-gradient-to-tr from-gray-800 to-gray-600"
              >
                <h5 class="uppercase text-7xl text-white">{vendor.name}</h5>
                <p class="font-normal text-white mb-0">{vendor.vendorCode}</p>
              </div>
            </div>
          ))}
      </div>
    </div>
  );
};

export default GoldenGateXPage;
