import React, { useState } from "react";
import {
  Button,
  Modal,
  FormGroup,
  InputGroup,
  Input,
  ModalHeader,
  ModalBody,
  Form,
  Label,
  InputGroupAddon,
  InputGroupText,
  Col,
} from "reactstrap";

import Select from "react-select";
import { useSelector } from "react-redux";

import { bankTypes, electronicWalletTypes } from "./constants.js";
import { nofitySuccess, notifyDanger, notifyWarning } from "components/Toasts";

import { createMOP } from "api/p2p";

const CreateMopModal = (props) => {
  const [isFormOpened, setIsFormOpened] = useState(false);

  const [bankType, setBankType] = useState();
  const [electronicWalletType, setelectronicWalletType] = useState();
  const [walletType, setWalletType] = useState();

  const [accountName, setAccountName] = useState();
  const [accountNumber, setAccountNumber] = useState();
  const [minimum, setMinimum] = useState();
  const [maximum, setMaximum] = useState();
  const [remarks, setRemarks] = useState();

  const accountUUID = useSelector((state) => state.auth.uuid);

  const refreshTable = () => {
    props.sync();
  };

  const closeForm = () => {
    setIsFormOpened(false);
  };

  const submitForm = (e) => {
    e.preventDefault();

    if (!isInputsValid()) {
      notifyWarning(
        "Form submission failed",
        "Please fill all required fields"
      );
      return;
    } else if (minimum > maximum) {
      notifyWarning(
        "Form submission failed",
        "Minimum must be greater than maximum"
      );
      return;
    }

    createMOP({
      accountName: accountName,
      accountNumber: accountNumber,
      bankType: bankType?.value,
      electronicWalletType: electronicWalletType?.value,
      minimum: minimum,
      maximum: maximum,
      status: "ACTIVE",
      uuidAccount: accountUUID,
      type: walletType,
      remarks: remarks,
    }).then((response) => {
      if (response.statusCode === "OK") {
        nofitySuccess(`MOP created ${accountName} w/ #${accountNumber}`);
        refreshTable();
      } else {
        notifyDanger(response.message);
      }
    });
    closeForm();
  };

  const isInputsValid = () => {
    if (
      !accountName ||
      !walletType ||
      !accountName ||
      !accountNumber ||
      !minimum ||
      !maximum
    ) {
      return false;
    }

    if (walletType === "BANK") {
      if (!bankType) {
        return false;
      }
    } else {
      if (!electronicWalletType) {
        return false;
      }
    }

    return true;
  };

  const requiredAsterisk = <span className="text-danger">*</span>;

  return (
    <Button
      className="btn-icon btn-3 mt-3 ml-0 mt-md-0"
      color="primary"
      type="button"
      onClick={() => setIsFormOpened(true)}
      outline
    >
      <span className="btn-inner--icon">
        <i className="fa fa-plus"></i>
      </span>
      <span className="btn-inner--text">Create</span>

      <Modal className="modal-dialog-centered" isOpen={isFormOpened}>
        <ModalHeader toggle={closeForm}>Create Mode of Payment</ModalHeader>
        <ModalBody>
          <Form role="form" onSubmit={submitForm}>
            <FormGroup row>
              <Label md={12}>Wallet Type{requiredAsterisk}</Label>

              <Col md={6}>
                <div className="custom-control custom-radio custom-control-inline">
                  <Input
                    className="custom-control-input"
                    id="customRadioInline1"
                    name="walletType"
                    type="radio"
                    onClick={() => setWalletType("BANK")}
                  />
                  <label
                    className="custom-control-label"
                    htmlFor="customRadioInline1"
                  >
                    Bank
                  </label>
                </div>
              </Col>
              <Col md={6}>
                <div className="custom-control custom-radio custom-control-inline">
                  <Input
                    className="custom-control-input"
                    id="customRadioInline2"
                    name="walletType"
                    type="radio"
                    onClick={() => setWalletType("E_WALLET")}
                  />
                  <label
                    className="custom-control-label"
                    htmlFor="customRadioInline2"
                  >
                    E-Wallet
                  </label>
                </div>
              </Col>
            </FormGroup>
            {walletType === "BANK" && (
              <FormGroup>
                <Label>Bank{requiredAsterisk}</Label>
                <Select
                  invalid
                  className="react-select info"
                  classNamePrefix="react-select"
                  placeholder="Choose Account Here"
                  closeMenuOnSelect={true}
                  value={bankType}
                  onChange={(value) => setBankType(value)}
                  options={bankTypes}
                  required={walletType === "BANK"}
                />
              </FormGroup>
            )}
            {walletType === "E_WALLET" && (
              <FormGroup>
                <Label>E-Wallet{requiredAsterisk}</Label>
                <Select
                  invalid
                  className="react-select info"
                  classNamePrefix="react-select"
                  placeholder="Choose Account Here"
                  closeMenuOnSelect={true}
                  value={electronicWalletType}
                  onChange={(value) => setelectronicWalletType(value)}
                  options={electronicWalletTypes}
                  required={walletType === "E_WALLET"}
                />
              </FormGroup>
            )}
            <FormGroup>
              <Label>Account Name{requiredAsterisk}</Label>
              <Input
                placeholder="Account Name"
                type="text"
                autoComplete="off"
                onChange={(e) => setAccountName(e.target.value)}
                required
              />
            </FormGroup>
            <FormGroup>
              <Label>Account Number{requiredAsterisk}</Label>
              <Input
                placeholder="Account Number"
                type="text"
                autoComplete="off"
                onChange={(e) => setAccountNumber(e.target.value)}
                required
              />
            </FormGroup>
            <FormGroup row>
              <Col md={6}>
                <Label>Minimum{requiredAsterisk}</Label>
                <InputGroup className="input-group-alternative border">
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                      <i className="ni ni-bold-down" />
                    </InputGroupText>
                  </InputGroupAddon>
                  <Input
                    placeholder="Minimum Amount"
                    type="number"
                    autoComplete="off"
                    minLength={1}
                    required
                    onChange={(e) => {
                      setMinimum(e.target.value);
                    }}
                  />
                </InputGroup>
              </Col>
              <Col md={6}>
                <Label>Maximum{requiredAsterisk}</Label>
                <InputGroup className="input-group-alternative border">
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                      <i className="ni ni-bold-up" />
                    </InputGroupText>
                  </InputGroupAddon>
                  <Input
                    placeholder="Maximum Amount"
                    type="number"
                    autoComplete="off"
                    minLength={1}
                    required
                    onChange={(e) => {
                      setMaximum(e.target.value);
                    }}
                  />
                </InputGroup>
              </Col>
            </FormGroup>
            <FormGroup>
              <Label>Remarks</Label>
              <Input
                placeholder="(Optional)"
                type="text"
                autoComplete="off"
                onChange={(e) => setRemarks(e.target.value)}
              />
            </FormGroup>
            <FormGroup className="text-right mb-0">
              <Button
                color="secondary"
                data-dismiss="modal"
                type="button"
                onClick={closeForm}
              >
                Close
              </Button>
              <Button
                className="mutted"
                color="primary"
                type="button"
                onClick={submitForm}
              >
                Submit
              </Button>
            </FormGroup>
          </Form>
        </ModalBody>
      </Modal>
    </Button>
  );
};

export default CreateMopModal;
