import { useState, useEffect } from "react";
import { Card, CardBody, Input, FormGroup, Label, Row, Col } from "reactstrap";

import Select from "react-select";
import { getMOPChannels } from "api/p2p";

const NewAccountOptions = ({ onChange, gcashOnly = false }) => {
  const [walletType, setWalletType] = useState(null);
  const [channels, setChannels] = useState([]);
  const [selectedType, setSelectedType] = useState();
  const [isTypeSelected, setIsTypeSelected] = useState();

  const [mopAccount, setMOPAccount] = useState({
    type: null,
    accountName: null,
    accountNumber: null,
    electronicWalletType: null,
    bankType: null,
    contactNumber: null,
    bankBranch: null,
  });

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setMOPAccount((prevInquiry) => ({ ...prevInquiry, [name]: value }));
  };

  const handlelectronicWalletTypeChange = (type) => {
    setWalletType(type);
    setMOPAccount({ ...mopAccount, type: type });

    getMOPChannels(type).then((response) => {
      response = response.filter((channel) => {
        if (gcashOnly) {
          return channel.value === "GCASH";
        }
        return true;
      });
      setChannels(response);
    });
  };

  const handleTypeChange = (type) => {
    setIsTypeSelected(true);
    setSelectedType(type);

    if (walletType === "BANK") {
      setMOPAccount({ ...mopAccount, bankType: type.value });
    } else {
      setMOPAccount({ ...mopAccount, electronicWalletType: type.value });
    }
  };

  useEffect(() => {
    onChange(mopAccount);
  }, [mopAccount, onChange]);

  return (
    <>
      <FormGroup>
        <Label>Select Wallet Type</Label>
        <div className="d-flex">
          <Card
            className="card-frame bg-dark rounded-md w-50 pointer mr-3"
            onClick={() => handlelectronicWalletTypeChange("E_WALLET")}
            active={walletType === "E_WALLET"}
          >
            <CardBody className="d-flex mx-1 mx-sm-3 mx-lg-3">
              <Row>
                <Col sm={12} md={9}>
                  <Input type="radio" checked={walletType === "E_WALLET"} />
                  <div className="mr-auto ml-2">
                    <h3 className="text-white mb-0">E-Wallet</h3>

                    {gcashOnly ? (
                      <small>(GCASH)</small>
                    ) : (
                      <small>(GCASH, MAYA, Coins.ph and more)</small>
                    )}
                  </div>
                </Col>
                <Col sm={12} md={3}>
                  <div className="mt-1">
                    <div className="icon icon-shape bg-gradient-default text-white rounded-circle shadow">
                      <i className="ni ni-money-coins"></i>
                    </div>
                  </div>
                </Col>
              </Row>
            </CardBody>
          </Card>

          {!gcashOnly && (
            <Card
              className="card-frame bg-dark rounded-md w-50 pointer mr-3"
              onClick={() => handlelectronicWalletTypeChange("BANK")}
              active={walletType === "BANK"}
            >
              <CardBody className="d-flex mx-0 ml-2">
                <Row>
                  <Col sm={12} md={9}>
                    <Input type="radio" checked={walletType === "BANK"} />
                    <div className="mr-auto ml-2">
                      <h3 className="text-white mb-0">Bank Transfer</h3>
                      <small>(BPI, BDO, PNB and more)</small>
                    </div>
                  </Col>
                  <Col sm={12} md={3}>
                    <div className="mt-1">
                      <div className="icon icon-shape bg-gradient-default text-white rounded-circle shadow">
                        <i className="fa fa-bank"></i>
                      </div>
                    </div>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          )}
        </div>
      </FormGroup>

      {walletType && (
        <FormGroup>
          <Label for="exampleSelect">Select Type</Label>
          <Select
            id="exampleSelect"
            name="select"
            type="select"
            className="text-dark"
            onChange={handleTypeChange}
            value={selectedType}
            options={channels.map((channel) => {
              return {
                label: channel.title,
                value: channel.value,
              };
            })}
          />
        </FormGroup>
      )}
      {isTypeSelected && (
        <>
          <FormGroup>
            <Label for="accountName">Account Name</Label>
            <Input
              id="accountName"
              name="accountName"
              placeholder="Your wallet name"
              type="text"
              required
              value={mopAccount.accountName}
              onChange={handleInputChange}
            />
          </FormGroup>
          <FormGroup>
            <Label for="accountNumber">Account Number</Label>
            <Input
              id="accountNumber"
              name="accountNumber"
              placeholder="Your wallet Number"
              type="text"
              required
              value={mopAccount.accountNumber}
              onChange={handleInputChange}
            />
          </FormGroup>

          {walletType === "BANK" && (
            <>
              <FormGroup>
                <Label for="bankBranch">Branch</Label>
                <Input
                  id="bankBranch"
                  name="bankBranch"
                  placeholder="Your bank branch"
                  type="text"
                  required
                  value={mopAccount.bankBranch}
                  onChange={handleInputChange}
                />
              </FormGroup>
              <FormGroup>
                <Label for="contactNumber">Contact Number</Label>
                <Input
                  id="contactNumber"
                  name="contactNumber"
                  placeholder="09xxxxxxxxx"
                  type="text"
                  required
                  value={mopAccount.contactNumber}
                  maxLength={11}
                  onChange={handleInputChange}
                />
              </FormGroup>
            </>
          )}
        </>
      )}
    </>
  );
};

export default NewAccountOptions;
