// Spinners
import {
  PropagateLoader,
  RiseLoader,
  PulseLoader,
  PacmanLoader,
  ClimbingBoxLoader,
} from "react-spinners";

import { Alert } from "reactstrap";

const randomize = (myArray) => {
  return myArray[Math.floor(Math.random() * myArray.length)];
};

export const LoadingBar = (props) => {
  var arr = [
    <PropagateLoader
      color={props.color && props.color === "dark" ? "#ffff" : "#263b65"}
      cssOverride={{ marginBottom: 20, marginTop: 20 }}
    />,
    <RiseLoader
      color={props.color && props.color === "dark" ? "#ffff" : "#263b65"}
      cssOverride={{ marginBottom: 20, marginTop: 20 }}
    />,
    <PulseLoader
      color={props.color && props.color === "dark" ? "#ffff" : "#263b65"}
      cssOverride={{ marginBottom: 20, marginTop: 20 }}
    />,
    <PacmanLoader
      color={props.color && props.color === "dark" ? "#ffff" : "#263b65"}
      cssOverride={{ width: "100%", marginBottom: 20, marginTop: 20 }}
    />,
    <ClimbingBoxLoader
      color={props.color && props.color === "dark" ? "#ffff" : "#263b65"}
      cssOverride={{ width: "100%", marginBottom: 20, marginTop: 20 }}
    />,
  ];

  return (
    <Alert
      color={`${
        props.color && props.color === "dark" ? "alert-default" : "secondary"
      } mt-5 mb-5 w-lg-25 mx-auto`}
    >
      <div className="text-center">
        <span
          className={`h2 ${
            props.color && props.color === "dark" && "text-white"
          }`}
        >
          LOADING...
        </span>
        {randomize(arr)}
      </div>
    </Alert>
  );
};

export default LoadingBar;
