//Site Owner
import StatementDetails from "views/shared/reports/statementdetails";
//Agent
import ChipsHistory from "views/shared/reports/history/chips";
import DownlineAccounts from "views/shared/accounts/downlines";
import CommissionSummary from "views/shared/commission/chips";
import CoinsSummary from "views/shared/commission/coins";
import BetHistory from "views/shared/reports/history/bet";

const ALL = "ALL";

var routes = [
  {
    path: "/history/bet/:name",
    invisible: true,
    component: BetHistory,
    layout: "/portal",
    role: [ALL],
  },
  {
    path: "/downlines/:name",
    invisible: true,
    component: DownlineAccounts,
    layout: "/portal",
    role: [ALL],
  },
  {
    path: "/commission/summary/:username",
    name: "Chips Summary",
    component: CommissionSummary,
    layout: "/portal",
    invisible: true,
    icon: "fa fa-caret-right",
  },
  {
    path: "/commission/coins/:username",
    name: "Coins Summary",
    component: CoinsSummary,
    layout: "/portal",
    invisible: true,
    icon: "fa fa-caret-right",
  },
  {
    path: "/statement-details/:username",
    name: "Statement",
    code: "STATEMENT_DETAILS",
    component: StatementDetails,
    layout: "/portal",
    invisible: true,
    icon: "fa fa-caret-right",
  },
  {
    path: "/history/chips/:username",
    name: "Chips History",
    invisible: true,
    component: ChipsHistory,
    layout: "/portal",
    icon: "fa fa-caret-right",
  },
];
export default routes;
