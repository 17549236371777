import { useState, useEffect, useRef } from "react";

import { Link, useHistory } from "react-router-dom";
// reactstrap components
import {
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle,
  Navbar,
  Nav,
  Container,
  Media,
  Form,
  FormGroup,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Input,
} from "reactstrap";

// alert prompt
import Swal from "sweetalert2";

import { useSelector, useDispatch } from "react-redux";

import { loggedOut, loggedInStart } from "../../actions";
import SearchModal from "components/Modals/search";

const AdminNavbar = props => {
  const history = useHistory();
  const childRef = useRef();

  const dispatch = useDispatch();

  const name = useSelector(state => state.entities.user?.name);
  const viewPermissions = useSelector(state => state.ui.permissions.view);
  const isLoggedIn = useSelector(state => state.ui.isLoggedIn);
  const loginOutcome = useSelector(state => state.ui.loginOutcome);

  const [searchName, setSearchName] = useState();

  useEffect(() => {
    // APITokenCheck(JWT, dispatch);

    if (isLoggedIn === "OUT") {
      Swal.fire({
        title: "Connection lost",
        text: "Another device is connecting to this account",
        icon: "warning",
        confirmButtonText: "RELOGIN",
        showLoaderOnConfirm: true,
        preConfirm: () => {
          handleLogout();
          dispatch(loggedInStart());
        },
        allowOutsideClick: false,
      });
    }

    if (loginOutcome === "LOGGED_OUT") {
      history.push("/home");
    }
    // eslint-disable-next-line
  }, [isLoggedIn]);

  const handleLogout = () => {
    dispatch(loggedOut());
    history.push("/home");
  };

  const handleSearch = e => {
    e.preventDefault();
    childRef.current.searchName(searchName);
  };

  return (
    <>
      <Navbar className="navbar-top navbar-dark" expand="md" id="navbar-main">
        <Container fluid>
          <Link
            to={"#"}
            className="h4 mb-0 text-white text-uppercase d-none d-lg-inline-block"
          >
            {props.brandText}
          </Link>

          <span className="d-none d-md-flex ml-auto">
            <SearchModal ref={childRef} />
            <Form
              onSubmit={handleSearch}
              className="navbar-search navbar-search-dark form-inline mr-3 d-none d-md-flex ml-lg-auto"
            >
              <FormGroup className="mb-0">
                <InputGroup className="input-group-alternative">
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                      <i className="fas fa-search" />
                    </InputGroupText>
                  </InputGroupAddon>
                  <Input
                    onChange={e => setSearchName(e.target.value)}
                    placeholder="Search by USERNAME"
                    type="text"
                  />
                </InputGroup>
              </FormGroup>
            </Form>
          </span>

          <Nav className="align-items-center d-none d-md-flex" navbar>
            <UncontrolledDropdown nav>
              <DropdownToggle className="pr-0" nav>
                <Media className="align-items-center">
                  <span className="avatar avatar-sm rounded-circle">
                    <img
                      alt="..."
                      src={
                        require("../../assets/img/theme/default-avatar.png")
                          .default
                      }
                    />
                  </span>
                  <Media className="ml-2 d-none d-lg-block">
                    <span className="mb-0 text-sm font-weight-bold">
                      {name}
                    </span>
                  </Media>
                </Media>
              </DropdownToggle>

              <DropdownMenu className="dropdown-menu-arrow" right>
                <DropdownItem className="noti-title" header tag="div">
                  <h6 className="text-overflow m-0">Welcome! {name}</h6>
                </DropdownItem>
                <DropdownItem to="/portal/settings/changepassword" tag={Link}>
                  <i className="ni ni-single-02" />
                  <span>Change Password</span>
                </DropdownItem>

                {!viewPermissions.includes("VIEW_USER_INFORMATION") && (
                  <DropdownItem to="/portal/settings/editprofile" tag={Link}>
                    <i className="ni ni-settings-gear-65" />
                    <span>Settings</span>
                  </DropdownItem>
                )}

                <DropdownItem divider />
                <DropdownItem onClick={handleLogout}>
                  <i className="ni ni-user-run" />
                  <span>Logout</span>
                </DropdownItem>
              </DropdownMenu>
            </UncontrolledDropdown>
          </Nav>
        </Container>
      </Navbar>
    </>
  );
};

export default AdminNavbar;
