import { useState, useEffect } from "react";
import { useParams, useHistory } from "react-router-dom";

import {
  Row,
  Col,
  Button,
  Card,
  Input,
  FormFeedback,
  FormGroup,
} from "reactstrap";

import LoadingBar from "components/LoadBars";
import Swal from "sweetalert2";
import chipsLogo from "assets/img/chips-icon.png";
import { useSelector } from "react-redux";
import { commas, isNumeric } from "views/shared/utils";
import { getMOPDetails, postCreditRequest } from "api/p2p";

const alertDialog = (title, confirmLabel, type) => {
  Swal.fire({
    confirmButtonText: confirmLabel,
    title: title,
    icon: type,
    color: "#fff",
    confirmButtonColor: "rgb(157 134 0)",
    background:
      "radial-gradient(79.09% 79.09% at 50% 14.18%, #4e4d4c 0%, #060606 100%)",
  });
};

const DepositAmount = () => {
  const { id } = useParams();
  const history = useHistory();
  const username = useSelector((state) => commas(state.entities.user.username));

  const [data, setData] = useState({ type: "E_WALLET" });
  const [selectedAmount, setSelectedAmount] = useState();
  const [isAmountValid, setIsAmountValid] = useState(true);
  const [isAmountCap, setIsAmountCap] = useState(false);
  const [inProcess, setInProcess] = useState(false);

  useEffect(() => {
    getMOPDetails(id).then((result) => setData(result));
    // eslint-disable-next-line
  }, []);

  if (!data) {
    return <div>Loading...</div>;
  }

  const depositAmounts = [20, 50, 100, 150, 200, 500, 1000, 2000];

  const checkIfNumericOrInCap = (amount) => {
    if (!amount) {
      return;
    }

    if (isNumeric(amount)) {
      setIsAmountValid(true);
    } else {
      setIsAmountValid(false);
    }

    if (isAmountInCap(amount)) {
      setIsAmountCap(false);
    } else {
      setIsAmountCap(true);
    }

    setSelectedAmount(amount);
  };

  const isAmountInCap = (amount) => {
    return amount >= data.minimum && amount <= data.maximum;
  };

  const changeAmount = (amount) => {
    setSelectedAmount(amount);
    setIsAmountValid(true);
    // setSelectedAmount(item);
  };

  const handleDeposit = (e) => {
    e.preventDefault();

    setInProcess(true);
    setTimeout(() => {
      postCreditRequest({
        username: username,
        amount: selectedAmount,
        uuidMOP: id,
        remarks: "",
      }).then((result) => {
        if (result.status === 412) {
          setInProcess(false);
          alertDialog(
            "Please note that you still have a pending transaction. Kindly contact your upline for further assistance",
            "Close",
            "warning"
          );
          return;
        }

        alertDialog(
          "Request submitted, Please upload screenshot to continue!",
          "Okay",
          "success"
        );
        history.push(
          "/player/deposit-transaction/" + id + "/" + result[0].message + '/' +  selectedAmount
        );
      });
    }, 1000);
  };

  return (
    <section className="pb-5 pt-3 text-center container mx-auto text-white">
      <div className="">
        <h1 className="text-white pt-4 text-center">
          <b>
            {data.type === "E_WALLET"
              ? data.electronicWalletType
              : data.bankType}
          </b>
        </h1>
      </div>

      {inProcess ? (
        <div className="border rounded-md my-5 py-3">
          <h1 className="text-white">Processing data, please wait...</h1>
          <LoadingBar color="dark" />
          <h1 className="text-white">
            Contacting Payment Provider. <br /> Please wait...
          </h1>
        </div>
      ) : (
        <div>
          <h2 className="text-white">Select Deposit Amount</h2>

          <Row className="">
            {depositAmounts
              .filter((item) => isAmountInCap(item))
              .map((item) => {
                const isSelected = selectedAmount === item;
                return (
                  <Col md={3} className="my-2 pointer hover-gold">
                    <Card
                      className={`bg-dark rounded-md d-flex flex-row align-items-center justify-content-center p-2 ${
                        isSelected && "border border-gold"
                      }`}
                      onClick={() => changeAmount(item)}
                    >
                      <img
                        alt="..."
                        width={40}
                        src={chipsLogo}
                        className="mr-2"
                      />
                      <div className="text-white h2 mb-0">{item}</div>
                    </Card>
                  </Col>
                );
              })}
          </Row>
          <FormGroup>
            <Input
              type="text"
              className="bg-dark rounded-md mt-2 text-white text-xl text-center"
              value={selectedAmount}
              onChange={(e) => checkIfNumericOrInCap(e.target.value)}
              invalid={!isAmountValid || isAmountCap}
            />
            {!isAmountValid && (
              <FormFeedback>Oh noes! invalid amount</FormFeedback>
            )}
            {isAmountCap && (
              <FormFeedback>
                Please put between amount {data.minimum} - {data.maximum}
              </FormFeedback>
            )}
          </FormGroup>

          <div>
            Deposit Limits: Min.{" "}
            <strong className="text-lg">{data.minimum}</strong> Max.{" "}
            <strong className="text-lg">{data.maximum}</strong>
          </div>

          <Button
            disabled={!isAmountValid || !selectedAmount}
            block
            type="submit"
            color="primary"
            className="my-3"
            onClick={handleDeposit}
          >
            Deposit Now
          </Button>
        </div>
      )}

      <div className="text-white mt-3">
        All Bonus offers are optional, valid only at the time of deposit and
        subject to Terms and Conditions. Bonus offers cannot be claimed after
        deposit.
      </div>
    </section>
  );
};

export default DepositAmount;
