import { useEffect, useRef } from "react";
import { useLocation, Route, Switch, useHistory } from "react-router-dom";
// reactstrap components
import { Container } from "reactstrap";
// core components
import AdminNavbar from "components/Navbars/AdminNavbar.js";
import AdminFooter from "components/Footers/AdminFooter.js";
import Sidebar from "components/Sidebar/Sidebar.js";

import routes from "routes.js";
import routesShared from "routes-shared";

import { useSelector } from "react-redux";

const Admin = props => {
  const mainContent = useRef(null);
  const location = useLocation();
  const history = useHistory();
  const isRoleUplines = useSelector(state => state.ui?.userType) === "UPLINES";
  const isLoggedIn =
    useSelector(state => state.ui.loginOutcome) === "LOGGED_IN";

  useEffect(() => {
    if (isLoggedIn && !isRoleUplines) {
      history.push("/home");
    }

    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
    mainContent.current.scrollTop = 0;
    // eslint-disable-next-line
  }, [location]);

  const getRoutes = routes => {
    return routes.map((prop, key) => {
      if (prop.collapse) {
        return getRoutes(prop.views);
      }

      if (prop.layout === "/portal") {
        return (
          <Route
            path={prop.layout + prop.path}
            component={prop.component}
            key={key}
          />
        );
      } else {
        return null;
      }
    });
  };

  const getBrandText = () => {
    for (let i = 0; i < routes.length; i++) {
      if (
        props.location.pathname.indexOf(routes[i].layout + routes[i].path) !==
        -1
      ) {
        return routes[i].name;
      }
    }
    return "Management";
  };

  return (
    <>
      <Sidebar
        {...props}
        routes={routes}
        logo={{
          innerLink: "/portal/index",
          imgSrc: require("../assets/img/brand/iplayevo.png").default,
          imgAlt: "...",
        }}
      />
      <div className="main-content" ref={mainContent}>
        <AdminNavbar
          {...props}
          brandText={getBrandText(props.location.pathname)}
        />
        <Switch>
          {getRoutes(routes)}
          {getRoutes(routesShared)}

          {/* <Redirect from="*" to="/home" /> */}
        </Switch>
        <Container fluid>
          <AdminFooter />
        </Container>
      </div>
    </>
  );
};

export default Admin;
