import { useState, useEffect } from "react";
import {
  Card,
  CardHeader,
  Button,
  Row,
  Col,
  Input,
  CardBody,
} from "reactstrap";

import { getGameSettings, patchGameSettings } from "api/video-karera";
import { nofitySuccess } from "components/Toasts";

function InBetweenSettings() {
  const [sync, setSync] = useState(false);
  const [modal, setModal] = useState(false);

  const [maximumBet, setMaximumBet] = useState(false);
  const [maintenanceMode, setMaintenanceMode] = useState(false);

  const toggle = () => setModal(!modal);

  // Table Handling
  useEffect(() => {
    // Getting Maximum Bet
    getGameSettings("MAXIMUM_BET").then((response) => {
      setMaximumBet(response[0].value);
    });

    // Getting Maximum Bet
    getGameSettings("MAINTENANCE_MODE").then((response) => {
      setMaintenanceMode(response[0].value);
    });
  }, [sync]);

  const refreshTable = () => {
    setSync(!sync);
  };

  const handleUpdateMaximumBetAmount = (e) => {
    e.preventDefault();

    patchGameSettings("MAXIMUM_BET", maximumBet);

    nofitySuccess("In Between - Maximum Bet Updated");
    refreshTable();
  };

  const handleUpdateMaintenanceMode = (e) => {
    patchGameSettings("MAINTENANCE_MODE", e.target.checked);
    setMaintenanceMode(e.target.checked);
    nofitySuccess("In Between - Maintenance mode updated");
  };

  return (
    <>
      <Card className="shadow">
        <CardHeader className="border-0">
          <Row>
            <Col>
              <h1 className="mb-0">Game Settings</h1>
            </Col>
            <Col className="text-right">
              <Button onClick={toggle} color="primary">
                UPDATE
              </Button>
            </Col>
          </Row>
        </CardHeader>
      </Card>

      <Row className="my-3">
        <Col>
          <Card className="shadow">
            <CardHeader className="border-0">
              <h1 className="mb-0">Maximum Bet Amount</h1>
            </CardHeader>
            <CardBody>
              <Row>
                <Col>
                  <Input
                    type="text"
                    placeholder="amount"
                    value={maximumBet}
                    onChange={(e) => setMaximumBet(e.target.value)}
                  />
                </Col>
                <Col>
                  <Button
                    color="primary"
                    onClick={handleUpdateMaximumBetAmount}
                  >
                    Update
                  </Button>
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Col>
        <Col className="mt-sm-0 mt-3">
          <Card className="shadow">
            <CardHeader className="border-0">
              <h1 className="mb-0">Maintenance Mode</h1>
            </CardHeader>
            <CardBody>
              <label className="custom-toggle">
                <input
                  defaultChecked={maintenanceMode}
                  type="checkbox"
                  className="h1"
                  onChange={handleUpdateMaintenanceMode}
                />
                <span className="custom-toggle-slider rounded-circle" />
              </label>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </>
  );
}

export default InBetweenSettings;
