import { useState } from "react";
import { useSelector } from "react-redux";
// alert prompt
import Swal from "sweetalert2";

import UserHeader from "components/Headers/UserHeader";
import {
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  Container,
  Row,
  Col,
  Button,
} from "reactstrap";
// axios API library
const axios = require("axios").default;
const PROXY = process.env.REACT_APP_API_KEY;
function ChangePassword() {
  const [currentPassword, setCurrentPassword] = useState();
  const [password, setPassword] = useState();
  const [confirmPassword, setConfirmPassword] = useState();
  const name = useSelector((state) => state.entities.user.username);
  const uuid = useSelector((state) => state.entities.user.uuid);

  const handleSubmit = (e) => {
    e.preventDefault();

    if (password === confirmPassword) {
      axios
        .post(PROXY + "/user/change-password", {
          username: name,
          uuid: uuid,
          password: currentPassword,
          newPassword: password,
        })
        .then(function (response) {
          if (response.data.statusCode === "OK") {
            Swal.fire({
              title: "Success!",
              text: "Password changed",
              icon: "success",
            });
          } else {
            Swal.fire({
              title: "User is Not Valid!",
              text: "Incorrect current password",
              icon: "error",
            });
          }
        });
    } else {
      Swal.fire({
        title: "Warning!",
        text: "Confirm password did not match",
        icon: "error",
      });
    }
  };

  const [eye, seteye] = useState(true);
  const [showPassword, setShowPassword] = useState("password");

  const handleEye = () => {
    if (showPassword === "password") {
      setShowPassword("text");
      seteye(false);
    } else {
      setShowPassword("password");
      seteye(true);
    }
  };

  return (
    <>
      <UserHeader />
      <Container className="mt--7" fluid>
        <Row className="mx-auto">
          <Col className="mx-auto" xl="6">
            <Card className="bg-secondary shadow">
              <CardHeader className="bg-white border-0">
                <Row className="align-items-center">
                  <Col xs="8">
                    <h3 className="mb-0">Change Password</h3>
                  </Col>
                  <Col className="text-right" xs="4"></Col>
                </Row>
              </CardHeader>
              <CardBody className="mx-auto col-12">
                <Form onSubmit={handleSubmit}>
                  <div className="mx-auto">
                    <Row className="mx-auto">
                      <Col md="12">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-current-password"
                          >
                            Current Password
                          </label>
                          <Input
                            className="form-control-alternative mb-3"
                            id="input-current-password"
                            type={showPassword}
                            required
                            onChange={(e) => setCurrentPassword(e.target.value)}
                          />
                        </FormGroup>
                      </Col>
                      <Col md="12">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-new-password"
                          >
                            New Password
                          </label>
                          <Input
                            className="form-control-alternative"
                            id="input-new-password"
                            type={showPassword}
                            required
                            onChange={(e) => setPassword(e.target.value)}
                          />
                        </FormGroup>
                      </Col>
                      <Col md="12">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-confirm-password"
                          >
                            Confirm Password
                          </label>
                          <Input
                            className="form-control-alternative"
                            id="input-current-password"
                            type={showPassword}
                            required
                            onChange={(e) => setConfirmPassword(e.target.value)}
                          />
                        </FormGroup>
                      </Col>
                      <Col>
                        <span
                          onClick={handleEye}
                          id="showPasswordEye"
                          className="pointer"
                        >
                          <i
                            className={`fa ${eye ? "fa-eye-slash" : "fa-eye"}`}
                          />{" "}
                          {showPassword === "password" ? "Show" : "Hide"}{" "}
                          Password
                        </span>
                      </Col>
                    </Row>
                    <Button
                      color="success"
                      type="submit"
                      className="px-5 ml-3 m-3"
                    >
                      Save
                    </Button>
                  </div>
                </Form>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default ChangePassword;
