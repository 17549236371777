import { useState } from "react";

import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Col,
  Row,
} from "reactstrap";

const ProofPayment = ({ item }) => {
  let base_url = process.env.REACT_APP_API_KEY + "/images/proof-of-payment/";

  const [modal, setModal] = useState(false);
  const toggle = () => setModal(!modal);

  const showImages = () => {
    var payments = [];
    for (let i = 0; i < item.proofOfPaymentCount; i++) {
      payments.push(
        <Col key={i}>
          <img
            src={`${base_url}${item.transactionId}-${i + 1}.jpg`}
            alt="example"
            width={300}
          />
        </Col>
      );
    }
    return payments;
  };

  return item.proofOfPaymentCount !== 0 ? (
    <div className="d-flex flex-column">
      <Button color="primary" onClick={toggle}>
        Show
      </Button>

      <div className="text-success mt-2">
        <b>Screenshot Uploaded</b>
      </div>
      <Modal isOpen={modal} toggle={toggle} centered>
        <ModalHeader toggle={toggle}>
          Account <b>{item.username}'s</b> proof of payment
        </ModalHeader>
        <ModalBody className="d-flex justify-content-center">
          <Row>{showImages()}</Row>
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={toggle}>
            Close
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  ) : (
    <span className="">
      <b className="text-danger mb-1">PENDING UPLOAD</b> <br />
      <span>Please wait...</span>
    </span>
  );
};

export default ProofPayment;
