import { commas } from "views/shared/utils";

import chipsImage from "assets/img/chips-icon.png";
import coinsImage from "assets/img/coins-icon.png";

export const columnsAgent = [
  {
    name: "transaction Id",
    sortable: true,
    selector: (row) => row.transactionId,
  },
  {
    name: "date",
    selector: (row) => row.date,
    sortable: true,
  },
  {
    name: "username",
    selector: (row) => row.username,
    sortable: true,
    cell: (row) => <b>{row.username}</b>,
  },
  {
    name: "amount",
    selector: (row) => commas(row.amount),
    sortable: true,
  },
  {
    name: "credit Type",
    selector: (row) => row.creditType,
    sortable: true,
    cell: (d) => (
      <>
        <img
          src={d.creditType === "CHIPS" ? chipsImage : coinsImage}
          alt=""
          width={20}
        />
        <span className="ml-2">{d.creditType}</span>
      </>
    ),
  },
  {
    name: "Remarks",
    selector: (row) => row.remarks,
    sortable: true,
  },
];
