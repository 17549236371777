import { useEffect, useState } from "react";

// React strap tools
import {
  Input,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  FormGroup,
  Form,
  Label,
  Spinner,
  Row,
  Col,
} from "reactstrap";

// Redux
import { useDispatch, useSelector } from "react-redux";

import { UpdateBasicInformation } from "api/AccountAPI";
import {
  patchChipsCommissionPercentage,
  patchCoinsCommissionPercentage,
} from "api/Commision";
import {
  updateAccountViewRestriction,
  getRestrictedPermissions,
} from "api/Permissions";

import { nofitySuccess, notifyDanger } from "components/Toasts";

import ConfirmForm from "components/Modals/confirm";
import InputModal from "components/Modals/input";

import { refresh } from "actions";

export const EditForm = (props) => {
  const dispatch = useDispatch();
  const JWT = useSelector((state) => state.auth.jwt);

  const [open, setOpen] = useState(false);

  // Add Handling
  const [name, setName] = useState(props.row.name);
  const [mobile, setMobile] = useState(props.row.mobileNumber);
  const [email, setEmail] = useState(props.row.email ? props.row.email : "");
  const [isMOPEnabled, setIsMOPEnabled] = useState();

  const [isLoadingState, setIsLoadingState] = useState(false);
  const editPermissions = useSelector((state) => state.ui.permissions.edit);
  const isAllowedChangeStatus = !editPermissions?.includes(
    "CHANGE_ACCOUNT_STATUS"
  );

  const handleSubmit = (e) => {
    e.preventDefault();
    setIsLoadingState(true);

    const payload = {
      email: email,
      mobileNumber: mobile,
      name: name,
    };

    UpdateBasicInformation(props.row.uuid, payload, JWT).then((response) => {
      if (response.statusCode === "OK") {
        nofitySuccess(`User: ${props.row.username} information updated`);

        dispatch(refresh());
        setIsLoadingState(false);
        handleCloseModal();
      } else {
        notifyDanger(response.message);
        setIsLoadingState(false);
      }
    });
  };

  const handleCloseModal = () => {
    setOpen(false);
  };

  useEffect(() => {
    if (open) {
      getRestrictedPermissions(props.row.uuid).then((response) => {
        setIsMOPEnabled(!response.view.includes("P2P_TRANSACTION"));
      });
    }
    // eslint-disable-next-line
  }, [open]);

  const handleAccountPermissionMOP = (e) => {
    setIsMOPEnabled(isMOPEnabled ? false : !isMOPEnabled);

    updateAccountViewRestriction(
      props.row.uuid,
      "P2P_TRANSACTION",
      isMOPEnabled ? false : !isMOPEnabled
    ).then(() => {
      nofitySuccess(
        `${props.row.username}'s MOP is ${
          !isMOPEnabled ? "enabled" : "disabled"
        }`
      );
    });
  };

  return (
    <>
      <Button
        className="btn-icon btn-3 w-100"
        color="primary"
        type="button"
        onClick={() => setOpen(true)}
        outline
        size="sm"
      >
        <span className="btn-inner--icon">
          <i className="fas fa-user-edit" />
          <span className="btn-inner--text">EDIT</span>
        </span>
      </Button>
      <Modal
        className="modal-dialog-centered"
        size="md"
        isOpen={open}
        toggle={handleCloseModal}
      >
        <ModalHeader toggle={handleCloseModal}>
          Edit - {props.row.username}
        </ModalHeader>
        <ModalBody>
          <Row className="mb-4">
            <Col md={12} className="mb-2">
              Account Options:
            </Col>

            {props.row.role !== "PLAYER" && (
              <Col md={6}>
                <InputModal
                  title={`Update ${props.row.username} commision percentage`}
                  color="info"
                  id={props.row.uuid}
                  defaultValue={props.row.commissionChipsPercentage}
                  buttonLabel={"SET COMMISSION CHIPS"}
                  api={patchChipsCommissionPercentage}
                  name={props.row.username}
                  sync={props.sync}
                />
              </Col>
            )}

            {props.row.role !== "PLAYER" && (
              <Col md={6}>
                <InputModal
                  title={`Update ${props.row.username} commision percentage`}
                  color="info"
                  id={props.row.uuid}
                  defaultValue={props.row.commissionCoinsPercentage}
                  buttonLabel={"SET COMMISSION COINS"}
                  api={patchCoinsCommissionPercentage}
                  name={props.row.username}
                  sync={props.sync}
                />
              </Col>
            )}

            {isAllowedChangeStatus && (
              <Col md={6} className="mt-1">
                <ConfirmForm
                  color="danger"
                  useCase="ACCOUNT_STATUS"
                  data="INACTIVE"
                  id={props.row.uuid}
                  title="DEACTIVATE"
                  message={`Make ${props.row.username} inactive account ?`}
                  icon="ni-fat-remove"
                  sync={props.sync}
                ></ConfirmForm>
              </Col>
            )}
          </Row>

          {(props.row.role === "FINANCIER" || props.row.role === "MASTER") && (
            <Row className="my-3">
              <Col md={12} className="mb-2">
                Account Restrictions:
              </Col>
              <Col md={12}>
                <div className="d-flex">
                  <label className="custom-toggle">
                    <input
                      defaultChecked={isMOPEnabled}
                      type="checkbox"
                      value=""
                      onClick={handleAccountPermissionMOP}
                    />
                    <span className="custom-toggle-slider rounded-circle" />
                  </label>
                  <span className="ml-2">Mode of Payment</span>
                </div>
              </Col>
            </Row>
          )}

          <Form role="form" onSubmit={handleSubmit}>
            <FormGroup>
              <Label for="name">Name</Label>
              <Input
                placeholder="Name"
                type="text"
                minLength={5}
                autoComplete="name"
                value={name}
                onChange={(e) => setName(e.target.value)}
              />
            </FormGroup>

            <FormGroup>
              <Label for="username">Email</Label>
              <Input
                placeholder="Email"
                type="text"
                minLength={5}
                autoComplete=""
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </FormGroup>

            <FormGroup>
              <Label for="mobile">Mobile Number</Label>
              <Input
                placeholder="11 digit mobile number"
                type="text"
                required
                value={mobile}
                onChange={(e) => setMobile(e.target.value)}
              />
            </FormGroup>

            <div className="text-right">
              <Button
                color="secondary"
                data-dismiss="modal"
                type="button"
                onClick={handleCloseModal}
              >
                Close
              </Button>
              <Button className="mt-2" color="primary" type="submit">
                {isLoadingState ? <Spinner>Loading...</Spinner> : <>Update</>}
              </Button>
            </div>
          </Form>
        </ModalBody>
      </Modal>
    </>
  );
};

export default EditForm;
