import { useState } from "react";

// React strap tools
import {
  Input,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  FormGroup,
  Form,
  FormText,
  FormFeedback,
  Label,
  Spinner,
} from "reactstrap";

// Redux
import { useSelector } from "react-redux";

import PasswordStrengthBar from "react-password-strength-bar";

import { postAccount } from "api/AccountAPI";

import { nofitySuccess, notifyDanger } from "components/Toasts";

export const CreateForm = (props) => {
  const refferalCode = useSelector((state) => state.entities.user.referralCode);

  // Add Handling
  const [name, setName] = useState("");
  const [username, setUsername] = useState("");
  const [mobileNumber, setMobileNumber] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");

  const [showHide, setShowHide] = useState("password");
  const [isPasswordMatched, setIsPasswordMatched] = useState(true);
  const [isLoadingState, setIsLoadingState] = useState(false);
  const [eye, seteye] = useState(true);

  const [modal, setModal] = useState(false);
  const toggle = () => setModal(!modal);

  const handleSubmit = (e) => {
    e.preventDefault();
    setIsLoadingState(true);

    if (password !== confirmPassword) {
      setIsPasswordMatched(false);
      setIsLoadingState(false);
    } else {
      setIsPasswordMatched(true);

      const payload = {
        referralCode: refferalCode,
        name: name,
        username: username,
        password: password,
        mobileNumber: mobileNumber,
      };

      postAccount(payload).then((response) => {
        if (response.statusCode === "OK") {
          setName("");
          setUsername("");
          setMobileNumber("");
          setPassword("");
          setConfirmPassword("");
          toggle();
          handleSync();
          nofitySuccess(`New accounts is now live!`);
        } else {
          notifyDanger(`Failed! ${response.message}`);
        }
        setIsLoadingState(false);
      });
    }
  };

  const handleSync = () => {
    props.sync();
  };

  const handleEye = () => {
    if (showHide === "password") {
      setShowHide("text");
      seteye(false);
    } else {
      setShowHide("password");
      seteye(true);
    }
  };

  return (
    <>
      <Button
        className="btn-icon btn-3"
        color="primary"
        outline
        type="button"
        onClick={toggle}
      >
        <span className="btn-inner--icon">
          <i className="fa fa-plus"></i>
        </span>
        <span className="btn-inner--text">Create</span>
      </Button>

      <Modal
        className="modal-dialog-centered"
        size="md"
        isOpen={modal}
        toggle={toggle}
      >
        <ModalHeader toggle={toggle}>Create a new 'DOWNLINE'</ModalHeader>
        <ModalBody>
          <Form role="form" onSubmit={handleSubmit}>
            <FormGroup>
              <Label for="name">Name</Label>
              <Input
                placeholder="Name"
                type="text"
                minLength={5}
                value={name}
                required
                onChange={(e) => setName(e.target.value)}
              />
            </FormGroup>

            <FormGroup>
              <Label for="username">Username</Label>
              <Input
                placeholder="Username"
                type="text"
                minLength={5}
                value={username}
                required
                onChange={(e) => setUsername(e.target.value)}
              />
            </FormGroup>

            <FormGroup>
              <Label for="mobile">Mobile Number</Label>
              <Input
                maxLength={11}
                placeholder="11 digit mobile number"
                type="number"
                autoComplete="new-password"
                required
                value={mobileNumber}
                onChange={(e) => setMobileNumber(e.target.value)}
              />
            </FormGroup>

            <FormGroup>
              <Label for="password">Password</Label>
              <Input
                invalid={!isPasswordMatched}
                placeholder="Your password here..."
                value={password}
                autoComplete="new-password"
                minLength={6}
                required
                type={showHide}
                onChange={(e) => setPassword(e.target.value)}
              />
              <FormFeedback>Oh noes! password did not matched</FormFeedback>
              <FormText onClick={handleEye} className="pointer">
                <div className="font-italic mt-2">
                  <i className={`fa ${eye ? "fa-eye-slash" : "fa-eye"}`} />
                  <span className="ml-2">{eye ? "Show" : "Hide"} password</span>
                </div>
              </FormText>
            </FormGroup>
            <FormGroup>
              <Label for="confirm">Confirm password</Label>
              <Input
                invalid={!isPasswordMatched}
                placeholder="Confirm here..."
                type={showHide}
                required
                onChange={(e) => setConfirmPassword(e.target.value)}
              />
            </FormGroup>

            <div className="text-muted font-italic">
              <small>
                password strength:
                <PasswordStrengthBar minLength="5" password={password} />
              </small>
            </div>
            <div className="text-center">
              <Button className="mt-2" color="primary" type="submit">
                {isLoadingState ? (
                  <Spinner>Loading...</Spinner>
                ) : (
                  <>Create account</>
                )}
              </Button>
            </div>
          </Form>
        </ModalBody>
      </Modal>
    </>
  );
};

export default CreateForm;
