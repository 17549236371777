import { useState, forwardRef, useImperativeHandle } from "react";

// reactstrap components
import {
  Form,
  Input,
  Modal,
  ModalHeader,
  ModalBody,
  Button,
  Col,
  Row,
  Card,
  CardHeader,
  CardBody,
  Badge,
  Breadcrumb,
  BreadcrumbItem,
  Spinner,
} from "reactstrap";

import { useDispatch } from "react-redux";

import EditForm from "components/Modals/edit";
import ChangePasswordForm from "components/Modals/changepassword";

import { commas, formatWithTwoDecimal } from "views/shared/utils";

// alert prompt
import Swal from "sweetalert2";

import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

import { nofitySuccess, notifyDanger } from "components/Toasts";
import { thisWeekDate } from "components/Filters/default";
import LoadingBar from "components/LoadBars";

import { getAccountInformation, syncUserData } from "api/AccountAPI";
import { getChipCommissions, getUserCoinsCommission } from "api/Commision";
import { directCredit } from "api/Credit";

import AgentImage from "assets/img/icons/agents.png";
import MasterImage from "assets/img/icons/master.png";
import SiteOwnerImage from "assets/img/icons/siteowners.png";
import PlayerImage from "assets/img/icons/player.png";
import NoResultImage from "assets/img/noresult.png";

import chipsImage from "assets/img/chips-icon.png";
import coinsImage from "assets/img/coins-icon.png";

const SearchModal = forwardRef((props, ref) => {
  const [isFormOpened, setIsFormOpened] = useState(false);
  const [isSearchOn, setIsSearchOn] = useState(false);
  const [searchName, setSearchName] = useState();
  const [searchResult, setSearchResult] = useState();

  const [commissionChips, setCommissionChipsResult] = useState(0);
  const [commissionCoins, setCommissionCoinsResult] = useState(0);
  const [loadingCommissionChips, setLoadingCommissionChips] = useState(false);
  const [loadingCommissionCoins, setLoadingCommissionCoins] = useState(false);

  const dispatch = useDispatch();
  const imageUrls = new Map();

  imageUrls.set("ADMIN", SiteOwnerImage);
  imageUrls.set("PLAYER", PlayerImage);
  imageUrls.set("AGENT", AgentImage);
  imageUrls.set("MASTER", MasterImage);
  imageUrls.set("SITE_OWNER", SiteOwnerImage);

  const role = useSelector((state) => state.entities.user?.role);
  const viewRestrictedPermissions = useSelector(
    (state) => state.ui.permissions.view
  );
  const editRestrictedPermissions = useSelector(
    (state) => state.ui.permissions.edit
  );

  const creditPermissions = {
    isAllowedDeposit: !editRestrictedPermissions.includes("DEPOSIT"),
    isAllowedWithraw: !editRestrictedPermissions.includes("WITHDRAW"),
  };

  useImperativeHandle(ref, () => ({
    searchName(username) {
      setSearchName(username);
      handleSearch(username);
    },
  }));

  const handleSearch = (searchName) => {
    setIsFormOpened(true);
    setIsSearchOn(true);

    getAccountInformation(searchName)
      .then((response) => {
        if (response.status === 200 && response.data) {
          setSearchResult(response.data);

          if (response.data.role !== "PLAYER") {
            setLoadingCommissionChips(true);

            getChipCommissions(thisWeekDate(searchName)).then((response) => {
              setCommissionChipsResult(
                formatWithTwoDecimal(response.netProfit)
              );
              setLoadingCommissionChips(false);
            });
            getUserCoinsCommission(searchName).then((response) => {
              setCommissionCoinsResult(
                formatWithTwoDecimal(response.netProfit)
              );
              setLoadingCommissionCoins(false);
            });
          }
        }
        setIsSearchOn(false);
      })
      .catch(() => {
        setIsSearchOn(false);
        setSearchResult(null);
      });
  };

  const handleDirectCredit = (e, creditType, transactionType) => {
    e.preventDefault();

    Swal.fire({
      title: `${transactionType} ${creditType} to ${searchName}`,
      text: `Balance ${creditType} ${commas(
        creditType === "CHIPS"
          ? searchResult.balanceChips
          : searchResult.balanceCoin
      )}`,
      input: "number",
      imageUrl: creditType === "CHIPS" ? chipsImage : coinsImage,
      imageHeight: "70%",
      imageAlt: "credit image",
      inputAttributes: {
        autocapitalize: "off",
        min: 1,
        max: 1000000000,
        step: 1,
        pattern: "[0-9]{10}",
      },
      inputPlaceholder: "Amount to request",
      showCancelButton: true,
      confirmButtonText: "Proceed",
      showLoaderOnConfirm: true,
      preConfirm: (amount) => {
        return directCredit({
          username: searchName,
          creditType: creditType,
          transactionType: transactionType,
          amount: amount,
        })
          .then((response) => {
            if (response.status === 200) {
              handleSearch(searchName);
              syncUserData(dispatch);

              nofitySuccess(
                `${transactionType} for ${searchName} with amount ${amount} done`
              );
            } else {
              notifyDanger(response?.message);
            }
          })
          .catch((error) => {
            Swal.showValidationMessage(`${error.response.data.message}`);
          });
      },
      allowOutsideClick: () => !Swal.isLoading(),
    });
  };

  const handleClodeModal = () => {
    setSearchResult();
    setIsFormOpened(false);
  };

  const placeBadge = (strNumber) => {
    return (
      <Badge color="" className="badge-dot">
        <i
          className={`bg-${
            strNumber && strNumber.includes("-") ? "danger" : "success"
          }`}
        />
      </Badge>
    );
  };

  const btnSelectSM = (label, icon) => {
    return (
      <Button
        className="btn-icon btn-3"
        color="primary"
        type="button"
        size="sm"
        onClick={handleClodeModal}
        outline
      >
        <span className="btn-inner--icon">
          <i className={`ni ni-${icon}`} />
        </span>
        <span className="btn-inner--text">{label}</span>
      </Button>
    );
  };

  return (
    <Modal
      centered
      scrollable
      isOpen={isFormOpened}
      toggle={handleClodeModal}
      className="modal-dialog-centered modal-info"
      contentClassName="bg-gradient-info"
    >
      <ModalHeader toggle={handleClodeModal}>Account Information</ModalHeader>

      <ModalBody>
        {searchResult ? (
          <Card className="card-profile shadow mt-md-5 mt-sm-0">
            {role !== "AGENT" &&
              creditPermissions.isAllowedDeposit &&
              creditPermissions.isAllowedWithraw && (
                <Row className="justify-content-center d-none d-sm-flex">
                  <Col className="order-lg-2" lg="3">
                    <div className="card-profile-image">
                      <a href="null" onClick={(e) => e.preventDefault()}>
                        <img
                          alt="..."
                          className="rounded-circle"
                          width={300}
                          src={imageUrls.get(searchResult.role)}
                        />
                      </a>
                    </div>
                  </Col>
                </Row>
              )}

            <CardHeader className="text-center border-0 pb-0 pb-md-4 pt-xs-5">
              {role !== "AGENT" && (
                <>
                  {creditPermissions.isAllowedDeposit && (
                    <div className="d-flex justify-content-between">
                      <Button
                        className="float-left"
                        color="warning"
                        href="null"
                        onClick={(e) =>
                          handleDirectCredit(e, "CHIPS", "DEPOSIT")
                        }
                        size="sm"
                        outline
                      >
                        <span className="btn-inner-icon">
                          <i className="ni ni-fat-add"></i>
                        </span>
                        <span className="btn-inner-text">CHIPS</span>
                      </Button>

                      <Button
                        className="float-left"
                        color="info"
                        href="null"
                        onClick={(e) =>
                          handleDirectCredit(e, "COINS", "DEPOSIT")
                        }
                        size="sm"
                        outline
                      >
                        <span className="btn-inner-icon">
                          <i className="ni ni-fat-add"></i>
                        </span>
                        <span className="btn-inner-text">COINS</span>
                      </Button>
                    </div>
                  )}

                  {creditPermissions.isAllowedDeposit && (
                    <div className="d-flex justify-content-between mt-3">
                      <Button
                        className="float-left"
                        color="warning"
                        href="null"
                        onClick={(e) =>
                          handleDirectCredit(e, "CHIPS", "WITHDRAW")
                        }
                        size="sm"
                        outline
                      >
                        <span className="btn-inner-icon">
                          <i className="ni ni-fat-delete"></i>
                        </span>
                        <span className="btn-inner-text">CHIPS</span>
                      </Button>

                      <Button
                        className="float-left"
                        color="info"
                        href="null"
                        onClick={(e) =>
                          handleDirectCredit(e, "COINS", "WITHDRAW")
                        }
                        size="sm"
                        outline
                      >
                        <span className="btn-inner-icon">
                          <i className="ni ni-fat-delete"></i>
                        </span>
                        <span className="btn-inner-text">COINS</span>
                      </Button>
                    </div>
                  )}
                </>
              )}
            </CardHeader>

            <CardBody className="pt-0 text-default">
              <div className="card-profile-stats d-flex justify-content-center">
                <Row className="w-100">
                  {searchResult.role !== "PLAYER" &&
                    !viewRestrictedPermissions.includes(
                      "COMMISSION_REPORT"
                    ) && (
                      <>
                        <Col md={6}>
                          <span className="heading text-default">
                            {loadingCommissionChips ? (
                              <Spinner size="sm">Loading...</Spinner>
                            ) : (
                              <>
                                {placeBadge(commissionChips)}
                                {commas(commissionChips)}
                              </>
                            )}
                          </span>
                          <span className="description">Commission Chips</span>
                        </Col>
                        <Col md={6}>
                          <span className="heading text-default">
                            {loadingCommissionCoins ? (
                              <Spinner size="sm">Loading...</Spinner>
                            ) : (
                              <>
                                {placeBadge(commissionCoins)}
                                {commas(commissionCoins)}
                              </>
                            )}
                          </span>
                          <span className="description">Commission Coins</span>
                        </Col>
                      </>
                    )}

                  <Col md={6}>
                    <span className="heading text-default">
                      {commas(searchResult.balanceChips)}
                    </span>
                    <span className="description">Chips</span>
                  </Col>
                  <Col md={6}>
                    <span className="heading text-default">
                      {commas(searchResult.balanceCoin)}
                    </span>
                    <span className="description">Coins</span>
                  </Col>
                </Row>
              </div>
              <hr className="mt-0 mb-3" />
              <div className="text-center">
                <h3>
                  {searchResult.username}
                  <span className="font-weight-light mr-2">
                    , {searchResult.role}
                  </span>
                  <i className="ni ni-app" />
                  <Badge
                    className="ml-2"
                    color={
                      searchResult.status === "ACTIVE" ? "success" : "danger"
                    }
                  >
                    {searchResult.status}
                  </Badge>
                </h3>

                <div className="text-center">
                  {role !== "AGENT" &&
                    searchResult.hierarchy &&
                    searchResult.hierarchy.length && (
                      <>
                        <p className="h6 text-left">UPLINES:</p>
                        <Breadcrumb>
                          {searchResult.hierarchy.map((index) => {
                            return (
                              <BreadcrumbItem
                                key={index.value}
                                className="mt-1"
                              >
                                <Button
                                  size="sm"
                                  type="button"
                                  onClick={(e) => {
                                    handleSearch(index.value);
                                    setSearchName(index.value);
                                  }}
                                >
                                  {index.value}
                                </Button>
                              </BreadcrumbItem>
                            );
                          })}
                        </Breadcrumb>
                      </>
                    )}

                  <Row className="justify-content-center mt-3">
                    {!viewRestrictedPermissions.includes(
                      "STATEMENT_DETAILS"
                    ) && (
                      <Col md={4} sm={12}>
                        <Link
                          to={
                            "/portal/statement-details/" + searchResult.username
                          }
                        >
                          {btnSelectSM("STATEMENT", "single-copy-04")}
                        </Link>
                      </Col>
                    )}

                    {searchResult.role !== "PLAYER" ? (
                      <>
                        {!viewRestrictedPermissions.includes(
                          "COMMISSION_REPORT"
                        ) && (
                          <>
                            <Col md={4} sm={12}>
                              <Link
                                to={
                                  "/portal/commission/summary/" +
                                  searchResult.username
                                }
                              >
                                {btnSelectSM("COMM. CHIPS", "chart-pie-35")}
                              </Link>
                            </Col>
                            <Col md={4} sm={12}>
                              <Link
                                to={
                                  "/portal/reports/commission/coins/" +
                                  searchResult.username
                                }
                              >
                                {btnSelectSM("COMM. COINS", "chart-pie-35")}
                              </Link>
                            </Col>
                          </>
                        )}
                        <Col className="mt-2" md={4} sm={12}>
                          <Link
                            to={"/portal/downlines/" + searchResult.username}
                          >
                            {btnSelectSM("DOWNLINES", "satisfied")}
                          </Link>
                        </Col>
                      </>
                    ) : (
                      <Col md={4} sm={12}>
                        <Link
                          to={"/portal/history/bet/" + searchResult.username}
                        >
                          {btnSelectSM("BET HISTORY", "chart-pie-35")}
                        </Link>
                      </Col>
                    )}
                    {!editRestrictedPermissions.includes("AGENT") && (
                      <>
                        <Col md={4} sm={12} className="mt-2">
                          <EditForm row={searchResult} />
                        </Col>
                        <Col md={4} sm={12} className="mt-2">
                          <ChangePasswordForm
                            id={searchResult.uuid}
                            name={searchResult.username}
                          />
                        </Col>
                      </>
                    )}
                  </Row>

                  <Row className="justify-content-center mt-3">
                    <Col md={3} sm={12}>
                      <p className="h6">Mobile:</p>
                      <div className="h5">{searchResult.mobileNumber}</div>
                    </Col>
                    <Col md={3} sm={12}>
                      <p className="h6">Email:</p>
                      <div className="h5">{searchResult.email}</div>
                    </Col>
                    {searchResult.role !== "PLAYER" && (
                      <>
                        <Col md={3} sm={12}>
                          <p className="h6">Commission CHIPS %:</p>

                          <div className="h5">
                            {searchResult.commissionChipsPercentage} %
                          </div>
                        </Col>
                        <Col md={3} sm={12}>
                          <p className="h6">Commission COINS %:</p>

                          <div className="h5">
                            {searchResult.commissionCoinsPercentage} %
                          </div>
                        </Col>
                      </>
                    )}
                  </Row>

                  <hr className="my-3" />
                  <p className="h5 font-weight-300">
                    Date & Time Registered: {searchResult.dateRegistered}
                  </p>
                </div>
              </div>
            </CardBody>
          </Card>
        ) : isSearchOn ? (
          <LoadingBar />
        ) : (
          <div className="text-center">
            <img alt="..." width={300} src={NoResultImage} />
            <h2 className="text-white mt-4">No Result found.</h2>
          </div>
        )}

        <Form onSubmit={(e) => handleSearch(searchName)}>
          <Row className="mt-4">
            <Col md={12}>
              <h3 className="text-white">Search another account ?</h3>
            </Col>
            <Col md={9} sm={12} className="text-dark">
              <Input
                type="text"
                placeholder="Type username here..."
                onChange={(e) => setSearchName(e.target.value)}
              />
            </Col>
            <Col md={3} sm={12} className="text-center mt-md-0 mt-2">
              {isSearchOn ? (
                <Spinner color="light" size="">
                  Loading...
                </Spinner>
              ) : (
                <Button
                  onClick={(e) => handleSearch(searchName)}
                  color="info"
                  type="submit"
                  block
                >
                  Search
                </Button>
              )}
            </Col>
          </Row>
        </Form>
      </ModalBody>
    </Modal>
  );
});

export default SearchModal;
