export const columns = [
  {
    name: "Id",
    sortable: true,
    selector: (row) => row.id,
  },
  {
    name: "Scheme",
    selector: (row) => row.scheme,
    sortable: true,
  },
  {
    name: "Mesage",
    sortable: true,
    selector: (row) => row.message,
  },
];
