// components
import DepositChannels from "./pages/p2p/DepositChannels";
import DepositAmount from "./pages/p2p/DepositAmount";
import DepositTransaction from "./pages/p2p/DepositTransaction";
import WithdrawAmount from "./pages/p2p/WithdrawAmount";
import HomeContactPage from "./pages/contacts";
import PlayerMyAccount from "./pages/player/my-account/PlayerMyAccount";
import CashierAutoDepositPage from "./pages/player/cashier-auto/deposit";
import CashierAutoWithdrawPage from "./pages/player/cashier-auto/withdraw";

var routes = [
  {
    path: "/my-account",
    name: "my account",
    component: PlayerMyAccount,
    invisible: true,
    layout: "/player",
  },
  {
    path: "/cashier/deposit",
    name: "my account",
    component: DepositChannels,
    invisible: true,
    layout: "/player",
  },
  {
    path: "/cashier-auto/deposit",
    name: "auto deposit",
    component: CashierAutoDepositPage,
    invisible: true,
    layout: "/player",
  },
  {
    path: "/cashier-auto/withdraw",
    name: "auto deposit",
    component: CashierAutoWithdrawPage,
    invisible: true,
    layout: "/player",
  },
  {
    path: "/deposit/:id",
    name: "deposit channel to",
    component: DepositAmount,
    invisible: true,
    layout: "/player",
  },
  {
    path: "/deposit-transaction/:mopId/:transactionId/:amount",
    name: "deposit channel to",
    component: DepositTransaction,
    invisible: true,
    layout: "/player",
  },
  {
    path: "/cashier/withdraw",
    name: "Withdraw Dashboard",
    component: WithdrawAmount,
    invisible: true,
    layout: "/player",
  },
  {
    path: "/contact",
    name: "Contact Us",
    component: HomeContactPage,
    invisible: true,
    layout: "/player",
  },
];
export default routes;
