import { useEffect, useState } from "react";

// utils
import { updateGame } from "actions";
import LoadingBar from "components/LoadBars";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { Button } from "reactstrap";
import { getGameList, playGame } from "./api";
import jiliBanner from "./image/banner.jpg";

const JILIGameDashboard = () => {
  const [games, setGames] = useState([]);
  const [activeCategory, setActiveCategory] = useState(1);
  const [isFetching, setIsFetching] = useState(false);

  const username = useSelector((state) => state.entities.user.username);

  const loginOutcome = useSelector((state) => state.ui.loginOutcome);
  const dispatch = useDispatch();
  const history = useHistory();

  useEffect(() => {
    setIsFetching(true);
    getGameList().then((data) => {
      setGames(data);
      setIsFetching(false);
    });
  }, []);

  const gameCategories = [
    {
      id: 1,
      name: "Slot 🎰",
    },
    {
      id: 2,
      name: "Poker 🃏",
    },
    {
      id: 5,
      name: "Fishing 🎣",
    },
    {
      id: 8,
      name: "Casino 🎲",
    },
  ];

  const handlePlay = async (gameId) => {
    if (loginOutcome !== "LOGGED_IN") {
      alert("Please login to play");
      return;
    }

    playGame(gameId, username).then((data) => {
      dispatch(
        updateGame({
          jiliURL: data,
        })
      );
      history.push("/game/JILI/" + gameId);
    });
  };

  return (
    <div className="bg-gray-900 w-full min-h-screen flex justify-center">
      {isFetching ? (
        <>
          <div className="w-full h-screen flex justify-center items-center">
            <LoadingBar />
          </div>
        </>
      ) : (
        <div className="xl:container mt-24">
          <img
            className="w-full h-fit object-cover"
            src={jiliBanner}
            alt="banner-jili"
          />

          <div className="p-3">
            <div className="border-b border-gray-200 flex justify-center">
              <nav
                className=" flex flex-wrap justify-center gap-4"
                aria-label="Tabs"
              >
                {gameCategories.map((category) => (
                  <div
                    key={category.id}
                    href="#"
                    className={`
                      cursor-pointer px-4 py-2 text-sm font-medium text-white hover:text-red-500 uppercase
                       ${
                         category.id === activeCategory
                           ? "text-white bg-red-600 rounded-xl mb-2"
                           : "border-b-2 border-transparent"
                       }
                    `}
                    onClick={() => setActiveCategory(category.id)}
                  >
                    {category.name}
                  </div>
                ))}
              </nav>
            </div>

            <div className="grid grid-cols-2 sm:grid-cols-4 lg:grid-cols-6 xl:grid-cols-8 gap-5 py-3">
              {games &&
                games.map((game, index) => (
                  <div
                    key={index}
                    className={
                      activeCategory === game.GameCategoryId
                        ? "block"
                        : "hidden"
                    }
                  >
                    <div className="relative group">
                      <img
                        className="w-full h-full aspect-square object-cover rounded-lg bg-gray-800"
                        src={`/images/jili/200x200_EN_GAMEID_${
                          game.GameId < 10 ? `0${game.GameId}` : game.GameId
                        }.png`}
                        alt={game.name["en-US"]}
                        onError={
                          ((e) => {
                            e.target.src = "/images/jili/game-logo.png";
                          },
                          (e) => {
                            e.target.onerror = null;
                            e.target.src = e.target.src =
                              "/images/jili/game-logo.png";
                            e.target.style.objectFit = "contain";
                          })
                        }
                      />

                      <div className="absolute inset-0 bg-black bg-opacity-0 group-hover:bg-opacity-50 transition-all duration-300  items-center justify-center hidden group-hover:flex">
                        <Button
                          className="btn-gold px-4 mr-0 border-yellow-200"
                          onClick={() => handlePlay(game.GameId)}
                        >
                          Play
                        </Button>
                      </div>
                    </div>
                    <div className="text-center mt-2">
                      <p className="text-white font-semibold">
                        {/* {game.name["en-US"]} - {game.GameId} */}
                        {game.name["en-US"]}
                      </p>
                    </div>
                  </div>
                ))}
            </div>
          </div>

          <div className="text-white text-center mb-5">
            <div>© Copyright 2024 iPLAYEVOAll Right Reserved.</div>
            <div>SABTECHNOLOGY</div>
          </div>
        </div>
      )}
    </div>
  );
};

export default JILIGameDashboard;
