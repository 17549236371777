import homeBanner from "assets/img/homepage/home-banner-2.webp";

const HomeContactPage = () => {
  return (
    <section className="mt-3 pt-2 pb-10 lg:container mx-auto">
      <img
        className="w-full h-fit object-cover rounded-2xl"
        src={homeBanner}
        alt="banner-jili"
      />

      <div className="px-4 xl:container">
        <div className="py-4">
          <h2 className="text-xl font-semibold text-white">Contact Us</h2>
        </div>

        <a
          href="https://l.messenger.com/l.php?u=http%3A%2F%2Fm.me%2F105759015691700&h=AT1TUc5oo5VmOhmy2wYgnCvoOEXG_zim6hspS63gEwATBNzU6o3EEt5hXbjX_bLYXee0yfNyoZmYe3pGsXYFmUW1W56PuTCbTnxkA3X6KosIOLn8UpKGU-yAETYmB3WJOVjy5Q"
          target="_blank"
          rel="noreferrer"
          className="bg-red-200 pr-5 flex gap-3 items-center font-bold text-gray-800 sm:text-lg rounded-2xl w-fit h-fit"
        >
          <div className="py-2 px-4 bg-red-600 text-white rounded-2xl">
            Facebook
          </div>
          <div>FB Page Messenger</div>
        </a>
      </div>
    </section>
  );
};

export default HomeContactPage;
