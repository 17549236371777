import React, { useState } from "react";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Input,
  Label,
} from "reactstrap";
import { patchMOP } from "api/p2p";
import { nofitySuccess, notifyWarning } from "components/Toasts";

const UpdateMop = ({ row, sync }) => {
  const [minimum, setMinimum] = useState(row.minimum);
  const [maximum, setMaximum] = useState(row.maximum);

  const [modal, setModal] = useState(false);
  const toggle = () => setModal(!modal);

  const update = (e) => {
    e.preventDefault();

    if (minimum < maximum) {
      patchMOP(row.id, "MIN", minimum);
      patchMOP(row.id, "MAX", maximum);
      nofitySuccess(`${row.accountName} updated`);
      sync();
    } else {
      notifyWarning(
        "Invalid input",
        "Please select a minimum less than maximum"
      );
    }
  };

  return (
    <div>
      <Button color="primary" onClick={toggle}>
        Update
      </Button>
      <Modal isOpen={modal} toggle={toggle} centered>
        <ModalHeader toggle={toggle}>
          Update MOP of <b>{row.accountName}</b>
        </ModalHeader>
        <ModalBody>
          <div className="mb-3">
            <Label for="minimum">Minimum Fee</Label>
            <Input
              id="minimum"
              type="text"
              defaultValue={minimum}
              onChange={(e) => setMinimum(e.target.value)}
            />
          </div>
          <div>
            <Label for="maximum">Maximum Fee</Label>
            <Input
              id="maximum"
              type="text"
              defaultValue={maximum}
              onChange={(e) => setMaximum(e.target.value)}
            />
          </div>
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={(e) => update(e)}>
            Update
          </Button>{" "}
          <Button color="secondary" onClick={toggle}>
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
};

export default UpdateMop;
