export const increment = () => {
  return {
    type: "INCREMENT",
  };
};

export const webToken = (token) => {
  return {
    type: "JWT",
    payload: token,
  };
};

export const userUid = (str) => {
  return {
    type: "UID",
    payload: str,
  };
};

// UI Actions
export const autoRefresh = (payload) => {
  return {
    type: "AUTO_REFRESH",
    payload: payload,
  };
};

export const breadCrumb = (payload) => {
  return {
    type: "BREADCRUMB",
    payload: payload,
  };
};

export const selectedAccount = (payload) => {
  return {
    type: "SELECT_USER",
    payload: payload,
  };
};

export const loggedInStart = () => {
  return {
    type: "LOGIN_START",
  };
};

export const loggedInSuccess = (payload) => {
  return {
    type: "SIGNIN",
    payload: payload,
  };
};

export const loggedInError = (payload) => {
  return {
    type: "ERROR",
    payload: payload,
  };
};

export const loggedOut = () => {
  return {
    type: "SIGNOUT",
  };
};

export const depositCreditCount = (data) => {
  return {
    type: "DEPOSIT_CREDIT_COUNT",
    payload: data,
  };
};

// Auth Actions
export const updateUserData = (data) => {
  return {
    type: "UPDATE",
    payload: data,
  };
};

// Entities Actions
export const setAccountTree = (data) => {
  return {
    type: "ACCOUNT_TREE",
    payload: data,
  };
};

export const getAccessLevels = (data) => {
  return {
    type: "ACCESS_LEVEL",
    payload: data,
  };
};

export const getChipTransactions = (data) => {
  return {
    type: "CHIP_TRANSACTIONS",
    payload: data,
  };
};

export const getCoinTransactions = (data) => {
  return {
    type: "COIN_TRANSACTIONS",
    payload: data,
  };
};

export const getSiteOwners = (data) => {
  return {
    type: "SITE_OWNER",
    payload: data,
  };
};

export const getMasters = (data) => {
  return {
    type: "MASTER",
    payload: data,
  };
};

export const getAgents = (data) => {
  return {
    type: "AGENTS",
    payload: data,
  };
};

export const getActiveAccountsByUpper = (data) => {
  return {
    type: "ACCOUNTS_UPPER_ACTIVE",
    payload: data,
  };
};

export const getInactiveAccountsByUpper = (data) => {
  return {
    type: "ACCOUNTS_UPPER_INACTIVE",
    payload: data,
  };
};

export const refresh = () => {
  return {
    type: "REFRESH",
  };
};

export const getCommissionProfit = (data) => {
  return {
    type: "COMMISION",
    payload: data,
  };
};

export const getCoinsCommission = (data) => {
  return {
    type: "COMMISION_COINS",
    payload: data,
  };
};

export const setPermissions = (data) => {
  return {
    type: "PERMISSIONS",
    payload: data,
  };
};

export const setFilter = (data) => {
  return {
    type: "FILTER",
    payload: data,
  };
};

export const updateGame = (data) => {
  return {
    type: "GAME",
    payload: data,
  };
};
