export const columns = [
  {
    name: "Id",
    sortable: true,
    selector: (row) => row.id,
    width: "100px",
  },
  {
    name: "Date",
    sortable: true,
    selector: (row) => row.timeStamp,
  },
  {
    name: "Scheme",
    selector: (row) => row.scheme,
    sortable: true,
  },
  {
    name: "Mesage",
    sortable: true,
    selector: (row) => row.message,
  },
  {
    name: "Game Number",
    sortable: true,
    selector: (row) => row.number,
  },
  {
    name: "Position",
    sortable: true,
    selector: (row) => row.position,
  },
  {
    name: "Odds",
    sortable: true,
    selector: (row) => row.odds,
  },
];
