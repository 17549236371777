import React from "react";

import {
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import { patchMOP } from "api/p2p";
import { nofitySuccess } from "components/Toasts";

const UpdateMopStatus = ({ row, sync }) => {
  const handleUpdateStatus = (row, value) => {
    patchMOP(row.id, "STATUS", value).then(() => {
      nofitySuccess(`${row.accountName} updated status ${value}`);
      sync();
    });
  };

  return (
    <div
      className={`btn btn-sm ${
        row.status === "ACTIVE" ? "btn-success" : "btn-danger"
      }`}
    >
      <UncontrolledDropdown setActiveFromChild direction="left">
        <DropdownToggle caret className="nav-link text-white" tag="a">
          {row.status}
        </DropdownToggle>
        <DropdownMenu>
          <DropdownItem
            onClick={() => handleUpdateStatus(row, "ACTIVE")}
            active={row.status === "ACTIVE"}
          >
            Active
          </DropdownItem>
          <DropdownItem
            onClick={() => handleUpdateStatus(row, "INACTIVE")}
            active={row.status === "INACTIVE"}
          >
            In-Active
          </DropdownItem>
        </DropdownMenu>
      </UncontrolledDropdown>
    </div>
  );
};

export default UpdateMopStatus;
