import React from "react";
import {
  useHistory,
  useParams,
} from "react-router-dom/cjs/react-router-dom.min";
import useSWR from "swr";
import { fetcher } from "./api";
import { Gamepad2Icon } from "lucide-react";
import LoadingSpinner from "components/LoadBars/loading-spinner";

const GoldenGateXVendorGameListPage = () => {
  const { vendor } = useParams();
  const history = useHistory();
  const { data, isLoading } = useSWR(`/games?vendorCode=${vendor}`, fetcher);

  if (isLoading) {
    return (
      <div className="mt-24 p-3">
        <LoadingSpinner />
      </div>
    );
  }

  return (
    <div className="mt-24">
      <div className="px-4 py-10 sm:px-6 lg:px-8 space-y-4">
        <div className="mx-auto flex items-center justify-between gap-x-8 lg:mx-0">
          <div className="flex items-center gap-x-6">
            <div className="size-16 flex-none rounded-full ring-1 ring-gray-900/10 bg-red-400 flex justify-center items-center">
              <Gamepad2Icon size={48} className="text-red-800" />
            </div>

            <h1 className="text-white">
              <div className="text-lg text-white">Games from {vendor}</div>
              <div className="mt-1 text-base font-semibold">
                {data && data.length} games available
              </div>
            </h1>
          </div>
          <div className="flex items-center gap-x-4 sm:gap-x-6">
            <button
              onClick={() => history.push("/game/golden-gateX")}
              className="rounded-md bg-red-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-red-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-red-600"
            >
              Go back
            </button>
          </div>
        </div>
        <h1 className="text-3xl font-bold text-white">Games from {vendor}</h1>
        <div>
          {data && data.length > 0 ? (
            <div className="grid grid-cols-2 md:grid-cols-4 xl:grid-cols-8 gap-4">
              {data.map((game, index) => (
                <div key={index} className="w-full h-full relative">
                  <img
                    src={game.thumbnail}
                    alt="game image"
                    className="size-52 object-cover rounded-lg w-full"
                  />
                  <h5 class="uppercase text-base mt-2 text-white text-center">
                    {game.gameName}
                  </h5>
                </div>
              ))}
            </div>
          ) : (
            <h1 className="text-3xl font-bold text-white">
              No games available
            </h1>
          )}
        </div>
      </div>
    </div>
  );
};

export default GoldenGateXVendorGameListPage;
