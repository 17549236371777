import { useState, useEffect } from "react";
import Header from "components/Headers/Header.js";
import { Card, CardHeader, Row, Container, Col } from "reactstrap";

import DataTable from "react-data-table-component";
import DataTableExtensions from "react-data-table-component-extensions";

import { columns } from "./data";

// Components
import LoadingBar from "components/LoadBars";
import CreateSubAccount from "components/Modals/createsub";

import { useSelector } from "react-redux";
import { getSubAccounts } from "api/AccountAPI";

function SubAccount() {
  const [data, setData] = useState([]);
  const [sync, setSync] = useState(false);
  const [loading, setLoading] = useState(true);

  const permissions = useSelector((state) => state.ui.permissions);
  const isAllowedEdit = !permissions.edit.includes("SUB_ACCOUNT");
  const isAllowedCreate = !permissions.create.includes("SUB_ACCOUNT");

  const toggleSync = () => {
    setSync(!sync);
    setLoading(true);
  };

  // Table Handling
  useEffect(() => {
    getSubAccounts().then((response) => {
      setLoading(false);
      setData(response);
    });
    // eslint-disable-next-line
  }, [sync]);

  return (
    <>
      <Header />

      <Container className="mt--7" fluid>
        <Row>
          <div className="col">
            <Card className="shadow">
              <CardHeader className="border-0">
                <Row>
                  <Col lg={6} sm={12}>
                    <h2 className="mb-0">Sub Account Management</h2>
                  </Col>
                  <Col lg={6} sm={12} className="text-lg-right mt-3 mt-lg-0">
                    {isAllowedCreate && (
                      <CreateSubAccount refresh={toggleSync} />
                    )}
                  </Col>
                </Row>
              </CardHeader>

              <DataTableExtensions columns={columns(isAllowedEdit)} data={data}>
                <DataTable
                  pagination
                  striped
                  columns={columns(isAllowedEdit)}
                  data={data}
                  progressComponent={<LoadingBar />}
                  progressPending={loading}
                />
              </DataTableExtensions>
            </Card>
          </div>
        </Row>
      </Container>
    </>
  );
}

export default SubAccount;
