import React, { useState } from "react";
import { Button, Modal, ModalHeader, ModalBody, Input } from "reactstrap";

import { authorizeCredit } from "api/Credit";
import { syncUserData } from "api/AccountAPI";
import { useDispatch } from "react-redux";
import { autoRefresh } from "actions";

// Swipe Button Library
import ReactSwipeButton from "react-swipe-button";
import { nofitySuccess, notifyDanger, notifyWarning } from "components/Toasts";

const Confirm = (props) => {
  const dispatch = useDispatch();

  const [modal, setModal] = useState(false);
  const [reasonModal, setReasonModal] = useState(false);
  const isDeposit = props.type === "DEPOSIT";
  const [reason, setReason] = useState("");

  const closeApproveModal = () => {
    setModal(false);
  };

  const toggleModal = () => {
    setModal(!modal);
    dispatch(autoRefresh(false));
  };

  const toggleReasonModal = () => {
    setReasonModal(!reasonModal);
  };

  const openReasonModal = () => {
    toggleReasonModal();
    toggleModal();
  };

  const handleSync = () => {
    props.sync();
  };

  const handleCredit = (decision) => {
    if (decision === "APPROVE" && isDeposit && props.isP2PTransaction && !props.hasProfOfPayment) {
      notifyWarning("Screenshot required for transaction approval. Ask the user to provide it.");
      toggleModal();
      return;
    }

    authorizeCredit(props.id, decision).then((response) => {
      if (response?.statusCode === "OK") {
        nofitySuccess(`Credit proccessed for ${decision}`);
        syncUserData(dispatch);
      } else {
        notifyDanger(response.message);
      }
      closeApproveModal();
      handleSync();
    });
  };

  const cancelCredit = (e, reason) => {
    e.preventDefault();

    authorizeCredit(props.id, "DECLINE", reason).then((response) => {
      if (response.statusCode === "OK") {
        nofitySuccess(`Credit canceled for ${props.user}, ${reason}`);
      } else {
        notifyDanger(response.message);
      }

      toggleReasonModal();
      handleSync();
    });
  };

  return (
    <>
      <Button
        className="text-nowrap w-100"
        color="success"
        onClick={toggleModal}
        title="Approve"
        size="sm"
      >
        Approve
      </Button>
      <Button
        className="text-nowrap w-100 mx-0 mt-1"
        color="danger"
        onClick={(e) =>
          props.isP2PTransaction ? openReasonModal() : handleCredit("DECLINE")
        }
        size="sm"
        title="Approve"
      >
        Decline
      </Button>
      <Modal
        className="modal-dialog-centered"
        size="sm"
        isOpen={modal}
        centered
        toggle={() => closeApproveModal}
      >
        <ModalHeader toggle={closeApproveModal}>
          Authorise {props.type}
        </ModalHeader>
        <ModalBody className="text-center">
          <h1 className="display-1">
            <i className="ni ni-lock-circle-open" />
          </h1>
          <div className="mt-3 mb-3">
            {props.type} <b>{props.amount}</b> credit to <b>{props.user}</b>?
          </div>
          <ReactSwipeButton
            text="SWIPE TO APPROVE"
            color="#11cdef"
            onSuccess={() => handleCredit("APPROVE")}
          />

          <div>
            <br />
            <br />
            <br />
            NOTE: Please wait for the{" "}
            <strong className="text-danger">screenshot</strong> before approving
          </div>
        </ModalBody>
        <div className="text-center">
          <div className="mt-5">
            <Button
              color="danger"
              outline
              type="button"
              onClick={(e) =>
                props.isP2PTransaction
                  ? openReasonModal()
                  : handleCredit("DECLINE")
              }
            >
              <i className="fa fa-times"></i>
            </Button>
          </div>
          <div className="mt-1 mb-3">Decline / Cancel</div>
        </div>
      </Modal>

      <Modal
        className="modal-dialog-centered"
        size="sm"
        isOpen={reasonModal}
        centered
        toggle={() => toggleReasonModal}
      >
        <ModalHeader toggle={toggleReasonModal}>Reason</ModalHeader>
        <ModalBody>
          <div>
            Reciever: <b>{props.user}</b>
          </div>
          <div>
            Purpose of Message: <b>Cancelled Deposit</b>
          </div>
          <div className="my-2">
            <div>Reason:</div>

            {isDeposit ? (
              <>
                <Button
                  className="mt-2"
                  block
                  color="primary"
                  size="lg"
                  onClick={(e) =>
                    cancelCredit(
                      e,
                      "The Amount sent through e-wallet/bank is not equal to the request deposit amount."
                    )
                  }
                >
                  The Amount sent through e-wallet/bank is not equal to the
                  request deposit amount.
                </Button>
                <Button
                  className="ml-0 my-2"
                  block
                  color="primary"
                  size="lg"
                  onClick={(e) => cancelCredit(e, "Invalid minimum cash in")}
                >
                  Invalid minimum cash in
                </Button>
                <Button
                  className="mb-2 ml-0"
                  block
                  color="primary"
                  size="lg"
                  onClick={(e) =>
                    cancelCredit(
                      e,
                      "Numerous times sending the same proof of payment"
                    )
                  }
                >
                  Numerous times sending the same proof of payment
                </Button>
              </>
            ) : (
              <>
                <Input
                  type="text"
                  name="reason"
                  placeholder="Type reason here..."
                  onChange={(e) => setReason(e.target.value)}
                  value={reason}
                />
                <Button
                  className="mt-2"
                  block
                  color="primary"
                  onClick={(e) => cancelCredit(e, reason)}
                >
                  Submit
                </Button>
              </>
            )}
          </div>

          <div>Note: Please contact the loader for more info.</div>
        </ModalBody>
      </Modal>
    </>
  );
};

export default Confirm;
