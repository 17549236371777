import fetchClient from "api/axios";

// Authorization
import store from "store";

import { updateUserData, loggedOut } from "actions";
import { filterBuilder } from "views/shared/utils";

const getCurrentUserId = () => {
  return store.getState().auth.uuid;
};

export const syncUserData = async (dispatch) => {
  await fetchClient
    .get("/accounts/" + getCurrentUserId())
    .then((responseData) => {
      if (
        responseData?.status === 500 &&
        responseData?.message === "Session expired, Please re-login again."
      ) {
        dispatch(loggedOut());
        return;
      }
      return (
        responseData &&
        responseData.data &&
        dispatch(updateUserData(responseData.data[0]))
      );
    })
    .catch((error) => console.error(error));
};

export const getActiveAcounts = async () => {
  const response = await fetchClient.get(
    `/accounts/upper/${getCurrentUserId()}`
  );
  return response.status === 200 ? response.data : [];
};

export const postAccount = async (payload) => {
  const response = await fetchClient.post(`/user`, payload);
  return response.status === 200 ? response.data[0] : false;
};

export const deleteAccount = async (uuidAccount) => {
  const response = await fetchClient.delete(`/accounts/${uuidAccount}`);
  return response.status === 200 ? response.data : false;
};

export const UpdateBasicInformation = async (AccountUID, basicDataBody) => {
  const response = await fetchClient.patch(
    `/accounts/basic-data/${AccountUID}`,
    basicDataBody
  );
  return response.status === 200 ? response.data[0] : false;
};

export const UpdateReferralCode = async (AccountUID, code) => {
  const response = await fetchClient.patch(
    `/accounts/${AccountUID}?referralCode=${code}`
  );

  return response.status === 200 ? response.data.statusCode === "OK" : false;
};

//
export const changeStatus = async (id, status) => {
  const response = await fetchClient.patch(`/accounts/${id}?status=${status}`);
  return response.status === 200 ? response.data : false;
};

export const changePassword = async (id, password) => {
  const response = await fetchClient.patch(
    `/accounts/${id}?password=${password}`
  );
  return response.status === 200 ? response.data : false;
};

// List API's
export const getDownlineAccounts = async (status) => {
  const response = await fetchClient.get(
    `/accounts/upper/${getCurrentUserId()}?status=${status}`
  );
  return response.status === 200 ? response.data : [];
};

export const getActiveDownlines = async (user) => {
  const response = await fetchClient.get(`/accounts/upper?user=${user}`);
  return response.status === 200 ? response.data : [];
};

export const getAccountInformation = async (user) => {
  if (!user) {
    return null;
  }

  return await fetchClient.get(`/accounts?username=${user}`);
};

// SUB ACCOUNT
export const getSubAccounts = async () => {
  const response = await fetchClient.get(`/sub-accounts/${getCurrentUserId()}`);
  return response.status === 200 ? response.data : [];
};

export const createSubAccounts = async (payload) => {
  const response = await fetchClient.post(`/sub-accounts`, payload);
  return response.status === 200 ? response.data : [];
};

export const patchPermission = async (id, value, attribute) => {
  let URL = "";

  if (attribute === "VIEW") {
    URL = `/permissions/${id}?view=${value}`;
  } else if (attribute === "CREATE") {
    URL = `/permissions/${id}?create=${value}`;
  } else if (attribute === "EDIT") {
    URL = `/permissions/${id}?edit=${value}`;
  }
  // eslint-disable-next-line

  const response = await fetchClient.patch(URL);
  return response.status === 200 ? response.data : false;
};

// Master Account
export const getPlayersOfMaster = async () => {
  const response = await fetchClient.get(
    `/masters/players/${getCurrentUserId()}`
  );
  return response.status === 200 ? response.data : [];
};

// Logs
export const getLoginLogs = async (filter) => {
  const response = await fetchClient.get(
    `/user/logs/login` + filterBuilder(filter)
  );
  return response.status === 200 ? response.data : [];
};

export const checkSession = async (filter) => {
  const response = await fetchClient.get(
    `/accounts/check-session?username=${filter}`
  );
  return response.status === 200 ? response.data : [];
};
