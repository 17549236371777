import { Row, Col } from "reactstrap";

import { commas } from "views/shared/utils";

import chipsImage from "assets/img/chips-icon.png";
import coinsImage from "assets/img/coins-icon.png";

export const columns = [
  {
    name: "Transaction #",
    width: "180px",
    selector: (row) => row.transactionId,
    sortable: true,
  },
  {
    name: "Type / Executed By",
    selector: (row) => row.transactionType,
    sortable: true,
    width: "170px",
    cell: (row) =>
      row.executedBy && (
        <Row className="text-center">
          <Col md={12}>{row.transactionType}</Col>
          <Col md={12}>{row.executedBy}</Col>
        </Row>
      ),
  },
  {
    name: "Date / Time",
    selector: (row) => row.dateAndTime,
    sortable: true,
    wrap: true,
  },
  {
    name: "Game",
    selector: (row) => row.gameType,
    sortable: true,
  },
  {
    name: "Game #",
    selector: (row) => row.gameNumber,
    sortable: true,
  },
  {
    name: "Results",
    selector: (row) => row.gameResult,
    width: "156px",
    sortable: true,
  },
  {
    name: "Position",
    // selector: (row) => row.gamePosition,
    sortable: true,
    wrap: true,
    cell: (row) => {
      return row.gameType === "DODGE_BOMB" ? "" : row.gamePosition;
    },
  },
  {
    name: "Amount",
    selector: (row) => row.amount,
    sortable: true,
    cell: (row) =>
      row.creditEvent === "INCREASE" ? (
        <>
          <i className="text-success fa fa-arrow-up"></i>
          <span className="ml-1">{commas(row.amount)}</span>
        </>
      ) : (
        <>
          <i className="text-danger fa fa-arrow-down"></i>
          <span className="ml-1 text-danger">{commas(row.amount)}</span>
        </>
      ),
  },
  {
    name: "Balance",
    selector: (row) => commas(row.balance),
    sortable: true,
  },
  {
    name: "Credit",
    selector: (row) => row.creditType,
    sortable: true,
    cell: (d) => (
      <>
        <img
          src={d.creditType === "CHIPS" ? chipsImage : coinsImage}
          alt=""
          width={20}
        />
        <span className="ml-2">{d.creditType}</span>
      </>
    ),
  },
];
