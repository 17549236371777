import React, { useState, useEffect, useRef } from "react";

import {
  Card,
  CardHeader,
  Row,
  Container,
  Button,
  Col,
  Breadcrumb,
  BreadcrumbItem,
} from "reactstrap";

import { columnsUpper } from "./data/data";
import { columnsAgent } from "./data/agentdata";

import { pendingCredit } from "api/Credit";
import { useSelector } from "react-redux";

// Spinners
import LoadingBar from "components/LoadBars";
import Header from "components/Headers/Header.js";

import DataTable from "react-data-table-component";
import DataTableExtensions from "react-data-table-component-extensions";

import CreateForm from "./modals/Create";
import MultipleForm from "./modals/Multiple";
import ExpandedTable from "./data/ExpandedTable";

import Notification from "./Notification";
import AutoRefresh from "./AutoRefresh";

// resources
import { newDepositRequestSound } from "assets/sounds";

function Credit() {
  const audioRef = useRef(null);

  const [sync, setSync] = useState(false);
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);
  const [clearSelect, setClearSelect] = useState(false);
  const [selectedData, setSelectedData] = useState([]);
  const role = useSelector((state) => state.entities.user.role);
  const isRoleNotAgent = role !== "AGENT";

  const editPermissions = useSelector((state) => state.ui.permissions.edit);
  const viewPermission = useSelector((state) => state.ui.permissions.view);
  const isDepositAllowed = !editPermissions?.includes("DEPOSIT");
  const isMOPAllowed = !viewPermission?.includes("P2P_TRANSACTION");

  const playNewDepositRequest = () => {
    if (audioRef.current) {
      audioRef.current.play();
    }
  };

  // Table Handling
  useEffect(() => {
    pendingCredit("DEPOSIT").then((response) => {
      setData(response);
      setClearSelect(false);
      setLoading(false);
    });
    // eslint-disable-next-line
  }, [sync]);

  const handleSync = () => {
    setSync(!sync);
    setClearSelect(true);
    setLoading(true);
  };

  const handleLoad = () => {
    setLoading(true);
    pendingCredit("DEPOSIT").then((response) => {
      if (response.length > data.length) {
        playNewDepositRequest();
      }

      setData(response);
      setClearSelect(false);
      setLoading(false);
    });
  };

  const handleSelection = (value) => {
    setSelectedData(value.selectedRows);
  };

  let columns = null;
  if (isRoleNotAgent) {
    columns = columnsUpper(handleSync, isDepositAllowed, "DEPOSIT");
  } else {
    columns = columnsAgent;
  }

  if (!isMOPAllowed) {
    columns.shift();
  }

  return (
    <>
      <Header />
      <Notification />
      <audio ref={audioRef}>
        <source src={newDepositRequestSound} type="audio/mpeg" />
      </audio>
      <Container className="mt--7" fluid>
        <Row>
          <div className="col">
            <Card className="shadow">
              <Breadcrumb listClassName="breadcrumb-links">
                <BreadcrumbItem>Credit Management</BreadcrumbItem>
                <BreadcrumbItem>Deposit</BreadcrumbItem>
              </Breadcrumb>
              <CardHeader className="border-0">
                <Row>
                  <Col lg={6} sm={12}>
                    <h2 className="mb-0">Deposit Management</h2>
                  </Col>
                  <Col lg={6} sm={12} className="text-lg-right mt-3 mt-lg-0">
                    <Button
                      className="btn-icon btn-2"
                      color="default"
                      type="button"
                      outline
                      onClick={handleSync}
                    >
                      <span className="btn-inner--icon">
                        <i className="fa fa-refresh"></i>
                      </span>
                    </Button>

                    {isRoleNotAgent && isDepositAllowed ? (
                      <MultipleForm rows={selectedData} sync={handleSync} />
                    ) : null}
                    {isDepositAllowed && (
                      <CreateForm data="DEPOSIT" sync={handleSync} />
                    )}
                  </Col>
                </Row>
              </CardHeader>
              <div className="bg-option">
                <Row>
                  <Col md={3}>
                    <AutoRefresh sync={handleLoad} />
                  </Col>
                </Row>
              </div>

              <DataTableExtensions
                export={false}
                print={false}
                columns={columns}
                data={data}
              >
                <DataTable
                  title={"Pending Deposits (" + data.length + ")"}
                  columns={columns}
                  data={data}
                  defaultSortAsc={true}
                  onSelectedRowsChange={handleSelection}
                  clearSelectedRows={clearSelect}
                  selectableRows={isRoleNotAgent}
                  progressComponent={<LoadingBar />}
                  progressPending={loading}
                  pagination
                  striped
                  expandableRows
                  expandableRowsComponent={ExpandedTable}
                />
              </DataTableExtensions>
            </Card>
          </div>
        </Row>
      </Container>
    </>
  );
}

export default Credit;
