import { useState } from "react";

// React strap tools
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Row,
  Col,
} from "reactstrap";

export const TermsAndConditions = () => {
  const [modal, setModal] = useState(false);

  const toggle = () => setModal(!modal);

  return (
    <>
      <span color="danger" onClick={toggle}>
        Terms And Conditions
      </span>
      <Modal size="lg" isOpen={modal} toggle={toggle}>
        <ModalHeader toggle={toggle}>
          <h1 className="mx-auto">iPLAYEVO TERMS AND CONDITIONS</h1>
        </ModalHeader>
        <ModalBody>
          <Row>
            <Col md={12}></Col>
            <Col md={12}>
              By accessing and using the App (iPLAYEVO), owned and operated by
              (shinbtechnology), You agree to be bound by the terms and
              conditions set forth below. If you do not agree to by bound by
              this Agreement, do leave the App immediately. The Company only
              agrees to provide use of the App to you if You assent to this
              Agreement.
            </Col>

            <Col md={12}>
              <h3 className="mt-4">DEFINITIONS</h3>
            </Col>
            <Col md={12}>
              The parties referred to in this Agreement shall be defined as
              follows:
            </Col>
            <Col md={12}>
              Company,Us, We: The Company, as the creator, operator, and
              publisher of the App, make the App on it, available to users.
              Shinbtechnology, Company, We, Us, Our, Ours and other first-person
              pronouns will refer to the Company, as well as all employees and
              affiliates of the Company. You, the User, the Client: you as the
              user of the website will be referred to throughout this Agreement
              with second-person pronouns such as You, Your, Yours, or as User
              or Client. Parties: Collectively, the parties to this Agreement
              (the Company and You) will be referred to as Parties.
            </Col>
            <Col md={12}>
              <h3 className="mt-4">AGE RESTRICTION</h3>
            </Col>
            <Col md={12}>
              You must be at least Eighteen (18) years of age to use this App
              contained herein. By using this App, You represent and warrant
              that You are at least 18 years of age and may legally agree to
              this Agreement. The Company assumes no responsibility of liability
              for any misrepresentation of Your age.
            </Col>
            <Col md={12}>
              <h3 className="mt-4">INTELLECTUAL PROPERTY</h3>
            </Col>
            <Col md={12}>
              The App, including all content features in the App including all
              text, graphics, images, logos, trademarks, the link, and Services
              provided by the Company are the property of the Company. You agree
              that the Company owns all rights, title, and interest in and to
              the Content and that You will not use the Content for any unlawful
              or infringing purpose.
            </Col>
            <Col md={12}>
              Subject to this Agreement, the Company grants You a non-exclusive,
              limited, and revocable license to use the Content solely in
              connection with Your use of the App. The Content may not be used
              for any other purpose, and this license terminates upon Your
              cessation of the use of the App or at the termination of this
              Agreement.
            </Col>
            <Col md={12}>
              <h3 className="mt-4">USER ACCOUNTS AND OBLIGATIONS</h3>
            </Col>
            <Col md={12}>
              Some content on the App may only be accessed by the user by
              registering with Us. When you do so, you will choose a user
              identifier, which may be Your email address or another term, as
              well as a password. You may also be required to provide personal
              information, including, but not limited to, Your name. you are
              responsible for ensuring the accuracy of this information. You
              agree to keep Your user identifier and password confidential and
              that You will not share such identifying information with any
              third party. If You discover that your identifying information has
              been compromised, you agree to notify Us immediately in writing.
            </Col>
            <Col md={12}>
              You are responsible for maintaining the safety and security of
              your Identifying information. Providing false or inaccurate
              information, or using the App to further fraud or unlawful
              activity is ground for immediate termination of this Agreement.
            </Col>
            <Col md={12}>
              You agree that You are solely responsible for all acts or
              omissions that occur under Your identifying information or
              password, including the content of any transmissions using the
              App.
            </Col>
            <Col md={12}>
              <h3 className="mt-4">CHIPS/COINS PURCHASE</h3>
            </Col>
            <Col md={12}>
              You agree to ensure payment for chip/coin You purchase from Us,
              and You acknowledge and affirm that the minimum and maximum are
              subject to change. You agree to provide us a valid proof of
              payment. When purchasing a chip/coin, we reserve the right to
              reject or cancel the purchase for any reason, including errors or
              edited proof of payment You provide to Us.{" "}
            </Col>
            <Col md={12}>
              The Company accepts the following methods of payment through
              messenger: Gcash,Bank
            </Col>

            <Col md={12}>
              <h3 className="mt-4">PRIVACY</h3>
            </Col>
            <Col md={12}>
              Through Your Use of the App, you may provide Us with certain
              information. By using the App, You authorize the Company to use
              Your information in the Philippines.
            </Col>
            <Col md={12}>
              Please refer to Our privacy policy for further information about
              what information We collect, how we use it and store it, and your
              rights in relation to it.{" "}
            </Col>
            <Col md={12}>
              <h3 className="mt-4">ENTIRE AGREEMENT</h3>
            </Col>
            <Col md={12}>
              This Agreement constitutes the entire understanding between the
              Parties with respect to any and all use of this App. The Agreement
              supersedes and replaces all prior or contemporaneous agreements or
              understandings, written or oral, regarding the use of the App.
            </Col>
            <Col md={12}>
              <h3 className="mt-4">EFFECTIVE DATE</h3>
            </Col>
            <Col md={12}>
              This Agreement will become effective on __________.
            </Col>
          </Row>
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={toggle} outline>
            Accept
          </Button>
        </ModalFooter>
      </Modal>
    </>
  );
};

export default TermsAndConditions;
