import { useState } from "react";

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  Container,
  Row,
  Col,
} from "reactstrap";

// import NotificationAlert from "react-notification-alert";
import Swal from "sweetalert2";

// core components
import UserHeader from "components/Headers/UserHeader.js";

// Redux
import { useSelector, useDispatch } from "react-redux";

// API call
import {
  UpdateBasicInformation,
  UpdateReferralCode,
  syncUserData,
} from "api/AccountAPI";

const EditProfile = () => {
  const dispatch = useDispatch();
  const JWT = useSelector(state => state.auth.jwt);
  const UID = useSelector(state => state.auth.uuid);

  const username = useSelector(state => state.entities.user.username);
  const [name, setName] = useState(
    useSelector(state => state.entities.user.name)
  );
  const [mobile, setMobile] = useState(
    useSelector(state => state.entities.user.mobile)
  );
  const [email, setEmail] = useState(
    useSelector(state => state.entities.user.email)
  );
  const [referralCode, setReferralCode] = useState(
    useSelector(state => state.entities.user.referralCode)
  );

  const handleEditForm = e => {
    e.preventDefault();

    UpdateBasicInformation(
      UID,
      {
        email: email,
        mobileNumber: mobile,
        name: name,
      },
      JWT
    ).then(response => {
      if (response.statusCode === "OK") {
        Swal.fire({
          position: "top-end",
          icon: "success",
          title: "Your information has been saved",
          showConfirmButton: false,
          timer: 1500,
        });
        syncUserData(dispatch);
      } else {
        Swal.fire({
          title: "Failed!",
          text: response.message,
          icon: "error",
        });
      }
    });
  };

  const handleReferralCode = () => {
    Swal.fire({
      title: "Update 'Referral Code'",
      input: "text",
      confirmButtonText: "Update",
      showLoaderOnConfirm: true,
      showCancelButton: true,
      preConfirm: code => {
        return UpdateReferralCode(UID, code, JWT)
          .then(response => {
            if (!response) {
              Swal.showValidationMessage(
                `Update failed: ${code} is already used by other accounts.`
              );
            }
          })
          .catch(error => {
            Swal.showValidationMessage(`Request failed: ${error}`);
          });
      },
      allowOutsideClick: () => !Swal.isLoading(),
    }).then(result => {
      if (result.isConfirmed) {
        Swal.fire({
          icon: "success",
          title: `Record updated!`,
        });

        syncUserData(dispatch);
      }
    });
  };

  return (
    <>
      <div className="rna-wrapper">
        {/* <NotificationAlert ref={notificationAlertRef} /> */}
      </div>
      <UserHeader />
      <Container className="mt--7" fluid>
        <Row>
          <Col className="order-xl-1 mx-auto" xl="8">
            <Card className="bg-secondary shadow">
              <CardHeader className="bg-white border-0">
                <Row className="align-items-center">
                  <Col xs="8">
                    <h3 className="mb-0">My account</h3>
                  </Col>
                  <Col className="text-right" xs="4"></Col>
                </Row>
              </CardHeader>
              <CardBody>
                <Form
                  onSubmit={e => {
                    handleEditForm(e);
                  }}
                >
                  <h6 className="heading-small text-muted mb-4">
                    Basic information
                  </h6>
                  <div className="pl-lg-4">
                    <Row>
                      <Col lg="6">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-username"
                          >
                            Username
                          </label>
                          <Input
                            className="form-control-alternative"
                            id="input-username"
                            placeholder="iplayevo"
                            type="text"
                            required
                            value={username}
                            disabled
                          />
                        </FormGroup>
                      </Col>
                      <Col lg="6">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-email"
                          >
                            Email address
                          </label>
                          <Input
                            className="form-control-alternative"
                            id="input-email"
                            placeholder="iplayevo@example.com"
                            type="email"
                            value={email}
                            required
                            onChange={e => setEmail(e.target.value)}
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col lg="6">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-first-name"
                          >
                            Full name
                          </label>
                          <Input
                            className="form-control-alternative"
                            id="input-first-name"
                            placeholder="Pusoy"
                            type="text"
                            onChange={e => setName(e.target.value)}
                            value={name}
                          />
                        </FormGroup>
                      </Col>
                      <Col lg="6">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-last-name"
                          >
                            Mobile
                          </label>
                          <Input
                            className="form-control-alternative"
                            id="input-last-name"
                            placeholder="Your mobile..."
                            type="number"
                            required
                            onChange={e => setMobile(e.target.value)}
                            value={mobile}
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    <Button color="success" type="submit">
                      Save
                    </Button>
                  </div>
                  <hr className="my-4" />
                  <h6 className="heading-small text-muted mb-4">
                    Account Information
                  </h6>
                  <div className="pl-lg-4">
                    <Row>
                      <Col md="5">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-address"
                          >
                            Referral Code
                          </label>
                          <Input
                            className="form-control-alternative"
                            id="input-address"
                            placeholder="Your referral code"
                            type="text"
                            value={referralCode}
                            required
                            disabled
                            onChange={e => setReferralCode(e.target.value)}
                          />
                        </FormGroup>
                      </Col>
                      <Col md={2} className="d-flex align-items-center">
                        <Button onClick={handleReferralCode} color="info">
                          Update
                        </Button>
                      </Col>
                    </Row>
                  </div>
                </Form>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default EditProfile;
