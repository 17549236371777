import { convertDateAPIFormat } from "views/shared/utils";

import moment from "moment";

export const todaysDefaultFilter = (username) => ({
  dateTimeFrom: `${convertDateAPIFormat(
    moment().startOf("day").toDate()
  )}T00:00:00`,
  dateTimeTo: `${convertDateAPIFormat(
    moment().endOf("day").toDate()
  )}T23:59:00`,
  username: username ? username : "",
});

export const todaysDefaultFilterExecutedBy = (username) => ({
  dateTimeFrom: `${convertDateAPIFormat(
    moment().startOf("day").toDate()
  )}T00:00:00`,
  dateTimeTo: `${convertDateAPIFormat(
    moment().endOf("day").toDate()
  )}T23:59:00`,
  executedBy: username ? username : "",
});

export const thisWeekDate = (username) => ({
  dateTimeFrom: `${convertDateAPIFormat(
    moment().isoWeekday(1).toDate()
  )}T00:00:00`,
  dateTimeTo: `${convertDateAPIFormat(
    moment().endOf("week").add(1, "day").toDate()
  )}T23:59:00`,
  username: username,
});

export const usernameOnly = (username) => ({
  username: username,
});
