import { useEffect } from "react";
import { useHistory } from "react-router-dom";

import { loggedOut } from "actions";
import { useDispatch } from "react-redux";

import LoadingBar from "components/LoadBars";

function Logout() {
  const dispatch = useDispatch();
  const history = useHistory();

  useEffect(() => {
    dispatch(loggedOut());
    history.push("/home");
    // eslint-disable-next-line
  }, []);

  return (
    <div style={{ minHeight: "66vh" }} className="w-50 h-100">
      <LoadingBar />
      <h1 className="text-uppercase text-white text-center">
        Redirecting to home page...
      </h1>
    </div>
  );
}

export default Logout;
