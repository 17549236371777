// alert prompt
import Swal from "sweetalert2";

export function alertDialog(title, confirmLabel, type) {
  Swal.fire({
    confirmButtonText: confirmLabel,
    title: title,
    icon: type,
    color: "#fff",
    confirmButtonColor: "rgb(157 134 0)",
    background:
      "radial-gradient(79.09% 79.09% at 50% 14.18%, #4e4d4c 0%, #060606 100%)",
  });
}

export function alertDialogs(title, description, confirmLabel, type) {
  Swal.fire({
    confirmButtonText: confirmLabel,
    text: description,
    title: title,
    icon: type,
    color: "#fff",
    confirmButtonColor: "rgb(157 134 0)",
    background:
      "radial-gradient(79.09% 79.09% at 50% 14.18%, #4e4d4c 0%, #060606 100%)",
  });
}

export function showLoader(game) {
  return Swal.fire({
    html: `<div class="loadingspinner">
    <div id="square1"></div>
    <div id="square2"></div>
    <div id="square3"></div>
    <div id="square4"></div>
    <div id="square5"></div>
  </div>`,
    title: `Get ready! The ${game} is about to start.`,
    color: "#fff",
    showConfirmButton: false,
    background:
      "radial-gradient(79.09% 79.09% at 50% 14.18%, #4e4d4c 0%, #060606 100%)",
  });
}

export function alertWithConfirm(title, confirmLabel, type, callback) {
  Swal.fire({
    title: title,
    icon: type,
    allowOutsideClick: false,
    confirmButtonText: confirmLabel,
    color: "#fff",
    confirmButtonColor: "rgb(157 134 0)",
    background:
      "radial-gradient(79.09% 79.09% at 50% 14.18%, #4e4d4c 0%, #060606 100%)",
  }).then((result) => {
    if (result.isConfirmed) {
      callback();
    }
  });
}
