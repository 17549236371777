import axios from "axios";

// alert prompt
import Swal from "sweetalert2";

import fetchClient from "api/axios";

import { notifyDanger } from "components/Toasts";
import { filterBuilder } from "views/shared/utils";

const JWTHeader = (JWT) => {
  return { headers: { Authorization: "Bearer " + JWT } };
};

const BASE_URI = (path) => {
  return process.env.REACT_APP_API_KEY + "/game" + path;
};

export const getOddsSettings = async (JWT) => {
  const response = await axios.get(
    BASE_URI("/settings/odds?game=VIDEO_KARERA"),
    JWTHeader(JWT)
  );
  return response.status === 200 ? response.data : [];
};

export const getSettings = async (JWT) => {
  // Override timeout for this request as it's known to take a long time
  const response = await fetchClient.get("/game/settings?game=VIDEO_KARERA");

  return response.status === 200 ? response.data : [];
};

export const patchSchemePercentage = async (ID, value) => {
  const response = await fetchClient.patch(
    `/game/settings/${ID}?percentage=${value}`
  );
  return response.status === 200
    ? response.data.statusCode === "OK"
    : Swal.fire("Cannot Fetch Data");
};

export const getPlayerBetHistory = async (filter) => {
  const response = await fetchClient.get(
    `/game/history/bet` + filterBuilder(filter)
  );

  return response.status === 200 ? response.data : [];
};

export const getGameLogs = async () => {
  const response = await fetchClient.get(`/game/logs?game=VIDEO_KARERA`);
  return response.status === 200 ? response.data : [];
};

export const getGameSettings = async (name) => {
  const response = await fetchClient.get(
    `/game/settings?game=VIDEO_KARERA&name=` + name
  );
  return response.status === 200 ? response.data : [];
};

export const getVideoKareraOdds = async () => {
  const response = await fetchClient.get(`/game/settings/odds`);
  return response.status === 200 ? response.data : [];
};

export const patchGameSettings = async (scheme, percentage) => {
  const response = await fetchClient.patch(
    `/game/settings?name=${scheme}&value=${percentage}`
  );
  if (response.status !== 204) {
    notifyDanger("Failed to patch");
  }
};

export const updateVKOddsWinningPercentage = async (odds, percentage) => {
  const response = await fetchClient.patch(
    `/game/settings/odds?odds=${odds}&percentage=${percentage}`
  );
  return response.status === 204 ? true : [];
};
