import { commas } from "views/shared/utils";

import { Badge } from "reactstrap";
import chipsImage from "assets/img/chips-icon.png";
import coinsImage from "assets/img/coins-icon.png";

import { FaArrowUp, FaArrowDown } from "react-icons/fa";

export const columns = [
  {
    name: "transaction Id",
    sortable: true,
    selector: (row) => row.transactionId,
  },
  {
    name: "date",
    selector: (row) => row.date,
    sortable: true,
  },
  {
    name: "username",
    selector: (row) => row.username,
    sortable: true,
    cell: (row) => <b>{row.username}</b>,
  },
  {
    name: "Upper",
    selector: (row) => row.upper,
    sortable: true,
  },
  {
    name: "amount",
    selector: (row) => commas(row.amount),
    sortable: true,
  },
  {
    name: "credit Type",
    selector: (row) => row.creditType,
    sortable: true,
    cell: (d) => (
      <>
        <img
          src={d.creditType === "CHIPS" ? chipsImage : coinsImage}
          alt=""
          width={20}
        />
        <span className="ml-2">{d.creditType}</span>
      </>
    ),
  },
  {
    name: "Type",
    selector: (row) => row.transactionType,
    sortable: true,
    cell: (row) => (
      <div>
        {row.transactionType === "WITHDRAW" && (
          <FaArrowUp style={{ color: "green" }} />
        )}
        {row.transactionType === "DEPOSIT" && (
          <FaArrowDown style={{ color: "red" }} />
        )}
        <span className="ml-2">{row.transactionType}</span>
      </div>
    ),
  },
  {
    name: "Status",
    width: "6rem",
    selector: (row) => row.status,
    sortable: true,
    cell: (row) => {
      switch (row.status) {
        case "APPROVED":
          return <Badge color="success">APPROVED</Badge>;
        case "REJECTED":
          return <Badge color="danger">REJECTED</Badge>;
        case "PENDING":
          return <Badge color="warning">PENDING</Badge>;
        default:
        // code block
      }
    },
  },
  {
    name: "Reasons",
    selector: (row) => row.reason,
    sortable: true,
    cell: (row) => (
      <div style={{ overflowWrap: "break-word", textOverflow: "ellipsis" }}>
        {row.reason}
      </div>
    ),
  },
];
