import { useState } from "react";

// React strap tools
import {
  Input,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  FormGroup,
  Form,
  Label,
} from "reactstrap";

// Redux
import AsyncSearchBar from "components/SearchBar/AsyncSearchBar";

export const RestrictionCreate = (props) => {
  // Add Handling
  const [name, setName] = useState("");
  const [modal, setModal] = useState(false);
  const toggle = () => setModal(!modal);

  const handleSubmit = (e) => {
    e.preventDefault();
  };

  return (
    <>
      <Button
        className="btn-icon btn-3"
        color="primary"
        outline
        type="button"
        onClick={toggle}
      >
        <span className="btn-inner--icon">
          <i className="fa fa-plus"></i>
        </span>
        <span className="btn-inner--text">Create</span>
      </Button>

      <Modal
        className="modal-dialog-centered"
        size="md"
        isOpen={modal}
        toggle={toggle}
      >
        <ModalHeader toggle={toggle}>Create restriction</ModalHeader>
        <ModalBody>
          <Form role="form" onSubmit={handleSubmit}>
            <FormGroup>
              <Label for="name">Name</Label>
              <Input
                placeholder="Name"
                type="text"
                minLength={5}
                value={name}
                required
                onChange={(e) => setName(e.target.value)}
              />
            </FormGroup>

            <FormGroup>
              <Label for="name">Name</Label>
              <AsyncSearchBar setSearchName={setName} />
            </FormGroup>

            <div className="text-right">
              <Button className="mt-2" onClick={toggle} type="button">
                Close
              </Button>
              <Button className="mt-2" color="primary" type="submit">
                <>Create account</>
              </Button>
            </div>
          </Form>
        </ModalBody>
      </Modal>
    </>
  );
};

export default RestrictionCreate;
