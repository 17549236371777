// reactstrap components
import { Row, Col } from "reactstrap";
import { TermsAndConditions } from "components/Modals/termsandconditions";
import PrivacyPolicy from "components/Modals/privacypolicy";

import evolutionGaming from "assets/img/homepage/partners/evolution-gaming.png";
import faChaiGaming from "assets/img/homepage/partners/fa-chai-gaming.png";
import { logoplayevo } from "assets/img/homepage";

const LandingPageFooter = () => {
  return (
    <footer className="landing-page-footer">
      <Row className="d-flex justify-content-center my-3 mx-0">
        <Col md="auto" xs={6}>
          <div className="partners-card">
            <img alt="" src={evolutionGaming} />
          </div>
        </Col>
        <Col md="auto" xs={6}>
          <div className="partners-card">
            <img alt="" src={faChaiGaming} />
          </div>
        </Col>
      </Row>
      <Row className="justify-content-xl-between mx-0 mt-5">
        <Col lg={3} md={6} xs={6}>
          <img alt="" src={logoplayevo} className="w-75 ml-2 py-2" />
        </Col>
        <Col lg={3} md={12} sm={12} className="footer-context">
          <h2>About Us</h2>
          <p>
            SAB TECHNOLOGY is an entertainment website offering top-rated, safe,
            fair, and secure gaming. Our casino game selection offers a wide
            range of online casino games that everyone can enjoy.
          </p>
        </Col>
        <Col lg={3} md={12} sm={12} className="footer-context">
          <h2>Information</h2>
          <p>
            <i className="fas fa-gamepad pr-2"></i> Responsible Gaming
          </p>
          <p>
            <PrivacyPolicy />
          </p>
          <p>
            <i className="fa fa-file"></i> <TermsAndConditions />
          </p>
        </Col>
        <Col lg={3} md={12} sm={12} className="footer-context">
          <h2>About Us</h2>
          <p>
            <a
              href="https://l.messenger.com/l.php?u=http%3A%2F%2Fm.me%2F105759015691700&h=AT1TUc5oo5VmOhmy2wYgnCvoOEXG_zim6hspS63gEwATBNzU6o3EEt5hXbjX_bLYXee0yfNyoZmYe3pGsXYFmUW1W56PuTCbTnxkA3X6KosIOLn8UpKGU-yAETYmB3WJOVjy5Q"
              target="_blank"
              rel="noreferrer"
              className="text-white"
            >
              <i className="ni ni-chat-round"></i>Live Chat
            </a>
          </p>
          <p>
            <a
              href="https://www.facebook.com/OfficialIplayEvo"
              target="_blank"
              rel="noreferrer"
              className="text-white"
            >
              <i className="ni ni-laptop"></i>fb.com/iplayevo
            </a>
          </p>
        </Col>
        <Col className="footer-context text-center mt-4 mb-2" xl="12">
          <p>
            © Copyright {new Date().getFullYear()} <b>SABTECHNOLOGY</b>
          </p>
        </Col>
      </Row>
    </footer>
  );
};

export default LandingPageFooter;
