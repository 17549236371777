import { commas } from "../../utils";
import { Badge } from "reactstrap";

export const columns = [
  {
    name: "Transaction ID",
    selector: (row) => row.transactionId,
    sortable: true,
    wrap: true,
  },
  {
    name: "Transaction Date",
    selector: (row) => row.date,
    sortable: true,
    wrap: true,
  },
  {
    name: "User ID",
    selector: (row) => row.username,
    sortable: true,
    cell: (row) => <b>{row.username}</b>,
  },
  {
    name: "Amount",
    sortable: true,
    cell: (row) => {
      return (
        <>
          <i
            className={`${
              row.creditEvent === "INCREASE"
                ? "text-success fa fa-arrow-up"
                : "text-danger fa fa-arrow-down"
            }`}
          />
          <span
            className={`ml-1 ${
              row.creditEvent === "INCREASE" ? "text-success" : "text-danger"
            }`}
          >
            {commas(row.amount)}
          </span>
        </>
      );
    },
  },
  {
    name: "Transaction Type",
    selector: (row) => row.transactionType,
    sortable: true,
  },
  {
    name: "Balance",
    sortable: true,
    cell: (row) => (
      <b>
        {commas(
          row.creditType === "CHIPS" ? row.balanceChips : row.balanceCoins
        )}
      </b>
    ),
  },
  {
    name: "Excecuted By",
    selector: (row) => row.executedBy,
    sortable: true,
    cell: (row) => <b>{row.executedBy}</b>,
  },
  {
    name: "Status",
    selector: (row) => row.status,
    sortable: true,
    cell: (row) => {
      switch (row.status) {
        case "APPROVED":
          return <Badge color="success">APPROVED</Badge>;
        case "REJECTED":
          return <Badge color="danger">REJECTED</Badge>;
        case "PENDING":
          return <Badge color="warning">PENDING</Badge>;
        default:
        // code block
      }
    },
  },
  {
    name: "Remarks",
    selector: (row) => row.remarks,
    sortable: true,
    cell: (d) => (d.remarks === null ? "NONE" : d.remarks),
  },
];
