import toast from "react-hot-toast";

export const nofitySuccess = (message) => {
  toast.success(
    <span className="ml-1">
      Success!
      <div className="mt-1">{message}</div>
    </span>,

    {
      style: {
        borderLeft: "10px solid #3a9b0e",
        padding: "14px",
        color: "#faf6ff",
        backgroundColor: "#8fc53f",
        fontWeight: 400,
      },
      iconTheme: {
        primary: "#e1efcb",
        secondary: "#3da116",
      },
      duration: 6000,
    }
  );
};

export const notifyDanger = (message) => {
  toast.error(
    <span className="ml-1">
      Error!
      <div className="mt-1">{message}</div>
    </span>,
    {
      style: {
        borderLeft: "10px solid #fd375a",
        padding: "18px",
        fontWeight: 400,
      },
      iconTheme: {
        primary: "#fd375a",
        secondary: "#fff",
      },
      duration: 6000,
    }
  );
};

export const notifyWarning = (title, message) => {
  toast.error(
    <span className="ml-1">
      <b>Uh oh, {title}</b>
      <div className="mt-1">{message}</div>
    </span>,
    {
      style: {
        borderLeft: "10px solid #f0a92d",
        padding: "18px",
        fontWeight: 400,
      },
      iconTheme: {
        primary: "#f0a92d",
        secondary: "#fff",
      },
      duration: 6000,
    }
  );
};
