import axios from "axios";

// Authorization
import store from "store";

import Swal from "sweetalert2";

import { notifyDanger, notifyWarning } from "components/Toasts";

export const fetchClient = (token) => {
  const defaultOptions = {
    baseURL: process.env.REACT_APP_API_KEY,
    headers: {
      "Content-Type": "application/json",
    },
  };

  // Create an instance using the config defaults provided by the library
  // At this point the timeout config value is `0` as is the default for the library
  let instance = axios.create(defaultOptions);

  // Override timeout default for the library
  // Now all requests using this instance will wait 2.5 seconds before timing out
  // instance.defaults.timeout = 4000;

  // Set the AUTH token for any request
  instance.interceptors.request.use(function (config) {
    const token = store.getState().auth.jwt;
    config.headers.Authorization = token ? `Bearer ${token}` : "";
    return config;
  });

  // Add a response interceptor
  instance.interceptors.response.use(
    function (response) {
      // Do something with response data
      return response;
    },
    function (error) {
      const status = error.response.status;

      if (status === 0) {
        let timerInterval;
        Swal.fire({
          title: "Login session expired!",
          html: "Going to Login in <b></b> seconds.",
          timer: 5000,
          timerProgressBar: true,
          didOpen: () => {
            Swal.showLoading();
            const b = Swal.getHtmlContainer().querySelector("b");
            timerInterval = setInterval(() => {
              b.textContent = Math.floor(Swal.getTimerLeft() / 1000) + 1;
            }, 1000);
          },
          willClose: () => {
            clearInterval(timerInterval);
          },
        }).then((result) => {
          /* Read more about handling dismissals below */
          if (result.dismiss === Swal.DismissReason.timer) {
            window.location.replace("/auth/logout");
          }
        });
      } else {
        if (status === 302) {
          notifyWarning("Record Failed", "Record already exists.");
        } else if (
          status !== 404 &&
          status !== 401 &&
          status !== 428 &&
          status !== 412 &&
          status !== 500
        ) {
          // exclution of error message for outside handling
          notifyDanger(`${error.response.data?.message}`);
        }
      }

      // Do something with response error
      return error.response.data;
    }
  );

  return instance;
};

export default fetchClient();
