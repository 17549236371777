import fetchClient from "api/axios";

export const getGameList = async () => {
  const response = await fetchClient.get(`/jili/games`);
  return response.status === 200 ? response.data : [];
};

export const playGame = async (gameId, account) => {
  const response = await fetchClient.post(
    `/jili/play/${gameId}?account=${account}`
  );
  return response.status === 200 ? response.data : [];
};
