import { useEffect, useState } from "react";

import {
  Card,
  CardHeader,
  Row,
  Container,
  Col,
  Breadcrumb,
  BreadcrumbItem,
  Button,
} from "reactstrap";

// alert prompt
import DataTable from "react-data-table-component";
import DataTableExtensions from "react-data-table-component-extensions";

// Core components
import Header from "components/Headers/Header.js";

// Spinners
import LoadingBar from "components/LoadBars";

// Modal Forms
import CreateForm from "components/Modals/create";
import DeleteForm from "components/Modals/delete";

// API Calls
import { getDownlineAccounts } from "api/AccountAPI";
import { columns } from "./data";

// Redux
import { useSelector } from "react-redux";

const ActiveAccounts = () => {
  const [data, setData] = useState([]);
  const [selectedData, setSelectedData] = useState([]);
  const [sync, setSync] = useState(true);
  const [loading, setLoading] = useState(true);
  const [clearSelect, setClearSelect] = useState(false);

  const permissions = useSelector((state) => state.ui.permissions);
  const isCreateRestricted = !permissions.create?.includes("AGENT");
  const isEditRestricted = !permissions.edit?.includes("AGENT");

  const handleChange = (state) => {
    setSelectedData(
      state.selectedRows.map((entry) => {
        return { name: entry.username, value: entry.uuid };
      })
    );
  };

  const handleSync = () => {
    setSync(!sync);
    setLoading(true);
    setClearSelect(true);
  };

  useEffect(() => {
    getDownlineAccounts("ACTIVE").then((response) => {
      setData(response);
      setLoading(false);
      setClearSelect(false);
    });
    // eslint-disable-next-line
  }, [sync]);

  return (
    <>
      <Header />
      <Container className="mt--7" fluid>
        <Row>
          <div className="col">
            <Card className="shadow">
              <Breadcrumb listClassName="breadcrumb-links">
                <BreadcrumbItem>Downlines</BreadcrumbItem>
                <BreadcrumbItem>Active Accounts</BreadcrumbItem>
              </Breadcrumb>
              <CardHeader className="border-0">
                <Row>
                  <Col lg={6} sm={12}>
                    <span className="mb-0 h2">Accounts Management</span>
                  </Col>
                  <Col lg={6} sm={12} className="text-lg-right mt-3 mt-lg-0">
                    <Button
                      className="btn-icon btn-2"
                      color="default"
                      type="button"
                      outline
                      onClick={handleSync}
                    >
                      <span className="btn-inner--icon">
                        <i className="fa fa-refresh"></i>
                      </span>
                    </Button>
                    {isEditRestricted && (
                      <DeleteForm data={selectedData} sync={handleSync} />
                    )}
                    {isCreateRestricted && <CreateForm sync={handleSync} />}
                  </Col>
                </Row>
              </CardHeader>
              <DataTableExtensions
                export={false}
                print={false}
                columns={columns(handleSync, isEditRestricted)}
                data={data}
              >
                <DataTable
                  pagination
                  highlightOnHover
                  striped
                  selectableRows
                  title={"Active Accounts (" + data.length + ")"}
                  columns={columns(handleSync, isEditRestricted)}
                  data={data}
                  defaultSortAsc={true}
                  onSelectedRowsChange={handleChange}
                  progressComponent={<LoadingBar />}
                  progressPending={loading}
                  clearSelectedRows={clearSelect}
                />
              </DataTableExtensions>
            </Card>
          </div>
        </Row>
      </Container>
    </>
  );
};

export default ActiveAccounts;
