import { useState, useEffect, useRef } from "react";

import {
  Input,
  Card,
  CardBody,
  CardHeader,
  CardFooter,
  InputGroup,
  InputGroupText,
} from "reactstrap";
import { notifyWarning } from "components/Toasts";

import { getMessagesMOP, sendMessageReciverMOP } from "api/p2p";

const DepositMessages = ({ transactionId, sender, isTransactionFinished }) => {
  const [messages, setMessages] = useState([]);
  const [inputMessage, setInputMessage] = useState();
  const chatPanelRef = useRef(null);

  useEffect(() => {
    if (chatPanelRef.current) {
      chatPanelRef.current.scrollTop = chatPanelRef.current.scrollHeight;
    }
  }, [messages]);

  const addMessage = (message) => {
    if (message) {
      sendMessageReciverMOP(transactionId, inputMessage).then(() => {
        getMessagesMOP(transactionId).then((response) => setMessages(response));
      });
      setInputMessage("");
    } else {
      notifyWarning("Please type a message");
    }
    setMessages((prevMessages) => [...prevMessages, message]);
  };

  useEffect(() => {
    if (!isTransactionFinished) {
      setInterval(() => {
        getMessagesMOP(transactionId).then((response) => setMessages(response));
      }, 1500);
    }
    // eslint-disable-next-line
  }, []);

  return (
    <Card className="bg-dark rounded-md">
      <CardHeader className="bg-dark p-3">
        <h2 className="text-white">
          Message sender "<b>{sender}</b>"
        </h2>
      </CardHeader>

      <CardBody className="p-0">
        <div
          style={{
            height: "300px",
            overflowY: "scroll",
            padding: "10px",
          }}
          ref={chatPanelRef}
        >
          {messages.length === 0 ? (
            <div>Say Hi!</div>
          ) : (
            messages.map((item, index) => (
              <div
                key={index}
                className={`mx-1 my-2 ${
                  item.messageFrom === "LOADER" ? "text-left" : "text-right"
                }`}
              >
                <div className="mb-1">
                  <span
                    className={`p-2 rounded ${
                      item.messageFrom === "LOADER"
                        ? "bg-secondary text-dark"
                        : "bg-primary text-white"
                    }`}
                  >
                    {item.message}
                  </span>
                </div>
                <small>{item.dateAndTime}</small>
              </div>
            ))
          )}
          {}
        </div>
      </CardBody>
      <CardFooter className="bg-dark p-2">
        <InputGroup>
          <Input
            placeholder="Message reciever"
            value={inputMessage}
            onChange={(e) => setInputMessage(e.target.value)}
          />
          <InputGroupText
            onClick={addMessage}
            className="bg-primary text-white pointer"
          >
            Send
          </InputGroupText>
        </InputGroup>
      </CardFooter>
    </Card>
  );
};

export default DepositMessages;
