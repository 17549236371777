import { useState, useEffect } from "react";
// node.js library that concatenates classes (strings)

import { CopyToClipboard } from "react-copy-to-clipboard";
import Select from "react-select";
import {
  Button,
  Card,
  CardHeader,
  Table,
  Container,
  Row,
  Col,
  FormGroup,
  InputGroup,
  UncontrolledPopover,
  PopoverBody,
  Input,
  Modal,
  Form,
  InputGroupText,
  CardBody,
  CardTitle,
} from "reactstrap";

// import Swal from "sweetalert2";
import Header from "components/Headers/Header.js";

import { useSelector, useDispatch } from "react-redux";
import { syncUserData } from "api/AccountAPI";

const REG_LINK = window.location.origin + "/auth/register/";

const Index = () => {
  //* Manage Wallet Coins
  const [isFormOpened, setIsFormOpened] = useState(false);
  const handleCloseForm = () => setIsFormOpened(false);

  const [isSubmitOpened, setIsSubmitOpened] = useState(false);
  const handleOpenSubmit = () => setIsSubmitOpened(true);
  const handleCloseSubmit = () => setIsSubmitOpened(false);

  //* Manage Wallet Chips
  const [isFormOpenedChips, setIsFormOpenedChips] = useState(false);
  const handleCloseFormChips = () => setIsFormOpenedChips(false);

  const [isSubmitOpenedChips, setIsSubmitOpenedChips] = useState(false);
  const handleOpenSubmitChips = () => setIsSubmitOpenedChips(true);
  const handleCloseSubmitChips = () => setIsSubmitOpenedChips(false);

  const optiontransaction = [
    { value: "1", label: "Deposit" },
    { value: "2", label: "Withdraw" },
  ];

  const handleConfirmDeposit = () => {
    handleCloseForm();
    handleCloseSubmit();
  };

  const handleConfirmDepositChips = () => {
    handleCloseFormChips();
    handleCloseSubmitChips();
  };

  const dispatch = useDispatch();
  const referralCode = useSelector(state => state.entities.user?.referralCode);
  const role = useSelector(state => state.entities.user?.role);

  useEffect(() => {
    syncUserData(dispatch);
    // eslint-disable-next-line
  }, []);

  const activePlayerCount = useSelector(
    state => state.entities.user?.activePlayerCount
  );
  const inActivePlayerCount = useSelector(
    state => state.entities.user?.inActivePlayerCount
  );

  const playerOptions = [];

  return (
    <>
      <Header />
      {/* Page content */}
      <Container className="mt--7" fluid>
        <Row>
          {/* {role === "ADMIN" ? (
            <Col md={12}>
              <Card className="bg-shadow mb-3">
                <CardHeader>
                  <CardTitle className="mb-0" tag="h3">
                    Find Account
                  </CardTitle>
                </CardHeader>
                <CardBody>
                  <AsyncSearchBar />
                </CardBody>
              </Card>
            </Col>
          ) : null} */}

          {/* <Col lg={12} md={12}>
            <Row>
              <Col md={5}>
                <Card className="mt-4 mb-3 bg-gradient-success">
                  <CardBody className="text-white">
                    <Row>
                      <div className="col">
                        <CardTitle className="text-uppercase mb-0">
                          Total Tips
                        </CardTitle>
                        <span className="display-4 font-weight-bold mb-0">
                          {tipsCoins}
                        </span>
                      </div>
                      <Col className="col-auto">
                        <img
                          src={coinsImage}
                          alt="chipsImage"
                          className="w-100"
                        />
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Col> */}

          <Col lg={5} md={12}>
            <Row>
              <Col md={12} sm={12}>
                <Card className="bg-gradient-primary">
                  <CardBody>
                    <Row>
                      <div className="col">
                        <CardTitle className="text-uppercase text-white h4 mb-0">
                          Total Downlines
                        </CardTitle>
                        <span className="display-3 text-white font-weight-bold mb-0">
                          {activePlayerCount}
                        </span>
                      </div>
                      <Col className="col-auto">
                        <div className="icon icon-shape bg-white rounded-circle shadow">
                          <i className="fas fa-users" />
                        </div>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </Col>

              <Col md={12} sm={12}>
                <Card className="mt-4 mb-3">
                  <CardBody>
                    <Row>
                      <div className="col">
                        <CardTitle className="text-uppercase mb-0">
                          Inactive Downlines
                        </CardTitle>
                        <span className="display-4 font-weight-bold mb-0">
                          {inActivePlayerCount}
                        </span>
                      </div>
                      <Col className="col-auto">
                        <div className="icon icon-shape bg-danger text-white rounded-circle shadow">
                          <i className="ni ni-bell-55"></i>
                        </div>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Col>

          <Col className="mb-3 mt-0 mt-lg-0 mt-md-2" lg={7} md={12} sm={12}>
            <Card className="bg-shadow">
              <CardHeader>
                <CardTitle className="mb-0" tag="h3">
                  GOOD DAY - {role}
                </CardTitle>
              </CardHeader>
              <CardBody className="bg-gradient-info">
                <blockquote className="blockquote text-white mb-0">
                  <p>
                    Recently archived reports are now available and can be
                    viewed by visiting the "Report Archive Menu" Please bear in
                    mind that the platform only offers a "Read Only" access in
                    which you should not perform your typical transactions on
                    the said site. Thank you for your continuous support.
                  </p>
                  <footer className="blockquote-footer text-primary">
                    <cite title="Source Title">iPLAYEVO</cite>
                  </footer>
                </blockquote>
              </CardBody>
            </Card>
          </Col>

          {
            (role === "AGENT",
            "MASTER" ? (
              <Col className="mb-5 mt-2" xs="12" md="12">
                <Card className="bg-shadow">
                  <CardHeader className="bg-transparent">
                    <CardTitle className="mb-0" tag="h3">
                      Referral link and code
                    </CardTitle>
                  </CardHeader>
                  <CardBody>
                    <Row>
                      <Col md={6} sm={12}>
                        <FormGroup>
                          <label
                            className="form-control-label text-uppercase"
                            htmlFor="input-username"
                          >
                            Referral Link:
                          </label>
                          <InputGroup className="input-group-alternative">
                            <Input
                              placeholder={REG_LINK + referralCode}
                              type="text"
                              disabled
                            />
                          </InputGroup>
                          <CopyToClipboard text={REG_LINK + referralCode}>
                            <Button
                              color="primary"
                              className="mt-3"
                              id="tooltipLink"
                            >
                              <i className="ni ni-single-copy-04" />
                              <span className="ml-1">Copy Link</span>
                            </Button>
                          </CopyToClipboard>
                          <UncontrolledPopover
                            placement="bottom"
                            target="tooltipLink"
                          >
                            <PopoverBody>Copied 'Link' Success!</PopoverBody>
                          </UncontrolledPopover>
                        </FormGroup>
                      </Col>
                      <Col md={6} sm={12}>
                        <FormGroup>
                          <label
                            className="form-control-label text-uppercase "
                            htmlFor="input-username"
                          >
                            Referral CODE:
                          </label>
                          <p className="font-weight-bold h1 mt-2">
                            {referralCode}
                          </p>
                          <CopyToClipboard text={referralCode}>
                            <Button color="primary" id="tooltipCode">
                              <i className="ni ni-single-copy-04" />
                              <span className="ml-1">Copy CODE</span>
                            </Button>
                          </CopyToClipboard>
                          <UncontrolledPopover
                            placement="bottom"
                            target="tooltipCode"
                          >
                            <PopoverBody>Copied 'CODE' Success!</PopoverBody>
                          </UncontrolledPopover>
                        </FormGroup>
                      </Col>
                    </Row>
                    <span className="h4">
                      NOTE: Players that will register under this link will
                      automatically be under your account.
                    </span>
                  </CardBody>
                </Card>
              </Col>
            ) : null)
          }

          <Modal className="modal-dialog-centered" isOpen={isFormOpenedChips}>
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel">
                Wallet Management - Chips
              </h5>
              <button
                aria-label="Close"
                className="close"
                data-dismiss="modal"
                type="button"
                onClick={handleCloseFormChips}
              >
                <span aria-hidden={true}>×</span>
              </button>
            </div>
            <div className="modal-body">
              <Form
                role="form"
                onSubmit={e => {
                  null(e);
                }}
              >
                <FormGroup>
                  <Col className="mb-2 mt--3" xs="12">
                    <Card className="bg-danger">
                      <CardHeader className="bg-transparent">
                        <Row className="align-items-center">
                          <div className="col">
                            <h4 className="text-uppercase text-muted ls-1 mb-1 text-white">
                              Current Wallet Chips
                            </h4>
                            <h1 className="mb-0 text-white">0</h1>
                          </div>
                        </Row>
                      </CardHeader>
                    </Card>
                  </Col>
                  <label
                    className="form-control-label"
                    htmlFor="input-username"
                  >
                    Username
                  </label>
                  <Select placeholder="Select" options={playerOptions} />

                  <Col className="mb-2 mt-3" xs="12">
                    <Card className="bg-primary">
                      <CardHeader className="bg-transparent">
                        <Row className="align-items-center">
                          <div className="col">
                            <h4 className="text-uppercase text-muted ls-1 mb-1 text-white">
                              Username Here Wallet
                            </h4>
                            <h1 className="mb-0 text-white">25,564.64</h1>
                          </div>
                        </Row>
                      </CardHeader>
                    </Card>
                  </Col>
                </FormGroup>

                <FormGroup>
                  <label
                    className="form-control-label"
                    htmlFor="input-username"
                  >
                    Deposit
                  </label>
                  <Select placeholder="Select" options={optiontransaction} />
                  <InputGroup className="input-group-alternative mb-3">
                    <Input
                      required
                      placeholder="Enter Amount"
                      type="number"
                      maxLength="7"
                      className="mt-4"
                    />
                    <InputGroupText className="bg-shadow mt-4">
                      PHP
                    </InputGroupText>
                  </InputGroup>
                </FormGroup>

                <FormGroup className="mt--3">
                  <label className="form-control-label" htmlFor="input-remarks">
                    Remarks
                  </label>
                  <InputGroup className="input-group-alternative ">
                    <Input required placeholder="Details" type="textarea" />
                  </InputGroup>
                </FormGroup>
              </Form>
            </div>
            <div className="modal-footer">
              <Button
                color="secondary"
                data-dismiss="modal"
                type="button"
                onClick={handleCloseFormChips}
              >
                Close
              </Button>
              <Button
                className="mutted"
                color="primary"
                type="submit"
                onClick={handleOpenSubmitChips}
              >
                Submit
              </Button>
            </div>
          </Modal>

          <Modal className="modal-dialog-centered" isOpen={isSubmitOpenedChips}>
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel">
                Confirm Deposit - Chips
              </h5>
              <button
                aria-label="Close"
                className="close"
                data-dismiss="modal"
                type="button"
                onClick={handleCloseSubmitChips}
              >
                <span aria-hidden={true}>×</span>
              </button>
            </div>
            <div className="modal-body">
              <Form
                role="form"
                onSubmit={e => {
                  null(e);
                }}
              >
                <Table responsive>
                  <thead>
                    <tr>
                      <th>Amount to Deposit</th>
                      <th>Amount Here!</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>Username</td>
                      <td>tamir069</td>
                    </tr>
                    <tr>
                      <td>Full Name</td>
                      <td>Tamir Nadal</td>
                    </tr>
                    <tr>
                      <td>Phone</td>
                      <td>09261696546</td>
                    </tr>
                    <tr>
                      <td>Details</td>
                      <td></td>
                    </tr>
                    <tr>
                      <td>Remarks Here</td>
                      <td></td>
                    </tr>
                  </tbody>
                </Table>

                <FormGroup className="mt--3">
                  <label className="form-control-label" htmlFor="input-remarks">
                    Password
                  </label>
                  <InputGroup className="input-group-alternative ">
                    <Input
                      required
                      placeholder="Enter your Password"
                      type="password"
                    />
                  </InputGroup>
                </FormGroup>
              </Form>
            </div>

            <div className="modal-footer">
              <Button
                color="secondary"
                data-dismiss="modal"
                type="button"
                onClick={handleCloseSubmitChips}
              >
                Close
              </Button>
              <Button
                className="mutted"
                color="primary"
                type="submit"
                onClick={handleConfirmDepositChips}
              >
                Submit
              </Button>
            </div>
          </Modal>

          <Modal className="modal-dialog-centered" isOpen={isFormOpened}>
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel">
                Wallet Management - Coins
              </h5>
              <button
                aria-label="Close"
                className="close"
                data-dismiss="modal"
                type="button"
                onClick={handleCloseForm}
              >
                <span aria-hidden={true}>×</span>
              </button>
            </div>
            <div className="modal-body">
              <Form
                role="form"
                onSubmit={e => {
                  null(e);
                }}
              >
                <FormGroup>
                  <Col className="mb-2 mt--3" xs="12">
                    <Card className="bg-yellow">
                      <CardHeader className="bg-transparent">
                        <Row className="align-items-center">
                          <div className="col">
                            <h4 className="text-uppercase text-muted ls-1 mb-1 text-white">
                              Current Wallet Coins
                            </h4>
                            <h1 className="mb-0 text-white">0</h1>
                          </div>
                        </Row>
                      </CardHeader>
                    </Card>
                  </Col>
                  <label
                    className="form-control-label"
                    htmlFor="input-username"
                  >
                    Username
                  </label>
                  <Select placeholder="Select" options={playerOptions} />

                  <Col className="mb-2 mt-3" xs="12">
                    <Card className="bg-primary">
                      <CardHeader className="bg-transparent">
                        <Row className="align-items-center">
                          <div className="col">
                            <h4 className="text-uppercase text-muted ls-1 mb-1 text-white">
                              Username Here Wallet
                            </h4>
                            <h1 className="mb-0 text-white">569.69</h1>
                          </div>
                        </Row>
                      </CardHeader>
                    </Card>
                  </Col>
                </FormGroup>

                <FormGroup>
                  <label
                    className="form-control-label"
                    htmlFor="input-username"
                  >
                    Deposit
                  </label>
                  <Select placeholder="Select" options={optiontransaction} />
                  <InputGroup className="input-group-alternative mb-3">
                    <Input
                      required
                      placeholder="Enter Amount"
                      type="number"
                      maxLength="7"
                      className="mt-4"
                    />
                    <InputGroupText className="bg-shadow mt-4">
                      PHP
                    </InputGroupText>
                  </InputGroup>
                </FormGroup>

                <FormGroup className="mt--3">
                  <label className="form-control-label" htmlFor="input-remarks">
                    Remarks
                  </label>
                  <InputGroup className="input-group-alternative ">
                    <Input required placeholder="Details" type="textarea" />
                  </InputGroup>
                </FormGroup>
              </Form>
            </div>
            <div className="modal-footer">
              <Button
                color="secondary"
                data-dismiss="modal"
                type="button"
                onClick={handleCloseForm}
              >
                Close
              </Button>
              <Button
                className="mutted"
                color="primary"
                type="submit"
                onClick={handleOpenSubmit}
              >
                Submit
              </Button>
            </div>
          </Modal>

          <Modal className="modal-dialog-centered" isOpen={isSubmitOpened}>
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel">
                Confirm Deposit - Coins
              </h5>
              <button
                aria-label="Close"
                className="close"
                data-dismiss="modal"
                type="button"
                onClick={handleCloseSubmit}
              >
                <span aria-hidden={true}>×</span>
              </button>
            </div>
            <div className="modal-body">
              <Form
                role="form"
                onSubmit={e => {
                  null(e);
                }}
              >
                <Table responsive>
                  <thead>
                    <tr>
                      <th>Amount to Deposit</th>
                      <th>Amount Here!</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>Username</td>
                      <td>tamir069</td>
                    </tr>
                    <tr>
                      <td>Full Name</td>
                      <td>Tamir Nadal</td>
                    </tr>
                    <tr>
                      <td>Phone</td>
                      <td>09261696546</td>
                    </tr>
                    <tr>
                      <td>Details</td>
                      <td></td>
                    </tr>
                    <tr>
                      <td>Remarks Here</td>
                      <td></td>
                    </tr>
                  </tbody>
                </Table>

                <FormGroup className="mt--3">
                  <label className="form-control-label" htmlFor="input-remarks">
                    Password
                  </label>
                  <InputGroup className="input-group-alternative ">
                    <Input
                      required
                      placeholder="Enter your Password"
                      type="password"
                    />
                  </InputGroup>
                </FormGroup>
              </Form>
            </div>

            <div className="modal-footer">
              <Button
                color="secondary"
                data-dismiss="modal"
                type="button"
                onClick={handleCloseSubmit}
              >
                Close
              </Button>
              <Button
                className="mutted"
                color="primary"
                type="submit"
                onClick={handleConfirmDeposit}
              >
                Submit
              </Button>
            </div>
          </Modal>
        </Row>
      </Container>
    </>
  );
};

export default Index;
