import { useState, useEffect, useRef } from "react";
import { useParams, useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import { FaCopy } from "react-icons/fa";

import {
  Button,
  Input,
  FormFeedback,
  FormGroup,
  Row,
  Col,
  Badge,
} from "reactstrap";
import { nofitySuccess } from "components/Toasts";
import DepositMessages from "./chats/Messages";

import Swal from "sweetalert2";
import { getMOPDetails, updateScreenshot } from "api/p2p";

import { getTransactionById } from "api/Transactions";
import { syncUserData } from "api/AccountAPI";

import { getPaymentLogo } from "./utils";

const alertDialog = (title, confirmLabel, type) => {
  Swal.fire({
    confirmButtonText: confirmLabel,
    title: title,
    allowOutsideClick: false,
    icon: type,
    color: "#fff",
    confirmButtonColor: "rgb(157 134 0)",
    background:
      "radial-gradient(79.09% 79.09% at 50% 14.18%, #4e4d4c 0%, #060606 100%)",
  });
};

const DepositTransaction = () => {
  const { mopId, transactionId, amount } = useParams();
  const dispatch = useDispatch();

  const history = useHistory();
  const divRef = useRef(null);
  const [data, setData] = useState({ type: "E_WALLET" });
  const [isImageValid, setIsImageValid] = useState(false);
  const [image, setImage] = useState();

  const [proofOfPaymentCount, setProfOfPaymentCount] = useState(0);
  const [transactionOutcome, setTransactionOutcome] = useState("PENDING");
  const [declineReason, setDeclineReason] = useState();

  useEffect(() => {
    getMOPDetails(mopId).then((result) => setData(result));
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (transactionOutcome === "PENDING") {
      getTransactionById(transactionId).then((result) => {
        setProfOfPaymentCount(result[0].proofOfPaymentCount);
        setTransactionOutcome(result[0].status);
        setDeclineReason(result[0].reason);
      });
      setInterval(() => {
        getTransactionById(transactionId).then((result) => {
          setProfOfPaymentCount(result[0].proofOfPaymentCount);
          setTransactionOutcome(result[0].status);
          setDeclineReason(result[0].reason);
        });
      }, 1500);
    }
    // eslint-disable-next-line
  }, []);

  if (transactionOutcome) {
    if (transactionOutcome === "APPROVED") {
      Swal.fire({
        confirmButtonText: "Check Balance",
        allowOutsideClick: false,
        title: "Credit Approved!",
        icon: "success",
        text: "Please check your balance",
        color: "#fff",
        confirmButtonColor: "rgb(157 134 0)",
        background:
          "radial-gradient(79.09% 79.09% at 50% 14.18%, #4e4d4c 0%, #060606 100%)",
      }).then((result) => {
        if (result.isConfirmed) {
          syncUserData(dispatch);
          history.push("/player/my-account");
        }
      });
    } else if (transactionOutcome === "REJECTED") {
      Swal.fire({
        confirmButtonText: "Return home",
        allowOutsideClick: false,
        title: "Credit Declined!",
        text: declineReason + ", Please try again",
        icon: "error",
        color: "#fff",
        confirmButtonColor: "rgb(157 134 0)",
        background:
          "radial-gradient(79.09% 79.09% at 50% 14.18%, #4e4d4c 0%, #060606 100%)",
      }).then((result) => {
        if (result.isConfirmed) {
          history.push("/player/my-account");
        }
      });
    }
  }

  if (!data) {
    return <div>Loading...</div>;
  }

  const handleCopy = (event) => {
    event.preventDefault();
    const text = divRef.current.innerText;
    navigator.clipboard.writeText(text);

    nofitySuccess("Copied, " + text);
  };

  const addScreenshot = (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append("file", image);
    formData.append("transactionId", transactionId);
    updateScreenshot(formData).then((result) =>
      result
        ? alertDialog("File uploaded successfully.", "Close", "success")
        : alertDialog("File not uploaded, Please try again.", "Close", "error")
    );
    setIsImageValid(false);
  };

  return (
    <section className="pb-5 pt-3 container mx-auto text-white">
      <div className="">
        <h1 className="text-white pt-4">
          <b>
            {data.type === "E_WALLET" ? data.electronicWallet : data.bankType}
          </b>
        </h1>
      </div>

      <Row>
        <Col md={7}>
          <div className="mb-4">
            <h2 className="text-white">
              <Badge
                style={{ backgroundColor: "rgb(14, 203, 129)" }}
                className="text-white mr-2"
              >
                BUY
              </Badge>
              Chips from {data.accountName}
            </h2>
            <h4 className="text-white">
              The order is created, please wait for system confirmation.
            </h4>
          </div>

          <img
            src={getPaymentLogo(
              data.type === "E_WALLET" ? data.electronicWallet : data.bankType
            )}
            width={80}
            alt="payment.."
            className="mb-2"
          />

          <Row>
            <Col>
              <h2 className="text-white font-weight-bold">
                Wallet Account Number
              </h2>
              <div className="d-flex align-items-center mb-3">
                <strong ref={divRef} className="text-xl">
                  {data.accountNumber}
                </strong>
                <Badge
                  onClick={handleCopy}
                  color="light"
                  className="text-white ml-3 pointer"
                >
                  copy <FaCopy className="" />
                </Badge>
              </div>
            </Col>
            <Col>
              <h2 className="text-white font-weight-bold">Amount</h2>
              <div className="d-flex align-items-center mb-3">
                <strong className="text-xl">₱ {amount}</strong>
              </div>
            </Col>
          </Row>

          {proofOfPaymentCount !== 0 ? (
            <div className="my-2 border p-2">
              <div className="text-success">Screenshot Uploaded</div>
              <i>Please wait loader to process the transaction</i>
            </div>
          ) : (
            <>
              <h3 className="text-white">
                Please attached the screenshot here
              </h3>

              <FormGroup>
                <Input
                  type="file"
                  required
                  className="bg-dark rounded-md mt-2 text-white text-lg"
                  onChange={(e) => setImage(e.target.files[0])}
                  accept=".pdf,.png,.jpg"
                  max="1048576"
                  valid={isImageValid}
                />
                {isImageValid && (
                  <FormFeedback>
                    <p>
                      File is invalid. Please select a PDF, PNG, or JPG file up
                      to 5 MB
                    </p>
                  </FormFeedback>
                )}
                <Button
                  block
                  className="mt-2"
                  type="submit"
                  color="primary"
                  onClick={addScreenshot}
                >
                  UPLOAD
                </Button>
              </FormGroup>
            </>
          )}
        </Col>
        <Col md={5}>
          <DepositMessages
            transactionId={transactionId}
            sender={data.accountName}
            isTransactionFinished={transactionOutcome !== "PENDING"}
          />
        </Col>
      </Row>

      <div className="text-white mt-3">
        Tip: For deposits to be credired faster, please make sure to provide the
        screenshot right away.
      </div>
    </section>
  );
};

export default DepositTransaction;
