import React from "react";

import AllGames from "../components/AllGames";
import Banners from "views/homepage/components/Banners";

const AllGamesPage = () => {
  return (
    <section>
      <Banners />
      <div className="pb-5 text-center mx-4 mx-md-14 mx-lg-5 pt-3 mx-sm-3 mx-xs-2">
        <AllGames />
      </div>
    </section>
  );
};

export default AllGamesPage;
