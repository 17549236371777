import { useState, useEffect } from "react";
import {
  Card,
  CardHeader,
  Row,
  Container,
  Breadcrumb,
  BreadcrumbItem,
} from "reactstrap";

import { useSelector } from "react-redux";

import DataTable from "react-data-table-component";
import DataTableExtensions from "react-data-table-component-extensions";

import { columns } from "./data";

// Components
import LoadingBar from "components/LoadBars";
import Header from "components/Headers/Header.js";

import { getPermissions } from "api";

function Permissions() {
  const [data, setData] = useState([]);

  const JWT = useSelector((state) => state.auth.jwt);

  const tableData = {
    columns,
    data,
  };

  useEffect(() => {
    getPermissions(JWT).then((response) => setData(response));
    // eslint-disable-next-line
  }, []);

  return (
    <>
      <Header />

      <Container className="mt--7" fluid>
        <Row>
          <div className="col">
            <Card className="shadow">
              <Breadcrumb listClassName="breadcrumb-links">
                <BreadcrumbItem>Settings</BreadcrumbItem>
                <BreadcrumbItem>Permission</BreadcrumbItem>
              </Breadcrumb>
              <CardHeader className="border-0">
                <h2 className="mb-0">Permission Settings</h2>
              </CardHeader>

              <DataTableExtensions export={false} print={false} {...tableData}>
                <DataTable
                  columns={columns}
                  data={data}
                  pagination
                  striped
                  progressComponent={<LoadingBar />}
                  progressPending={!data.length}
                />
              </DataTableExtensions>
            </Card>
          </div>
        </Row>
      </Container>
    </>
  );
}

export default Permissions;
