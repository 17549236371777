import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { Link } from "react-router-dom";
import { Breadcrumb, BreadcrumbItem, Spinner } from "reactstrap";
import { getAccountInformation } from "api/AccountAPI";
import { pushLocationTo } from "views/shared/utils";

const BreadcrumbCustom = (props) => {
  const history = useHistory();

  const [hierarchy, setHierarchy] = useState([]);
  const [loading, setLoading] = useState(true);
  const username = props.username;
  const URI = props.uri;

  useEffect(() => {
    if (username) {
      setLoading(true);
      getAccountInformation(username).then((response) => {
        setHierarchy(response.data?.hierarchy);
        setLoading(false);
      });
    }
    // eslint-disable-next-line
  }, []);

  return (
    <>
      <Breadcrumb listClassName="breadcrumb-links mb-0">
        {loading && username ? (
          <BreadcrumbItem>
            <Spinner className="mr-2" color="primary" type="grow">
              Loading...
            </Spinner>
            <Spinner className="mr-2" color="primary" type="grow">
              Loading...
            </Spinner>
            <Spinner color="primary" type="grow">
              Loading...
            </Spinner>
          </BreadcrumbItem>
        ) : (
          <>
            <BreadcrumbItem>
              <Link to="/portal/index">Home</Link>
            </BreadcrumbItem>

            {props.downlinesURI && (
              <BreadcrumbItem>
                <Link to={props.downlinesURI}>Downlines</Link>
              </BreadcrumbItem>
            )}

            {hierarchy?.map((index) => {
              return (
                <BreadcrumbItem
                  key={index.name}
                  onClick={(e) => pushLocationTo(e, history, URI + index.value)}
                >
                  <Link to={URI + index.value}>{index.value}</Link>
                </BreadcrumbItem>
              );
            })}

            {username && (
              <BreadcrumbItem
                onClick={(e) => pushLocationTo(e, history, URI + username)}
              >
                <Link to={URI + username}>{username}</Link>
              </BreadcrumbItem>
            )}
          </>
        )}
      </Breadcrumb>
    </>
  );
};

export default BreadcrumbCustom;
