let authState = {
  jwt: "",
  uuid: "",
};

const authorizationHeader = (state = authState, action) => {
  switch (action.type) {
    case "SIGNOUT":
      return {
        jwt: "",
        uuid: "",
      };
    case "UID":
      return {
        ...state,
        uuid: action.payload,
      };
    case "JWT":
      return {
        ...state,
        jwt: action.payload,
      };
    default:
      return state;
  }
};

export default authorizationHeader;
