import { useEffect, useState } from "react";

import { useDispatch, useSelector } from "react-redux";

import { Button } from "reactstrap";
import { autoRefresh } from "actions";

const AutoRefresh = (props) => {
  const autoRefreshValue = useSelector((state) => state.ui?.autoRefresh);
  const dispatch = useDispatch();

  const [seconds, setSeconds] = useState(5);

  useEffect(() => {
    let interval = null;
    if (autoRefreshValue) {
      interval = setInterval(() => {
        if (seconds === 1) {
          props.sync();
          setSeconds(5);
        } else {
          setSeconds((seconds) => seconds - 1);
        }
      }, 1000);
    } else if (!autoRefreshValue && seconds !== 5) {
      clearInterval(interval);
    }
    return () => clearInterval(interval);
    // eslint-disable-next-line
  }, [autoRefreshValue, seconds]);

  return (
    <div className="d-flex flex-column">
      <div className="d-flex d-flex-col">
        <div>
          <Button
            color="info"
            className="mr-0"
            size="sm"
            onClick={() => dispatch(autoRefresh(!autoRefreshValue))}
          >
            {autoRefreshValue ? "Stop" : "Start"}
          </Button>
        </div>
        <div className="ml-2">
          <span>Automatic Refresh ({seconds})</span>
        </div>
      </div>

      {/* <p>Refreshing in {seconds}</p> */}
      {/* <Progress animated className="my-3" value={seconds} /> */}
    </div>
  );
};

export default AutoRefresh;
