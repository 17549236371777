import ConfirmForm from "components/Modals/confirm";

export const columns = (handleSync) => [
  {
    name: "Username",
    selector: (row) => row.username,
    sortable: true,
    cell: (row) => <b>{row.username}</b>,
  },
  {
    name: "Name",
    sortable: true,
    selector: (row) => row.name,
  },
  {
    name: "Email Add",
    sortable: true,
    selector: (row) => row.email,
  },
  {
    name: "Mobile Number",
    sortable: true,
    selector: (row) => row.mobileNumber,
  },
  {
    name: "Date Registered",
    sortable: true,
    selector: (row) => row.dateRegistered,
  },
  {
    name: "action",
    selector: (row) => row.transactionId,
    sortable: true,
    cell: (row) => (
      <ConfirmForm
        color="info"
        useCase="ACCOUNT_STATUS"
        data="ACTIVE"
        id={row.uuid}
        title="Activate Account"
        message={`Activate ${row.username} account ?`}
        icon="ni-check-bold"
        sync={handleSync}
      ></ConfirmForm>
    ),
  },
];
