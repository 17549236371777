import fetchClient from "api/axios";

import { thisWeekDate } from "components/Filters/default";
import { filterBuilder } from "views/shared/utils";

export const patchChipsCommissionPercentage = async (ID, value) => {
  const response = await fetchClient.patch(
    `/commissions/${ID}?creditType=CHIPS&percentage=${value}`
  );
  return response.status === 204;
};

export const patchCoinsCommissionPercentage = async (ID, value) => {
  const response = await fetchClient.patch(
    `/commissions/${ID}?creditType=COINS&percentage=${value}`
  );
  return response.status === 204;
};

export const getCommision = async () => {
  const response = await fetchClient.get(`/commissions`);
  return response.status === 200 ? response.data : null;
};

export const getChipCommissions = async (filter) => {
  const response = await fetchClient.get(
    `/commissions${filterBuilder(filter)}`
  );

  return response.status === 200 ? response.data : null;
};

export const getCurrentWeekCommision = async (username) => {
  const response = await fetchClient.get(
    `/commissions` + filterBuilder(thisWeekDate(username))
  );
  return response.status === 200 ? response.data : null;
};

// COIN COMMISSIONS
export const getCoinCommissions = async (filter) => {
  const response = await fetchClient.get(
    `/commissions/coins` + filterBuilder(filter)
  );
  return response.status === 200 ? response.data : null;
};

export const getUserCoinsCommission = async (username) => {
  const response = await fetchClient.get(
    `/commissions/coins?username=${username}`
  );
  return response.status === 200 ? response.data : null;
};
