import React from "react";
import { Row, Col, Card } from "reactstrap";

import { LiveCasino, Slot, microphone } from "assets/img/homepage";

const AllGames = () => {
  const items = [
    {
      id: 1,
      name: "LIVE CASINO",
      description:
        "Evolution gaming one of the most popular live game provider",
      image: LiveCasino,
    },
    {
      id: 2,
      name: "LUCKY SLOT",
      description:
        "Slot machine game generates random combinations of symbols on a dial.",
      image: Slot,
    },
  ];

  return (
    <section>
      <div className="text-left my-3">
        <h1 className="text-white">COMING SOON</h1>
      </div>
      <Card className="bg-dark p-3 p-md-3 text-left">
        <div>
          <img alt="" src={microphone} className="mr-2 float-left w-16 h-fit" />
          <h1 className="text-white">GAMES COMING SOON</h1>
        </div>

        <Row className="py-3">
          {items.map((index) => {
            return (
              <Col
                xl={4}
                lg={12}
                md={12}
                xs={12}
                className="mt-2"
                key={index.id}
              >
                <Card className="comming-soon-content">
                  <div className="text-white d-sm-flex align-items-sm-center">
                    <img
                      className="float-left mr-2"
                      alt="..."
                      src={index.image}
                    />
                    <p className="mt-2">
                      <b className="h2 text-white">{index.name}</b> <br />
                      {index.description}
                    </p>
                  </div>
                </Card>
              </Col>
            );
          })}
        </Row>
      </Card>
    </section>
  );
};

export default AllGames;
