//Site Owner
import StatementDetails from "views/shared/reports/statementdetails";
import Permission from "views/shared/settings/permission";
import Restrictions from "views/shared/settings/restrictions";

//Agent
import CoinsHistory from "views/shared/reports/history/coins";
import ChipsHistory from "views/shared/reports/history/chips";
import UserLoginHistory from "views/shared/reports/userloginhistory";

//Shared
import Dashboard from "views/shared/Dashboard";
import CreditDeposit from "views/shared/credit/deposit";
import CreditWithdraw from "views/shared/credit/withdraw";
import CreditHistory from "views/shared/credit/history";
import ModeOfPayment from "views/shared/credit/mop";

import ActiveAccounts from "views/shared/accounts/active";
import InactiveAccounts from "views/shared/accounts/inactive";
// import ForApproval from "views/shared/accounts/forapproval";

import Profile from "views/shared/Profile";
import EditProfile from "views/shared/profilesettings/editprofile";

import ChangePassword from "views/shared/profilesettings/changepassword";
import ForgetPassword from "views/shared/profilesettings/forgetpassword";
import NewPassword from "views/shared/profilesettings/newpassword";
import CommissionChipsSummary from "views/shared/commission/chips";
import CommissionCoinsSummary from "views/shared/commission/coins";
import SubAccount from "views/shared/accounts/subaccount";
import VideoKarera from "views/admin/game/videokarera";
import InBetween from "views/admin/game/inbetween";

const ADMIN = "ADMIN";
const SITE_OWNER = "SITE_OWNER";
const FINANCIER = "FINANCIER";
const MASTER = "MASTER";
const ALL = "ALL";

var routes = [
  {
    path: "/index",
    name: "Dashboard",
    icon: "ni ni-tv-2 text-primary",
    component: Dashboard,
    layout: "/portal",
    role: [ALL],
  },
  {
    path: "/user-profile",
    name: "User Profile",
    icon: "ni ni-single-02 text-yellow",
    invisible: true,
    component: Profile,
    layout: "/portal",
    role: [ALL],
  },

  {
    collapse: true,
    name: "Credit Management",
    icon: "ni ni-money-coins text-yellow",
    state: "creditCollapse",
    views: [
      {
        path: "/deposit",
        name: "Deposit",
        component: CreditDeposit,
        code: "DEPOSIT",
        icon: "fa fa-caret-right",
        layout: "/portal",
        role: [ALL],
      },
      {
        path: "/withdraw",
        name: "Withdraw",
        component: CreditWithdraw,
        icon: "fa fa-caret-right",
        code: "WITHDRAW",
        layout: "/portal",
        role: [ALL],
      },
      {
        path: "/credit/history",
        name: "Credit History",
        component: CreditHistory,
        code: "DECIDE_CASH_OUT_HISTORY",
        icon: "fa fa-caret-right",
        layout: "/portal",
        role: [ALL],
      },
      {
        path: "/credit/mode-of-payment",
        name: "Mode Of Payment",
        component: ModeOfPayment,
        code: "P2P_TRANSACTION",
        icon: "fa fa-caret-right",
        layout: "/portal",
        role: [FINANCIER, MASTER],
      },
    ],
  },
  {
    collapse: true,
    name: "Downlines",
    icon: "ni ni-circle-08 text-green",
    state: "downlinesCollapse",
    views: [
      {
        path: "/active-accounts",
        name: "Active",
        component: ActiveAccounts,
        layout: "/portal",
        icon: "fa fa-caret-right",
      },
      {
        path: "/inactive-accounts",
        name: "Inactive",
        component: InactiveAccounts,
        layout: "/portal",
        icon: "fa fa-caret-right",
      },
      // {
      //   path: "/forapproval-accounts",
      //   name: "For Approval",
      //   component: ForApproval,
      //   layout: "/portal",
      //   icon: "fa fa-caret-right",
      // },
    ],
  },
  {
    path: "/sub-accounts",
    name: "Sub Accounts",
    icon: "ni ni-circle-08 text-green",
    code: "SUB_ACCOUNT",
    component: SubAccount,
    layout: "/portal",
    role: [MASTER, FINANCIER, SITE_OWNER, ADMIN],
  },
  {
    collapse: true,
    name: "Commission",
    icon: "ni ni-money-coins text-red",
    state: "commissionCollapse",
    views: [
      {
        path: "/reports/commission/summary",
        name: "Chips Summary",
        component: CommissionChipsSummary,
        code: "COMMISSION_REPORT",
        layout: "/portal",
        icon: "fa fa-caret-right",
      },
      {
        path: "/reports/commission/coins",
        name: "Coins Summary",
        component: CommissionCoinsSummary,
        code: "COMMISSION_REPORT",
        layout: "/portal",
        icon: "fa fa-caret-right",
      },
    ],
  },
  {
    collapse: true,
    name: "Reports / History",
    icon: "ni ni-single-copy-04 text-grey",
    state: "reportsCollapse",
    views: [
      {
        path: "/history/chips",
        name: "Chips History",
        component: ChipsHistory,
        code: "CHIPS_HISTORY",
        layout: "/portal",
        icon: "fa fa-caret-right",
      },
      {
        path: "/history/coins",
        name: "Coins History",
        component: CoinsHistory,
        code: "COINS_HISTORY",
        layout: "/portal",
        icon: "fa fa-caret-right",
      },
      {
        path: "/reports/statement-details",
        name: "Statement Details",
        code: "STATEMENT_DETAILS",
        component: StatementDetails,
        layout: "/portal",
        icon: "fa fa-caret-right",
      },
      {
        path: "/reports/userlogin/history",
        name: "User Login History",
        component: UserLoginHistory,
        code: "USER_LOGIN_HISTORY",
        layout: "/portal",
        role: [ALL],
        icon: "fa fa-caret-right",
      },
    ],
  },
  {
    collapse: true,
    name: "Settings",
    icon: "ni ni-settings-gear-65 text-grey",
    state: "settingsCollapse",
    role: [ADMIN],
    views: [
      {
        path: "/settings/permission",
        name: "Permission",
        component: Permission,
        layout: "/portal",
        icon: "fa fa-caret-right",
      },
      {
        path: "/settings/restrictions",
        name: "Restrictions",
        component: Restrictions,
        layout: "/portal",
        icon: "fa fa-caret-right",
      },
    ],
  },
  {
    collapse: true,
    name: "Game",
    icon: "ni ni-button-play text-success",
    state: "gameCollapse",
    role: [ADMIN],
    views: [
      {
        path: "/game/video-karera",
        name: "Video Karera",
        component: VideoKarera,
        layout: "/portal",
        icon: "fa fa-caret-right",
      },
      {
        path: "/game/inbetween",
        name: "In Between",
        component: InBetween,
        layout: "/portal",
        icon: "fa fa-caret-right",
      },
    ],
  },

  {
    path: "/settings/changepassword",
    name: "Change Password",
    icon: "ni ni-single-02 text-orange",
    invisible: true,
    component: ChangePassword,
    layout: "/portal",
    role: [ALL],
  },
  {
    path: "/settings/editprofile",
    name: "Edit Profile",
    icon: "ni ni-single-02 text-red",
    invisible: true,
    component: EditProfile,
    layout: "/portal",
    role: [ALL],
  },
  {
    path: "/settings/forgetpassword",
    name: "Forget Password",
    icon: "ni ni-single-02 text-black",
    invisible: true,
    component: ForgetPassword,
    layout: "/auth",
    role: [ALL],
  },
  {
    path: "/newpassword",
    name: "New Password",
    invisible: true,
    component: NewPassword,
    layout: "/auth",
    role: [ALL],
  },
];
export default routes;
