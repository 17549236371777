import { useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import PrivacyPolicy from "components/Modals/privacypolicy";

// alert prompt
import Swal from "sweetalert2";

import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  Row,
  Col,
  Label,
  FormFeedback,
  FormText,
  Container,
} from "reactstrap";

// assets
// import Logo from "assets/img/brand/pusoy-logo.png";
import Background from "assets/img/background.png";

import PasswordStrengthBar from "react-password-strength-bar";

// axios API library
const axios = require("axios").default;

function Register() {
  let { referral } = useParams();
  const history = useHistory();

  const [username, setUsername] = useState("");
  const [mobileNumber, setMobileNumber] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");

  const [referralCode, setReferralCode] = useState(referral);

  const [setshowhide, setShowHide] = useState("password");
  const [isPasswordMatched, setIsPasswordMatched] = useState(true);
  const [eye, seteye] = useState(true);

  const handleRegister = e => {
    e.preventDefault();

    if (password !== confirmPassword) {
      setIsPasswordMatched(false);
    } else {
      setIsPasswordMatched(true);

      axios
        .post(process.env.REACT_APP_API_KEY + "/user", {
          referralCode: referralCode ? referralCode : "Shin-Agent",
          username: username,
          password: password,
          mobileNumber: mobileNumber,
          email: "",
          name: "",
        })
        .then(function (response) {
          if (response.status === 200) {
            if (response.data[0].statusCode === "OK") {
              Swal.fire({
                title: "Account Registered!",
                text: "Please wait for account approval",
                icon: "success",
              });

              if (response.data[0].message === "PLAYER") {
                history.push("/");
                Swal.fire({
                  text: "Kindly log in your player's account at iPlayEvo App!, thanks!",
                  icon: "success",
                });
              } else {
                history.push("/home");
              }
            } else {
              Swal.fire({
                title: response.data[0].message,
                icon: "error",
              });
            }
          }
        });
    }
  };

  const handleEye = () => {
    if (setshowhide === "password") {
      setShowHide("text");
      seteye(false);
    } else {
      setShowHide("password");
      seteye(true);
    }
  };

  return (
    <>
      <Container>
        <Card className="bg-secondary shadow border-0 mt-3">
          <Row>
            <Col lg="6" md="6" className="pl-md-0 d-none d-md-block">
              <Card
                className="h-100"
                style={{
                  backgroundImage: "url(" + Background + ")",
                  backgroundSize: "cover",
                  backgroundPosition: "center top",
                }}
              >
                <span
                  onClick={() => history.push("/home")}
                  className="h1 pointer float-left text-white p-4"
                  title="Back to Sign In"
                >
                  <i className="fa fa-arrow-left" />
                </span>
                {/* <div className="text-center my-auto">
                  <img alt="..." src={Logo} width={200} />
                </div> */}
              </Card>
            </Col>
            <Col lg={6} md={6} sm={12} className="pl-md-0">
              <div className="bg-secondary shadow h-100">
                <CardHeader className="bg-transparent pb-3 text-center">
                  <h1>Sign Up to iPLAYEVO!</h1>
                </CardHeader>

                <CardBody className="p-lg-5">
                  <Form role="form" onSubmit={handleRegister}>
                    <FormGroup>
                      <Label for="username">Referral Code</Label>
                      <Input
                        placeholder="Referral Code"
                        type="text"
                        disabled={referral}
                        autoComplete="new-email"
                        value={referralCode}
                        onChange={e => setReferralCode(e.target.value)}
                      />
                    </FormGroup>

                    <FormGroup>
                      <Label for="username">Username</Label>
                      <Input
                        placeholder="Username"
                        type="text"
                        minLength={5}
                        autoComplete="new-username"
                        value={username}
                        required
                        onChange={e => setUsername(e.target.value)}
                      />
                    </FormGroup>

                    <FormGroup>
                      <Label for="mobile">Mobile Number</Label>
                      <Input
                        placeholder="11 Digit Number"
                        type="number"
                        pattern="[0-9]"
                        required
                        autoComplete="new-password"
                        maxLength={11}
                        value={mobileNumber}
                        onChange={e => setMobileNumber(e.target.value)}
                      />
                    </FormGroup>

                    <FormGroup>
                      <Label for="password">Password</Label>
                      <Input
                        invalid={!isPasswordMatched}
                        placeholder="Your password here..."
                        value={password}
                        autoComplete="new-password"
                        minLength={6}
                        required
                        type={setshowhide}
                        onChange={e => setPassword(e.target.value)}
                      />
                      <FormFeedback>
                        Oh noes! password did not matched
                      </FormFeedback>
                      <FormText onClick={handleEye} className="pointer">
                        <div className="font-italic mt-2">
                          <i
                            className={`fa ${eye ? "fa-eye-slash" : "fa-eye"}`}
                          />
                          <span className="ml-2">
                            {eye ? "Show" : "Hide"} password
                          </span>
                        </div>
                      </FormText>
                    </FormGroup>
                    <FormGroup>
                      <Label for="confirm">Confirm password</Label>
                      <Input
                        invalid={!isPasswordMatched}
                        placeholder="Confirm here..."
                        type={setshowhide}
                        required
                        onChange={e => setConfirmPassword(e.target.value)}
                      />
                    </FormGroup>

                    <div className="text-muted font-italic">
                      <small>
                        password strength:
                        <PasswordStrengthBar
                          minLength="5"
                          password={password}
                        />
                      </small>
                    </div>
                    <Row className="my-4">
                      <Col xs="12">
                        <div className="custom-control custom-control-alternative custom-checkbox">
                          <Input
                            className="custom-control-input"
                            id="customCheckRegister"
                            type="checkbox"
                            required
                          />

                          <label
                            className="custom-control-label"
                            htmlFor="customCheckRegister"
                          >
                            <span className="text-muted">I agree with the</span>
                            <PrivacyPolicy />
                          </label>
                        </div>
                      </Col>
                    </Row>
                    <div className="text-center mb-3">
                      <Button className="mt-2" color="primary" type="submit">
                        Create account
                      </Button>
                    </div>
                  </Form>
                </CardBody>
              </div>
            </Col>
          </Row>
        </Card>
      </Container>
    </>
  );
}

export default Register;
