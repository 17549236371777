import PermissionForm from "components/Modals/permission";
import ConfirmForm from "components/Modals/confirm";

export const columns = (isAllowedEdit) => [
  {
    name: "Date Registered",
    sortable: true,
    selector: (row) => row.dateAndTime,
  },
  {
    name: "Username",
    selector: (row) => row.username,
    sortable: true,
    cell: (row) => <b>{row.username}</b>,
  },
  {
    name: "Name",
    sortable: true,
    selector: (row) => row.name,
  },
  {
    name: "Action",
    width: "300px",
    cell: (row) =>
      isAllowedEdit && (
        <>
          <PermissionForm row={row}></PermissionForm>
          <ConfirmForm
            color="danger"
            useCase="DELETE"
            id={row.uuid}
            title="DELETE"
            message={`Delete ${row.username} account`}
            icon="ni-fat-remove"
          ></ConfirmForm>
        </>
      ),
  },
];
