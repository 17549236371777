import React, { useState } from "react";
import { Button, Modal, ModalHeader, ModalBody } from "reactstrap";

import { authorizeCredit } from "api/Credit";

// Swipe Button Library
import ReactSwipeButton from "react-swipe-button";

// API calls
import { nofitySuccess, notifyDanger } from "components/Toasts";

const ConfirmMultiple = (props) => {
  const [modal, setModal] = useState(false);

  const closeApproveModal = () => {
    setModal(false);
  };

  const toggleModal = () => {
    setModal(!modal);
  };

  const handleSync = () => {
    props.sync();
  };

  const handleCredit = (decision) => {
    if (props.rows) {
      props.rows.forEach((index) => {
        authorizeCredit(index.transactionId, decision).then((response) => {
          if (response.statusCode === "OK") {
            nofitySuccess(`Credit proccessed for ${decision}`);
          } else {
            notifyDanger(response.message);
          }
        });
      });
      handleSync();
      closeApproveModal();
    }
  };

  const names = props.rows
    .map((index) => {
      return index.username + "(" + index.amount + ")";
    })
    .join();

  return (
    <>
      <Button
        disabled={!props.rows.length}
        color="dark"
        outline
        onClick={toggleModal}
      >
        Multiple Approves
      </Button>
      <Modal
        className="modal-dialog-centered"
        size="sm"
        isOpen={modal}
        centered
        toggle={() => closeApproveModal}
      >
        <ModalHeader toggle={closeApproveModal}>
          Authorise 'multiple' deposits
        </ModalHeader>
        <ModalBody className="text-center">
          <h1 className="display-1">
            <i className="ni ni-lock-circle-open" />
          </h1>
          <div className="mt-3 mb-3">
            Approve credits for <b>{names}</b> ?
          </div>
          <ReactSwipeButton
            text="SWIPE TO APPROVE"
            color="#11cdef"
            onSuccess={() => handleCredit("APPROVE")}
          />
        </ModalBody>
        <div className="text-center">
          <div className="mt-5">
            <Button
              color="danger"
              outline
              type="button"
              onClick={(e) => handleCredit("DECLINE")}
            >
              <i className="fa fa-times"></i>
            </Button>
          </div>
          <div className="mt-1 mb-3">Decline</div>
        </div>
      </Modal>
    </>
  );
};

export default ConfirmMultiple;
