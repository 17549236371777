// React core component
import { useState } from "react";

// React strap tools
import {
  FormGroup,
  InputGroup,
  Input,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
} from "reactstrap";

// Icons
import { BsExclamationTriangle } from "react-icons/bs";

import { deleteAccount } from "api/AccountAPI";

import { nofitySuccess, notifyDanger } from "components/Toasts";

const CONFIRM_KEYWORD = "DELETE";

export const DeleteForm = (props) => {
  const [deleteKeyword, setDeleteKeyword] = useState();
  const [modal, setModal] = useState(false);

  const handleSync = () => {
    props.sync();
  };
  const toggle = () => setModal(!modal);

  const handleSubmit = (e) => {
    e.preventDefault();

    props.data.forEach((index) => {
      if (props.api) {
        props.api(index.value).then((response) => {
          if (response?.statusCode === "OK") {
            nofitySuccess(props.successTitle);
            toggle();
            handleSync();
          }
        });
      } else {
        deleteAccount(index.value).then((response) => {
          if (response.statusCode === "OK") {
            nofitySuccess(`Account ${index.name} is now DELETED!`);
            toggle();
            handleSync();
          } else {
            notifyDanger(response.message);
          }
        });
      }
    });
  };

  const showNames = props.data
    .map((index) => {
      return index.name + ", ";
    })
    .join("");

  return (
    <>
      <Button
        className="btn-icon btn-3"
        color="danger"
        outline
        type="button"
        disabled={!props.data.length}
        onClick={toggle}
      >
        <span className="btn-inner--icon">
          <i className="fa fa-trash"></i>
        </span>
        <span className="btn-inner--text">Delete</span>
      </Button>

      <Modal
        className="modal-dialog-centered"
        size="md"
        isOpen={modal}
        toggle={toggle}
      >
        <ModalHeader toggle={toggle}>Confirm record termination</ModalHeader>

        <ModalBody>
          <p className="mb-3">
            <span className="mr-2">
              Permanently terminate
              <span className="font-weight-bold mx-1">
                {showNames.substring(0, showNames.length - 2)}
              </span>
              ?
            </span>
            <strong>This action can't be undone.</strong>
          </p>

          <blockquote className="border p-4">
            <p className="mb-3 font-weight-bold">
              <BsExclamationTriangle className="text-warning mr-2" />
              Terminating this data will also terminate its associated
              resources.
            </p>
            <strong>Accounts</strong> - account will be unable to logged in.
            <br />
            <strong>Additional resources</strong> - any resource associated with
            this access level will be also terminated.
          </blockquote>

          <FormGroup className="mb-3">
            <p className="">
              <span className="mr-2">Enter the word DELETE to confirm:</span>
            </p>
            <InputGroup className="input-group-alternative">
              <Input
                placeholder="Please type here"
                type="text"
                autoComplete="off"
                minLength={5}
                required="required"
                onChange={(e) => {
                  setDeleteKeyword(e.target.value);
                }}
              />
            </InputGroup>
            <p className="">
              <span className="mr-2 h6">This is case sensitive</span>
            </p>
          </FormGroup>
          <div className="text-right">
            <Button
              color="secondary"
              data-dismiss="modal"
              type="button"
              onClick={toggle}
            >
              Close
            </Button>
            <Button
              disabled={deleteKeyword !== CONFIRM_KEYWORD}
              className="mutted"
              color="primary"
              type="button"
              onClick={handleSubmit}
            >
              Delete
            </Button>
          </div>
        </ModalBody>
      </Modal>
    </>
  );
};

export default DeleteForm;
