export const columns = [
  {
    name: "Id",
    sortable: true,
    selector: (row) => row.id,
  },
  {
    name: "Code",
    selector: (row) => row.code,
    sortable: true,
  },
  {
    name: "Name",
    sortable: true,
    selector: (row) => row.name,
  },
];
