import React, { useState, useEffect } from "react";

import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  InputGroup,
  Input,
  InputGroupText,
  ModalFooter,
  Badge,
} from "reactstrap";

import { notifyWarning } from "components/Toasts";
import { getMessagesMOP, sendMessageMOP } from "api/p2p";

const Message = (props) => {
  const [modal, setModal] = useState(false);
  const [data, setData] = useState([]);

  const [message, setMessage] = useState();
  const [intervalId, setIntervalId] = useState(null);

  const closeModal = () => {
    setModal(false);
    clearInterval(intervalId);
  };

  const toggleModal = () => {
    setModal(!modal);
  };

  useEffect(() => {
    if (modal) {
      const id = setInterval(() => {
        getMessagesMOP(props.id).then((response) => setData(response));
      }, 1000);

      setIntervalId(id);
    }
    // eslint-disable-next-line
  }, [modal]);

  const handleSendMessage = () => {
    if (message) {
      sendMessageMOP(props.id, message).then(() => {
        getMessagesMOP(props.id).then((response) => setData(response));
      });
      setMessage("");
    } else {
      notifyWarning("Please type a message");
    }
  };

  return (
    <>
      <Button
        className="text-nowrap w-100"
        color="primary"
        onClick={toggleModal}
        title="Approve"
      >
        Chats
        <Badge className="ml-1 badge-white">{props.messageCount}</Badge>
      </Button>
      <Modal
        className="modal-dialog-centered"
        size="md"
        isOpen={modal}
        centered
        fullscreen
        scrollable
        toggle={closeModal}
      >
        <ModalHeader toggle={closeModal}>
          Mesage reciever <b>{props.user}</b>
        </ModalHeader>
        <ModalBody className="text-center">
          {data.map((item) => (
            <div
              className={`mx-1 my-2 ${
                item.messageFrom === "RECEIVER" ? "text-left" : "text-right"
              }`}
            >
              <div className="mb-1">
                <span
                  className={`p-2 rounded ${
                    item.messageFrom === "RECEIVER"
                      ? "bg-secondary"
                      : "bg-primary text-white"
                  }`}
                >
                  {item.message}
                </span>
              </div>
              <small>{item.dateAndTime}</small>
            </div>
          ))}
        </ModalBody>
        <ModalFooter>
          <InputGroup>
            <Input
              placeholder="Message reciever"
              value={message}
              onChange={(e) => setMessage(e.target.value)}
            />
            <InputGroupText
              onClick={handleSendMessage}
              className="bg-primary text-white pointer"
            >
              Send
            </InputGroupText>
          </InputGroup>
        </ModalFooter>
      </Modal>
    </>
  );
};

export default Message;
