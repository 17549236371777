// reactstrap components
import { Container, Row, Col } from "reactstrap";
import { TermsAndConditions } from "components/Modals/termsandconditions";
import PrivacyPolicy from "components/Modals/privacypolicy";
const Login = () => {
  return (
    <footer className="py-3">
      <Container>
        <Row className="align-items-center justify-content-xl-between">
          <Col className="text-center text-white copyright" xl="12">
            <span className="font-weight-bold mr-3 pointer">
              Responsible Gaming
            </span>
            |
            <span className="font-weight-bold mx-3 pointer">
              <PrivacyPolicy />
            </span>
            |
            <span className="font-weight-bold mx-3 pointer">
              <TermsAndConditions />
            </span>
            <br />
            <br />© Copyright {new Date().getFullYear()} <b>iPlayEvo</b>
            <span className="ml-1">All Right Reserved.</span>
            <br />
            <span className="font-weight-bold">SABTECHNOLOGY.</span>
          </Col>
        </Row>
      </Container>
    </footer>
  );
};

export default Login;
