import { useState } from "react";

// React strap tools
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";

// alert prompt
import Swal from "sweetalert2";
import { nofitySuccess, notifyDanger } from "components/Toasts";

// API calls
import { changeStatus, deleteAccount } from "api/AccountAPI";

export const ConfirmModal = (props) => {
  const [open, setOpen] = useState(false);
  const toggle = () => setOpen(!open);

  const handleSync = () => {
    props.sync();
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (props.useCase === "ACCOUNT_STATUS") {
      changeStatus(props.id, props.data).then((response) => {
        if (response.statusCode === "OK") {
          nofitySuccess(`Account is now on ${props.data}!`);
        } else {
          notifyDanger(response.message);
        }
        if (props.sync) {
          handleSync();
        }
        toggle();
      });
    } else if (props.useCase === "DELETE") {
      deleteAccount(props.id).then((response) => {
        if (response.statusCode === "OK") {
          successMessage().then((result) => {
            if (result.isConfirmed) {
              window.location.reload();
            }
          });
          toggle();
        }
      });
    }
  };

  const successMessage = () => {
    return Swal.fire({
      title: "Proccessed Successfully!",
      icon: "success",
    });
  };

  return (
    <>
      <Button
        className="btn-icon btn-2 w-100"
        color="primary"
        type="button"
        size="sm"
        onClick={() => setOpen(true)}
        outline
      >
        <span className="btn-inner--icon">
          <i className={`ni ${props.icon}`} /> {props.title}
        </span>
      </Button>
      <Modal
        className={`modal-dialog-centered modal-${props.color}`}
        contentClassName={`bg-gradient-${props.color}`}
        isOpen={open}
        toggle={toggle}
      >
        <ModalHeader toggle={toggle}>{props.title}</ModalHeader>
        <ModalBody>
          <div className="py-3 text-center">
            <i className="ni ni-bell-55 ni-3x" />
            <h3 className="heading mt-4">Confirm Process!</h3>
            <p className="h2 text-white">{props.message}</p>
          </div>
        </ModalBody>
        <ModalFooter>
          <Button
            onClick={handleSubmit}
            className="btn-white"
            color="default"
            type="button"
          >
            Yes
          </Button>
          <Button
            className="text-white ml-auto"
            color="link"
            data-dismiss="modal"
            type="button"
            onClick={toggle}
          >
            Close
          </Button>
        </ModalFooter>
      </Modal>
    </>
  );
};

export default ConfirmModal;
