import { useState, useEffect } from "react";
import { Card, CardHeader } from "reactstrap";

import DataTable from "react-data-table-component";
import DataTableExtensions from "react-data-table-component-extensions";

import { columns } from "./data";

// Components
import LoadingBar from "components/LoadBars";

import { getGameLogs } from "api/Game";

function VideoKareraLogs() {
  const [data, setData] = useState([]);

  const tableData = {
    columns,
    data,
  };

  useEffect(() => {
    getGameLogs().then((response) => setData(response));
    // eslint-disable-next-line
  }, []);

  return (
    <Card className="shadow">
      <CardHeader className="border-0">
        <h2 className="mb-0">Game Logs</h2>
      </CardHeader>

      <DataTableExtensions export={false} print={false} {...tableData}>
        <DataTable
          columns={columns}
          data={data}
          pagination
          striped
          progressComponent={<LoadingBar />}
          progressPending={!data.length}
        />
      </DataTableExtensions>
    </Card>
  );
}

export default VideoKareraLogs;
