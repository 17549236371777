import { Badge } from "reactstrap";

import { commas } from "views/shared/utils";

import chipsImage from "assets/img/chips-icon.png";
import coinsImage from "assets/img/coins-icon.png";

export const columns = [
  {
    name: "Transaction #",
    selector: (row) => row.transactionId,
    width: "17%",
    sortable: true,
  },
  {
    name: "Date / Time",
    selector: (row) => row.date,
    width: "12%",
    sortable: true,
    wrap: true,
  },
  {
    name: "Amount",
    selector: (row) => row.amount,
    sortable: true,
    cell: (row) => (
      <>
        <>
          {row.creditEvent === "INCREASE" ? (
            <>
              <i className="text-success fa fa-arrow-up"></i>
              <span className="ml-1">{commas(row.amount)}</span>
            </>
          ) : (
            <>
              <i className="text-danger fa fa-arrow-down"></i>
              <span className="ml-1 text-danger">{commas(row.amount)}</span>
            </>
          )}
          <>
            <img
              src={row.creditType === "CHIPS" ? chipsImage : coinsImage}
              alt=""
              width={20}
              className="ml-2"
            />
          </>
        </>
      </>
    ),
  },
  {
    name: "Chips",
    selector: (row) => commas(row.balanceChips),
    width: "11%",
    sortable: true,
  },
  {
    name: "Coins",
    selector: (row) => commas(row.balanceCoins),
    sortable: true,
  },
  {
    name: "Transaction",
    selector: (row) => row.transactionType,
    sortable: true,
    cell: (row) => (
      <span
        className={
          row.transactionType === "DEPOSIT" ? "text-success" : "text-danger"
        }
      >
        {row.transactionType}
      </span>
    ),
  },
  {
    name: "Payment URL",
    selector: (row) => row.paymentLink,
    width: "12%",
    cell: (row) =>
      row.paymentLink && row.status === "PENDING" ? (
        <div>
          <a
            href={row.paymentLink}
            target="_blank"
            rel="noopener noreferrer"
            className="text-primary hover:underline"
          >
            GOTO PAYMENT
          </a>
        </div>
      ) : null,
  },
  {
    name: "Status",
    width: "20%",
    selector: (row) => row.status,
    sortable: true,
    cell: (row) => {
      switch (row.status) {
        case "APPROVED":
          return <Badge color="success">APPROVED</Badge>;
        case "REJECTED":
          return (
            <div className="d-flex flex-column">
              <div>
                <Badge color="danger">REJECTED</Badge>
              </div>

              <div className="mt-1">
                NOTES: {row.reason === "undefined" ? "" : row.reason}
              </div>
            </div>
          );
        case "PENDING":
          return <Badge color="warning">PENDING</Badge>;
        default:
        // code block
      }
    },
  },
];
