import fetchClient from "api/axios";
// Authorization
import store from "store";

const getSessionID = () => {
  return store.getState().auth.uuid;
};

export const getRestrictedPermissions = async (id) => {
  const response = await fetchClient.get(
    `/permissions/restricted/${id ? id : getSessionID()}`
  );
  return response.status === 200 ? response.data : [];
};

export const updateAccountViewRestriction = async (id, code, isEnabled) => {
  const response = await fetchClient.put(
    `/permissions/restriction/${id}?permissionCode=${code}&isEnabled=${isEnabled}`
  );
  return response.status === 200 ? response.data : [];
};
