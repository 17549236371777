import { useState } from "react";

// React strap tools
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Row,
  Col,
} from "reactstrap";

export const PrivacyPolicy = () => {
  const [modal, setModal] = useState(false);

  const toggle = () => setModal(!modal);

  return (
    <>
      <span color="danger" onClick={toggle}>
        <i className="fa fa-paperclip"></i>
        Privacy Policy
      </span>
      <Modal size="lg" isOpen={modal} toggle={toggle}>
        <ModalHeader toggle={toggle}>
          <h1 className="mx-auto">iPLAYEVO PRIVACY POLICY</h1>
        </ModalHeader>
        <ModalBody>
          <Row>
            <Col md={12}>
              <h3>Shinbtechnology</h3>
            </Col>
            <Col md={12}>
              We ask that you read this privacy notice carefully, as it contains
              important information on who we are, how and why we collect,
              store, use, and share personal information, your rights in
              relation to your personal information and on how to contact us and
              supervisory authorities in the event you have a complaint.
            </Col>

            <Col md={12}>
              <h3 className="mt-4">1. Who we are</h3>
            </Col>
            <Col md={12}>
              The parties referred to in this Agreement shall be defined as
              follows:
            </Col>
            <Col md={12}>
              Shinbtechnology(“Shinbtechnology,” “We,” or “Us”) collects, uses
              and is responsible for certain personal information about you, as
              part of providing the Shinbtechnology website and mobile
              applications (collectively, the “Service”).
            </Col>
            <Col md={12}>
              For those in the European Union, we are regulated under the
              General Data Protection Regulation which applies across the
              European Union, and we are responsible as controller of that
              personal information for the purposes of those laws.
            </Col>
            <Col md={12}>
              <h3 className="mt-4">
                2. The Personal Information we collect and use
              </h3>
            </Col>
            <Col md={12}>
              Information collected by us: In the course of providing you with
              the Service, we collect the following personal information, either
              automatically or when you explicitly provide it to us:
            </Col>
            <Col md={12}>
              Your IP address, browser type, and information about your gameplay
              and activities on the Service are collected automatically while
              you use the Service (your “Analytic Information”)
            </Col>
            <Col md={12}>Your unique Advertising ID</Col>
            <Col md={12}>
              Your name and email address, if you sign up for our newsletter
            </Col>
            <Col md={12}>
              How we use your Personal Information: We use your personal
              information as follows:
            </Col>
            <Col md={12}>
              Your email address is used to communicate with you about
              Service-related issues (including receiving and responding to
              communications that you send us through the Service), and to send
              you Service-related communications about new products and
              services.
            </Col>
            <Col md={12}>
              Your Analytic Information is gathered and pooled with other users’
              information, and used for the purposes of improving the Service,
              such as tracking the total number of users or visitors to the
              Service, their aggregate activities on the Service, gameplay data
              and other gameplay analytics, and tracking the sites that refer
              visitors to Shinbtechnology.
            </Col>
            <Col md={12}>
              Your Advertising ID is used to display personalized advertisements
              on the Service (see Who we share your Personal Information with,
              below, for more information).
            </Col>
            <Col md={12}>
              We will require to upload your score data to the game’s global
              leaderboard once you start playing the game.
            </Col>
            <Col md={12}>
              Who we share your Personal Information with: We share your
              Personal Information with certain third parties as part of our
              operation of the Service, whether shared by us or gathered
              directly by third parties through SDKs integrated into the
              Service. This data sharing enables us to provide you with the
              Service in the optimal way, such as serving you personalized,
              relevant advertisements within the Service.
            </Col>
            <Col md={12}>
              Some of those third-party recipients may be based outside the
              European Economic Area — for further information, including how we
              safeguard your personal data when this occurs, see their
              individual privacy policies linked to below and the section
              entitled “Transfer of your Information out of the EEA,” below.
            </Col>

            <Col md={12}>
              We share your Personal Information with the following third
              parties:
            </Col>
            <Col md={12}>
              Advertisements: Some of your personal information, such as your
              Advertising ID, is gathered by advertisers to display
              advertisements on the Service that are relevant to your interests.
            </Col>
            <Col md={12}>
              Please refer to Our privacy policy for further information about
              what information We collect, how we use it and store it, and your
              rights in relation to it.{" "}
            </Col>
            <Col md={12}>Our third-party advertisers are:</Col>
            <Col md={12}>Facebook – Privacy Policy, Unity – Privacy Policy</Col>
            <Col md={12}>
              We may add and remove advertisers from this list from time to
              time.
            </Col>
            <Col md={12}>
              If you would like more information about online advertising and to
              know your choices about not having this information used by these
              companies, please see:
              http://www.networkadvertising.org/managing/opt_out.asp
            </Col>

            <Col md={12}>
              Analytics: We may use third party website and mobile application
              analytic tools on the Service, that employ cookies and other
              tracking methods (such as tracking pixels) to collect certain
              information concerning your use of the Service. These tools
              include:
            </Col>

            <Col md={12}>Google Analytics – Privacy Policy</Col>
            <Col md={12}>Other Sharing:</Col>
            <Col md={12}>
              We will share your Personal Information if we have a good faith
              belief that (i) access, use, preservation or disclosure of such
              information is reasonably necessary to satisfy any applicable law,
              regulation, legal process, such as a court order or subpoena, or a
              request by law enforcement or governmental authorities, (ii) such
              action is necessary to detect, prevent, or otherwise address
              fraud, security or technical issues associated with the Service,
              or (iii) such action is appropriate to protect the rights,
              property or safety of Shinbtechnology, its employees, clients, or
              users of the Service.
            </Col>
            <Col md={12}>
              How long your Personal Information will be kept: We will keep your
              Personal Information for the length of time required to provide
              you with the Service and for the reasons described in this privacy
              policy, unless a longer retention period is required or permitted
              by law. Afterwards, we delete all aforementioned data in our
              possession within a reasonable timeframe. We do not verify the
              correctness of personal data that we collect or you provide.
            </Col>
            <Col md={12}>
              Please note that some data may be retained if necessary, to
              resolve disputes, enforce Shinbtechnology user agreements, and
              comply with technical and legal requirements and constraints
              related to the security, integrity, and operation of the Service.
            </Col>
            <Col md={12}>
              Reasons we can collect and use your Personal Information: The laws
              in some jurisdictions require that we notify you of the lawful
              basis for collecting your Personal Information.
            </Col>
            <Col md={12}>
              To the extent that such laws apply, we rely on the following as
              the lawful basis on which we collect and use your personal
              information:
            </Col>
            <Col md={12}>
              Consent: At times we may collect, process, store, transfer or
              disclose your personal data on the basis of your implied or
              explicit consent.
            </Col>
            <Col md={12}>
              To fulfill our contractual obligations: In order to fulfill our
              contractual obligations to you, we must collect, process, and
              store your personal data and information. For example, we may
              handle your personal data in this manner if you make a purchase on
              the Service, though generally that personal data (including
              payment information) is solely collected by Google, Apple, Amazon,
              or other platform holders when you make the purchase through them,
              and is governed by their privacy policies.
            </Col>
            <Col md={12}>
              Furtherance of legitimate interests: We may collect, process,
              store, transfer or disclose your personal data in furtherance of
              those legitimate interests of ours which are not overridden by
              your interests or fundamental rights and freedoms as set forth in
              the applicable laws. These legitimate interests include, but are
              not limited to, (i) providing you with the Service, (ii)
              protecting our users, staff, and property, (iii) collecting
              information on how you use the Service in order to optimize the
              design and functionality of the Service, (iv) display personalized
              advertisements, and (v) communicating with you via e-mail for
              communications you have explicitly opted into (e.g., notifications
              about new products and services) or for communications regarding
              the Service (e.g., customer service and troubleshooting).
            </Col>
            <Col md={12}>
              Legal compliance: We may collect, process, store, transfer or
              disclose your personal data to comply with our legal obligations.
            </Col>
            <Col md={12}>
              Depersonalized Information: We may make your Personal Information
              non-personally identifiable by either combining or aggregating it
              with information about other users, or by removing characteristics
              (such as your name or email address) that make the information
              personally identifiable. This process is known as de-personalizing
              your information and is used to analyze usage and improve the
              Service.
            </Col>
            <Col md={12}>
              Children’s Privacy: We do not knowingly collect any personal
              information from children under the age of 13, allow them to sign
              up for newsletters, make purchases, or use the Service. In
              addition, we may limit how we collect, use, and store some of the
              information of EU users between 13 and 16.
            </Col>
            <Col md={12}>
              Shinbtechnology takes children’s privacy seriously and encourages
              parents to play an active role in their children’s online
              experience at all times. We urge parents to instruct their
              children never to give out their real names, addresses, or phone
              numbers, without parental permission, when online. If you have any
              concerns about your child’s personal information, please contact
              us at slotmachineoc247@gmail.com
            </Col>
            <Col md={12}>
              Links to Other Sites: The Service may contain links to other sites
              and applications. If you click on a third-party link, you will be
              directed to that site. These external services are not operated by
              us. Therefore, we strongly advise you to review the Privacy Policy
              of these websites and mobile applications. We have no control over
              and assume no responsibility for the content, privacy policies, or
              practices of any third-party sites or services.
            </Col>
            <Col md={12}>
              <h3 className="mt-4">
                3. Transfer of your Information out of the EEA
              </h3>
            </Col>

            <Col md={12}>
              Shinbtechnology is based in the Philippines. No matter where you
              are located, you consent to the processing, transfer and storage
              of your information in and to the Philippines, and other
              countries, in accordance with this privacy policy and the privacy
              policies of third parties with whom we share your Personal
              Information. The laws of the Philippines and other countries
              governing data collection and use may not be as comprehensive or
              protective as the laws of the country where you live.
            </Col>

            <Col md={12}>
              If you would like further information, please contact us (see “How
              to contact us” below).
            </Col>

            <Col md={12}>
              <h3 className="mt-4">4. Your Rights</h3>
            </Col>

            <Col md={12}>
              Under the laws of some jurisdictions, including the General Data
              Protection Regulation in the EEA, you have a number of important
              rights with regard to your Personal Information.
            </Col>

            <Col md={12}>
              By law, you can ask us what information we hold about you, and you
              can ask us to correct it if it is inaccurate. If we have asked for
              your consent to process your personal data, you may withdraw that
              consent at any time.
            </Col>

            <Col md={12}>
              If we are processing your personal data for reasons of consent or
              to fulfill a contract, you can ask us to give you a copy of the
              information in a machine-readable format so that you can transfer
              it to another provider.
            </Col>

            <Col md={12}>
              If we are processing your personal data for reasons of consent or
              legitimate interest, you can request that your data be erased.
            </Col>

            <Col md={12}>
              You have the right to ask us to stop using your information for a
              period of time, if you believe we are not doing so lawfully.
            </Col>

            <Col md={12}>
              Finally, in some circumstances, you can ask us not to reach
              decisions affecting you using automated processing or profiling.
            </Col>

            <Col md={12}>
              For further information on each of those rights, including the
              circumstances in which they apply, see the Guidance from the UK
              Information Commissioner’s Office (ICO) on individuals rights
              under the General Data Protection Regulation.
            </Col>

            <Col md={12}>
              If you would like to exercise any of those rights, please email us
              at slotmachineoc247@gmail.com.
            </Col>

            <Col md={12}>
              If you would like to unsubscribe from any email communication from
              us, you can also click on the unsubscribe button at the bottom of
              the email. It may take a few days for this to take effect.
            </Col>

            <Col md={12}>
              <h3 className="mt-4">
                5. Keeping your Personal Information secure
              </h3>
            </Col>

            <Col md={12}>
              We have taken steps to put appropriate security measures in place
              to prevent personal information from being accidentally lost, or
              used or accessed in an unauthorized way.
            </Col>

            <Col md={12}>
              For example, we limit access to your personal information to those
              who have a genuine business need to know it. Those processing your
              information will do so only in an authorized manner and are
              subject to a duty of confidentiality.
            </Col>

            <Col md={12}>
              We also have procedures in place to deal with any suspected data
              security breach. We will notify you and any applicable regulator
              of a suspected data security breach where we are legally required
              to do so.
            </Col>

            <Col md={12}>
              We use SSL encryption and other technologies to keep data safe
              when we transfer and store it.
            </Col>

            <Col md={12}>
              <h3 className="mt-4">6. How to complain</h3>
            </Col>

            <Col md={12}>
              We hope that we can resolve any questions or concerns you raise
              about our use of your Personal Information. Please contact us via
              the methods listed below in the section entitled “How to contact
              us” to let us know about any of your questions or concerns, and we
              will get back to you to resolve the issue.
            </Col>

            <Col md={12}>
              If you are an EU citizen, the General Data Protection Regulation
              also gives you the right to lodge a complaint with a supervisory
              authority, in particular in the European Union (or European
              Economic Area) state where you work, normally live, or where any
              alleged infringement of data protection laws occurred.
            </Col>

            <Col md={12}>
              <h3 className="mt-4">7. Changes to this Privacy Notice</h3>
            </Col>

            <Col md={12}>
              We may change this privacy notice from time to time. When we do,
              we will inform you via email if you have signed up for our mailing
              list, or by posting a notification on the Service.
            </Col>

            <Col md={12}>
              <h3 className="mt-4">8. How to contact us</h3>
            </Col>

            <Col md={12}>
              Please contact us if you have any questions about this privacy
              notice or the information we hold about you.
            </Col>

            <Col md={12}>If you wish to contact us:</Col>

            <Col md={12}>
              please send an email to slotmachineoc247@gmail.com, or use the
              contact form on the Service.
            </Col>
          </Row>
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={toggle} outline>
            Accept
          </Button>
        </ModalFooter>
      </Modal>
    </>
  );
};

export default PrivacyPolicy;
