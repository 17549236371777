import { useState } from "react";
import {
  Card,
  CardHeader,
  Row,
  Container,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
} from "reactstrap";

// Components
import Header from "components/Headers/Header.js";

import VideoKareraLogs from "./logs";
import VideoKareraSettings from "./settings";
import VideoKareraOddsSettings from "./odds";

import classnames from "classnames";

function VideoKarera() {
  const [state, setState] = useState({
    tabs: 1,
  });

  const toggleNavs = (e, state, index) => {
    e.preventDefault();
    setState({
      [state]: index,
    });
  };

  return (
    <>
      <Header />
      <Container className="mt--7" fluid>
        <Row>
          <div className="col">
            <Card className="shadow mb-3">
              <CardHeader className="border-0">
                <h2 className="mb-3">Video Karera HUB</h2>
                <Nav
                  className="nav-fill flex-column flex-sm-row"
                  id="tabs-text"
                  pills
                  role="tablist"
                >
                  <NavItem>
                    <NavLink
                      aria-selected={state.tabs === 1}
                      className={classnames("mb-sm-3 mb-md-0", {
                        active: state.tabs === 1,
                      })}
                      onClick={(e) => toggleNavs(e, "tabs", 1)}
                      href="#pablo"
                      role="tab"
                    >
                      Scheme
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      aria-selected={state.tabs === 2}
                      className={classnames("mb-sm-3 mb-md-0", {
                        active: state.tabs === 2,
                      })}
                      onClick={(e) => toggleNavs(e, "tabs", 2)}
                      href="#pablo"
                      role="tab"
                    >
                      Odds Settings
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      aria-selected={state.tabs === 3}
                      className={classnames("mb-sm-3 mb-md-0", {
                        active: state.tabs === 3,
                      })}
                      onClick={(e) => toggleNavs(e, "tabs", 3)}
                      href="#pablo"
                      role="tab"
                    >
                      Logs
                    </NavLink>
                  </NavItem>
                </Nav>
              </CardHeader>
            </Card>
            <TabContent activeTab={"tabs" + state.tabs}>
              <TabPane tabId="tabs1">
                <VideoKareraSettings />
              </TabPane>
              <TabPane tabId="tabs2">
                <VideoKareraOddsSettings />
              </TabPane>
              <TabPane tabId="tabs3">
                <VideoKareraLogs />
              </TabPane>
            </TabContent>
          </div>
        </Row>
      </Container>
    </>
  );
}

export default VideoKarera;
