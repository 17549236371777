import { useState, useEffect } from "react";
import { useParams, Link, useHistory } from "react-router-dom";

// React strap components
import { Card, Row, Container, CardHeader, CardBody, Table } from "reactstrap";
import { useSelector } from "react-redux";

// Data table
import Header from "components/Headers/Header.js";
import { thisWeekDate } from "components/Filters/default";
import Filter from "components/Filters";
import LoadingBar from "components/LoadBars";
import BreadcrumbCustom from "components/BreadcrumbCustom";

import { getChipCommissions } from "api/Commision";

import { designNo, pushLocationTo } from "views/shared/utils";

const calculateTotalNetProfit = (value) =>
  value.reduce((total, row) => total + row.netProfit, 0);

const calculateTotalAmount = (value, field) =>
  value.reduce((total, row) => total + row[field], 0);

function CommissionChips() {
  const { username } = useParams();
  const currentUsername = useSelector((state) => state.entities.user.username);
  const sessionFilter = useSelector((state) => state.ui.filter);

  const history = useHistory();
  const [data, setData] = useState({
    downLineDetails: [],
    netProfit: 0,
    upperDetails: null,
    username: "",
  });

  const existingUsername = username ? username : currentUsername;
  if (sessionFilter?.username) {
    sessionFilter.username = existingUsername;
  }

  const [filter, setFilter] = useState(
    sessionFilter ? sessionFilter : thisWeekDate(existingUsername)
  );

  const [loading, setLoading] = useState(true);
  const [refreshTrigger, setRefreshTrigger] = useState(1);

  useEffect(() => {
    getChipCommissions(filter).then((response) => {
      setData(response);
      setLoading(false);
    });
    // eslint-disable-next-line
  }, [refreshTrigger]);

  const toggleFilter = () => {
    setLoading(true);
    setRefreshTrigger(refreshTrigger + 1);
  };

  const flatData = data?.downLineDetails?.reduce((acc, curr) => {
    curr.value.forEach((item) => {
      acc.push({
        name: curr.name,
        gameType: item.gameType,
        betAmount: item.betAmount,
        winOrLoseAmount: item.winOrLoseAmount,
        netProfit: item.netProfit,
        sharePercentage: item.sharePercentage,
      });
    });
    return acc;
  }, []);

  const currValueCount = (name) => {
    return flatData.filter((item) => item.name === name).length;
  };

  let totalNetProfit = 0.0;
  let totalBetAmount = 0.0;
  let totalWinOrLoseAmount = 0.0;
  if (data?.upperDetails) {
    const { upperDetails } = data;
    totalNetProfit = calculateTotalNetProfit(upperDetails.value);
    totalBetAmount = calculateTotalAmount(upperDetails.value, "betAmount");
    totalWinOrLoseAmount = calculateTotalAmount(
      upperDetails.value,
      "winOrLoseAmount"
    );
  }

  return (
    <>
      <Header />

      <Container className="mt--7" fluid>
        <Row>
          <div className="col">
            <Card className="shadow">
              <BreadcrumbCustom
                username={username}
                uri={"/portal/commission/summary/"}
              />
              <CardHeader className="border-0">
                <h2 className="mb-0">CHIPS Commision Summary</h2>
                <Filter
                  username={username ? username : currentUsername}
                  filter={setFilter}
                  sync={toggleFilter}
                  searchToggle={loading}
                  thisWeekRange
                  sessionFilter
                />
              </CardHeader>
              <CardBody>
                {loading ? <LoadingBar /> : null}

                {data?.downLineDetails && data?.downLineDetails.length ? (
                  <>
                    <h3 className="mb-3">Upline Breakdown</h3>
                    <Table responsive striped bordered>
                      <thead>
                        <tr>
                          <th>User ID</th>
                          <th>Game Type</th>
                          <th>Bet Amount</th>
                          <th>W / L Amount</th>
                          <th>Share %</th>
                          <th>Net Profit</th>
                        </tr>
                      </thead>
                      <tbody className="h1">
                        {data.upperDetails
                          ? data.upperDetails.value.map((row, index) => (
                              <tr key={index}>
                                <th scope="row">
                                  {index === 0 && data.upperDetails.name}
                                </th>

                                <td>{row.gameType}</td>
                                <td>{designNo(row.betAmount)}</td>
                                <td>{designNo(row.winOrLoseAmount)}</td>
                                <td>{row.sharePercentage}%</td>
                                <td>{designNo(row.netProfit)}</td>
                              </tr>
                            ))
                          : null}

                        <tr className="bg-secondary">
                          <th className="text-lg">Total</th>
                          <td></td>
                          <td className="text-lg">
                            {designNo(totalBetAmount)}
                          </td>
                          <td className="text-lg">
                            {designNo(totalWinOrLoseAmount)}
                          </td>
                          <td></td>
                          <td className="text-lg">
                            {designNo(totalNetProfit)}
                          </td>
                        </tr>
                      </tbody>
                    </Table>

                    <h3 className="my-3">Downlines Breakdown</h3>

                    <Table responsive striped bordered>
                      <thead>
                        <tr>
                          <th>User ID</th>
                          <th>Game Type</th>
                          <th>Bet Amount</th>
                          <th>W / L Amount</th>
                          <th>Share %</th>
                          <th>Net Profit</th>
                        </tr>
                      </thead>
                      <tbody className="h1">
                        {flatData.map((item, index) => (
                          <tr key={index}>
                            {index === 0 ||
                            flatData[index - 1].name !== item.name ? (
                              <td rowSpan={currValueCount(item.name)}>
                                {data.upperRole === "AGENT" ? (
                                  <>{item.name}</>
                                ) : (
                                  <Link
                                    className="cursor-pointer text-primary"
                                    to={"#"}
                                    onClick={(e) =>
                                      pushLocationTo(
                                        e,
                                        history,
                                        "/portal/commission/summary/" +
                                          item.name
                                      )
                                    }
                                  >
                                    {item.name}
                                  </Link>
                                )}
                              </td>
                            ) : null}

                            <td>{item.gameType}</td>
                            <td>{designNo(item.betAmount)}</td>
                            <td>{designNo(item.winOrLoseAmount)}</td>
                            <td>{item.sharePercentage}%</td>
                            <td>{designNo(item.netProfit)}</td>
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                  </>
                ) : loading ? null : (
                  <h1 className="text-center">No result available.</h1>
                )}
              </CardBody>
            </Card>
          </div>
        </Row>
      </Container>
    </>
  );
}

export default CommissionChips;
