import { useState, useEffect } from "react";
import {
  Card,
  CardHeader,
  Button,
  Row,
  Col,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Table,
  Input,
} from "reactstrap";

import DataTable from "react-data-table-component";

// Components
import LoadingBar from "components/LoadBars";
import { nofitySuccess } from "components/Toasts";

import { columns } from "./data";

import { getVideoKareraOdds } from "api/Game";
import { updateVKOddsWinningPercentage } from "api/video-karera";

function VideoKareraOddsSettings() {
  const [rows, setRows] = useState([]);
  const [sync, setSync] = useState(false);
  const [modal, setModal] = useState(false);

  const [totalPercent, setTotalPercent] = useState(0);
  const [inputs, setInputs] = useState({});

  // Table Handling
  useEffect(() => {
    getVideoKareraOdds().then((response) => {
      setRows(response);
      setSync(false);

      setTotalPercent(
        response
          .map((item) => item.winningPercentage)
          .reduce((prev, next) => prev + next)
      );
    });
  }, [sync]);

  const refreshTable = () => {
    setSync(!sync);
  };

  const toggle = () => setModal(!modal);

  const handleSubmit = (e) => {
    e.preventDefault();

    Object.keys(inputs).forEach((odds) => {
      updateVKOddsWinningPercentage(odds, inputs[odds]);
    });
    nofitySuccess("Video Karera Odds Updated");
    toggle();
    refreshTable();
  };

  const handleChange = (e) => {
    e.preventDefault();

    const key = e.target.id;
    const value = e.target.value;

    setInputs((prevState) => ({
      ...prevState,
      [key]: value,
    }));

    rows.find((item) => item.odds === +key).winningPercentage = +value;

    setTotalPercent(
      rows
        .map((item) => item.winningPercentage)
        .reduce((prev, next) => prev + next)
    );
  };

  return (
    <Card className="shadow">
      <CardHeader className="border-0">
        <Row>
          <Col>
            <h1 className="mb-0">Odds Settings</h1>
          </Col>
          <Col className="text-right">
            <Button onClick={toggle} color="primary">
              UPDATE
            </Button>
          </Col>
        </Row>
      </CardHeader>

      <DataTable
        columns={columns(refreshTable)}
        data={rows}
        pagination
        striped
        progressComponent={<LoadingBar />}
        progressPending={!rows.length}
      />
      <Modal centered isOpen={modal} toggle={toggle}>
        <ModalHeader toggle={toggle}>Game Scheme Percentage</ModalHeader>
        <ModalBody>
          <Table hover bordered>
            <thead>
              <tr>
                <th>#</th>
                <th>Odds</th>
                <th>Percentage</th>
              </tr>
            </thead>
            <tbody>
              {rows.map((item) => {
                return (
                  <tr key={item.id}>
                    <td>{item.id}</td>
                    <td>
                      <h4>
                        <b> {item.odds}</b>
                      </h4>
                    </td>
                    <td>
                      <Input
                        type="number"
                        id={item.odds}
                        name="odds"
                        defaultValue={item.winningPercentage}
                        onChange={handleChange}
                        maxLength="5"
                      />
                    </td>
                  </tr>
                );
              })}
              <tr>
                <td></td>
                <td>TOTAL %</td>
                <td>
                  <b className="text-right text-lg">
                    <span
                      className={`text-${
                        totalPercent === 100 ? "success" : "danger"
                      }`}
                    >
                      {totalPercent}
                    </span>
                  </b>
                </td>
              </tr>
            </tbody>
          </Table>
        </ModalBody>
        <ModalFooter>
          <Button
            color="primary"
            onClick={handleSubmit}
            disabled={totalPercent !== 100}
          >
            Update
          </Button>
          <Button color="secondary" onClick={toggle}>
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
    </Card>
  );
}

export default VideoKareraOddsSettings;
