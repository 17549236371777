/*eslint-disable*/
import { useRef, useEffect } from "react";
import { Route, Switch, useHistory } from "react-router-dom";

// core components
import LandingPageNavbar from "components/Navbars/AuthNavbar.js";
import LandingPageFooter from "components/Footers/LandingPageFooter";
import routes from "views/homepage/routes";
import { useSelector } from "react-redux";

const Player = (props) => {
  const mainContent = useRef(null);
  const history = useHistory();
  const role = useSelector((state) => state.entities.user.role);

  useEffect(() => {
    if (role !== "PLAYER") {
      history.push("/home");
    }

    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
    mainContent.current.scrollTop = 0;
  }, [location]);

  return (
    <>
      <div className="main-content" ref={mainContent}>
        <LandingPageNavbar />

        <div
          ref={mainContent}
          style={{
            background: "#0E121A",
            backgroundSize: "cover",
            backgroundPosition: "center top",
            minHeight: "40.1rem",
            paddingTop: "100px",
          }}
        >
          <Switch>
            {routes.map((prop, key) => {
              return (
                <Route
                  path={prop.layout + prop.path}
                  component={prop.component}
                  key={key}
                />
              );
            })}
          </Switch>
          <LandingPageFooter />
        </div>
      </div>
    </>
  );
};

export default Player;
