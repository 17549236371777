import { useEffect, useState } from "react";
import { Row, Col, Card } from "reactstrap";
import { Link } from "react-router-dom";
import { getPaymentMOP } from "api/p2p";
import { BsLock, BsCardChecklist, BsShield, BsHourglass } from "react-icons/bs";

import { getPaymentLogo } from "./utils";

const CashierDeposit = () => {
  const [data, setData] = useState([]);

  useEffect(() => {
    getPaymentMOP().then((result) => setData(result));
  }, []);

  return (
    <section className="pb-5 mx-4 mx-md-14 mx-lg-5 pt-3 mx-sm-3 mx-xs-2">
      <div className="">
        <h1 className="text-white p-2">
          <b>Deposit Channels</b>
        </h1>
      </div>
      <div>
        <Row>
          {data.map((item) => (
            <Col md={3} className="hover-gold">
              <Link to={"/player/deposit/" + item.uuid}>
                <Card className="bg-dark p-3 d-flex flex-row">
                  <div>
                    <img
                      src={getPaymentLogo(
                        item.type === "E_WALLET"
                          ? item.electronicWalletType
                          : item.bankType
                      )}
                      width={80}
                      alt="payment.."
                    />
                  </div>
                  <div className="ml-3 d-flex flex-column justify-content-center text-left text-white">
                    <strong>
                      {item.type === "E_WALLET"
                        ? item.electronicWalletType
                        : item.bankType}
                    </strong>
                    <div>
                      Per Trans: {item.minimum} - {item.maximum}
                    </div>
                  </div>
                </Card>
              </Link>
            </Col>
          ))}
        </Row>
        <div className="my-3 text-white">
          <i>
            {data.length === 0 ? (
              <h2 className="text-white">
                Deposit Channels are not available. Please contact your uplines
                for information on available payment channels.
              </h2>
            ) : (
              <>
                NOTE: All <b>P2P deposits</b> will redirect to{" "}
                <strong>CHIPS</strong> only.
              </>
            )}
          </i>
        </div>
        <Row className="text-center text-white container-fluid border-top p-3 mt-5">
          <Col className="border-right">
            <div className="h1 text-white">
              <BsLock />
            </div>
            <div>
              <strong>SAFE & SECURE</strong>
            </div>
            <div>
              <small>Online Environment</small>
            </div>
          </Col>
          <Col className="border-right">
            <div className="h1 text-white">
              <BsCardChecklist />
            </div>
            <div>
              <strong>HIGHEST CREDIT ACCEPTANCE</strong>
            </div>
            <div>
              <small>Nation Wide</small>
            </div>
          </Col>
          <Col className="border-right">
            <div className="h1 text-white">
              <BsShield />
            </div>
            <div>
              <strong>TRUSTED EXPERIENCE</strong>
            </div>
            <div>
              <small>Since 2022</small>
            </div>
          </Col>
          <Col>
            <div className="h1 text-white">
              <BsHourglass />
            </div>
            <div>
              <strong>48 HOUR PAYOUTS</strong>
            </div>
            <div>
              <small>Fastest in the industry</small>
            </div>
          </Col>
        </Row>
      </div>
    </section>
  );
};

export default CashierDeposit;
