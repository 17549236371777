// components
import HomePage from "views/homepage/index";

// pages
import AllGamesPage from "views/homepage/pages/AllGamesPage";
import CommingSoonPage from "views/homepage/pages/CommingSoonPage";
import HotGamesPage from "views/homepage/pages/HotGamesPage";
import PinoyGames from "views/homepage/pages/PinoyGames";

import DodgeGamePage from "./pages/dodge-bomb/DodgeGamePage";

//icons
import AllGames from "assets/img/homepage/buttons/all-games-btn.png";
import DodgeBomb from "assets/img/homepage/buttons/dodge-bomb-btn.png";
import HomeBtn from "assets/img/homepage/buttons/home-btn.png";
import HotGames from "assets/img/homepage/buttons/hot-games-btn.png";
import LiveCasino from "assets/img/homepage/buttons/live-casino-btn.png";
import PinoyGamesBtn from "assets/img/homepage/buttons/pinoy-game-btn.png";
import SlotGames from "assets/img/homepage/buttons/slot-games-btn.png";
//icons mobile
import AllGamesM from "assets/img/homepage/buttons/mobile-all-games.png";
import DodgeBombM from "assets/img/homepage/buttons/mobile-dodge-bomb.png";
import HomeM from "assets/img/homepage/buttons/mobile-home.png";
import HotGamesM from "assets/img/homepage/buttons/mobile-hot-games.png";
import LiveCasinoM from "assets/img/homepage/buttons/mobile-live-casino.png";
import PinoyGamesBtnM from "assets/img/homepage/buttons/mobile-pinoy-games.png";
import SlotGamesM from "assets/img/homepage/buttons/mobile-slot-games.png";
//icons mobile hover
import AllGamesMHover from "assets/img/homepage/buttons/mobile-all-games-active.png";
import DodgeBombMHover from "assets/img/homepage/buttons/mobile-dodge-bomb-active.png";
import HomeHover from "assets/img/homepage/buttons/mobile-home-active.png";
import HotGamesMHover from "assets/img/homepage/buttons/mobile-hot-games-active.png";
import LiveCasinoMHover from "assets/img/homepage/buttons/mobile-live-casino-active.png";
import PinoyGamesBtnMHover from "assets/img/homepage/buttons/mobile-pinoy-games-active.png";
import SlotGamesMHover from "assets/img/homepage/buttons/mobile-slot-games-active.png";
//butoon hover
import AllGamesHover from "assets/img/homepage/buttons/all-games-btn-active.png";
import DodgeBombHover from "assets/img/homepage/buttons/dodge-bomb-btn-active.png";
import HomeBtnHover from "assets/img/homepage/buttons/home-btn-active.png";
import HotGamesHover from "assets/img/homepage/buttons/hot-games-btn-active.png";
import LiveCasinoHover from "assets/img/homepage/buttons/live-casino-btn-active.png";
import PinoyGamesBtnHover from "assets/img/homepage/buttons/pinoy-game-btn-active.png";
import SlotGamesHover from "assets/img/homepage/buttons/slot-games-btn-active.png";
import SlotMachinePage from "./pages/slot-machine/page";

var homepageRoutes = [
  {
    path: "/home",
    component: HomePage,
    name: "Home",
    mobileName: "Home",
    layout: "/pas",
    icon: HomeBtn,
    iconHover: HomeBtnHover,
    iconMobile: HomeHover,
    iconMobileHover: HomeM,
  },
  {
    path: "/dodge-bomb",
    name: "dodge bomb",
    mobileName: "dodge",
    component: DodgeGamePage,
    layout: "/pas",
    icon: DodgeBomb,
    iconHover: DodgeBombHover,
    iconMobile: DodgeBombM,
    iconMobileHover: DodgeBombMHover,
  },
  {
    path: "/slot-games",
    name: "slot games",
    mobileName: "slot",
    component: SlotMachinePage,
    layout: "/pas",
    icon: SlotGames,
    iconHover: SlotGamesHover,
    iconMobile: SlotGamesM,
    iconMobileHover: SlotGamesMHover,
  },
  {
    path: "/all-games",
    component: AllGamesPage,
    name: "all games",
    mobileName: "all",
    layout: "/pas",
    icon: AllGames,
    iconHover: AllGamesHover,
    iconMobile: AllGamesM,
    iconMobileHover: AllGamesMHover,
  },
  {
    path: "/hot-games",
    component: HotGamesPage,
    name: "hot games",
    mobileName: "hot",
    layout: "/pas",
    hot: true,
    icon: HotGames,
    iconHover: HotGamesHover,
    iconMobile: HotGamesM,
    iconMobileHover: HotGamesMHover,
  },
  {
    path: "/pinoy-games",
    component: PinoyGames,
    name: "pinoy games",
    mobileName: "pinoy",
    layout: "/pas",
    icon: PinoyGamesBtn,
    iconHover: PinoyGamesBtnHover,
    iconMobile: PinoyGamesBtnM,
    iconMobileHover: PinoyGamesBtnMHover,
  },
  {
    path: "/live-casino",
    name: "live casino",
    mobileName: "casino",
    layout: "/pas",
    component: CommingSoonPage,
    icon: LiveCasino,
    iconHover: LiveCasinoHover,
    iconMobile: LiveCasinoM,
    iconMobileHover: LiveCasinoMHover,
  },
];
export default homepageRoutes;
