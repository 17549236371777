export const columns = () => [
  {
    name: "Scheme",
    sortable: true,
    selector: (row) => row.name,
  },
  {
    name: "Percentage",
    sortable: true,
    selector: (row) => row.value,
    cell: (row) => row.value + " %",
  },
];
