import { useState, useEffect } from "react";
import {
  Card,
  CardHeader,
  Row,
  Container,
  Breadcrumb,
  BreadcrumbItem,
  Col,
  Button,
} from "reactstrap";

import { useSelector } from "react-redux";

import DataTable from "react-data-table-component";
import DataTableExtensions from "react-data-table-component-extensions";

import { columns } from "./data";

// Components
import LoadingBar from "components/LoadBars";
import Header from "components/Headers/Header.js";
import RestrictionCreate from "./create";

import { getPermissions } from "api";

function AccountRestriction() {
  const [data, setData] = useState([]);
  const [sync, setSync] = useState(true);

  const JWT = useSelector((state) => state.auth.jwt);

  const tableData = {
    columns,
    data,
  };

  useEffect(() => {
    getPermissions(JWT).then((response) => setData(response));
    // eslint-disable-next-line
  }, []);

  const handleSync = () => {
    setSync(!sync);
  };

  return (
    <>
      <Header />

      <Container className="mt--7" fluid>
        <Row>
          <div className="col">
            <Card className="shadow">
              <Breadcrumb listClassName="breadcrumb-links">
                <BreadcrumbItem>Settings</BreadcrumbItem>
                <BreadcrumbItem>Permission</BreadcrumbItem>
              </Breadcrumb>
              <CardHeader className="border-0">
                <Row>
                  <Col lg={6} sm={12}>
                    <h3 className="mb-0">Account Restrictions</h3>
                  </Col>
                  <Col lg={6} sm={12} className="text-lg-right mt-3 mt-lg-0">
                    <Button
                      className="btn-icon btn-2"
                      color="default"
                      type="button"
                      outline
                      onClick={handleSync}
                    >
                      <span className="btn-inner--icon">
                        <i className="fa fa-refresh"></i>
                      </span>
                    </Button>

                    <RestrictionCreate />
                  </Col>
                </Row>
              </CardHeader>

              <DataTableExtensions export={false} print={false} {...tableData}>
                <DataTable
                  columns={columns}
                  data={data}
                  pagination
                  striped
                  progressComponent={<LoadingBar />}
                  progressPending={!data.length}
                />
              </DataTableExtensions>
            </Card>
          </div>
        </Row>
      </Container>
    </>
  );
}

export default AccountRestriction;
