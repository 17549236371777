import fetchClient from "api/axios";

// Authorization
import store from "store";

const getUsername = () => {
  return store.getState().entities.user.username;
};

export const postAutoCreditDeposit = async (amount) => {
  let payload = {
    amount: amount,
    origin: "AUTOMATIC",
    creditType: "CHIPS",
    transactionType: "DEPOSIT",
    username: getUsername(),
  };

  const response = await fetchClient.post(`/credit`, payload);
  return response.status === 200 ? response.data : { status: 500 };
};

export const postAutoCreditWithdraw = async (body) => {
  let payload = {
    origin: "AUTOMATIC",
    creditType: "CHIPS",
    transactionType: "WITHDRAW",
    ...body,
  };

  const response = await fetchClient.post(
    `/credit?withdrawalPin=` + body.pin,
    payload
  );
  return response.status === 200 ? response.data : { status: 500 };
};
