import { useState } from "react";

// React strap tools
import {
  Input,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  FormGroup,
  Form,
  FormText,
  FormFeedback,
  Label,
  Spinner,
} from "reactstrap";

// alert prompt
import Swal from "sweetalert2";

// Redux
import { useSelector } from "react-redux";

import PasswordStrengthBar from "react-password-strength-bar";

import { createSubAccounts } from "api/AccountAPI";

export const CreateSubAccount = (props) => {
  const UID = useSelector((state) => state.auth.uuid);

  // Add Handling
  const [name, setName] = useState("");
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");

  const [isModal, setIsModal] = useState(false);
  const [showHide, setShowHide] = useState("password");
  const [isPasswordMatched, setIsPasswordMatched] = useState(true);
  const [isLoadingState, setIsLoadingState] = useState(false);
  const [eye, seteye] = useState(true);

  const handleSubmit = (e) => {
    e.preventDefault();
    setIsLoadingState(true);

    if (password !== confirmPassword) {
      setIsPasswordMatched(false);
      setIsLoadingState(false);
    } else {
      setIsPasswordMatched(true);

      const payload = {
        name: name,
        username: username,
        password: password,
        accountType: "CHILD",
        uuidParent: UID,
      };

      createSubAccounts(payload).then((response) => {
        if (response.statusCode === "OK") {
          Swal.fire({
            title: "Account Registered!",
            icon: "success",
          });
          handleRefresh();
          toggleModal();
        } else {
          Swal.fire({
            title: "Failed!",
            text: response.message,
            icon: "error",
          });
        }
        setIsLoadingState(false);
      });
    }
  };

  const toggleModal = () => {
    setIsModal(!isModal);
  };

  const handleCloseModal = () => {
    setIsModal(false);
  };

  const handleRefresh = () => {
    props.refresh();
  };

  const handleEye = () => {
    if (showHide === "password") {
      setShowHide("text");
      seteye(false);
    } else {
      setShowHide("password");
      seteye(true);
    }
  };

  return (
    <>
      <Button
        className="btn-icon btn-3"
        color="primary"
        outline
        type="button"
        onClick={toggleModal}
      >
        <span className="btn-inner--icon">
          <i className="fa fa-plus"></i>
        </span>
        <span className="btn-inner--text">Create</span>
      </Button>
      <Modal
        className="modal-dialog-centered"
        size="md"
        isOpen={isModal}
        toggle={handleCloseModal}
      >
        <ModalHeader toggle={handleCloseModal}>
          Create a new 'Sub Account'
        </ModalHeader>
        <ModalBody>
          <Form role="form" onSubmit={handleSubmit}>
            <FormGroup>
              <Label for="name">Name</Label>
              <Input
                placeholder="Name"
                type="text"
                minLength={5}
                value={name}
                required
                onChange={(e) => setName(e.target.value)}
              />
            </FormGroup>

            <FormGroup>
              <Label for="username">Username</Label>
              <Input
                placeholder="Username"
                type="text"
                minLength={5}
                value={username}
                required
                onChange={(e) => setUsername(e.target.value)}
              />
            </FormGroup>

            <FormGroup>
              <Label for="password">Password</Label>
              <Input
                invalid={!isPasswordMatched}
                placeholder="Your password here..."
                value={password}
                autoComplete="new-password"
                minLength={6}
                required
                type={showHide}
                onChange={(e) => setPassword(e.target.value)}
              />
              <FormFeedback>Oh noes! password did not matched</FormFeedback>
              <FormText onClick={handleEye} className="pointer">
                <div className="font-italic mt-2">
                  <i className={`fa ${eye ? "fa-eye-slash" : "fa-eye"}`} />
                  <span className="ml-2">{eye ? "Show" : "Hide"} password</span>
                </div>
              </FormText>
            </FormGroup>
            <FormGroup>
              <Label for="confirm">Confirm password</Label>
              <Input
                invalid={!isPasswordMatched}
                placeholder="Confirm here..."
                type={showHide}
                required
                onChange={(e) => setConfirmPassword(e.target.value)}
              />
            </FormGroup>

            <div className="text-muted font-italic">
              <small>
                password strength:
                <PasswordStrengthBar minLength="5" password={password} />
              </small>
            </div>
            <div className="text-center">
              <Button className="mt-2" color="primary" type="submit">
                {isLoadingState ? (
                  <Spinner>Loading...</Spinner>
                ) : (
                  <>Create account</>
                )}
              </Button>
            </div>
          </Form>
        </ModalBody>
      </Modal>
    </>
  );
};

export default CreateSubAccount;
