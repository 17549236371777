import React from "react";
import "react-responsive-carousel/lib/styles/carousel.min.css";

import AllGames from "./components/AllGames";
import HotGames from "./components/HotGames";
import CommingSoon from "./components/CommingSoon";

import Banners from "views/homepage/components/Banners";

const HomePage = () => {
  return (
    <section>
      <Banners />
      <div className="pb-5 text-center mx-4 mx-md-14 mx-lg-5 pt-3 mx-sm-3 mx-xs-2">
        <HotGames />
        <AllGames />
        <CommingSoon />
      </div>
    </section>
  );
};

export default HomePage;
