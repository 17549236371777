import axios from "axios";
import fetchClient from "api/axios";
import store from "store";

const PROXY = process.env.REACT_APP_API_KEY;

const getSessionID = () => {
  return store.getState().auth.uuid;
};

const JWTHeader = (JWT) => {
  return { headers: { Authorization: "Bearer " + JWT } };
};

export const APIFindTransactions = async (UUID, JWT) => {
  const response = await axios.get(
    `${PROXY}/transactions/upper/${UUID}`,
    JWTHeader(JWT)
  );

  return response.status === 200 ? response.data : [];
};

export const getTransactions = async (filter, creditType) => {
  const response = await fetchClient.post(`/transactions`, {
    filter: filter,
    creditType: creditType,
  });

  return response.status === 200 ? response.data : [];
};

export const getTransactionById = async (transactionId) => {
  const response = await fetchClient.get(`/transactions/` + transactionId);
  return response.status === 200 ? response.data : [];
};

export const getAllWithdrawTransactions = async () => {
  const response = await fetchClient.get(
    PROXY +
      "/transactions/upper/" +
      getSessionID() +
      "?transactionType=WITHDRAW"
  );

  return response.status === 200 ? response.data : [];
};

export const getLast20Transactions = async () => {
  const response = await fetchClient.get(
    `/transactions/last-20/${getSessionID()}`
  );
  return response.status === 200 ? response.data : [];
};
