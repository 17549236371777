export const commas = (number) => {
  if (number) {
    return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }

  return number;
};

export const formatCurrency = (number) => {
  return commas(Number(number).toFixed(2));
};

export const formatWithTwoDecimal = (number) => {
  return (Math.round(number * 100) / 100).toFixed(2);
};

export const isNumeric = (str) => {
  if (typeof str !== "string") return false;
  return !isNaN(str) && !isNaN(parseFloat(str));
};

export const designNo = (number) => {
  return (
    <span className={`text-${number < 0 ? "danger" : "success"}`}>
      {formatCurrency(number)}
    </span>
  );
};

export const negative = (number) => {
  return number < 0;
};

export const convertDateWebFormat = (str) => {
  var date = new Date(str),
    mnth = ("0" + (date.getMonth() + 1)).slice(-2),
    day = ("0" + date.getDate()).slice(-2);
  return [mnth, day, date.getFullYear()].join("-");
};

export const convertDateAPIFormat = (str) => {
  var date = new Date(str),
    mnth = ("0" + (date.getMonth() + 1)).slice(-2),
    day = ("0" + date.getDate()).slice(-2);
  return [date.getFullYear(), mnth, day].join("-");
};

export const formatPhoneNumber = (value) => {
  // if input value is falsy eg if the user deletes the input, then just return
  if (!value) return value;

  // clean the input for any non-digit values.
  const phoneNumber = value.replace(/[^\d]/g, "");

  // phoneNumberLength is used to know when to apply our formatting for the phone number
  const phoneNumberLength = phoneNumber.length;

  // we need to return the value with no formatting if its less then four digits
  // this is to avoid weird behavior that occurs if you  format the area code to early
  if (phoneNumberLength < 4) return phoneNumber;

  // if phoneNumberLength is greater than 4 and less the 7 we start to return
  // the formatted number
  // if (phoneNumberLength < 7) {
  //   return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(3)}`;
  // }

  // finally, if the phoneNumberLength is greater then seven, we add the last
  // bit of formatting and return it.
  return `${phoneNumber.slice(0, 4)}-${phoneNumber.slice(
    4,
    7
  )}-${phoneNumber.slice(7, 11)}`;
};

export const pushLocationTo = (e, history, location) => {
  e.preventDefault();

  history.push(location);
  window.location.reload(false);
};

export const filterBuilder = (filter) => {
  let URL = "";
  if (filter && filter.dateTimeFrom) {
    URL = `?dateTimeFrom=${filter.dateTimeFrom}&dateTimeTo=${filter.dateTimeTo}`;

    if (filter.username) {
      URL = URL + `&username=${filter.username}`;
    }
    if (filter.creditType) {
      URL = URL + `&creditType=${filter.creditType}`;
    }
  }

  if (filter && filter.username && !filter.dateTimeFrom) {
    URL = URL + `?username=${filter.username}`;
  }

  return URL;
};

export const downlineRole = (upLineRole) => {
  switch (upLineRole) {
    case "ADMIN":
      return "SITE_OWNER";
    case "SITE_OWNER":
      return "FINANCIER";
    case "FINANCIER":
      return "MASTER";
    case "MASTER":
      return "AGENT";
    case "AGENT":
      return "PLAYER";
    default:
  }
};
