import React from "react";

import CommingSoon from "../components/CommingSoon";
import Banners from "views/homepage/components/Banners";

const CommingSoonPage = () => {
  return (
    <div>
      <Banners />
      <div className="pb-5 text-center mx-4 mx-md-14 mx-lg-5 pt-3 mx-sm-3 mx-xs-2">
        <CommingSoon />
      </div>
    </div>
  );
};

export default CommingSoonPage;
