/*eslint-disable*/
import { PropTypes } from "prop-types";
import { useEffect, useRef, useState } from "react";
import { Link, NavLink as NavLinkRRD, useHistory } from "react-router-dom";

// reactstrap components
import {
  Col,
  Collapse,
  Container,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Form,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Media,
  Nav,
  NavItem,
  NavLink,
  Navbar,
  NavbarBrand,
  Row,
  UncontrolledDropdown,
} from "reactstrap";

import { loggedOut } from "actions";
import SearchModal from "components/Modals/search";
import { useDispatch, useSelector } from "react-redux";

const Sidebar = (props) => {
  const childRef = useRef();

  const viewPermissions = useSelector((state) => state.ui.permissions.view);
  const [collapseStates, setCollapseStates] = useState({});

  const sidebar = useRef();
  // this creates the intial state of this component based on the collapse routes
  // that it gets through props.routes
  const getCollapseStates = (routes) => {
    let initialState = {};
    routes.map((prop, key) => {
      if (prop.collapse) {
        initialState = {
          [prop.state]: getCollapseInitialState(prop.views),
          ...getCollapseStates(prop.views),
          ...initialState,
        };
      }
      return null;
    });
    return initialState;
  };
  // this verifies if any of the collapses should be default opened on a rerender of this component
  // for example, on the refresh of the page,
  // while on the src/views/forms/RegularForms.js - route /admin/regular-forms
  const getCollapseInitialState = (routes) => {
    for (let i = 0; i < routes.length; i++) {
      if (routes[i].collapse && getCollapseInitialState(routes[i].views)) {
        return true;
      } else if (window.location.pathname.indexOf(routes[i].path) !== -1) {
        return true;
      }
    }
    return false;
  };

  const [collapseOpen, setCollapseOpen] = useState();
  const role = useSelector((state) => state.entities.user?.role);
  const name = useSelector((state) => state.entities.user?.name);
  const [searchName, setSearchName] = useState();

  // this function creates the links and collapses that appear in the sidebar (left menu)
  const createLinks = (routes) => {
    return routes.map((prop, key) => {
      if (prop.invisible) return null;

      if (prop.code && viewPermissions?.includes(prop.code)) {
        return null;
      }

      if (prop.role && prop.role[0] !== "ALL") {
        if (!prop.role.includes(role)) {
          return null;
        }
      }

      if (prop.redirect) {
        return null;
      }

      if (prop.collapse) {
        var st = {};
        st[prop["state"]] = !collapseStates[prop.state];

        return (
          <span
            className={getCollapseInitialState(prop.views) ? "active" : ""}
            key={key}
          >
            <NavLink
              href="#pablo"
              data-toggle="collapse"
              aria-expanded={collapseStates[prop.state]}
              onClick={(e) => {
                e.preventDefault();
                setCollapseStates(st);
              }}
            >
              {prop.icon !== undefined ? (
                <>
                  <i className={prop.icon} />
                  <span className="sidebar-normal"> {prop.name}</span>
                  <b className="caret" />
                </>
              ) : (
                <>
                  <span className="sidebar-mini-icon">{prop.mini}</span>
                  <span className="sidebar-normal">
                    {prop.name}
                    <b className="caret" />
                  </span>
                </>
              )}
            </NavLink>
            <Collapse
              isOpen={collapseStates[prop.state]}
              style={{
                visibility: "visible",
              }}
            >
              {createLinks(prop.views)}
            </Collapse>
          </span>
        );
      }
      return (
        <NavItem key={key}>
          <NavLink
            to={prop.layout + prop.path}
            tag={NavLinkRRD}
            onClick={closeCollapse}
            active={activeRoute(prop.layout + prop.path)}
          >
            {prop.icon !== undefined ? (
              <>
                <i className={prop.icon} />
                <span ref={sidebar} className="sidebar-normal">
                  {prop.name}
                </span>
              </>
            ) : (
              <>
                <span className="sidebar-mini-icon">{prop.mini}</span>
                <span className="sidebar-normal">{prop.name}</span>
              </>
            )}
          </NavLink>
        </NavItem>
      );
    });
  };

  // verifies if routeName is the one active (in browser input)
  const activeRoute = (routeName) => {
    return props.location.pathname.indexOf(routeName) > -1;
  };
  // React.useEffect(() => {
  //   // if you are using a Windows Machine, the scrollbars will have a Mac look
  //   if (navigator.platform.indexOf("Win") > -1) {
  //     ps = new PerfectScrollbar(sidebar.current, {
  //       suppressScrollX: true,
  //       suppressScrollY: false,
  //     });
  //   }
  //   return function cleanup() {
  //     // we need to destroy the false scrollbar when we navigate
  //     // to a page that doesn't have this component rendered
  //     if (navigator.platform.indexOf("Win") > -1) {
  //       ps.destroy();
  //     }
  //   };
  // });
  useEffect(() => {
    setCollapseStates(getCollapseStates(props.routes));
  }, []);

  // toggles collapse between opened and closed (true/false)
  const toggleCollapse = () => {
    setCollapseOpen((data) => !data);
  };
  // closes the collapse
  const closeCollapse = () => {
    setCollapseOpen(false);
  };

  let nodeId = 1;

  const { bgColor, routes, logo } = props;
  let navbarBrandProps;
  if (logo && logo.innerLink) {
    navbarBrandProps = {
      to: logo.innerLink,
      tag: Link,
    };
  } else if (logo && logo.outterLink) {
    navbarBrandProps = {
      href: logo.outterLink,
      target: "_blank",
    };
  }

  const history = useHistory();
  const dispatch = useDispatch();

  const handleLogout = () => {
    dispatch(loggedOut());
    history.push("/home");
  };

  useEffect(() => {
    const fetchAPI = () => {};
    fetchAPI();
  }, []);

  const handleSearch = (e) => {
    e.preventDefault();
    childRef.current.searchName(searchName);
  };

  return (
    <Navbar
      className="navbar-vertical fixed-left navbar-light bg-white w-top-bottom"
      expand="md"
      id="sidenav-main"
    >
      <Container fluid>
        {/* Toggler */}

        <button
          className="navbar-toggler"
          type="button"
          onClick={toggleCollapse}
        >
          <span className="navbar-toggler-icon" />
        </button>

        {/* Brand */}
        {logo && (
          <NavbarBrand className="pt-0" {...navbarBrandProps}>
            <img
              style={{
                width: "70%",
                maxHeight: "10%",
              }}
              alt={logo.imgAlt}
              className="mx-auto mt-2 navbar-brand-img d-none d-md-block"
              src={logo.imgSrc}
            />
          </NavbarBrand>
        )}
        {/* User */}
        <Nav className="align-items-center d-md-none">
          <UncontrolledDropdown nav>
            <DropdownToggle nav>
              <Media className="align-items-center">
                <span className="avatar avatar-sm rounded-circle">
                  <img alt="..." src={logo.imgSrc} />
                </span>
              </Media>
            </DropdownToggle>
            <DropdownMenu className="dropdown-menu-arrow" right>
              <DropdownItem className="noti-title" header tag="div">
                <h6 className="text-overflow m-0">Welcome! {name}</h6>
              </DropdownItem>

              <DropdownItem to="/portal/settings/changepassword" tag={Link}>
                <i className="ni ni-single-02" />
                <span>Change Password</span>
              </DropdownItem>

              {!viewPermissions.includes("VIEW_USER_INFORMATION") && (
                <DropdownItem to="/portal/settings/editprofile" tag={Link}>
                  <i className="ni ni-settings-gear-65" />
                  <span>Settings</span>
                </DropdownItem>
              )}

              <DropdownItem divider />
              <DropdownItem onClick={handleLogout}>
                <i className="ni ni-user-run" />
                <span>Logout</span>
              </DropdownItem>
            </DropdownMenu>
          </UncontrolledDropdown>
        </Nav>

        {/* Collapse */}
        <Collapse
          navbar
          isOpen={collapseOpen}
          style={{
            visibility: "visible",
          }}
        >
          {/* Collapse header */}
          <div className="navbar-collapse-header d-md-none">
            <Row>
              {logo ? (
                <Col className="collapse-brand" xs="6">
                  {logo.innerLink ? (
                    <Link to={logo.innerLink}>
                      <img alt={logo.imgAlt} src={logo.imgSrc} />
                    </Link>
                  ) : (
                    <a href={logo.outterLink}>
                      <img alt={logo.imgAlt} src={logo.imgSrc} />
                    </a>
                  )}
                </Col>
              ) : null}
              <Col className="collapse-close" xs="6">
                <button
                  className="navbar-toggler"
                  type="button"
                  onClick={toggleCollapse}
                >
                  <span />
                  <span />
                </button>
              </Col>
            </Row>
          </div>
          {/* Form */}
          <SearchModal ref={childRef} />
          <Form onSubmit={handleSearch} className="mt-4 mb-3 d-md-none">
            <InputGroup className="input-group-rounded input-group-merge">
              <Input
                aria-label="Search"
                className="form-control-rounded form-control-prepended"
                placeholder="Search by 'username'"
                type="search"
                onChange={(e) => setSearchName(e.target.value)}
              />
              <InputGroupAddon addonType="prepend">
                <InputGroupText>
                  <span className="fa fa-search" />
                </InputGroupText>
              </InputGroupAddon>
            </InputGroup>
          </Form>

          {/* Navigation */}
          <Nav navbar>{createLinks(routes)}</Nav>
        </Collapse>
      </Container>
    </Navbar>
  );
};

Sidebar.defaultProps = {
  routes: [{}],
};

Sidebar.propTypes = {
  // links that will be displayed inside the component
  routes: PropTypes.arrayOf(PropTypes.object),
  logo: PropTypes.shape({
    // innerLink is for links that will direct the user within the app
    // it will be rendered as <Link to="...">...</Link> tag
    innerLink: PropTypes.string,
    // outterLink is for links that will direct the user outside the app
    // it will be rendered as simple <a href="...">...</a> tag
    outterLink: PropTypes.string,
    // the image src of the logo
    imgSrc: PropTypes.string.isRequired,
    // the alt for the img
    imgAlt: PropTypes.string.isRequired,
  }),
};

export default Sidebar;
