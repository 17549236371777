export const walletTypes = [
  {
    label: "ALL",
    value: "ALL",
  },
  {
    label: "E-Wallet",
    value: "E_WALLET",
  },
  {
    label: "Bank",
    value: "BANK",
  },
];

export const bankTypes = [
  {
    label: "AUB",
    value: "AUB",
  },
  {
    label: "BDO",
    value: "BDO",
  },
  {
    label: "BPI",
    value: "BPI",
  },
  {
    label: "CHINA BANK",
    value: "CHINA_BANK",
  },
  {
    label: "EAST WEST BANKING CORP.",
    value: "EAST_WEST_BANKING_CORPORATION",
  },
  {
    label: "LAND BANK",
    value: "LAND_BANK",
  },
  {
    label: "METROBANK",
    value: "METROBANK",
  },
  {
    label: "PNB",
    value: "PNB",
  },
  {
    label: "PS BANK",
    value: "PS_BANK",
  },
  {
    label: "RCBC",
    value: "RCBC",
  },
  {
    label: "SECURITY BANK",
    value: "SECURITY_BANK",
  },
  {
    label: "UNION BANK",
    value: "UNION_BANK",
  },
];

export const electronicWalletTypes = [
  {
    label: "GCASH",
    value: "GCASH",
  },
  {
    label: "COINS.PH",
    value: "COINS_PH",
  },
  {
    label: "KOMO/EAST WEST RURAL BANK",
    value: "KOMO_EAST_WEST_RURAL_BANK",
  },
  {
    label: "MAYA",
    value: "MAYA",
  },
];
