import React from "react";
import { useLocation, Route, Switch, useHistory } from "react-router-dom";

import background from "assets/img/homepage/background.png";
// reactstrap components
import { Container, Row } from "reactstrap";

// core components
import AuthNavbar from "components/Navbars/AuthNavbar.js";
import AuthFooter from "components/Footers/AuthFooter.js";
import { useSelector } from "react-redux";

import Register from "views/auth/Register";
import Logout from "views/auth/Logout";

const Auth = () => {
  const mainContent = React.useRef(null);
  const history = useHistory();
  const location = useLocation();
  const role = useSelector((state) => state.entities.user.role);
  const isLoggedIn =
    useSelector((state) => state.ui.loginOutcome) === "LOGGED_IN";

  React.useEffect(() => {
    if (isLoggedIn) {
      if (role !== "PLAYER") {
        history.push("/portal/index");
      }
    }

    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
    mainContent.current.scrollTop = 0;
    // eslint-disable-next-line
  }, [location]);

  return (
    <>
      <div
        className="main-content"
        ref={mainContent}
        style={{
          backgroundImage: "url(" + background + ")",
          backgroundSize: "cover",
          backgroundPosition: "center top",
        }}
      >
        <AuthNavbar />
        <div className="py-7 py-lg-7"></div>
        <Container className="mt--5 pb-5">
          <Row className="justify-content-center">
            <Switch>
              {/* {getRoutes(routes)} */}
              {/* <Route path="/auth/login">
                <Login />
              </Route> */}
              <Route path="/auth/register/:referral?">
                <Register />
              </Route>
              <Route path="/auth/logout">
                <Logout />
              </Route>

              {/* <Redirect from="*" to="/home" /> */}
            </Switch>
          </Row>
        </Container>
        <AuthFooter />
      </div>
    </>
  );
};

export default Auth;
