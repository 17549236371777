import { useState } from "react";

// React strap tools
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  Table,
  Input,
} from "reactstrap";

// alert prompt
// import NotificationAlert from "react-notification-alert";
import { nofitySuccess } from "components/Toasts";
import { patchPermission } from "api/AccountAPI";

export const PermissionForm = (props) => {
  const [open, setOpen] = useState(false);

  // Add Handling
  const handleSubmit = (e, permissionId, toggle, name) => {
    patchPermission(permissionId, e.target.checked, toggle).then((response) => {
      if (response.statusCode === "OK") {
        console.log(name);
      }
    });
  };

  const handleCloseModal = () => {
    setOpen(false);
  };

  return (
    <>
      <Button
        className="btn-icon btn-2 w-100"
        color="primary"
        type="button"
        onClick={() => setOpen(true)}
        outline
        size="sm"
      >
        <span className="btn-inner--icon">
          <i className="ni ni-settings-gear-65" />
        </span>
        <span className="btn-inner--text">PERMISSION</span>
      </Button>
      <Modal
        className="modal-dialog-centered"
        isOpen={open}
        toggle={handleCloseModal}
        size="lg"
      >
        <ModalHeader toggle={handleCloseModal}>
          {props.row.username} - permissions
        </ModalHeader>
        <ModalBody>
          <Table striped>
            <thead>
              <tr>
                <th>#</th>
                <th>Function</th>
                <th>View</th>
                <th>Create</th>
                <th>Edit</th>
              </tr>
            </thead>
            <tbody>
              {props.row.permissionSettings.map((permission, index) => {
                return (
                  <tr key={index}>
                    <th>{index + 1}</th>
                    <td>{permission.name}</td>
                    <td>
                      {permission.view != null && (
                        <Input
                          className="ml-1 pointer"
                          name={"view" + index}
                          type="checkbox"
                          onChange={(e) =>
                            handleSubmit(
                              e,
                              permission.permissionId,
                              "VIEW",
                              permission.name,
                              nofitySuccess(
                                `${permission.name} Function Applied`
                              )
                            )
                          }
                          defaultChecked={permission.view}
                        />
                      )}
                    </td>
                    <td>
                      {permission.create != null && (
                        <Input
                          className="ml-1 pointer"
                          name={"edit" + index}
                          type="checkbox"
                          defaultChecked={permission.create}
                          onChange={(e) =>
                            handleSubmit(
                              e,
                              permission.permissionId,
                              "CREATE",
                              permission.name,
                              nofitySuccess(
                                `${permission.name} Function Applied`
                              )
                            )
                          }
                        />
                      )}
                    </td>
                    <td>
                      {permission.edit != null && (
                        <Input
                          className="ml-1 pointer"
                          name={"edit" + index}
                          type="checkbox"
                          onChange={(e) =>
                            handleSubmit(
                              e,
                              permission.permissionId,
                              "EDIT",
                              permission.name,
                              nofitySuccess(
                                `${permission.name} Function Applied`
                              )
                            )
                          }
                          defaultChecked={permission.edit}
                        />
                      )}
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </Table>
        </ModalBody>
      </Modal>
    </>
  );
};

export default PermissionForm;
