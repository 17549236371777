import fetchClient from "api/axios";
import { filterBuilder } from "views/shared/utils";

// Authorization
import store from "store";

const getSessionID = () => {
  return store.getState().auth.uuid;
};

export const pendingCredit = async (creditType) => {
  const response = await fetchClient.get(
    `/credit/pending/upper?uuid=${getSessionID()}&transactionType=${creditType}`
  );
  return response.status === 200 ? response.data : [];
};

export const authorizeCredit = async (transactionId, decision, reason) => {
  const response = await fetchClient.put(
    `/credit/${transactionId}?decision=${decision}&reason=${reason}`
  );

  return response.status === 200 ? response.data : null;
};

export const postCredit = async (data) => {
  const response = await fetchClient.post("/credit", data);
  return response.status === 200 ? response.data[0] : null;
};

export const directCredit = async (data) => {
  return await fetchClient.post("/credit/direct", data);
};

export const getCreditHistory = async (filter) => {
  const response = await fetchClient.get(
    `/credit/history` + filterBuilder(filter)
  );
  return response.status === 200 ? response.data : [];
};

// Mode of Payment
export const getAllModeOfPayments = async () => {
  const response = await fetchClient.get(`/mode-of-payment/${getSessionID()}`);
  return response.status === 200 ? response.data : [];
};

// balance
export const putBalanceExchange = async (credit, amount) => {
  const response = await fetchClient.put(
    `/balance/exchange/?credit=${credit}&amount=${amount}`
  );
  return response.status === 200 ? response.data : [];
};
