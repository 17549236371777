import { commas } from "views/shared/utils";
import { Badge } from "reactstrap";

import chipsImage from "assets/img/chips-icon.png";
import coinsImage from "assets/img/coins-icon.png";

const getGameType = (game) => {
  if (game === "VIDEO_KARERA") {
    return (
      <span>
        <i className="fas fa-horse-head mr-3"></i>
        <span>Video Karera</span>
      </span>
    );
  } else if (game === "DODGE_BOMB") {
    return (
      <span>
        <i className="fa fa-bomb mr-3"></i>
        <span>Dodge Bomb</span>
      </span>
    );
  } else if (game === "JILI_SLOT") {
    return (
      <span>
        <i className="fas fa-sliders mr-3"></i>
        <span>Slot Machine (JILI)</span>
      </span>
    );
  }
};

export const columns = [
  {
    name: "Bet History Date",
    selector: (row) => row.timeStamp,
    sortable: true,
    wrap: true,
  },
  {
    name: "Game Type",
    selector: (row) => row.gameType,
    sortable: true,
    cell: (row) => getGameType(row.gameType),
  },
  {
    name: "Game #",
    selector: (row) => row.gameNumber,
    sortable: true,
    cell: (row) => (
      <h4>
        <span className="ml-2">
          <b>{row.gameNumber}</b>
        </span>
      </h4>
    ),
  },
  {
    name: "Amount",
    selector: (row) => commas(commas(row.amount)),
    sortable: true,
  },
  {
    name: "Position",
    selector: (row) => row.position,
    sortable: true,
  },
  {
    name: "Odds",
    selector: (row) => row.odds,
    sortable: true,
  },
  {
    name: "Credit Type",
    selector: (row) => row.credit,
    sortable: true,
    cell: (row) => (
      <>
        <img
          src={row.credit === "CHIPS" ? chipsImage : coinsImage}
          alt=""
          width={20}
        />
        <span className="ml-2">{row.credit}</span>
      </>
    ),
  },
  {
    name: "WIN",
    selector: (row) => row.win,
    sortable: true,
    cell: (row) => (
      <Badge color={row.win ? "success" : "danger"}>
        {row.win ? "WIN" : "LOSE"}
      </Badge>
    ),
  },
];
