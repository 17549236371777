import { useState } from "react";

// React strap tools
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Input,
} from "reactstrap";

// API calls
import { nofitySuccess, notifyDanger } from "components/Toasts";

export const InputModal = (props) => {
  const [open, setOpen] = useState(false);
  const [value, setValue] = useState(props.defaultValue);

  const toggleModal = () => setOpen(!open);

  const handleSubmit = (e) => {
    e.preventDefault();
    props
      .api(props.id, value)
      .then((response) => {
        if (response) {
          toggleModal();
          handleRefresh();
          nofitySuccess(`${props.name} profile is updated`);
        }
      })
      .catch((response) => {
        notifyDanger(`Failed to ` + response.response.data.message);
      });
  };

  const handleRefresh = () => {
    if (props.sync) {
      props.sync();
    }
  };

  return (
    <>
      <Button
        className="btn-icon btn-2 w-100"
        color="primary"
        type="button"
        onClick={() => setOpen(true)}
        size="sm"
        outline
      >
        <span className="btn-inner--icon">
          <i className="ni ni-ruler-pencil mr-1" /> {props.buttonLabel}
        </span>
      </Button>
      <Modal
        className={`modal-dialog-centered modal-${props.color}`}
        contentClassName={`bg-gradient-${props.color}`}
        isOpen={open}
        toggle={toggleModal}
      >
        <ModalHeader toggle={toggleModal}></ModalHeader>
        <ModalBody className="mx-3">
          <div className="py-3 text-center">
            <i className="ni ni-ruler-pencil ni-3x" />
            <p className="h2 text-white mt-3 mb-3">{props.title}</p>
            <Input
              className="text-center"
              type="text"
              placeholder="Your value here..."
              value={value}
              onChange={(e) => setValue(e.target.value)}
            />
          </div>
        </ModalBody>
        <ModalFooter>
          <Button
            className="text-white ml-auto"
            color="link"
            data-dismiss="modal"
            type="button"
            onClick={toggleModal}
          >
            Close
          </Button>
          <Button
            onClick={handleSubmit}
            className="btn-white"
            color="default"
            type="button"
          >
            Update
          </Button>
        </ModalFooter>
      </Modal>
    </>
  );
};

export default InputModal;
