import { useEffect, useState } from "react";

import {
  Card,
  CardHeader,
  Row,
  Container,
  Col,
  Breadcrumb,
  BreadcrumbItem,
  Button,
} from "reactstrap";

import DataTable from "react-data-table-component";
import DataTableExtensions from "react-data-table-component-extensions";

// Core components
import Header from "components/Headers/Header.js";
import { getDownlineAccounts } from "api/AccountAPI";

// Spinners
import LoadingBar from "components/LoadBars";

// Modal Forms
import DeleteForm from "components/Modals/delete";

import { columns } from "./data";

const InActiveAccounts = () => {
  // States
  const [data, setData] = useState([]);
  const [selectedData, setSelectedData] = useState([]);
  const [sync, setSync] = useState(false);
  const [loading, setLoading] = useState(true);
  const [clearSelect, setClearSelect] = useState(false);

  const handleChange = (state) => {
    setSelectedData(
      state.selectedRows.map((entry) => {
        return { name: entry.username, value: entry.uuid };
      })
    );
  };

  const handleSync = () => {
    setSync(true);
    setClearSelect(true);
    setLoading(true);
  };

  useEffect(() => {
    getDownlineAccounts("INACTIVE").then((response) => {
      setData(response);
      setSync(false);
      setClearSelect(false);
      setLoading(false);
    });
    // eslint-disable-next-line
  }, [sync]);

  return (
    <>
      <Header />
      <Container className="mt--7" fluid>
        <Card className="shadow">
          <Breadcrumb listClassName="breadcrumb-links">
            <BreadcrumbItem>Downlines</BreadcrumbItem>
            <BreadcrumbItem>InActive Accounts</BreadcrumbItem>
          </Breadcrumb>
          <CardHeader className="border-0">
            <Row>
              <Col lg={6} sm={12}>
                <span className="mb-0 h2">Accounts Management</span>
              </Col>
              <Col lg={6} sm={12} className="text-lg-right mt-3 mt-lg-0">
                <Button
                  className="btn-icon btn-2"
                  color="default"
                  type="button"
                  outline
                  onClick={handleSync}
                >
                  <span className="btn-inner--icon">
                    <i className="fa fa-refresh"></i>
                  </span>
                </Button>
                <DeleteForm data={selectedData} sync={handleSync} />
              </Col>
            </Row>
          </CardHeader>
          <DataTableExtensions
            export={false}
            print={false}
            columns={columns(handleSync)}
            data={data}
          >
            <DataTable
              title={"Total Inactive (" + data.length + ")"}
              columns={columns(handleSync)}
              data={data}
              defaultSortAsc={true}
              onSelectedRowsChange={handleChange}
              pagination
              highlightOnHover
              striped
              selectableRows
              progressComponent={<LoadingBar />}
              progressPending={loading}
              clearSelectedRows={clearSelect}
            />
          </DataTableExtensions>
        </Card>
      </Container>
    </>
  );
};

export default InActiveAccounts;
