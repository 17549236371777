import React, { useState, useEffect } from "react";
import {
  Button,
  Modal,
  FormGroup,
  InputGroup,
  Input,
  ModalHeader,
  ModalBody,
  Form,
  Label,
  FormText,
  InputGroupAddon,
  InputGroupText,
} from "reactstrap";

import { postCredit } from "api/Credit";
import { getActiveAcounts, getPlayersOfMaster } from "api/AccountAPI";

import { isNumeric } from "views/shared/utils";
import { useSelector } from "react-redux";

import Select from "react-select";

import { nofitySuccess, notifyDanger } from "components/Toasts";

import chipsImage from "assets/img/chips-icon.png";
import coinsImage from "assets/img/coins-icon.png";

const CreateCreditModal = (props) => {
  const [isFormOpened, setIsFormOpened] = useState(false);
  const [dropdownDropdown, setDownlineDropdown] = useState([]);

  const [downline, setDownlines] = useState();
  const [player, setPlayer] = useState();
  const [creditType, setCreditType] = useState();
  const [amount, setAmount] = useState();
  const [remarks, setRemarks] = useState();

  const [downlinePresent, setDownlinePresent] = useState(true);
  const [playerPresent, setPlayerPresent] = useState(true);
  const [creditTypePresent, setCreditTypePresent] = useState(true);
  const [amountPresent, setAmountPresent] = useState(true);

  const role = useSelector((state) => state.entities.user.role);
  const [playersDropdown, setPlayersDropdown] = useState([]);
  const isRoleMaster = role === "MASTER";
  const [isForPlayer, setIsForPlayer] = useState(false);

  const togglePlayerDropdown = () => {
    setIsForPlayer(!isForPlayer);
  };

  const refreshTable = () => {
    props.sync();
  };

  const closeForm = () => {
    setIsFormOpened(false);
  };

  const requestCredit = (e) => {
    e.preventDefault();

    if (isForPlayer) {
      if (!player) {
        setPlayerPresent(false);
      }
    } else {
      if (!downline) {
        setDownlinePresent(false);
      }
    }
    if (!creditType) {
      setCreditTypePresent(false);
    }
    if (!amount) {
      setAmountPresent(false);
    }
    if (amount && !isNumeric(amount)) {
      setAmountPresent(false);
    }

    if ((isForPlayer ? player : downline) && creditType && amount) {
      postCredit({
        username: isForPlayer ? player.value : downline.value,
        creditType: creditType.value,
        transactionType: props.data,
        amount: amount,
        remarks: remarks,
      }).then((response) => {
        if (response.statusCode === "OK") {
          nofitySuccess(
            `Credit for ${downline.value} with amount ${amount} save`
          );
          refreshTable();
        } else {
          notifyDanger(response.message);
        }
      });
      closeForm();
    }
  };

  useEffect(() => {
    if (isFormOpened) {
      getActiveAcounts().then((response) => {
        setDownlineDropdown(
          response
            .sort((a, b) => a.username.localeCompare(b.username))
            .map((index) => {
              return { label: index.username, value: index.username };
            })
        );
      });

      if (isRoleMaster) {
        getPlayersOfMaster().then((response) => {
          setPlayersDropdown(
            response.sort((a, b) => a.value.localeCompare(b.value))
          );
        });
      }
    }

    // eslint-disable-next-line
  }, [isFormOpened]);

  return (
    <Button
      className="btn-icon btn-3 mt-3 ml-0 mt-md-0"
      color="primary"
      type="button"
      onClick={() => setIsFormOpened(true)}
      outline
    >
      <span className="btn-inner--icon">
        <i className="fa fa-plus"></i>
      </span>
      <span className="btn-inner--text">Create</span>

      <Modal className="modal-dialog-centered" isOpen={isFormOpened}>
        <ModalHeader toggle={closeForm}>Create a '{props.data}'</ModalHeader>
        <ModalBody>
          <Form role="form" onSubmit={requestCredit}>
            {isForPlayer && isRoleMaster ? (
              <FormGroup>
                <Label>Downline Players</Label>
                <Select
                  invalid
                  className="react-select info"
                  classNamePrefix="react-select"
                  placeholder="Choose Account Here"
                  closeMenuOnSelect={true}
                  value={player}
                  onChange={(value) => {
                    setPlayer(value);
                    setPlayerPresent(true);
                  }}
                  options={playersDropdown}
                />
                {playerPresent ? null : (
                  <FormText>
                    <span className="text-danger">
                      Please select your downline player
                    </span>
                  </FormText>
                )}
              </FormGroup>
            ) : (
              <FormGroup>
                <Label>Downlines</Label>
                <Select
                  invalid
                  className="react-select info"
                  classNamePrefix="react-select"
                  placeholder="Choose Account Here"
                  closeMenuOnSelect={true}
                  value={downline}
                  onChange={(value) => {
                    setDownlines(value);
                    setDownlinePresent(true);
                  }}
                  options={dropdownDropdown}
                />
                {downlinePresent ? null : (
                  <FormText>
                    <span className="text-danger">
                      Please select your downline
                    </span>
                  </FormText>
                )}
              </FormGroup>
            )}
            {isRoleMaster ? (
              <FormGroup row>
                <Label className="custom-toggle ml-3">
                  <input onChange={togglePlayerDropdown} type="checkbox" />
                  <span
                    className="custom-toggle-slider rounded-circle"
                    data-label-off="No"
                    data-label-on="Yes"
                  />
                </Label>
                <FormText>
                  <span className="text-mute ml-2">Switch to "players"</span>
                </FormText>
              </FormGroup>
            ) : null}

            <FormGroup className="mb-3">
              <Label>Credit Type</Label>

              <Select
                value={creditType}
                required
                onChange={(value) => {
                  setCreditType(value);
                  setCreditTypePresent(true);
                }}
                options={[
                  {
                    value: "COINS",
                    label: (
                      <>
                        <img alt="" src={coinsImage} width={22} />
                        <b className="ml-2">Coins</b>
                      </>
                    ),
                  },
                  {
                    value: "CHIPS",
                    label: (
                      <>
                        <img alt="" src={chipsImage} width={20} />
                        <b className="ml-2">Chips</b>
                      </>
                    ),
                  },
                ]}
              />
              {creditTypePresent ? null : (
                <FormText>
                  <span className="text-danger">Please select credit type</span>
                </FormText>
              )}
            </FormGroup>

            <FormGroup>
              <Label>Amount</Label>
              <InputGroup className="input-group-alternative">
                <InputGroupAddon addonType="prepend">
                  <InputGroupText>
                    <i className="ni ni-money-coins" />
                  </InputGroupText>
                </InputGroupAddon>
                <Input
                  placeholder="Credit Amount"
                  type="number"
                  autoComplete="off"
                  minLength={1}
                  required
                  onChange={(e) => {
                    setAmount(e.target.value);
                    setAmountPresent(true);
                  }}
                />
              </InputGroup>
              {amountPresent ? null : (
                <FormText>
                  <span className="text-danger">Please put a valid amount</span>
                </FormText>
              )}
            </FormGroup>
            <FormGroup>
              <Label>Remarks</Label>
              <Input
                placeholder="(Optional)"
                type="text"
                autoComplete="off"
                onChange={(e) => setRemarks(e.target.value)}
              />
            </FormGroup>
            <FormGroup className="text-right">
              <Button
                color="secondary"
                data-dismiss="modal"
                type="button"
                onClick={closeForm}
              >
                Close
              </Button>
              <Button
                className="mutted"
                color="primary"
                type="button"
                onClick={requestCredit}
              >
                Submit
              </Button>
            </FormGroup>
          </Form>
        </ModalBody>
      </Modal>
    </Button>
  );
};

export default CreateCreditModal;
