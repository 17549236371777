import axios from "axios";
// alert prompt
import Swal from "sweetalert2";

import fetchClient from "api/axios";

import { filterBuilder } from "views/shared/utils";

const PROXY = process.env.REACT_APP_API_KEY;

const JWTHeader = (JWT) => {
  return { headers: { Authorization: "Bearer " + JWT } };
};

export const APIFindAccountsUnderUUID = async (UUID, JWT, setData) => {
  await axios
    .get(PROXY + "/accounts/upper/" + UUID + "?status=ACTIVE", JWTHeader(JWT))
    .then(function (response) {
      getResponseDataWhenSuccess(response, setData);
    });
};

export const APIFindAccountsUnderUUIDPending = async (UUID, JWT, setData) => {
  await axios
    .get(PROXY + "/accounts/upper/" + UUID + "?status=PENDING", JWTHeader(JWT))
    .then(function (response) {
      getResponseDataWhenSuccess(response, setData);
    });
};

export const APIFindAccountsUnderUUIDInactive = async (UUID, JWT, setData) => {
  await axios
    .get(PROXY + "/accounts/upper/" + UUID + "?status=INACTIVE", JWTHeader(JWT))
    .then(function (response) {
      getResponseDataWhenSuccess(response, setData);
    });
};

export const APIFindAccountByUsername = async (name, JWT, UID) => {
  const response = await axios.get(
    `${PROXY}/accounts?uuid=${UID}&username=${name}`,
    JWTHeader(JWT)
  );
  return response.status === 200 ? response.data[0] : null;
};

export const APIPendingCredit = async (UID, creditType, JWT) => {
  const response = await axios.get(
    `${PROXY}/credit/pending/upper?uuid=${UID}&transactionType=${creditType}`,
    JWTHeader(JWT)
  );
  return response.status === 200 ? response.data : [];
};

export const APISearchAsync = async (username) => {
  const response = await fetchClient.get(
    `${PROXY}/accounts/search?username=${username}`
  );
  return response.status === 200 ? response.data : false;
};

export const APICreditRequest = async (data, JWT) => {
  const response = await axios.post(PROXY + "/credit", data, JWTHeader(JWT));

  return response.status === 200 ? response.data[0] : null;
};

export const APICreditAddRequest = async (data, JWT) => {
  const response = await axios.post(
    PROXY + "/credit/add",
    data,
    JWTHeader(JWT)
  );

  return response.status === 200 ? response.data[0] : null;
};

export const APIApproveCredit = async (transactionId, JWT) => {
  await axios
    .put(
      PROXY + "/credit?decision=APPROVE&transactionUID=" + transactionId,
      JWTHeader(JWT)
    )
    .then((response) => {
      if (response.status === 200) {
        Swal.fire({
          position: "top-end",
          icon: "success",
          title: "Success! Credit processed.",
          showConfirmButton: false,
          timer: 1500,
        });
      }
    });
};

export const APIApprovePending = async (UUID, JWT) => {
  await axios
    .patch(PROXY + "/accounts/" + UUID + "?status=ACTIVE", JWTHeader(JWT))
    .then((response) => {
      if (response.status === 200) {
        Swal.fire({
          position: "top-end",
          icon: "success",
          title: "Success! Account Activated!.",
          showConfirmButton: false,
          timer: 1500,
        });
      }
    });
};

export const APIFindAllWithdraw = async (JWT, setData) => {
  await axios
    .get(
      PROXY + "/transactions?status=PENDING&transactionType=WITHDRAW",
      JWTHeader(JWT)
    )
    .then(function (response) {
      getResponseDataWhenSuccess(response, setData);
    });
};

export const APIFindAllCoins = async (UUID, JWT) => {
  const response = await axios.get(
    PROXY + "/transactions/upper/" + UUID + "?creditType=COINS",
    JWTHeader(JWT)
  );

  return response.status === 200 ? response.data : [];
};

export const APIFindTransactions = async (UUID, creditType, JWT) => {
  const response = await axios.get(
    `${PROXY}/transactions/upper/${UUID}?creditType=${creditType}`,
    JWTHeader(JWT)
  );

  return response.status === 200 ? response.data : [];
};

export const APIFindAllChips = async (UUID, JWT, setData) => {
  await axios
    .get(
      PROXY +
        "/transactions/upper/" +
        UUID +
        "?creditType=CHIPS&transactionType=DEPOSIT",
      JWTHeader(JWT)
    )
    .then(function (response) {
      getResponseDataWhenSuccess(response, setData);
    });
};

export const APIUserLoginHistory = async (JWT, setData) => {
  await axios
    .get(PROXY + "/user/find-all", JWTHeader(JWT))
    .then(function (response) {
      getResponseDataWhenSuccess(response, setData);
    });
};

export const APIFindAdminAllCoins = async (UUID, JWT, setData) => {
  await axios
    .get(PROXY + "/transactions/" + UUID + "?creditType=COINS", JWTHeader(JWT))
    .then(function (response) {
      getResponseDataWhenSuccess(response, setData);
    });
};

export const APIFindAdminAllChips = async (UUID, JWT, setData) => {
  await axios
    .get(
      PROXY + "/transactions/upper/" + UUID + "?creditType=CHIPS",
      JWTHeader(JWT)
    )
    .then(function (response) {
      getResponseDataWhenSuccess(response, setData);
    });
};

export const APIFindAllActiveAccounts = async (JWT, setData) => {
  await axios
    .get(PROXY + "/players?status=ACTIVE", JWTHeader(JWT))
    .then(function (response) {
      getResponseDataWhenSuccess(response, setData);
    });
};

export const APIFindAllInActiveAccounts = async (JWT, setData) => {
  await axios
    .get(PROXY + "/players?status=INACTIVE", JWTHeader(JWT))
    .then(function (response) {
      getResponseDataWhenSuccess(response, setData);
    });
};

export const APIFindAllSiteOwners = async (JWT, setData) => {
  await axios
    .get(PROXY + "/site-owners", JWTHeader(JWT))
    .then(function (response) {
      getResponseDataWhenSuccess(response, setData);
    });
};

export const APIFindAdminMasters = async (JWT, setData) => {
  await axios.get(PROXY + "/masters", JWTHeader(JWT)).then(function (response) {
    if (response.status === 200) {
      setData(response.data);
    }
  });
};

export const APIFindAdminAgents = async (JWT, setData) => {
  await axios.get(PROXY + "/agents", JWTHeader(JWT)).then(function (response) {
    if (response.status === 200) {
      setData(response.data);
    }
  });
};

export const APIAuthorizeCredit = async (UUID, decision, JWT) => {
  const response = await axios.put(
    `${PROXY}/credit/${UUID}?decision=${decision}`,
    JWTHeader(JWT)
  );

  return response.status === 200 ? response.data : null;
};

export const getCommision = async (UUID, JWT) => {
  const response = await axios.get(
    `${PROXY}/commissions/${UUID}`,
    JWTHeader(JWT)
  );

  return response.status === 200 ? response.data : null;
};

export const getPermissions = async (JWT) => {
  const response = await axios.get(`${PROXY}/permissions`, JWTHeader(JWT));
  return response.status === 200 ? response.data : null;
};

const getResponseDataWhenSuccess = (response, setData) => {
  return response.status === 200 ? setData(response.data) : setData([]);
};

// V2
export const getStatements = async (filter) => {
  const response = await fetchClient.get(`/statements` + filterBuilder(filter));
  return response.status === 200 ? response.data : null;
};
