import { useState } from "react";
import { useHistory } from "react-router-dom";

import {
  Button,
  Card,
  Col,
  FormFeedback,
  FormGroup,
  Input,
  Row,
} from "reactstrap";

import { postAutoCreditDeposit } from "api/credit/auto";
import chipsLogo from "assets/img/chips-icon.png";
import LoadingBar from "components/LoadBars";
import Swal from "sweetalert2";
import { isNumeric } from "views/shared/utils";
import { alertDialogTheme } from "../../components/swal-dialogs";

const CashierAutoDepositPage = () => {
  const history = useHistory();

  const creditInfo = {
    type: "E_WALLET",
    minimum: 50,
    maximum: 50000,
  };

  const [totalAmount, setTotalAmount] = useState();
  const [isAmountValid, setIsAmountValid] = useState(true);
  const [isAmountCap, setIsAmountCap] = useState(false);
  const [inProcess, setInProcess] = useState(false);

  const depositAmounts = [50, 100, 150, 200, 500, 1000, 2000];

  const checkIfNumericOrInCap = (amount) => {
    if (!amount) {
      return;
    }

    if (isNumeric(amount)) {
      setIsAmountValid(true);
    } else {
      setIsAmountValid(false);
    }

    if (isAmountInCap(amount)) {
      setIsAmountCap(false);
    } else {
      setIsAmountCap(true);
    }

    setTotalAmount(amount);
  };

  const isAmountInCap = (amount) => {
    return amount >= creditInfo.minimum && amount <= creditInfo.maximum;
  };

  const changeAmount = (amount) => {
    setTotalAmount(amount);
    setIsAmountValid(true);
  };

  const handleDeposit = (e) => {
    e.preventDefault();

    setInProcess(true);
    setTimeout(() => {
      postAutoCreditDeposit(totalAmount).then((result) => {
        const resultBody = result[0].body;

        if (result[0]?.statusCode === "FOUND") {
          setInProcess(false);
          alertDialogTheme(
            "You can only request one deposit at a time. You can check your transaction history to view the status of your previous deposit request.",
            "Close",
            "warning"
          );
          return;
        }

        if (resultBody?.status === 0) {
          setInProcess(false);
          alertDialogTheme(
            "Our payment provider is currently unavailable. Please try again later.",
            "Close",
            "warning"
          );
          return;
        }

        Swal.fire({
          title:
            "Deposit requested! Please pay the amount to the provided QR code.",
          icon: "success",
          color: "#fff",
          background:
            "radial-gradient(79.09% 79.09% at 50% 14.18%, #4e4d4c 0%, #060606 100%)",
          showCancelButton: true,
          confirmButtonText: "GOTO PAYMENT",
          cancelButtonText: "Close",
          confirmButtonColor: "rgb(157 134 0)",
          cancelButtonColor: "#d33",
          preConfirm: () => {
            window.open(resultBody.qrcode_url, "_blank");
          },
        });

        history.push("/player/my-account");
      });
    }, 1000);
  };

  return (
    <section className="pb-5 pt-3 text-center container mx-auto text-white">
      <div className="">
        <h1 className="text-white pt-4 text-center">
          <b>
            {creditInfo.type === "E_WALLET"
              ? creditInfo.electronicWalletType
              : creditInfo.bankType}
          </b>
        </h1>
      </div>

      {inProcess ? (
        <div className="border rounded-md my-5 py-3">
          <h1 className="text-white">Processing data, please wait...</h1>
          <LoadingBar color="dark" />
          <h1 className="text-white">
            Contacting Payment Provider. <br /> Please wait...
          </h1>
        </div>
      ) : (
        <div>
          <h2 className="text-white">Select Deposit Amount</h2>

          <Row className="">
            {depositAmounts
              .filter((item) => isAmountInCap(item))
              .map((item) => {
                const isSelected = totalAmount === item;
                return (
                  <Col md={3} className="my-2 pointer hover-gold">
                    <Card
                      className={`bg-dark rounded-md d-flex flex-row align-items-center justify-content-center p-2 ${
                        isSelected && "border border-gold"
                      }`}
                      onClick={() => changeAmount(item)}
                    >
                      <img
                        alt="..."
                        width={40}
                        src={chipsLogo}
                        className="mr-2"
                      />
                      <div className="text-white h2 mb-0">{item}</div>
                    </Card>
                  </Col>
                );
              })}
          </Row>
          <FormGroup>
            <Input
              type="text"
              className="bg-dark rounded-md mt-2 text-white text-xl text-center"
              value={totalAmount}
              onChange={(e) => checkIfNumericOrInCap(e.target.value)}
              invalid={!isAmountValid || isAmountCap}
            />
            {!isAmountValid && (
              <FormFeedback>Oh noes! invalid amount</FormFeedback>
            )}
            {isAmountCap && (
              <FormFeedback>
                Please put between amount {creditInfo.minimum} -{" "}
                {creditInfo.maximum}
              </FormFeedback>
            )}
          </FormGroup>

          <div>
            Deposit Limits: Min.{" "}
            <strong className="text-lg">{creditInfo.minimum}</strong> Max.{" "}
            <strong className="text-lg">{creditInfo.maximum}</strong>
          </div>

          <Button
            disabled={!isAmountValid || !totalAmount}
            block
            type="submit"
            color="primary"
            className="my-3"
            onClick={handleDeposit}
          >
            Deposit Now
          </Button>
        </div>
      )}

      <div className="text-white mt-3">
        All Bonus offers are optional, valid only at the time of deposit and
        subject to Terms and Conditions. Bonus offers cannot be claimed after
        deposit.
      </div>
    </section>
  );
};

export default CashierAutoDepositPage;
