const initialState = {
  isLoggedIn: "START",
  loginOutcome: "VALID",
  permissions: {
    create: [],
    edit: [],
    view: [],
  },
  selectedUser: null,
  isRefresh: false,
  autoRefresh: false,
  depositCreditCount: 0,
  withdrawalPin: "",
  filter: {
    dateTimeFrom: "",
    dateTimeTo: "",
    username: "",
  },
  game: {
    dodgeBombUrl: "",
    jiliURL: "",
  },
};

const UIReducer = (state = initialState, action) => {
  switch (action.type) {
    case "GAME":
      return {
        ...state,
        game: {
          ...action.payload,
        },
      };
    case "LOGIN_START":
      return {
        ...state,
        isLoggedIn: "START",
      };
    case "SIGNIN":
      return {
        ...state,
        isLoggedIn: "OK",
        loginOutcome: "LOGGED_IN",
        userType: action.payload,
      };
    case "SIGNOUT":
      return {
        ...state,

        isLoggedIn: "LOGGED_OUT",
        loginOutcome: "LOGGED_OUT",
        filter: {
          dateTimeFrom: "",
          dateTimeTo: "",
          username: "",
        },
        userType: "",
      };
    case "ERROR":
      return {
        ...state,
        isLoggedIn: "NOK",
        loginOutcome: action.payload,
      };
    case "SELECT_USER":
      return {
        ...state,
        selectedUser: action.payload,
      };
    case "REFRESH":
      return {
        ...state,
        isRefresh: true,
      };
    case "DEPOSIT_CREDIT_COUNT":
      return {
        ...state,
        depositCreditCount: action.payload,
      };
    case "PERMISSIONS":
      return {
        ...state,
        permissions: action.payload,
      };
    case "FILTER":
      return {
        ...state,
        filter: action.payload,
      };
    case "AUTO_REFRESH":
      return {
        ...state,
        autoRefresh: action.payload,
      };
    default:
      return state;
  }
};

export default UIReducer;
