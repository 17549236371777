/*eslint-disable*/
import { useState } from "react";
import { NavLink as NavLinkRRD } from "react-router-dom";

// nodejs library to set properties for components
import { PropTypes } from "prop-types";

// reactstrap components
import { Navbar, NavItem, NavLink, Nav, Container } from "reactstrap";
import { useMediaQuery } from "react-responsive";

import downloadAppHere from "assets/img/homepage/download-app-here.png";

const Sidebar = (props) => {
  const { routes } = props;

  const isMobile = useMediaQuery({
    query: "(max-width: 640px)",
  });

  // this creates the intial state of this component based on the collapse routes
  // that it gets through props.routes
  const getCollapseStates = (routes) => {
    let initialState = {};
    routes.map((prop) => {
      if (prop.collapse) {
        initialState = {
          [prop.state]: getCollapseInitialState(prop.views),
          ...getCollapseStates(prop.views),
        };
      }
      return null;
    });
    return initialState;
  };
  // this verifies if any of the collapses should be default opened on a rerender of this component
  // for example, on the refresh of the page,
  // while on the src/views/forms/RegularForms.js - route /admin/regular-forms
  const getCollapseInitialState = (routes) => {
    for (let i = 0; i < routes.length; i++) {
      if (routes[i].collapse && getCollapseInitialState(routes[i].views)) {
        return true;
      } else if (window.location.pathname.indexOf(routes[i].path) !== -1) {
        return true;
      }
    }
    return false;
  };

  const [collapseOpen, setCollapseOpen] = useState();

  // this function creates the links and collapses that appear in the sidebar (left menu)
  const createLinks = (routes) => {
    return routes.map((prop, key) => {
      if (prop.invisible) return null;

      const inActiveImage = isMobile ? prop.iconMobile : prop.icon;
      const activeImage = isMobile ? prop.iconMobileHover : prop.iconHover;

      const isOnCurrentPath = window.location.pathname === prop.path;

      return (
        <NavItem key={key} className="">
          <NavLink
            to={prop.path}
            tag={NavLinkRRD}
            onClick={closeCollapse}
            active={activeRoute(prop.path)}
            className="nav-link-home"
          >
            <img
              alt=""
              src={isOnCurrentPath ? activeImage : inActiveImage}
              className="w-100 object-contain"
              loading="lazy"
            />
          </NavLink>
        </NavItem>
      );
    });
  };

  // verifies if routeName is the one active (in browser input)
  const activeRoute = (routeName) => {
    return props.location.pathname.indexOf(routeName) > -1;
  };

  // closes the collapse
  const closeCollapse = () => {
    setCollapseOpen(false);
  };

  return (
    <Navbar
      className="navbar-vertical navbar-vertical-home fixed-left navbar-light bg-theme-delux w-top-bottom scroll-bar-theme"
      expand="md"
      id="sidenav-main"
    >
      <Container fluid className="container-home">
        <Nav navbar className="mx-0 mt-1">
          {createLinks(routes)}
        </Nav>

        <div className="pt-5">
          <a
            href={process.env.REACT_APP_APP_URL}
            className="mt-auto d-none d-sm-block px-4"
          >
            <img alt="..." src={downloadAppHere} className="w-100" />
          </a>
        </div>
      </Container>
    </Navbar>
  );
};

Sidebar.defaultProps = {
  routes: [{}],
};

Sidebar.propTypes = {
  // links that will be displayed inside the component
  routes: PropTypes.arrayOf(PropTypes.object),
  logo: PropTypes.shape({
    // innerLink is for links that will direct the user within the app
    // it will be rendered as <Link to="...">...</Link> tag
    innerLink: PropTypes.string,
    // outterLink is for links that will direct the user outside the app
    // it will be rendered as simple <a href="...">...</a> tag
    outterLink: PropTypes.string,
    // the image src of the logo
    imgSrc: PropTypes.string.isRequired,
    // the alt for the img
    imgAlt: PropTypes.string.isRequired,
  }),
};

export default Sidebar;
