const Notification = () => {
  // useEffect(() => {
  //   const eventSource = new EventSource(process.env.REACT_APP_API_KEY + "/sse");
  //   eventSource.onmessage = (event) => {
  //     console.log(event.data); // log the event payload to the console
  //     // display the event payload as a notification using the UI of your choice
  //   };
  // }, []);

  return <></>;
};

export default Notification;
