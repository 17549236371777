export const columns = [
  {
    name: "ID",
    selector: (row) => row.id,
    sortable: true,
  },
  {
    name: "Date",
    selector: (row) => row.timeStamp,
    sortable: true,
  },

  {
    name: "Username",
    selector: (row) => row.username,
    sortable: true,
    cell: (row) => <b>{row.username}</b>,
  },
  {
    name: "IP Address",
    selector: (row) => row.ipAddress,
    sortable: true,
  },
];
