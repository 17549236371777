import { useState, useEffect } from "react";

import { Row, Col, Card, CardBody, Input, FormGroup, Label } from "reactstrap";
import { getPaymentLogo } from "../utils";

const SavedAccountOptions = ({ onChange, playerOptions, gcashOnly }) => {
  const [selectedSavedMOP, setSelectedSavedMOP] = useState({
    uuid: null,
    playerMOPOptions: [],
  });

  useEffect(() => {
    onChange(selectedSavedMOP.uuid);
  }, [selectedSavedMOP, onChange]);

  return (
    <>
      <FormGroup>
        <Label>Choose from saved E-Wallets</Label>
        <Row>
          {playerOptions
            .filter((item) => item.type === "E_WALLET")
            .map((item, i) => {
              const isSelected = selectedSavedMOP === item;

              return (
                <Col md={6} className="px-2 py-1 hover-gold" key={i}>
                  <Card
                    className={`card-frame bg-dark rounded-md w-100 pointer ${
                      isSelected && "border border-gold"
                    }`}
                    onClick={() => setSelectedSavedMOP(item)}
                  >
                    <CardBody className="mx-2 py-2">
                      <Row>
                        <Col className="col-auto">
                          <Input
                            type="radio"
                            checked={selectedSavedMOP === item}
                          />
                        </Col>
                        <Col className="col-auto pl-0 pr-2 d-flex align-items-center">
                          <img
                            alt="payment-logos"
                            height={40}
                            className="w-16 h-auto"
                            src={getPaymentLogo(
                              item.type === "BANK"
                                ? item.bankType
                                : item.electronicWalletType
                            )}
                          />
                        </Col>
                        <div className="col ml--2">
                          <h4
                            className="mb-0 text-white"
                            style={{ fontSize: "90%" }}
                          >
                            {item.type === "BANK"
                              ? item.bankType
                              : item.electronicWalletType}
                          </h4>
                          <p className="text-sm text-muted mb-0">
                            {item.accountName}
                          </p>
                          <span className="text-success mr-1">#</span>
                          <small>{item.accountNumber}</small>
                        </div>
                        {/* <Col className="col-auto">
                        <Button color="danger" size="sm" type="button">
                          Delete
                        </Button>
                      </Col>  */}
                      </Row>
                    </CardBody>
                  </Card>
                </Col>
              );
            })}
        </Row>
      </FormGroup>
      <FormGroup>
        <Label>Choose from saved Banks</Label>
        <Row>
          {playerOptions
            .filter((item) => item.type === "BANK")
            .map((item, i) => {
              const isSelected = selectedSavedMOP === item;

              return (
                <Col md={6} className="px-2 py-1 hover-gold" key={i}>
                  <Card
                    className={`card-frame bg-dark rounded-md w-100 pointer ${
                      isSelected && "border border-gold"
                    }`}
                    onClick={() => setSelectedSavedMOP(item)}
                  >
                    <CardBody className="mx-2 py-2">
                      <Row>
                        <Col className="col-auto">
                          <Input
                            type="radio"
                            checked={selectedSavedMOP === item}
                          />
                        </Col>
                        <Col className="col-auto pl-0 pr-2 d-flex align-items-center">
                          <img
                            alt="payment-logo"
                            height={40}
                            src={getPaymentLogo(
                              item.type === "BANK"
                                ? item.bankType
                                : item.electronicWalletType
                            )}
                          />
                        </Col>
                        <div className="col ml--2">
                          <h4
                            className="mb-0 text-white"
                            style={{ fontSize: "90%" }}
                          >
                            {item.type === "BANK"
                              ? item.bankType
                              : item.electronicWalletType}
                          </h4>
                          <p className="text-sm text-muted mb-0">
                            {item.accountName}
                          </p>
                          <span className="text-success mr-1">#</span>
                          <small>{item.accountNumber}</small>
                        </div>
                        {/* <Col className="col-auto">
                        <Button color="danger" size="sm" type="button">
                          Delete
                        </Button>
                      </Col>  */}
                      </Row>
                    </CardBody>
                  </Card>
                </Col>
              );
            })}
        </Row>
      </FormGroup>
    </>
  );
};

export default SavedAccountOptions;
