import { useState, useEffect } from "react";
import {
  Card,
  Row,
  Col,
  Button,
  CardBody,
  Collapse,
  Input,
  FormGroup,
  Label,
  Form,
  Spinner,
  CardHeader,
  Badge,
} from "reactstrap";

import { DefinedRange, createStaticRanges, DateRange } from "react-date-range";
import { useMediaQuery } from "react-responsive";
import Select from "react-select";

import moment from "moment";

import { convertDateWebFormat, convertDateAPIFormat } from "views/shared/utils";

// Images
import chipsImage from "assets/img/chips-icon.png";
import coinsImage from "assets/img/coins-icon.png";

import { setFilter } from "actions";
import { useSelector, useDispatch } from "react-redux";

const Filter = (props) => {
  const isLaptopSize = useMediaQuery({
    query: "(min-width: 1582px)",
  });
  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 1224px)" });

  // Redux Items
  const dispatch = useDispatch();
  const currentFilter = useSelector((state) => state.ui.filter);

  const [ranges, setRanges] = useState([
    {
      startDate: moment().startOf("day").toDate(),
      endDate: moment().startOf("day").toDate(),
      key: "rollup",
    },
  ]);

  const staticRanges = createStaticRanges([
    {
      label: "Today",
      range: () => ({
        label: "Today",
        startDate: moment().startOf("day").toDate(),
        endDate: moment().startOf("day").toDate(),
      }),
    },
    {
      label: "Last Week",
      range: () => ({
        label: "Last Week",
        startDate: moment().subtract(1, "week").isoWeekday(1).toDate(),
        endDate: moment()
          .subtract(1, "week")
          .endOf("week")
          .add(1, "day")
          .toDate(),
      }),
    },
    {
      label: "This Week",
      range: () => ({
        label: "This Week",
        startDate: moment().isoWeekday(1).toDate(),
        endDate: moment().endOf("week").add(1, "day").toDate(),
      }),
    },
    {
      label: "Last Month",
      range: () => ({
        label: "Last Month",
        startDate: moment().subtract(1, "month").startOf("month").toDate(),
        endDate: moment().subtract(1, "month").endOf("month").toDate(),
      }),
    },
    {
      label: "This Month",
      range: () => ({
        label: "This Month",
        startDate: moment().startOf("month").toDate(),
        endDate: moment().endOf("month").toDate(),
      }),
    },
    {
      label: "This Year",
      range: () => ({
        label: "This Year",
        startDate: moment().startOf("year").toDate(),
        endDate: moment().endOf("day").toDate(),
      }),
    },
    {
      label: "Last Year",
      range: () => ({
        label: "Last Year",
        startDate: moment().subtract(1, "years").startOf("year").toDate(),
        endDate: moment().subtract(1, "years").endOf("year").toDate(),
      }),
    },
  ]);

  const [dateFrom, setDateFrom] = useState(
    convertDateWebFormat(moment().startOf("day").toDate())
  );
  const [timeFrom, setTimeFrom] = useState("00:00");
  const [dateTo, setDateTo] = useState(
    convertDateWebFormat(moment().endOf("day").toDate())
  );
  const [timeTo, setTimeTo] = useState("23:59");

  let defaultUsername = props.username ? props.username : "";
  const [username, setUsername] = useState(defaultUsername);

  const [executedBy, setExecutedBy] = useState(props.executedByDefault);
  const [creditType, setCreditType] = useState();

  const [isFilterOpened, setIsFilterOpened] = useState(false);
  const [isDateRangeOpen, setIsDateRangeOpen] = useState(false);
  const [filterLabel, setFilterLabel] = useState();

  const toggleFilter = () => {
    setIsFilterOpened(!isFilterOpened);
  };

  const toggleDateRange = () => {
    setIsDateRangeOpen(!isDateRangeOpen);
  };

  const handleDateRange = (range) => {
    setRanges([range.rollup]);

    setFilterLabel(
      convertDateWebFormat(range.rollup.startDate).toLocaleString("en-US") +
        " - " +
        convertDateWebFormat(range.rollup.endDate).toLocaleString("en-US")
    );

    setDateFrom(convertDateWebFormat(range.rollup.startDate));
    setDateTo(convertDateWebFormat(range.rollup.endDate));
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const data = {
      dateTimeFrom: `${convertDateAPIFormat(dateFrom)}T${timeFrom}:00`,
      dateTimeTo: `${convertDateAPIFormat(dateTo)}T${timeTo}:00`,
      username: username,
      executedBy: executedBy,
    };

    if (props.creditEnabled && creditType) {
      data.creditType = creditType.value;
    }

    dispatch(setFilter(data));
    props.filter(data);
    setIsDateRangeOpen(false);
    toggleSync();
    formatFilterLabel(data);
  };

  const toggleSync = () => {
    props.sync();
  };

  useEffect(() => {
    let defaultFilter = {
      dateTimeFrom: `${convertDateAPIFormat(dateFrom)}T${timeFrom}:00`,
      dateTimeTo: `${convertDateAPIFormat(dateTo)}T${timeTo}:00`,
    };

    if (props.executedByDefault) {
      defaultFilter.executedBy = defaultUsername;
    } else {
      defaultFilter.username = defaultUsername;
    }

    if (props.thisWeekRange) {
      defaultFilter.dateTimeFrom = `${convertDateAPIFormat(
        moment().isoWeekday(1).toDate()
      )}T00:00:00`;
      defaultFilter.dateTimeTo = `${convertDateAPIFormat(
        moment().endOf("week").add(1, "day").toDate()
      )}T23:59:00`;
    }

    if (props.sessionFilter && currentFilter) {
      formatFilterLabel(currentFilter);
    } else {
      formatFilterLabel(defaultFilter);
    }

    props.filter(defaultFilter);
    // eslint-disable-next-line
  }, []);

  const formatFilterLabel = (filter) => {
    const dateFrom = new Date(filter?.dateTimeFrom).toDateString();
    const dateTo = new Date(filter?.dateTimeTo).toDateString();

    if (dateFrom === dateTo) {
      setFilterLabel(dateFrom);
    } else {
      setFilterLabel(dateFrom + " - " + dateTo);
    }
  };

  return (
    <Row className="mt-3">
      <Col md={12}>
        <Button
          className="btn-icon btn-3"
          color="primary"
          type="button"
          outline
          onClick={toggleFilter}
        >
          <span className="btn-inner--icon">
            <i className="fa fa-filter" />
          </span>
          <span className="btn-inner--text">FILTER</span>
        </Button>
        <Button
          className="btn-icon btn-3"
          color="primary"
          type="button"
          outline
          onClick={toggleSync}
        >
          <span className="btn-inner--icon">
            <i className="fa">&#xf0e2;</i>
          </span>
          <span className="btn-inner--text">REFRESH</span>
        </Button>
      </Col>
      <Col md={12} className="mt-3">
        <Collapse
          isOpen={isFilterOpened}
          style={{
            visibility: "visible",
          }}
        >
          <Form onSubmit={handleSubmit}>
            <Card>
              <CardHeader>
                <Row>
                  <Col className="align-self-center">
                    <h2>Filter Table</h2>
                  </Col>
                  <Col className="text-right">
                    <Button color="primary" disabled={props.searchToggle}>
                      {props.searchToggle ? (
                        <Spinner size="sm">Loading...</Spinner>
                      ) : (
                        <i className="fas fa-search" />
                      )}

                      <span> Apply</span>
                    </Button>
                  </Col>
                </Row>
              </CardHeader>

              <CardBody className="bg-secondary">
                <Row>
                  <Col xl={3} lg={6} md={6} sm={12}>
                    <FormGroup row>
                      <Label md={12} for="exampleTime">
                        Date Picker
                      </Label>
                      <Col md={12}>
                        <Button
                          className="mt-1"
                          color="primary w-100"
                          outline
                          onClick={toggleDateRange}
                        >
                          <span className="btn-inner--icon">
                            <i className="ni ni-calendar-grid-58" />
                          </span>
                          <span className="btn-inner--text">Date Range</span>
                        </Button>
                      </Col>
                    </FormGroup>
                  </Col>
                  <Col xl={3} lg={6} md={6} sm={12}>
                    <FormGroup row>
                      <Label md={12} for="exampleTime">
                        From Date
                      </Label>
                      <Col md={isLaptopSize ? 6 : 12}>
                        <Input
                          className="mt-1"
                          type="text"
                          placeholder="Date From"
                          value={dateFrom}
                          readOnly
                          onClick={() => setIsDateRangeOpen(true)}
                          required
                        />
                      </Col>
                      <Col md={isLaptopSize ? 6 : 12}>
                        <Input
                          name="time"
                          className="mt-1"
                          placeholder="time placeholder"
                          type="time"
                          defaultValue={timeFrom}
                          onChange={(e) => setTimeFrom(e.target.value)}
                        />
                      </Col>
                    </FormGroup>
                  </Col>
                  <Col xl={3} lg={6} md={6} sm={12}>
                    <FormGroup row>
                      <Label md={12} for="exampleTime">
                        To Date
                      </Label>
                      <Col md={isLaptopSize ? 6 : 12}>
                        <Input
                          className="mt-1"
                          type="text"
                          placeholder="Date To"
                          value={dateTo}
                          readOnly
                          onClick={() => setIsDateRangeOpen(true)}
                          required
                        />
                      </Col>
                      <Col md={isLaptopSize ? 6 : 12}>
                        <Input
                          id="exampleTime"
                          className="mt-1"
                          name="time"
                          placeholder="time to"
                          type="time"
                          value={timeTo}
                          onChange={(e) => setTimeTo(e.target.value)}
                        />
                      </Col>
                    </FormGroup>
                  </Col>
                  {!props.noUsername && (
                    <Col xl={3} lg={6} md={6} sm={12}>
                      <FormGroup row>
                        <Label md={12} for="exampleEmail">
                          Username
                        </Label>
                        <Col md={12}>
                          <Input
                            className="mt-1"
                            placeholder="Username here..."
                            onChange={(e) => setUsername(e.target.value)}
                            defaultValue={username}
                          />
                        </Col>
                      </FormGroup>
                    </Col>
                  )}

                  {props.executedBy && (
                    <Col xl={3} lg={6} md={6} sm={12}>
                      <FormGroup row>
                        <Label md={12} for="exampleEmail">
                          Executed By
                        </Label>
                        <Col md={12}>
                          <Input
                            className="mt-1"
                            placeholder="Executed By..."
                            onChange={(e) => setExecutedBy(e.target.value)}
                            value={executedBy}
                          />
                        </Col>
                      </FormGroup>
                    </Col>
                  )}

                  {props.creditEnabled && (
                    <Col xl={3} lg={6} md={6} sm={12}>
                      <FormGroup row className="mb-3">
                        <Label md={12}>Credit Type</Label>
                        <Col md={12}>
                          <Select
                            className="mt-1"
                            value={creditType}
                            required
                            onChange={(value) => setCreditType(value)}
                            options={[
                              {
                                value: "CHIPS",
                                label: (
                                  <div className="flex">
                                    <img
                                      alt=""
                                      src={chipsImage}
                                      className="w-6 h-6"
                                    />
                                    <b className="ml-2">Chips</b>
                                  </div>
                                ),
                              },
                              {
                                value: "COINS",
                                label: (
                                  <div className="flex">
                                    <img
                                      alt=""
                                      src={coinsImage}
                                      className="w-6 h-6"
                                    />
                                    <b className="ml-2">Coins</b>
                                  </div>
                                ),
                              },
                            ]}
                          />
                        </Col>
                      </FormGroup>
                    </Col>
                  )}

                  <Col md={12}>
                    <Collapse
                      isOpen={isDateRangeOpen}
                      style={{
                        visibility: "visible",
                      }}
                    >
                      <Row>
                        <Col xl={2} lg={3} md={12} sm={12}>
                          <DefinedRange
                            onChange={handleDateRange}
                            ranges={ranges}
                            staticRanges={staticRanges}
                          />
                        </Col>
                        <Col lg={3} md={12} sm={12}>
                          <DateRange
                            editableDateInputs={true}
                            onChange={handleDateRange}
                            moveRangeOnFirstSelection={false}
                            months={2}
                            ranges={ranges}
                            direction={
                              isTabletOrMobile ? "vertical" : "horizontal"
                            }
                          />
                        </Col>
                      </Row>
                    </Collapse>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Form>
        </Collapse>
      </Col>
      <Col>
        <h3 className="mt-2">
          {filterLabel && <Badge color="info">Date: {filterLabel}</Badge>}

          {username && (
            <>
              {" / "}
              <Badge color="success"> {username} </Badge>
            </>
          )}

          {executedBy && (
            <>
              {" / "}
              <Badge color="primary"> Executed By: {executedBy}</Badge>
            </>
          )}

          {creditType && (
            <>
              {" / "}
              <Badge color="warning"> Credit: {creditType.value}</Badge>
            </>
          )}
        </h3>
      </Col>
    </Row>
  );
};

export default Filter;
