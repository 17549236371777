import { fetchClient } from "api/axios";
// Authorization
import store from "store";

const getSessionID = () => {
  return store.getState().auth.uuid;
};

export const getRestrictedAccountPermissions = async () => {
  const response = await fetchClient().get(
    `/permissions/restricted/${getSessionID()}`
  );
  return response.status === 200 ? response.data : [];
};
