import React, { useState, useEffect } from "react";

import {
  Card,
  CardHeader,
  Row,
  Container,
  Button,
  Col,
  Breadcrumb,
  BreadcrumbItem,
  FormGroup,
  Label,
} from "reactstrap";

import { getAllModeOfPayments } from "api/Credit";
import { useSelector } from "react-redux";
import Select from "react-select";

// Spinners
import LoadingBar from "components/LoadBars";
import Header from "components/Headers/Header.js";

import { deleteMOP } from "api/p2p";

import DataTable from "react-data-table-component";
import DataTableExtensions from "react-data-table-component-extensions";

import { columns } from "./data";
import CreateForm from "./create";
import DeleteForm from "components/Modals/delete";
import { walletTypes } from "./constants.js";

const ModeOfPayment = () => {
  const [loading, setLoading] = useState(true);

  const [data, setData] = useState([]);
  const [bankType, setBankType] = useState(walletTypes[0]);
  const [clearSelect, setClearSelect] = useState(false);
  const [selectedData, setSelectedData] = useState([]);
  const role = useSelector((state) => state.entities.user.role);
  const isRoleNotAgent = role !== "AGENT";

  // Table Handling
  useEffect(() => {
    getAllModeOfPayments().then((response) => {
      setData(response);
      setClearSelect(false);
      setLoading(false);
    });
    // eslint-disable-next-line
  }, []);

  const handleSync = () => {
    setClearSelect(true);
    setLoading(true);

    setTimeout(() => {
      getAllModeOfPayments().then((response) => {
        setData(response);
        setClearSelect(false);
        setLoading(false);
      });
    }, 1000);
  };

  const handleSelection = (item) => {
    setSelectedData(
      item.selectedRows.map((entry) => {
        return { name: entry.accountName, value: entry.uuid };
      })
    );
  };

  return (
    <>
      <Header />
      <Container className="mt--7" fluid>
        <Row>
          <div className="col">
            <Card className="shadow">
              <Breadcrumb listClassName="breadcrumb-links mb-0">
                <BreadcrumbItem>Credit Management</BreadcrumbItem>
                <BreadcrumbItem>Mode of Payment</BreadcrumbItem>
              </Breadcrumb>
              <CardHeader className="border-0">
                <Row>
                  <Col lg={6} sm={12} className="d-flex align-items-center">
                    <h2 className="mb-0">Mode of Payment</h2>
                  </Col>
                  <Col
                    lg={6}
                    sm={12}
                    className="mt-3 mt-lg-0 d-flex justify-content-end"
                  >
                    <Button
                      className="btn-icon btn-2"
                      color="default"
                      type="button"
                      outline
                      onClick={handleSync}
                    >
                      <span className="btn-inner--icon">
                        <i className="fa fa-refresh"></i>
                      </span>
                    </Button>

                    <DeleteForm
                      data={selectedData}
                      api={deleteMOP}
                      successTitle={`Deleted MOP`}
                      sync={handleSync}
                    />
                    <CreateForm sync={handleSync} />
                  </Col>
                </Row>
              </CardHeader>
              <div className="p-4 bg-lighter">
                <div className="w-25">
                  <FormGroup className="mb-0">
                    <Label>Wallet Type</Label>
                    <Select
                      invalid
                      className="react-select info text-left"
                      classNamePrefix="react-select"
                      placeholder="Choose Wallet Type Here"
                      closeMenuOnSelect={true}
                      value={bankType}
                      onChange={(value) => setBankType(value)}
                      options={walletTypes}
                    />
                  </FormGroup>
                </div>
              </div>

              <DataTableExtensions
                export={false}
                print={false}
                columns={columns(handleSync)}
                data={data.filter(
                  (item) =>
                    bankType.value === "ALL" || item.type === bankType.value
                )}
              >
                <DataTable
                  defaultSortAsc={true}
                  onSelectedRowsChange={handleSelection}
                  clearSelectedRows={clearSelect}
                  pagination
                  striped
                  selectableRows={isRoleNotAgent}
                  progressComponent={<LoadingBar />}
                  progressPending={loading}
                />
              </DataTableExtensions>
            </Card>
          </div>
        </Row>
      </Container>
    </>
  );
};

export default ModeOfPayment;
