import gcashLogo from "assets/img/payment-logo/gcash.png";
import mayaLogo from "assets/img/payment-logo/maya.png";
import defaultLogo from "assets/img/payment-logo/default.png";
import komoLogo from "assets/img/payment-logo/komo.png";
import bdoLogo from "assets/img/payment-logo/bdo.png";
import bpiLogo from "assets/img/payment-logo/bpi.png";
import coinsLogo from "assets/img/payment-logo/coins.png";

export const getPaymentLogo = (type) => {
  switch (type) {
    case "GCASH": {
      return gcashLogo;
    }
    case "MAYA": {
      return mayaLogo;
    }
    case "COINS_PH": {
      return coinsLogo;
    }
    case "BPI": {
      return bpiLogo;
    }
    case "BDO": {
      return bdoLogo;
    }
    case "KOMO_EAST_WEST_RURAL_BANK": {
      return komoLogo;
    }

    default: {
      return defaultLogo;
    }
  }
};
