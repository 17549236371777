import axios from "axios";
import qs from "qs";

import Swal from "sweetalert2";

import {
  webToken,
  userUid,
  loggedInSuccess,
  loggedOut,
  setAccountTree,
  loggedInError,
  getAccessLevels,
  getChipTransactions,
  getCoinTransactions,
  getActiveAccountsByUpper,
  getInactiveAccountsByUpper,
  getMasters,
  getAgents,
  getCommissionProfit,
  getCoinsCommission,
} from "../../actions";

import { getCurrentWeekCommision, getUserCoinsCommission } from "api/Commision";

let PROXY = process.env.REACT_APP_API_KEY;

const alertDialog = (title, confirmLabel, type) => {
  Swal.fire({
    confirmButtonText: confirmLabel,
    title: title,
    icon: type,
    color: "#fff",
    confirmButtonColor: "rgb(157 134 0)",
    background:
      "radial-gradient(79.09% 79.09% at 50% 14.18%, #4e4d4c 0%, #060606 100%)",
  });
};

export const APIReduxLogin = async (userCredentials, dispatch) => {
  try {
    await axios({
      method: "post",
      url: PROXY + "/login",
      data: qs.stringify(userCredentials),
      headers: {
        "content-type": "application/x-www-form-urlencoded; charset=utf-8",
      },
    }).then((response) => {
      if (response.status === 200) {
        const token = response.data[0].accessToken;

        dispatch(webToken(token));

        axios
          .get(PROXY + "/user/token/id", {
            headers: {
              Authorization: "Bearer " + token,
            },
          })
          .then((response) => {
            if (response.status === 200) {
              const responseData = response.data[0];

              if (responseData.status === "PENDING") {
                Swal.fire({
                  title: "Sign In Failed",
                  text: "Account needs to be 'ACTIVE'",
                  icon: "warning",
                });
                dispatch(loggedInError("INACTIVE"));
              } else {
                dispatch(userUid(responseData.uuid));
                if (responseData.role === "PLAYER") {
                  dispatch(loggedInSuccess("PLAYER"));
                  alertDialog("Login success!", "Close", "success");
                } else {
                  getCurrentWeekCommision(responseData.username).then(
                    (response) => {
                      dispatch(getCommissionProfit(response.netProfit));
                    }
                  );

                  getUserCoinsCommission(responseData.username).then(
                    (response) => {
                      dispatch(getCoinsCommission(response.netProfit));
                    }
                  );

                  dispatch(loggedInSuccess("UPLINES"));
                  // window.location = "/portal/index";
                }
              }
            }
          });
      }
    });
  } catch (error) {
    dispatch(loggedInError("INVALID"));
  }
};

export const APITokenCheck = async (token, dispatch) => {
  try {
    await axios
      .get(PROXY + "/user/token/validate", {
        headers: {
          Authorization: "Bearer " + token,
        },
      })
      .then(function (responseData) {
        if (responseData.status === 200) {
          if (!responseData.data[0].isIpAddressSame) {
            dispatch(loggedOut());
          }
        }
      });
  } catch (error) {
    dispatch(loggedInError("INVALID"));
  }
};

// Credit Managament
export const APIPendingCredit = async (props, dispatch) => {
  const UID = props.ID;
  const jwtToken = props.JWT;

  await axios
    .get(PROXY + "/credit/pending/upper?uuid=" + UID, {
      headers: {
        Authorization: "Bearer " + jwtToken,
      },
    })
    .then(function (response) {
      // dispatch(getPendingCredit(response.data));
    });
};

export const APIApproveCredit = async (props) => {
  const jwtToken = props.JWT;
  const transactionId = props.transactionId;

  await axios
    .put(PROXY + "/credit?decision=APPROVE&transactionUID=" + transactionId, {
      headers: {
        Authorization: "Bearer " + jwtToken,
      },
    })
    .then(function (response) {
      if (response.data.statusCode === "OK") {
      }
    });
};

// Entities API
export const APIAccessLevel = async (JWT, dispatch) => {
  await axios
    .get(PROXY + "/admin/access-level", {
      headers: {
        Authorization: "Bearer " + JWT,
      },
    })
    .then(function (response) {
      if (response.status === 200) {
        dispatch(getAccessLevels(response.data));
      }
    });
};

// Entities API
export const APIUpperTransactions = async (payload, dispatch) => {
  await axios
    .get(
      PROXY +
        "/transactions/upper/" +
        payload.UUID +
        "?creditType=" +
        payload.creditType,
      {
        headers: {
          Authorization: "Bearer " + payload.JWT,
        },
      }
    )
    .then(function (response) {
      if (response.status === 200) {
        if (payload.creditType === "CHIPS") {
          dispatch(getChipTransactions(response.data));
        } else {
          dispatch(getCoinTransactions(response.data));
        }
      }
    });
};

export const APIUpperAccounts = async (payload, dispatch) => {
  await axios
    .get(
      PROXY + "/accounts/upper/" + payload.UUID + "?status=" + payload.status,
      {
        headers: {
          Authorization: "Bearer " + payload.JWT,
        },
      }
    )
    .then(function (response) {
      if (response.status === 200) {
        if (payload.status === "ACTIVE") {
          dispatch(getActiveAccountsByUpper(response.data));
        } else {
          dispatch(getInactiveAccountsByUpper(response.data));
        }
      }
    });
};

// Entities API
export const APIMasters = async (JWT, dispatch) => {
  await axios
    .get(PROXY + "/admin/master", {
      headers: {
        Authorization: "Bearer " + JWT,
      },
    })
    .then(function (response) {
      if (response.status === 200) {
        dispatch(getMasters(response.data));
      }
    });
};

// Entities API
export const APIFindAllAgents = async (JWT, dispatch) => {
  await axios
    .get(PROXY + "/agents", {
      headers: {
        Authorization: "Bearer " + JWT,
      },
    })
    .then(function (response) {
      if (response.status === 200) {
        dispatch(getAgents(response.data));
      }
    });
};

// Admin API's
export const APIAccountTree = async (JWT, dispatch) => {
  await axios
    .get(PROXY + "/admin/account-tree", {
      headers: {
        Authorization: "Bearer " + JWT,
      },
    })
    .then(function (response) {
      dispatch(setAccountTree(response.data));
    });
};
