import { useEffect } from "react";
import { useHistory } from "react-router-dom";

// services
import { loggedOut } from "actions";
import { checkSession } from "api/AccountAPI";
import { useDispatch, useSelector } from "react-redux";

// utils
import AccountDetection from "features/websocket/AccountDetection";
import { alertWithConfirm } from "views/homepage/utils";

const JILIGame = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const getUsername = useSelector((state) => state.entities.user.username);
  const isLoggedIn =
    useSelector((state) => state.ui.loginOutcome) === "LOGGED_IN";

  const gameURL = useSelector((state) => state.ui.game?.jiliURL);

  useEffect(() => {
    if (!isLoggedIn) {
      alertWithConfirm(
        "Connection lost, Another device is connected to this account.",
        "Re-login",
        "warning",
        () => {
          history.push("/home");
        }
      );
    } else {
      checkSession(getUsername).then((response) => {
        if (response?.statusCode !== "OK") {
          dispatch(loggedOut());
        }
      });
    }
    // eslint-disable-next-line
  }, [isLoggedIn]);

  return (
    <>
      <AccountDetection />
      <div className="dodge-game-frame">
        {isLoggedIn && (
          <iframe
            src={gameURL}
            allowFullScreen
            style={{ display: "block" }}
            className="w-100 h-100"
            id="JILL-iframe"
            title="JILI"
          ></iframe>
        )}
      </div>
    </>
  );
};

export default JILIGame;
