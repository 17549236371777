import { Link, useHistory } from "react-router-dom";

// reactstrap components
import {
  Navbar,
  Row,
  Col,
  UncontrolledCollapse,
  Nav,
  NavItem,
  DropdownItem,
  Media,
  DropdownToggle,
  UncontrolledDropdown,
  DropdownMenu,
} from "reactstrap";

import LoginForm from "components/Modals/login";
import logo from "assets/img/homepage/logo.png";
import chipsLogo from "assets/img/chips-icon.png";
import coinsLogo from "assets/img/coins-icon.png";

// redux
import { useSelector, useDispatch } from "react-redux";
import { loggedOut } from "../../actions";

import androidIcon from "assets/img/homepage/icons/android.png";
import { BsPersonCircle } from "react-icons/bs";

import { commas } from "views/shared/utils";

// reponsive
import { useMediaQuery } from "react-responsive";
import { logoplayevo } from "assets/img/homepage";

const HomeNavbar = () => {
  const history = useHistory();

  const balanceChips = useSelector((state) =>
    commas(state.entities.user?.chips)
  );
  const balanceCoins = useSelector((state) =>
    commas(state.entities.user?.coins)
  );
  const isLoggedIn = useSelector(
    (state) => state.ui.loginOutcome === "LOGGED_IN"
  );
  const userName = useSelector((state) => state.entities.user?.username);
  const dispatch = useDispatch();

  const handleLogout = () => {
    dispatch(loggedOut());
    history.push("/home");
  };

  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 576px)" });

  return (
    <Navbar
      className="landing-navbar navbar-horizontal navbar-dark justify-content-center"
      expand="md"
    >
      <Row className="w-100">
        <Col md={7} xs={5}>
          <Row className="d-flex justify-content-start align-items-center h-100">
            <Col xl="auto" lg={4} md="auto" xs={12}>
              <a href="/home" tag={Link} className="">
                <img
                  alt="..."
                  src={logoplayevo}
                  className={`nav-logo ${
                    isTabletOrMobile && "w-75"
                  } py-2 object-contain`}
                />
              </a>
            </Col>
            <Col md="auto" xs={6} className="d-md-none d-md-inline-block">
              <a href={process.env.REACT_APP_APP_URL} className="w-25">
                <button className="btn-gold download-app-btn download-app-nav mr-auto">
                  <img alt="" src={androidIcon} className="mr-2" />
                  DOWNLOAD
                </button>
              </a>
            </Col>
          </Row>
        </Col>

        <Col
          md={5}
          xs={7}
          className="d-flex align-items-center justify-content-end"
        >
          {!isLoggedIn ? (
            <>
              <LoginForm style={`d-flex justify-content-center`} />

              <a href="/auth/register">
                <button
                  className="btn-gold-outline"
                  onClick={() => history.push("/auth/register")}
                >
                  <span className="gold-font">REGISTER</span>
                </button>
              </a>
            </>
          ) : (
            <>
              <Link className="d-none d-md-block" to={"/player/my-account"}>
                <div className="text-white d-flex align-items-center justify-content-center">
                  <img alt="..." className="w-8 h-8" src={coinsLogo} />

                  <span className="h2 mb-0 font-weight-bold text-white mx-2">
                    {balanceCoins}
                  </span>
                </div>
              </Link>
              <div className="d-none d-md-block text-white mx-2">|</div>

              <Link to={"/player/my-account"}>
                <div className="text-white d-flex align-items-center justify-content-center">
                  <img alt="..." className="w-8 h-8" src={chipsLogo} />

                  <span className="h2 mb-0 font-weight-bold text-white mx-2">
                    {balanceChips}
                  </span>
                </div>
              </Link>

              <div className="d-none d-md-block text-white mx-2">|</div>

              <Nav className="align-items-center" navbar>
                <UncontrolledDropdown nav>
                  <DropdownToggle className="pr-0" nav>
                    <Media className="align-items-center text-white">
                      <span className="text-xl d-flex">
                        <BsPersonCircle />
                      </span>
                      <Media className="ml-2">
                        <span className="mb-0 text-md font-weight-bold">
                          {userName}
                        </span>
                      </Media>
                    </Media>
                  </DropdownToggle>

                  <DropdownMenu className="dropdown-menu-arrow" right>
                    <DropdownItem className="noti-title" header tag="div">
                      <h6 className="text-overflow m-0">Welcome!</h6>
                    </DropdownItem>

                    <DropdownItem to="/player/my-account" tag={Link}>
                      <i className="ni ni-single-02" />
                      <span>My Account</span>
                    </DropdownItem>

                    <DropdownItem divider />

                    <DropdownItem className="noti-title" header tag="div">
                      <h6 className="text-overflow m-0">Balance</h6>
                    </DropdownItem>

                    <DropdownItem to="/player/my-account" tag={Link}>
                      <div className="text-white d-flex align-items-center">
                        <img
                          alt="..."
                          className="w-8 h-8 object-contain"
                          src={chipsLogo}
                        />

                        <span className="h2 mb-0 font-weight-bold mx-2">
                          {balanceChips}
                        </span>
                      </div>
                    </DropdownItem>

                    <DropdownItem to="/player/my-account" tag={Link}>
                      <div className="text-white d-flex align-items-center">
                        <img
                          alt="..."
                          className="w-8 h-8 object-contain"
                          src={coinsLogo}
                        />

                        <span className="h2 mb-0 font-weight-bold mx-2">
                          {balanceCoins}
                        </span>
                      </div>
                    </DropdownItem>

                    <DropdownItem divider />

                    <DropdownItem onClick={handleLogout}>
                      <i className="ni ni-user-run" />
                      <span>Logout</span>
                    </DropdownItem>
                  </DropdownMenu>
                </UncontrolledDropdown>
              </Nav>
            </>
          )}
        </Col>
      </Row>

      <div className="d-sm-none">
        <UncontrolledCollapse
          navbar
          toggler="#navbar-collapse-main"
          style={{
            background:
              "linear-gradient(95.17deg, #1a2025 1.02%, #374149 24.82%, #222428 48.45%, #3d464a 73.19%, #151b20 100%)",
          }}
        >
          <div className="navbar-collapse-header d-md-none">
            <Row>
              <Col className="collapse-brand" xs="6">
                <Link to="/">
                  <img alt="..." src={logo} />
                </Link>
              </Col>
              <Col className="collapse-close" xs="6">
                <button className="navbar-toggler" id="navbar-collapse-main">
                  <span className="bg-white" />
                  <span className="bg-white" />
                </button>
              </Col>
            </Row>
          </div>
          <Nav className="ml-auto" navbar>
            <NavItem>
              <a href={process.env.REACT_APP_APP_URL}>
                <button className="btn-gold download-app-btn w-100 d-flex justify-content-center">
                  <img alt="" src={androidIcon} className="mr-2" />
                  DOWNLOAD{" "}
                  <span className="d-lg-block d-none ml-1"> APP HERE</span>
                </button>
              </a>
            </NavItem>
            <NavItem className="mt-3">
              <Row>
                <Col xs="6" className="pr-0">
                  <LoginForm className="w-100" id="navbar-collapse-main" />
                </Col>
                <Col xs="6" className="pl-0 collapse-close">
                  <a href={process.env.REACT_APP_APP_URL}>
                    <button className="btn-gold-outline">
                      <span className="gold-font">REGISTER</span>
                    </button>
                  </a>
                </Col>
              </Row>
            </NavItem>
          </Nav>
        </UncontrolledCollapse>
      </div>
    </Navbar>
  );
};

export default HomeNavbar;
