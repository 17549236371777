// React core component
import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";

// React strap tools
import {
  FormGroup,
  Input,
  InputGroup,
  InputGroupText,
  Modal,
  ModalBody,
  ModalHeader,
  Spinner,
} from "reactstrap";

import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";

// UI Actions
import { getCommissionProfit, loggedInStart, setPermissions } from "actions";
import { syncUserData } from "api/AccountAPI";
import { getCurrentWeekCommision } from "api/Commision";
import { getRestrictedAccountPermissions } from "api/apiPermissions";
import { APIReduxLogin } from "api/redux";

// alert prompt
import Swal from "sweetalert2";

const alertDialog = (title, confirmLabel, type) => {
  Swal.fire({
    confirmButtonText: confirmLabel,
    title: title,
    icon: type,
    color: "#fff",
    confirmButtonColor: "rgb(157 134 0)",
    background:
      "radial-gradient(79.09% 79.09% at 50% 14.18%, #4e4d4c 0%, #060606 100%)",
  });
};

export const LoginForm = (props) => {
  const [modal, setModal] = useState(false);

  const [validationCode, setValidationCode] = useState();
  const [inputValidationCode, setInputValidationCode] = useState();

  const [isShowError, setIsShowError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const isLoggedIn = useSelector((state) => state.ui.isLoggedIn);
  const loginOutcome = useSelector((state) => state.ui.loginOutcome);
  const userType = useSelector((state) => state.ui.userType);

  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [isLoggedInTriggered, setIsLoggedInTriggered] = useState(false);

  const toggle = () => {
    setIsShowError(false);
    setModal(!modal);
  };
  const dispatch = useDispatch();
  const history = useHistory();
  const { session } = useParams();

  const handleLogin = (e) => {
    e.preventDefault();

    if (
      !inputValidationCode ||
      inputValidationCode === "" ||
      parseInt(inputValidationCode) !== validationCode
    ) {
      setErrorMessage("Please enter validation code correctly");
      setIsShowError(true);

      return;
    } else {
      setErrorMessage("");
      setIsShowError(false);
    }
    // }

    setIsLoggedInTriggered(true);
    APIReduxLogin({ username: username, password: password }, dispatch);
  };

  const forgotPassword = (e) => {
    e.preventDefault();
    alertDialog(
      "Please contact upline to reset your password",
      "Okay",
      "question"
    );
  };

  useEffect(() => {
    setValidationCode(Math.floor(1000 + Math.random() * 9000));

    if (session) {
      history.push("/auth/login");
    }

    dispatch(loggedInStart());

    if (loginOutcome === "INVALID") {
      setErrorMessage("Invalid username / password");
      setIsLoggedInTriggered(false);
      setIsShowError(true);

      return;
    }

    if (loginOutcome === "LOGGED_IN") {
      syncUserData(dispatch);

      if (userType === "PLAYER") {
        setErrorMessage("");
        setIsShowError(false);
      } else {
        setErrorMessage("");
        setIsShowError(false);

        if (isLoggedIn === "OK") {
          getRestrictedAccountPermissions().then((response) => {
            dispatch(setPermissions(response));
          });

          if (username) {
            getCurrentWeekCommision(username).then((response) => {
              dispatch(getCommissionProfit(response?.netProfit));
            });
          }
          // getUserCoinsCommission(username).then((response) => {
          //   console.log(response);
          //   dispatch(getCoinsCommission(response?.netProfit));
          // });
          history.push("/portal/index");
        }
      }
    }

    // eslint-disable-next-line
  }, [isLoggedIn, loginOutcome]);

  const [eye, seteye] = useState(true);
  const [setshowhide, setShowHide] = useState("password");

  const handleEye = () => {
    if (setshowhide === "password") {
      setShowHide("text");
      seteye(false);
    } else {
      setShowHide("password");
      seteye(true);
    }
  };

  return (
    <>
      <button
        className={`${props.style} d-flex justify-content-center btn-gold`}
        onClick={toggle}
      >
        LOGIN
      </button>

      <Modal
        className="modal-dialog-centered text-center login-modal"
        size="md"
        isOpen={modal}
        toggle={toggle}
        backdrop={true}
        contentClassName="bg-gold-theme"
        backdropClassName="bg-theme-delux"
      >
        <ModalHeader className="" toggle={toggle}>
          <span className="text-white">Login</span>
        </ModalHeader>

        <ModalBody className="pt-0">
          <FormGroup className="mb-3">
            <InputGroup className="input-group-alternative mb-2">
              <Input
                placeholder="PLAYER ID"
                className="home-input"
                style={{
                  fontSize: "16px",
                }}
                type="email"
                autoComplete="off"
                minLength={5}
                required
                onChange={(e) => setUsername(e.target.value)}
              />
            </InputGroup>
            <InputGroup className="input-group-alternative mb-2">
              <Input
                placeholder="PASSWORD"
                type={setshowhide}
                autoComplete="off"
                className="home-input"
                style={{
                  fontSize: "16px",
                }}
                minLength={5}
                required
                onChange={(e) => setPassword(e.target.value)}
              />
              <div className="input-group-append pointer">
                <span className="input-group-text" id="showPasswordEye">
                  <i
                    onClick={handleEye}
                    className={`fa ${eye ? "fa-eye-slash" : "fa-eye"}`}
                  />
                </span>
              </div>
            </InputGroup>
            <InputGroup className="input-group-alternative">
              <Input
                placeholder="VALIDATION"
                className="home-input"
                type="text"
                autoComplete="off"
                minLength={4}
                maxLength={4}
                required
                onChange={(e) => setInputValidationCode(e.target.value)}
                style={{
                  fontSize: "16px",
                }}
              />

              <InputGroupText className="validation-bg no-copy">
                <span>{validationCode}</span>
              </InputGroupText>
            </InputGroup>

            {isShowError && (
              <h3 className="text-danger mt-3">
                <>{errorMessage}</>
              </h3>
            )}

            <h5 className="mt-3 text-white pointer">
              <span onClick={forgotPassword}>Forgot Password ?</span>
            </h5>
          </FormGroup>

          <div className="text-center">
            {isLoggedInTriggered ? (
              <Spinner color="light" className="mt-3 mb-3">
                Loading...
              </Spinner>
            ) : (
              <button
                className="w-100 btn-gold d-flex justify-content-center"
                onClick={handleLogin}
              >
                LOG IN
              </button>
            )}
          </div>
        </ModalBody>
      </Modal>
    </>
  );
};

export default LoginForm;
