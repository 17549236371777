import fetchClient from "api/axios";

export const postAuth = async (payload) => {
  try {
    const response = await fetchClient.post(`/dodge-bomb/auth`, payload);
    return response.data;
  } catch (error) {
    // Handle any errors here if needed
    console.error("Error in postAuth:", error);
    return [];
  }
};

export const postPlay = async (payload) => {
  try {
    const response = await fetchClient.post(`/dodge-bomb/play`, payload);
    return response.data;
  } catch (error) {
    // Handle any errors here if needed
    console.error("Error in postPlay:", error);
    return [];
  }
};
