import { useSelector } from "react-redux";

const usePermission = () => {
  const role = useSelector((state) => state.entities.user.role);

  const isAgent = role === "AGENT";
  return { isAgent };
};

export default usePermission;
