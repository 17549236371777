import React from "react";
import AsyncSelect from "react-select/async";
import makeAnimated from "react-select/animated";

import { APISearchAsync } from "api";

const AsyncSearchBar = ({ handleSearch, setSearchName }) => {
  //get animated components wrapper
  const animatedComponents = makeAnimated();

  // fetch filteres search results for dropdown
  const loadOptions = (value) => {
    return APISearchAsync(value);
  };

  return (
    <>
      <AsyncSelect
        cacheOptions
        components={animatedComponents}
        getOptionLabel={(e) => e.label}
        getOptionValue={(e) => e.value}
        loadOptions={loadOptions}
        onInputChange={handleSearch}
        onChange={(value) => {
          // handleSearch();
          setSearchName(value);
        }}
      />
    </>
  );
};

export default AsyncSearchBar;
