// reactstrap components
import { Container, Row, Col } from "reactstrap";

const Footer = () => {
  return (
    <footer className="py-3">
      <Container>
        <Row className="align-items-center justify-content-xl-between">
          <Col className="text-center copyright" xl="12">
            <a
              className="font-weight-bold mr-3"
              href="https://iplayevo.com/home"
            >
              Responsible Gaming
            </a>
            |
            <a
              className="font-weight-bold mx-3"
              href="https://iplayevo.com/home"
            >
              Privacy Agreement
            </a>
            |
            <a
              className="font-weight-bold ml-3"
              href="https://iplayevo.com/home"
            >
              Terms and Conditions
            </a>
            <br />
            <br />© Copyright {new Date().getFullYear()} <b>iPLAYEVO</b>
            <span className="ml-1">All Right Reserved.</span>
            <br />
            <span className="font-weight-bold">SABTECHNOLOGY.</span>
          </Col>
        </Row>
      </Container>
    </footer>
  );
};

export default Footer;
