import React from "react";
import { Row, Col, Button } from "reactstrap";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

// redux
import { useSelector } from "react-redux";

import {
  Game1,
  Game2,
  Game3,
  Game4,
  goldenGateXBanner,
} from "assets/img/homepage";
import { alertDialog } from "../utils";

const games = [
  {
    name: "Golden GateX",
    image: goldenGateXBanner,
    play: "web",
    href: "/game/golden-gateX",
  },
  {
    name: "JILI Game",
    image: Game4,
    play: "web",
    href: "/slot-games",
  },
  {
    name: "Video Karera",
    image: Game1,
    play: "mobile",
  },
  {
    name: "Dodge Bomb",
    image: Game3,
    play: "web",
  },
  {
    name: "In Between",
    image: Game2,
    play: "mobile",
  },
];

const AllGames = () => {
  const history = useHistory();

  const isLoggedIn = useSelector(
    (state) => state.ui.loginOutcome === "LOGGED_IN"
  );

  const handlePlay = (e, game) => {
    e.preventDefault();

    if (game.play === "mobile") {
      window.location.href = process.env.REACT_APP_APP_URL;
    } else {
      if (!isLoggedIn) {
        alertDialog("Please login to continue", "Okay", "warning");
        return;
      }
      forwardToBomb();
    }
  };

  const forwardToBomb = () => {
    history.push("/dodge-bomb");
  };

  return (
    <section>
      <div className="text-left my-3">
        <h1 className="text-white">ALL GAMES</h1>
      </div>
      <Row>
        {games.map((item, index) => {
          return (
            <Col
              xl={2}
              lg={4}
              md={4}
              sm={6}
              xs={6}
              className="pr-0"
              key={index}
            >
              <div className="card-games">
                <div className="content">
                  <div className="front">
                    <img className="profile" src={item.image} alt="VK" />
                  </div>
                  <div className="back from-bottom">
                    <h2>{item.name}</h2>
                    <span
                      onClick={(e) => {
                        if (item.href) {
                          history.push(item.href);
                        } else {
                          handlePlay(e, item);
                        }
                      }}
                    >
                      <Button className="btn-gold download-app-btn px-4 mr-0">
                        PLAY
                      </Button>
                    </span>
                    <br />
                  </div>
                </div>
              </div>
            </Col>
          );
        })}
      </Row>
    </section>
  );
};

export default AllGames;
