import axios from "axios";

// alert prompt
import Swal from "sweetalert2";

import fetchClient from "api/axios";

const JWTHeader = (JWT) => {
  return { headers: { Authorization: "Bearer " + JWT } };
};

const BASE_URI = (path) => {
  return process.env.REACT_APP_API_KEY + "/game" + path;
};

export const getOddsSettings = async (JWT) => {
  const response = await axios.get(
    BASE_URI("/settings/odds?game=VIDEO_KARERA"),
    JWTHeader(JWT)
  );
  return response.status === 200 ? response.data : [];
};

export const getSettings = async (JWT) => {
  // Override timeout for this request as it's known to take a long time
  const response = await fetchClient.get("/game/settings?game=VIDEO_KARERA");

  return response.status === 200 ? response.data : [];
};

export const patchSchemePercentage = async (ID, value) => {
  const response = await fetchClient.patch(
    `/game/settings/${ID}?percentage=${value}`
  );
  return response.status === 200
    ? response.data.statusCode === "OK"
    : Swal.fire("Cannot Fetch Data");
};

export const getGameLogs = async () => {
  const response = await fetchClient.get(`/game/logs?game=VIDEO_KARERA`);
  return response.status === 200 ? response.data : [];
};

export const getGameScheme = async () => {
  const response = await fetchClient.get(`/game/settings?game=VIDEO_KARERA`);
  return response.status === 200 ? response.data : [];
};

export const getVideoKareraOdds = async () => {
  const response = await fetchClient.get(`/game/settings/odds`);
  return response.status === 200 ? response.data : [];
};

export const update = async () => {
  const response = await fetchClient.get(`/game/settings/odds`);
  return response.status === 200 ? response.data : [];
};
