import React from "react";
import { Row, Col, Button } from "reactstrap";

import { Game1, Game2 } from "assets/img/homepage";

const AllGames = () => {
  const games = [
    {
      id: 1,
      name: "Video Karera",
      image: Game1,
    },
    {
      id: 2,
      name: "In Between",
      image: Game2,
    },
  ];

  return (
    <section>
      <div className="text-left my-3">
        <h1 className="text-white">PINOY GAMES</h1>
      </div>
      <Row>
        {games.map((index) => {
          return (
            <Col
              xl={2}
              lg={4}
              md={4}
              sm={6}
              xs={6}
              className="pr-0"
              key={index.id}
            >
              <div className="card-games">
                <div className="content">
                  <div className="front">
                    <img className="profile" src={index.image} alt="VK" />
                  </div>
                  <div className="back from-bottom">
                    <h2>{index.name}</h2>
                    <a href={process.env.REACT_APP_APP_URL}>
                      <Button className="btn-gold download-app-btn px-4 mr-0">
                        PLAY
                      </Button>
                    </a>
                    <br />
                  </div>
                </div>
              </div>
            </Col>
          );
        })}
      </Row>
    </section>
  );
};

export default AllGames;
