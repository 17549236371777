import React from "react";

import PinoyGames from "../components/PinoyGames";
import Banners from "views/homepage/components/Banners";

const HotGamesPage = () => {
  return (
    <section>
      <Banners />

      <div className="pb-5 text-center mx-4 mx-md-14 mx-lg-5 pt-3 mx-sm-3 mx-xs-2">
        <PinoyGames />
      </div>
    </section>
  );
};

export default HotGamesPage;
