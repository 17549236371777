import { useState, useEffect } from "react";
import { useParams, Link, useHistory } from "react-router-dom";

// React strap components
import { Card, Row, Container, CardHeader, CardBody, Table } from "reactstrap";
import { useSelector } from "react-redux";

// Data table
import Header from "components/Headers/Header.js";
import { usernameOnly } from "components/Filters/default";
import Filter from "components/Filters";
import LoadingBar from "components/LoadBars";
import BreadcrumbCustom from "components/BreadcrumbCustom";

import { getCoinCommissions } from "api/Commision";
import { designNo, pushLocationTo } from "views/shared/utils";

function CommissionCoins() {
  const { username } = useParams();
  const currentUsername = useSelector((state) => state.entities.user.username);
  const sessionFilter = useSelector((state) => state.ui.filter);

  const history = useHistory();
  const [data, setData] = useState({
    downLineDetails: [],
    netProfit: 0,
    upperDetails: null,
    username: "",
  });

  const existingUsername = username ? username : currentUsername;
  if (sessionFilter?.username) {
    sessionFilter.username = existingUsername;
  }

  const [filter, setFilter] = useState(
    sessionFilter ? sessionFilter : usernameOnly(existingUsername)
  );

  const [loading, setLoading] = useState(true);
  const [refreshTrigger, setRefreshTrigger] = useState(1);

  useEffect(() => {
    getCoinCommissions(filter).then((response) => {
      setData(response);
      setLoading(false);
    });
    // eslint-disable-next-line
  }, [refreshTrigger]);

  const toggleFilter = () => {
    setLoading(true);
    setRefreshTrigger(refreshTrigger + 1);
  };

  return (
    <>
      <Header />

      <Container className="mt--7" fluid>
        <Row>
          <div className="col">
            <Card className="shadow">
              <BreadcrumbCustom
                username={username}
                uri={"/portal/reports/commission/coins/"}
              />
              <CardHeader className="border-0">
                <h2 className="mb-0">COINS Commision Summary</h2>
                <Filter
                  username={username ? username : currentUsername}
                  filter={setFilter}
                  sync={toggleFilter}
                  searchToggle={loading}
                  thisWeekRange
                  sessionFilter
                />
              </CardHeader>
              <CardBody>
                {loading ? <LoadingBar /> : null}

                {data?.downLineDetails && data?.downLineDetails.length ? (
                  <>
                    <h3 className="mb-3">Upline Breakdown</h3>
                    <Table responsive striped bordered>
                      <thead>
                        <tr>
                          <th>Account</th>
                          <th>Total Coins</th>
                          <th>Table</th>
                          <th>No. of game played</th>
                          <th>Net Profit</th>
                        </tr>
                      </thead>
                      <tbody className="h1">
                        {data.upperDetails &&
                          data.upperDetails.value.map((row, index) => {
                            return (
                              <tr key={index}>
                                <th scope="row">{data.upperDetails.name}</th>

                                <td>{row.totalCoins}</td>
                                <td>{row.table}</td>
                                <td>{row.numberOfGamesPlayed}</td>
                                <td>{designNo(row.netProfit)}</td>
                              </tr>
                            );
                          })}
                        <tr>
                          <th scope="row"></th>
                          <td>{designNo(data.totalAmount)}</td>
                          <td></td>
                          <td></td>
                          <td>{designNo(data.netProfit)}</td>
                        </tr>
                      </tbody>
                    </Table>

                    <h3 className="my-3">Downlines Breakdown</h3>
                    <Table responsive striped bordered>
                      <thead>
                        <tr>
                          <th>Account</th>
                          <th>Total Coins</th>
                          <th>Table</th>
                          <th>No. of game played</th>
                          <th>{data.upperRole !== "AGENT" && "Net Profit"}</th>
                        </tr>
                      </thead>
                      <tbody className="h1">
                        {data.downLineDetails.map((row, index) =>
                          row.value.map((value) => {
                            return (
                              <tr key={index}>
                                <td>
                                  {data.upperRole === "AGENT" ? (
                                    <>{row.name}</>
                                  ) : (
                                    <Link
                                      className="cursor-pointer text-primary"
                                      to={"#"}
                                      onClick={(e) =>
                                        pushLocationTo(
                                          e,
                                          history,
                                          "/portal/commission/coins/" + row.name
                                        )
                                      }
                                    >
                                      {row.name}
                                    </Link>
                                  )}
                                </td>
                                <td>{value.totalCoins}</td>
                                <td>{value.table}</td>
                                <td>{value.numberOfGamesPlayed}</td>

                                <td>
                                  {data.upperRole !== "AGENT" &&
                                    designNo(value.netProfit)}
                                </td>
                              </tr>
                            );
                          })
                        )}
                      </tbody>
                    </Table>
                  </>
                ) : loading ? null : (
                  <h1 className="text-center">No result available.</h1>
                )}
              </CardBody>
            </Card>
          </div>
        </Row>
      </Container>
    </>
  );
}

export default CommissionCoins;
