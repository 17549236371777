import React from "react";

import logo from "../../assets/img/homepage/logo.webp";

const LoadingSpinner = () => {
  return (
    <div className="py-20 text-center uppercase font-semibold">
      <div class="flex-col gap-4 w-full flex items-center justify-center relative">
        <div class="w-28 h-28 border-8 text-red-400 text-4xl animate-spin border-gray-300 flex items-center justify-center border-t-red-600 rounded-full relative"></div>
        <img src={logo} className="absolute size-16 animate-pulse" />
      </div>
      <div className="mt-3 text-lg text-white">Loading...</div>
    </div>
  );
};

export default LoadingSpinner;
