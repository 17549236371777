import { commas } from "views/shared/utils";

import UpdateMopStatus from "./events/updateStatus";
import UpdateMop from "./events/update";

export const columns = (sync) => [
  {
    name: "Row",
    sortable: true,
    selector: (row, i) => i + 1,
    width: "80px",
    heigth: "100px",
  },
  {
    name: "Provider",
    sortable: true,
    selector: (row) =>
      row.type === "E_WALLET" ? row.electronicWalletType : row.bankType,
  },
  {
    name: "Account Number",
    selector: (row) => row.accountNumber,
    sortable: true,
  },
  {
    name: "Account Name",
    selector: (row) => row.accountName,
    sortable: true,
    cell: (row) => <b>{row.accountName}</b>,
  },
  {
    name: "Min",
    selector: (row) => commas(row.minimum),
    sortable: true,
  },
  {
    name: "Max",
    selector: (row) => commas(row.maximum),
    sortable: true,
  },
  {
    name: "Status",
    selector: (row) => row.status,
    allowOverflow: true,
    cell: (row) => <UpdateMopStatus row={row} sync={sync} />,
  },
  {
    name: "Action",
    selector: (row) => row.status,
    cell: (row) => <UpdateMop row={row} sync={sync} />,
  },
];
