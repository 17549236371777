import React from "react";
import {
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  Container,
  Row,
  Col,
  Button,
} from "reactstrap";
function NewPassword() {
  return (
    <>
      {" "}
      <Container className="mt-4" fluid>
        <Row className="mx-auto">
          <Col className="mx-auto" xl="6">
            <Card className="bg-secondary shadow">
              <CardHeader className="bg-white border-0">
                <Row className="align-items-center">
                  <Col xs="8">
                    <h3 className="mb-0">Enter New Password</h3>
                  </Col>
                  <Col className="text-right" xs="4"></Col>
                </Row>
              </CardHeader>
              <CardBody className="mx-auto col-12">
                <Form>
                  <div className="mx-auto">
                    <Row className="mx-auto">
                      <Col md="12">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-new-password"
                          >
                            New Password
                          </label>
                          <Input
                            className="form-control-alternative"
                            id="input-current-password"
                            type="password"
                            required
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row className="mx-auto">
                      <Col md="12">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-confirm-password"
                          >
                            Confirm Password
                          </label>
                          <Input
                            className="form-control-alternative"
                            id="input-current-password"
                            type="password"
                            required
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    <Button color="success" href="/home" className="px-5 ml-3">
                      Save
                    </Button>
                  </div>
                </Form>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default NewPassword;
