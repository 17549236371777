import { withdraw } from "assets/img/homepage";
import { useState } from "react";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import { Button, Modal, ModalBody, ModalHeader } from "reactstrap";

const PlayerWithdrawModal = ({ args }) => {
  const [modal, setModal] = useState(false);

  const toggle = () => setModal(!modal);

  return (
    <div>
      <div
        onClick={toggle}
        className="text-center bg-dark rounded-md py-2 px-4 mr-2 h-full cursor-pointer"
      >
        <img alt="..." src={withdraw} height={60} />
        <div className="h3 text-secondary uppercase mt-2">Withdraw</div>
      </div>

      <Modal isOpen={modal} toggle={toggle} {...args} centered>
        <ModalHeader toggle={toggle}>Select Withdraw Method</ModalHeader>
        <ModalBody className="space-x-2">
          <Link to="/player/cashier-auto/withdraw">
            <Button color="primary" onClick={toggle}>
              Auto Withdraw
            </Button>
          </Link>

          <Link to="/player/cashier/withdraw">
            <Button color="primary" onClick={toggle}>
              P2P Withdraw
            </Button>
          </Link>
        </ModalBody>
      </Modal>
    </div>
  );
};

export default PlayerWithdrawModal;
