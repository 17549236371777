import axios from "axios";

const API_URL = process.env.REACT_APP_GOLDEN_GATEX_URL;

export const fetcher = (url) =>
  axios.get(API_URL + url).then((res) => res.data);

export const getVendorList = async () => {
  const response = await axios.get(API_URL + `/vendors`);
  return response.status === 200 ? response.data : [];
};
