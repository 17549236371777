import { useState } from "react";

import {
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  Container,
  Row,
  Col,
  Button,
  ModalHeader,
  Modal,
  ModalFooter,
  ModalBody,
} from "reactstrap";

function ForgetPassword() {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <>
      <Container className="mt-4" fluid>
        <Row className="mx-auto">
          <Col className="mx-auto" xl="6">
            <Card className="bg-secondary shadow">
              <CardHeader className="bg-white border-0">
                <Row className="align-items-center">
                  <Col xs="8">
                    <h3 className="mb-0">Find Your Account</h3>
                  </Col>
                  <Col className="text-right" xs="4"></Col>
                </Row>
              </CardHeader>
              <CardBody className="mx-auto col-12">
                <Form>
                  {/* Address */}

                  <div className="mx-auto">
                    <Row className="mx-auto">
                      <Col md="12">
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-current-password"
                          >
                            Enter Your Phone Number
                          </label>
                          <Input
                            className="form-control-alternative mb-3"
                            id="input-current-password"
                            type="number"
                            required
                          />
                        </FormGroup>
                      </Col>
                    </Row>

                    <Button
                      variant="success"
                      className="px-5 ml-3"
                      onClick={handleShow}
                    >
                      Search
                    </Button>
                    <Modal show={show} onHide={handleClose}>
                      <ModalHeader>Check your Phone</ModalHeader>
                      <ModalBody>
                        You'll receive a code to verify here so you can reset
                        your account password.
                        <FormGroup>
                          <label
                            className="form-control-label"
                            htmlFor="input-current-password"
                          ></label>
                          <Input
                            className="form-control-alternative mb-3"
                            id="input-current-password"
                            type="number"
                            required
                            placeholder="Enter your Code"
                          />
                        </FormGroup>
                      </ModalBody>
                      <ModalFooter>
                        <Button variant="success" href="/auth/newpassword">
                          Save Changes
                        </Button>
                        <Button variant="danger" onClick={handleClose}>
                          Close
                        </Button>
                      </ModalFooter>
                    </Modal>
                  </div>
                </Form>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default ForgetPassword;
