import { useEffect, useState } from "react";
import { useParams, Link, useHistory } from "react-router-dom";

import {
  Card,
  CardHeader,
  Row,
  Container,
  Col,
  Breadcrumb,
  BreadcrumbItem,
  Button,
} from "reactstrap";

// alert prompt
import DataTable from "react-data-table-component";
import DataTableExtensions from "react-data-table-component-extensions";

// Core components
import Header from "components/Headers/Header.js";

// Spinners
import LoadingBar from "components/LoadBars";

// API Calls
import { getActiveDownlines, getAccountInformation } from "api/AccountAPI";

import { columns } from "./data";

const Downlines = () => {
  let { name } = useParams();
  let history = useHistory();

  // Table Handling
  const [data, setData] = useState([]);
  const [hierarchy, setHierarchy] = useState([]);

  // Table Components
  const [sync, setSync] = useState(true);

  const refreshTable = () => {
    setSync(!sync);
  };

  useEffect(() => {
    const loadCurrentParent = async () => {
      getAccountInformation(name).then((response) => {
        setHierarchy(response.data?.hierarchy);
      });
    };

    const loadUsers = async () => {
      getActiveDownlines(name).then((response) => {
        setData(response);
        setSync(false);
      });
    };

    loadCurrentParent();
    loadUsers();
    // eslint-disable-next-line
  }, [name]);

  const pushLocationTo = (e, location) => {
    e.preventDefault();

    history.push(location);
    window.location.reload(false);
  };

  return (
    <>
      <Header />
      <Container className="mt--7" fluid>
        <Row>
          <div className="col">
            <Card className="shadow">
              <Breadcrumb listClassName="breadcrumb-links">
                <BreadcrumbItem>
                  <Link to="/portal/index">Home</Link>
                </BreadcrumbItem>
                <BreadcrumbItem>
                  <Link to="/portal/active-accounts">My Downlines</Link>
                </BreadcrumbItem>
                {hierarchy?.map((index) => {
                  return (
                    <BreadcrumbItem
                      key={index.name}
                      onClick={(e) =>
                        pushLocationTo(e, "/portal/downlines/" + index.value)
                      }
                    >
                      <Link to={"/portal/downlines/" + index.value}>
                        {index.value}
                      </Link>
                    </BreadcrumbItem>
                  );
                })}
                <BreadcrumbItem
                  onClick={(e) =>
                    pushLocationTo(e, "/portal/downlines/" + name)
                  }
                >
                  <Link to={"/portal/downlines/" + name}>{name}</Link>
                </BreadcrumbItem>
              </Breadcrumb>

              <CardHeader className="border-0">
                <Row>
                  <Col lg={6} sm={12}>
                    <span className="mb-0 h2">Downlines of {name}</span>
                  </Col>
                  <Col lg={6} sm={12} className="text-lg-right mt-3 mt-lg-0">
                    <Button
                      className="btn-icon btn-2"
                      color="default"
                      type="button"
                      outline
                      onClick={refreshTable}
                    >
                      <span className="btn-inner--icon">
                        <i className="fa fa-refresh"></i>
                      </span>
                    </Button>
                  </Col>
                </Row>
              </CardHeader>
              <DataTableExtensions
                export={false}
                print={false}
                columns={columns(history)}
                data={data}
              >
                <DataTable
                  title={"Active Accounts (" + data.length + ")"}
                  columns={columns(history)}
                  data={data}
                  defaultSortAsc={true}
                  pagination
                  highlightOnHover
                  striped
                  progressComponent={<LoadingBar />}
                  progressPending={sync}
                />
              </DataTableExtensions>
            </Card>
          </div>
        </Row>
      </Container>
    </>
  );
};

export default Downlines;
