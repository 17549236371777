export const columns = () => [
  {
    name: "Odds",
    selector: (row) => row.odds,
    sortable: true,
  },
  {
    name: "Position",
    selector: (row) => row.position,
    sortable: true,
  },
  {
    name: "Winning %",
    selector: (row) => row.winningPercentage,
    sortable: true,
  },
];
