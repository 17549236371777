// Authorization
import auth from "./auth";

// User Interface
import ui from "./ui";

// Entities
import entities from "./entities";

import { combineReducers } from "redux";

const allReducers = combineReducers({
  auth: auth,
  ui: ui,
  entities: entities,
});

export default allReducers;
