// React core component
import { useState } from "react";

// React strap tools
import {
  FormGroup,
  InputGroup,
  Input,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
} from "reactstrap";

import { changePassword } from "api/AccountAPI";
import { nofitySuccess, notifyDanger } from "components/Toasts";

export const ChangePasswordForm = (props) => {
  const [modal, setModal] = useState(false);

  const [password, setPassword] = useState();

  const toggle = () => setModal(!modal);

  const handleSubmit = (e) => {
    e.preventDefault();

    changePassword(props.id, password).then((response) => {
      if (response.statusCode === "OK") {
        nofitySuccess(`Change password success!`);
        toggle();
      } else {
        notifyDanger(response.message);
      }
    });
  };

  return (
    <>
      <Button
        className="btn-icon btn-3 w-100"
        color="primary"
        outline
        type="button"
        onClick={toggle}
        size="sm"
      >
        <span className="btn-inner--icon">
          <i className="fa fa-key"></i> PASSWORD
        </span>
      </Button>

      <Modal
        className="modal-dialog-centered"
        size="md"
        isOpen={modal}
        toggle={toggle}
      >
        <ModalHeader toggle={toggle}>Change {props.name} password</ModalHeader>

        <ModalBody>
          <FormGroup className="mb-3">
            <p className="">
              <span className="mr-2">
                Enter the <b>{props.name}</b> 'NEW' password:
              </span>
            </p>
            <InputGroup className="input-group-alternative">
              <Input
                placeholder="Please type here"
                type="text"
                autoComplete="off"
                minLength={5}
                required="required"
                onChange={(e) => setPassword(e.target.value)}
              />
            </InputGroup>
            <p className="">
              <span className="mr-2 h6">This is case sensitive</span>
            </p>
          </FormGroup>
          <div className="text-right">
            <Button
              color="secondary"
              data-dismiss="modal"
              type="button"
              onClick={toggle}
            >
              Close
            </Button>
            <Button
              className="mutted"
              color="primary"
              type="button"
              onClick={handleSubmit}
            >
              Update
            </Button>
          </div>
        </ModalBody>
      </Modal>
    </>
  );
};

export default ChangePasswordForm;
