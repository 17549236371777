import { useState, useEffect, useCallback } from "react";
import { useHistory } from "react-router-dom";

import {
  Row,
  Col,
  Button,
  Card,
  CardBody,
  Input,
  FormFeedback,
  FormGroup,
  Form,
  CardTitle,
  Label,
  FormText,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
} from "reactstrap";

import classnames from "classnames";

import LoadingBar from "components/LoadBars";
import { useDispatch } from "react-redux";
import Swal from "sweetalert2";
import chipsLogo from "assets/img/chips-icon.png";

import { useSelector } from "react-redux";
import { commas, isNumeric } from "views/shared/utils";

import WithdrawalPINModal from "./WithdrawalPINModal";
import {
  postWithdrawCreditRequest,
  getMOPChannelsOfPlayer,
  postMOPToPlayer,
} from "api/p2p";
import { syncUserData } from "api/AccountAPI";
import { nofitySuccess } from "components/Toasts";
import { notifyDanger } from "components/Toasts";
import SavedAccountOptions from "./withdraw/SavedAccountOptions";
import NewAccountOptions from "./withdraw/NewAccountOptions";

const alertDialog = (title, confirmLabel, type) => {
  Swal.fire({
    confirmButtonText: confirmLabel,
    title: title,
    icon: type,
    color: "#fff",
    confirmButtonColor: "rgb(157 134 0)",
    background:
      "radial-gradient(79.09% 79.09% at 50% 14.18%, #4e4d4c 0%, #060606 100%)",
  });
};

const WithdrawAmount = () => {
  const history = useHistory();
  const dispatch = useDispatch();

  const username = useSelector((state) => commas(state.entities.user.username));
  const balanceChips = useSelector((state) =>
    commas(state.entities.user.chips)
  );

  const [isAmountValid, setIsAmountValid] = useState(true);
  const [isAmountCap, setIsAmountCap] = useState(false);
  const [inProcess, setInProcess] = useState(false);

  const [playerMOPs, setPlayerMOPs] = useState([]);
  const [selectedAmount, setSelectedAmount] = useState();
  const [selectedSavedMOP, setSelectedSavedMOP] = useState();
  const [withdrawalPin, setWithdrawalPin] = useState();

  const [tabs, setTabs] = useState(1);

  const toggleNavs = (e, state, index) => {
    e.preventDefault();
    setTabs(index);

    if (index === 1) {
      setSelectedSavedMOP();
    }
  };

  const [mopAccount, setMOPAccount] = useState({
    type: null,
    accountName: null,
    accountNumber: null,
    electronicWalletType: null,
    bankType: null,
    contactNumber: null,
    bankBranch: null,
  });

  const amounts = [20, 50, 100, 150, 200, 500, 1000, 2000];
  const checkIfNumericOrInCap = (amount) => {
    if (!amount) {
      return;
    }

    if (isNumeric(amount)) {
      setIsAmountValid(true);
    } else {
      setIsAmountValid(false);
    }

    if (isAmountInCap(amount)) {
      setIsAmountCap(false);
    } else {
      setIsAmountCap(true);
    }

    setSelectedAmount(amount);
  };

  const isAmountInCap = (amount) => {
    return amount < Number(balanceChips.replace(",", ""));
  };

  const changeAmount = (amount) => {
    setSelectedAmount(amount);
    setIsAmountValid(true);
    // setSelectedAmount(item);
  };

  function hasAccount(
    accounts,
    accountName,
    accountNumber,
    walletChannel,
    type
  ) {
    return accounts.some(
      (account) =>
        account.accountName === accountName &&
        account.accountNumber === accountNumber &&
        account.type === type &&
        (account.type === "BANK"
          ? account.bankType === walletChannel
          : account.electronicWalletType === walletChannel)
    );
  }

  const handleWithdraw = (e) => {
    e.preventDefault();

    setInProcess(true);
    window.scrollTo({ top: 0, behavior: "smooth" });

    let inqury = {
      username: username,
      amount: selectedAmount,
      pin: withdrawalPin,
    };

    if (selectedSavedMOP) {
      inqury.uuidMOP = selectedSavedMOP;
    } else {
      inqury.mop = mopAccount;
    }

    setTimeout(() => {
      postWithdrawCreditRequest(inqury).then((result) => {
        if (result.status === 401) {
          alertDialog(
            "We're sorry, but the PIN you entered is incorrect. Please double-check your PIN and try again.",
            "Close",
            "warning"
          );
          setInProcess(false);
          return;
        }

        if (result.status === 412) {
          alertDialog(
            "Please note that you still have a pending transaction. Kindly contact your upline for further assistance",
            "Check Account",
            "warning"
          );
          history.push("/player/my-account");
          setInProcess(false);
          return;
        }

        if (
          !selectedSavedMOP &&
          !hasAccount(
            playerMOPs,
            mopAccount.accountName,
            mopAccount.accountNumber,
            mopAccount.type === "BANK"
              ? mopAccount.bankType
              : mopAccount.electronicWalletType,
            mopAccount.type
          )
        ) {
          alertDialogInput();
        } else {
          alertDialog(
            "Withdrawal successful! Your funds are on their way.",
            "Okay",
            "success"
          );
          syncUserData(dispatch);
          history.push("/player/my-account");
        }
      });
    }, 1000);
  };

  const alertDialogInput = () => {
    Swal.fire({
      title: "Would you like to save this wallet to your account?",
      text: "Don't worry, you can easily select this wallet again later if needed.",
      icon: "question",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, save it!",
      cancelButtonTextColor: "No",
    }).then((result) => {
      if (result.isConfirmed) {
        postMOPToPlayer(mopAccount).then((result) => {
          if (result) {
            nofitySuccess(
              "Your payment method has been saved to your account."
            );
            history.push("/player/my-account");
          } else {
            notifyDanger("Your payment is not saved. Try again later.");
          }
        });
      } else {
        alertDialog(
          "Withdrawal successful! Your funds are on their way.",
          "Okay",
          "success"
        );
      }
    });
  };

  const handleSelectExistingMOP = useCallback((existingMOP) => {
    setSelectedSavedMOP(existingMOP);
  }, []);

  const handleNewAccountMOP = useCallback((newAccountMOP) => {
    setMOPAccount(newAccountMOP);
  }, []);

  useEffect(() => {
    getMOPChannelsOfPlayer().then((response) => setPlayerMOPs(response));
  }, []);

  return (
    <section className="pb-5 container pt-3 mx-auto text-white">
      <h1 className="text-white">WITHDRAW</h1>

      <Card className="bg-gradient-default text-left mb-3">
        <CardBody>
          <Row>
            <div className="col">
              <CardTitle className="text-uppercase text-muted mb-0 text-white">
                Total Chips
              </CardTitle>
              <span className="display-3 font-weight-bold mb-0 text-white">
                {balanceChips}
              </span>
            </div>
            <Col className="col-auto">
              <div className="icon icon-shape bg-white text-dark rounded-circle shadow">
                <img src={chipsLogo} alt="chips" />
              </div>
            </Col>
          </Row>
          <p className="mt-1 mb-0 text-sm">
            <span className="text-nowrap">
              NOTE: Withdraw transaction are{" "}
              <strong className="text-uppercase">available for chips</strong>{" "}
              only
            </span>
          </p>
        </CardBody>
      </Card>
      {inProcess ? (
        <div className="border rounded-md my-5 py-3 text-center">
          <h1 className="text-white">Processing data, please wait...</h1>
          <LoadingBar color="dark" />
          <h1 className="text-white">
            Contacting Payment Provider. <br /> Please wait...
          </h1>
        </div>
      ) : (
        <div>
          <FormGroup>
            <Label>Select Amount</Label>

            <Row className="">
              {amounts
                .filter((item) => isAmountInCap(item))
                .map((item, i) => {
                  const isSelected = selectedAmount === item;
                  return (
                    <Col
                      lg={2}
                      md={3}
                      xs={4}
                      className="my-2 pointer hover-gold"
                      key={i}
                    >
                      <Card
                        className={`bg-dark rounded-md d-flex flex-row align-items-center justify-content-center p-2 ${
                          isSelected && "border border-gold"
                        }`}
                        onClick={() => changeAmount(item)}
                      >
                        <img alt="..." src={chipsLogo} className="mr-2 w-25" />
                        <div className="text-white h2 mb-0">{item}</div>
                      </Card>
                    </Col>
                  );
                })}
            </Row>
            <Input
              type="text"
              className="bg-dark rounded-md mt-2 text-white text-xl text-center"
              value={selectedAmount}
              onChange={(e) => checkIfNumericOrInCap(e.target.value)}
              invalid={!isAmountValid || isAmountCap}
            />
            {!isAmountValid && (
              <FormFeedback>Oh noes! invalid amount</FormFeedback>
            )}
            {isAmountCap && (
              <FormFeedback>
                Please put amount less than {balanceChips}
              </FormFeedback>
            )}
          </FormGroup>

          {selectedAmount && (
            <>
              <Form>
                <div className="mb-2">Choose Payment Information</div>
                <Nav
                  className="nav-fill flex-column flex-md-row mb-3"
                  id="tabs-icons-text"
                  pills
                  role="tablist"
                >
                  <NavItem>
                    <NavLink
                      aria-selected={tabs === 1}
                      className={classnames("mb-sm-3 mb-md-0", {
                        active: tabs === 1,
                      })}
                      onClick={(e) => toggleNavs(e, "tabs", 1)}
                      href="#pablo"
                      role="tab"
                    >
                      <i className="ni ni-tablet-button mr-2" />
                      New Account
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      aria-selected={tabs === 2}
                      className={classnames("mb-sm-3 mb-md-0", {
                        active: tabs === 2,
                      })}
                      onClick={(e) => toggleNavs(e, "tabs", 2)}
                      href="#pablo"
                      role="tab"
                    >
                      <i className="ni ni-cloud-download-95 mr-2" />
                      Saved Accounts
                    </NavLink>
                  </NavItem>
                </Nav>
                <TabContent activeTab={"tabs" + tabs}>
                  <TabPane tabId="tabs1">
                    {tabs === 1 && (
                      <NewAccountOptions onChange={handleNewAccountMOP} />
                    )}
                  </TabPane>

                  <TabPane tabId="tabs2">
                    <SavedAccountOptions
                      onChange={handleSelectExistingMOP}
                      playerOptions={playerMOPs}
                    />
                  </TabPane>
                </TabContent>

                {(selectedSavedMOP ||
                  (mopAccount.accountNumber && mopAccount.accountName)) && (
                  <FormGroup>
                    <Label for="pin">Withdrawal PIN</Label>
                    <Input
                      id="pin"
                      name="pin"
                      placeholder="Your wallet PIN"
                      maxLength={4}
                      minLength={4}
                      required
                      value={withdrawalPin}
                      onChange={(e) => setWithdrawalPin(e.target.value)}
                    />
                    <FormText>
                      <WithdrawalPINModal
                        content={
                          <span>
                            Change / Set PIN <u>here</u>
                          </span>
                        }
                      />
                    </FormText>
                  </FormGroup>
                )}

                <Button
                  disabled={!isAmountValid || !selectedAmount}
                  block
                  type="submit"
                  color="primary"
                  className="my-3"
                  onClick={handleWithdraw}
                >
                  Withdraw Now
                </Button>
              </Form>
            </>
          )}
        </div>
      )}
      <div className="text-white mt-3">
        We understand that quick and hassle-free withdrawals are important to
        our players, which is why we strive to process all withdrawal requests
        as quickly as possible to ensure that our players receive their winnings
        promptly and efficiently.
      </div>
    </section>
  );
};

export default WithdrawAmount;
