import { useState } from "react";

import {
  Button,
  FormGroup,
  Form,
  Label,
  FormText,
  Modal,
  ModalHeader,
  ModalBody,
  Input,
} from "reactstrap";

import Swal from "sweetalert2";
import { useSelector } from "react-redux";
import { commas, isNumeric } from "views/shared/utils";

import { patchPIN, resetPIN } from "api/p2p";

const alertDialog = (title, confirmLabel, type) => {
  Swal.fire({
    confirmButtonText: confirmLabel,
    title: title,
    icon: type,
    color: "#fff",
    confirmButtonColor: "rgb(157 134 0)",
    background:
      "radial-gradient(79.09% 79.09% at 50% 14.18%, #4e4d4c 0%, #060606 100%)",
  });
};

const WithdrawalPIN = (props) => {
  const userWithdrawalPin = useSelector((state) =>
    commas(state.entities.user.withdrawalPin)
  );

  const [withdrawalPin, setWithdrawalPin] = useState();
  const [confirmWithdrawalPin, setConfirmWithdrawalPin] = useState();
  const [isPINMatched, setIsPinMatched] = useState(true);
  const [userPassword, setUserPassword] = useState();

  const [isSetPinModalOn, setPINModal] = useState(false);
  const [isChangePinModalOn, setIsChangePinModalOn] = useState(false);
  const [isPINNumeric, setIsPinNumeric] = useState(true);
  const toggleSetPIN = () => setPINModal(!isSetPinModalOn);
  const toggleChangePIN = () => setIsChangePinModalOn(!isChangePinModalOn);

  const handleSetPIN = (e) => {
    e.preventDefault();

    if (!isNumeric(withdrawalPin)) {
      setIsPinNumeric(false);
      return;
    } else {
      setIsPinNumeric(true);
    }

    if (withdrawalPin !== confirmWithdrawalPin) {
      setIsPinMatched(false);
      return;
    }

    setIsPinMatched(true);

    patchPIN(withdrawalPin).then((result) =>
      result
        ? alertDialog(
            "Success! Your PIN has been successfully set up.",
            "Close",
            "success"
          )
        : alertDialog("Error! PIN setup unsuccessful.", "Close", "error")
    );

    setPINModal(false);
  };

  const handleChangePIN = (e) => {
    e.preventDefault();

    if (!isNumeric(withdrawalPin)) {
      setIsPinNumeric(false);
      return;
    } else {
      setIsPinNumeric(true);
    }

    if (withdrawalPin !== confirmWithdrawalPin) {
      setIsPinMatched(false);
      return;
    }

    setIsPinMatched(true);

    resetPIN(userPassword, withdrawalPin).then((result) => {
      if (result.status === 200) {
        alertDialog(
          "Success! Your PIN has been successfully changed.",
          "Close",
          "success"
        );
      } else if (result.status === 401) {
        alertDialog(
          "We're sorry, but the password you entered is incorrect. Please double-check your password and try again.",
          "Close",
          "error"
        );
      } else {
        alertDialog(
          "Sorry, we were unable to change your PIN. Please try again later or contact customer support for assistance.",
          "Close",
          "error"
        );
      }
    });
    setIsChangePinModalOn(false);
  };

  return (
    <div>
      <div onClick={!userWithdrawalPin ? toggleSetPIN : toggleChangePIN}>
        {props.content}
      </div>

      <Modal isOpen={isSetPinModalOn} toggle={toggleSetPIN} centered>
        <ModalHeader toggle={toggleSetPIN}>Set PIN</ModalHeader>
        <ModalBody>
          <Form onSubmit={handleSetPIN}>
            <FormGroup>
              <Label for="accoutName">PIN</Label>
              <Input
                id="accoutName"
                name="accoutName"
                placeholder="E.G 1234"
                type="text"
                maxLength={4}
                minLength={4}
                required
                onChange={(e) => setWithdrawalPin(e.target.value)}
              />
            </FormGroup>
            <FormGroup>
              <Label for="exampleAddress">Confirm PIN</Label>
              <Input
                id="exampleAddress"
                name="address"
                maxLength={4}
                minLength={4}
                required
                onChange={(e) => setConfirmWithdrawalPin(e.target.value)}
              />
              {!isPINMatched && (
                <FormText color="danger">
                  Oops! The PIN you entered does not match the confirmation PIN.
                  Please try again.
                </FormText>
              )}
              {!isPINNumeric && (
                <FormText color="danger">
                  Oops! Your PIN must be a numeric value. Please enter a valid
                  PIN and try again.
                </FormText>
              )}
            </FormGroup>
            <Button color="primary" type="submit" className="ml-auto">
              Submit
            </Button>
          </Form>
        </ModalBody>
      </Modal>

      <Modal isOpen={isChangePinModalOn} toggle={toggleChangePIN} centered>
        <ModalHeader toggle={toggleChangePIN}>Change PIN</ModalHeader>
        <ModalBody>
          <Form onSubmit={handleChangePIN}>
            <FormGroup>
              <Label for="password">Password</Label>
              <Input
                id="password"
                name="password"
                placeholder="Your password"
                type="password"
                required
                onChange={(e) => setUserPassword(e.target.value)}
              />
            </FormGroup>
            <FormGroup>
              <Label for="accoutName">PIN</Label>
              <Input
                id="accoutName"
                name="accoutName"
                placeholder="E.G 1234"
                type="text"
                maxLength={4}
                minLength={4}
                required
                onChange={(e) => setWithdrawalPin(e.target.value)}
              />
            </FormGroup>
            <FormGroup>
              <Label for="exampleAddress">Confirm PIN</Label>
              <Input
                id="exampleAddress"
                name="address"
                maxLength={4}
                minLength={4}
                required
                onChange={(e) => setConfirmWithdrawalPin(e.target.value)}
              />
              {!isPINMatched && (
                <FormText color="danger">
                  Oops! The PIN you entered does not match the confirmation PIN.
                  Please try again.
                </FormText>
              )}
              {!isPINNumeric && (
                <FormText color="danger">
                  Oops! Your PIN must be a numeric value. Please enter a valid
                  PIN and try again.
                </FormText>
              )}
            </FormGroup>
            <Button color="primary" type="submit" className="ml-auto">
              Submit
            </Button>
          </Form>
        </ModalBody>
      </Modal>
    </div>
  );
};

export default WithdrawalPIN;
