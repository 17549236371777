import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import { Card, CardHeader, Row, Container } from "reactstrap";

import DataTable from "react-data-table-component";
import DataTableExtensions from "react-data-table-component-extensions";

import { columns } from "../data";

// Spinners
import LoadingBar from "components/LoadBars";
import Filter from "components/Filters";
import Header from "components/Headers/Header.js";
import BreadcrumbCustom from "components/BreadcrumbCustom";
import { todaysDefaultFilterExecutedBy } from "components/Filters/default";

import { useSelector } from "react-redux";
import { getTransactions } from "api/Transactions";

// Utilities
import usePermission from "hooks/usePermission";

const CoinsTransactions = () => {
  const currentUsername = useSelector((state) => state.entities.user.username);
  const { isRoleAgent } = usePermission();
  const { username } = useParams();

  const [data, setData] = useState([]);
  const [sync, setSync] = useState(false);
  const [loading, setLoading] = useState(true);
  const [filter, setFilter] = useState(
    todaysDefaultFilterExecutedBy(currentUsername)
  );

  const toggleSync = () => {
    setSync(!sync);
    setLoading(true);
  };

  useEffect(() => {
    getTransactions(filter, "COINS").then((response) => {
      setData(response);
      setLoading(false);
    });
    // eslint-disable-next-line
  }, [sync]);

  const tableData = {
    columns,
    data,
  };

  return (
    <>
      <Header />

      <Container className="mt--7" fluid>
        <Row>
          <div className="col">
            <Card className="shadow">
              <BreadcrumbCustom
                username={username}
                uri={"/portal/history/coins/"}
                downlinesURI={"/portal/history/coins"}
              />
              <CardHeader className="border-0">
                <h2 className="mb-0">Coins Transactions History</h2>
                <Filter
                  filter={setFilter}
                  sync={toggleSync}
                  searchToggle={loading}
                  executedBy={!isRoleAgent}
                  executedByDefault={currentUsername}
                />
              </CardHeader>
              <DataTableExtensions export={false} print={false} {...tableData}>
                <DataTable
                  title={"Coins History (" + data.length + ")"}
                  columns={columns}
                  data={data}
                  defaultSortField="id"
                  defaultSortAsc={true}
                  pagination
                  highlightOnHover
                  striped
                  progressComponent={<LoadingBar />}
                  progressPending={loading}
                />
              </DataTableExtensions>
            </Card>
          </div>
        </Row>
      </Container>
    </>
  );
};

export default CoinsTransactions;
