import { commas } from "views/shared/utils";

import EditForm from "components/Modals/edit";
import { Button, Row, Col } from "reactstrap";

import chipsImage from "assets/img/chips-icon.png";
import coinsImage from "assets/img/coins-icon.png";

import { Link } from "react-router-dom";

const pushLocationTo = (e, history, location) => {
  e.preventDefault();

  history.push(location);
  window.location.reload(false);
};

export const columns = (history) => [
  {
    name: "Action",
    width: "130px",
    cell: (row) => <EditForm row={row}></EditForm>,
  },
  {
    name: "Links",
    selector: (row) => row.username,
    sortable: true,
    width: "120px",
    cell: (row) => (
      <Row>
        {row.role === "PLAYER" ? (
          <Col md={12} className="mb-2">
            <Link
              onClick={(e) =>
                pushLocationTo(
                  e,
                  history,
                  "/portal/history/bet/" + row.username
                )
              }
              to="#"
            >
              <Button size="sm" color="primary" outline className="w-100">
                Bet-History
              </Button>
            </Link>
          </Col>
        ) : null}
        {row.role !== "PLAYER" ? (
          <Col md={12}>
            <Link
              onClick={(e) =>
                pushLocationTo(e, history, "/portal/downlines/" + row.username)
              }
              to="#"
            >
              <Button
                size="sm"
                color="primary"
                outline
                className="w-100 text-uppercase text-nowrap"
              >
                Downlines
              </Button>
            </Link>
          </Col>
        ) : null}
      </Row>
    ),
  },
  {
    name: "Username",
    selector: (row) => row.username,
    sortable: true,
    cell: (row) => <b>{row.username}</b>,
  },
  {
    name: "Name",
    sortable: true,
    selector: (row) => row.name,
  },
  {
    name: "Chips",
    sortable: true,
    cell: (row) => (
      <>
        <img src={chipsImage} alt="" width={20} />
        <span className="ml-2">{commas(row.balanceChips)}</span>
      </>
    ),
  },
  {
    name: "Coins",
    sortable: true,
    cell: (row) => (
      <>
        <img src={coinsImage} alt="" width={20} />
        <span className="ml-2">{commas(row.balanceCoin)}</span>
      </>
    ),
  },
  {
    name: "Mobile Number",
    sortable: true,
    selector: (row) => row.mobileNumber,
  },
  {
    name: "Email Address",
    sortable: true,
    selector: (row) => row.email,
  },
  {
    name: "Date Registered",
    sortable: true,
    selector: (row) => row.dateRegistered,
  },
];
