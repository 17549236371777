import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";

import { Card, CardHeader, Row, Container } from "reactstrap";
import DataTable from "react-data-table-component";
import DataTableExtensions from "react-data-table-component-extensions";

import { columns } from "./data";

// Components
import LoadingBar from "components/LoadBars";
import Filter from "components/Filters";
import Header from "components/Headers/Header.js";
import BreadcrumbItemCustom from "components/BreadcrumbCustom";
import { todaysDefaultFilter } from "components/Filters/default";

import { useSelector } from "react-redux";
import { getStatements } from "api";

const StatementDetails = () => {
  const { username } = useParams();
  const currentUsername = useSelector((state) => state.entities.user.username);

  const [data, setData] = useState([]);
  const [sync, setSync] = useState(true);
  const [loading, setLoading] = useState(true);
  const [filter, setFilter] = useState(
    todaysDefaultFilter(username ? username : currentUsername)
  );

  const toggleSync = () => {
    setSync(!sync);
    setLoading(true);
  };

  // Table Handling
  useEffect(() => {
    getStatements(filter).then((response) => {
      setLoading(false);
      setData(response);
    });
    // eslint-disable-next-line
  }, [sync]);

  return (
    <>
      <Header />

      <Container className="mt--7" fluid>
        <Row>
          <div className="col">
            <Card className="shadow">
              <BreadcrumbItemCustom
                username={username}
                uri={"/portal/statement-details/"}
                downlinesURI={"/portal/reports/statement-details"}
              />
              <CardHeader className="border-0">
                <h2 className="mb-0">
                  Statement Details History {username ? `(${username})` : null}
                </h2>
                <Filter
                  filter={setFilter}
                  username={filter.username}
                  sync={toggleSync}
                  searchToggle={loading}
                  creditEnabled
                />
              </CardHeader>

              <DataTableExtensions
                export={false}
                print={false}
                columns={columns}
                data={data}
              >
                <DataTable
                  columns={columns}
                  data={data}
                  pagination
                  striped
                  progressComponent={<LoadingBar />}
                  progressPending={loading}
                />
              </DataTableExtensions>
            </Card>
          </div>
        </Row>
      </Container>
    </>
  );
};

export default StatementDetails;
